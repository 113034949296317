import React, { ReactNode, useContext, useMemo } from "react";
import { useStat } from "functions";

import "../common/index.scss";
import { ReportContext } from "../../../context";
import { dayjs } from "utils/dayjs";
import { settingType } from "./settingSchema";
import { PropertyType } from "schemaComponents";
import { Loading } from "components/Loading";
import { Alert } from "react-bootstrap";
import { getGraphConfig } from "./graphConfig";
import {
  TableSchema,
  convertDataFrame,
  GraphSchema,
  getRequiredFields,
  defaultDigits,
} from "./statSchemaUtil";
import * as statConfigs from "./statConfig";
import {
  Column,
  ColumnAggregatedDataFrame,
  DataFrame,
  Row,
  RowAggregatedDataFrame,
} from "functions/dataFrame";
import { useShopList, useClientDoc, useIndustryDoc } from "models/hook";
import classNames from "classnames";
import { resolveStringIntl, useStringIntl } from "hooks/intl";
import { formatWithCommas, isNumericString } from "utils/string";
import { getNumberDigits } from "utils/number";

const getMonthString = (target: string | undefined, offset: number) => {
  return (target ? dayjs(target, "YYYYMM") : dayjs(target))
    .add(offset, "month")
    .format("YYYYMM");
};

const alignArray = <T,>(array: T, reverse?: boolean): T => {
  if (reverse && array && Array.isArray(array)) {
    return array.slice(0).reverse() as T;
  } else {
    return array;
  }
};

function TableToolComponent({ data }: { data?: settingType }) {
  const reportContext = useContext(ReportContext);
  const shopId = reportContext?.shopId;
  const givenMonth = reportContext?.month || getMonthString(undefined, -1);
  const questions = reportContext.questions;
  const clientId = reportContext.clientId;
  const condition = reportContext.condition;
  const dualColumns = reportContext.dualColumns;
  const prevMonth = getMonthString(givenMonth, -1);
  const statParams = reportContext.statParams;
  const { transpose, timeSeries, month, scaleMin, scaleMax } = data || {};
  const { statSchemas, action, columns, sequence } = (data?.statType &&
    statConfigs[data?.statType]({ isClientData: !shopId })) || {
    statSchemas: [] as TableSchema[],
  };

  const showTable = !dualColumns || data?.displayType !== "graph";
  const showGraph = !dualColumns || data?.displayType === "graph";

  const { list: shopList } = useShopList({ clientId });

  const monthSpan = month || 6;
  const currentMonth = useMemo(
    () =>
      timeSeries
        ? Array(monthSpan)
            .fill(null)
            .map((_, index) => {
              return dayjs(givenMonth, "YYYYMM")
                .add(-monthSpan + 1 + index, "month")
                .format("YYYYMM");
            })
        : givenMonth,
    [timeSeries, monthSpan, givenMonth]
  );

  const statFields = getRequiredFields(statSchemas);

  const params =
    data && action && statParams
      ? {
          action,
          columns: columns || data.fields,
          column: data.field,
          unique: data.unique,
          shopId,
          condition,
          ...statParams,
        }
      : undefined;

  const requestParams = (() => {
    if (!params) {
      return undefined;
    }
    if (Array.isArray(currentMonth)) {
      // 本来、前月分のstatFieldを適用しないといけない
      // 現状では前月比較 + 時系列は、正常に処理できない（禁止していないので注意）
      const prevMonth = getMonthString(currentMonth[0], -1);
      return [
        {
          ...params,
          month: prevMonth,
          label: prevMonth,
          stats: statFields.current,
        },
        ...currentMonth.map((month) => {
          return {
            ...params,
            month,
            label: month,
            stats: statFields.current,
          };
        }),
      ];
    } else {
      return [
        {
          ...params,
          label: currentMonth,
          month: currentMonth,
          stats: statFields.current,
        },
        {
          ...params,
          label: prevMonth,
          month: prevMonth,
          stats: statFields.prev,
        },
      ].filter((requestParam) => requestParam.stats.length > 0);
    }
  })();

  const statResult = useStat(
    requestParams,
    (dataFrames) => {
      const dataFrameMap = Object.fromEntries(
        dataFrames.map((dataFrame) => [dataFrame.label, dataFrame])
      ) as Record<string, DataFrame<number | null>>;
      const statMap = Object.fromEntries(
        statSchemas.map((statSchema) => [
          statSchema.id || statSchema.value,
          statSchema.title,
        ])
      );

      if (!Array.isArray(currentMonth)) {
        // 前月
        const month = currentMonth;
        const current = dataFrameMap[month];
        const prev = dataFrameMap[getMonthString(month, -1)];
        const dataFrame = convertDataFrame(current, prev, statSchemas);
        return {
          dataFrame,
          statMap,
          currentMonth,
        };
      }

      const statDataFrames = currentMonth
        .map((month) => {
          const current = dataFrameMap[month];
          const prev = dataFrameMap[getMonthString(month, -1)];
          return convertDataFrame(current, prev, statSchemas);
        })
        .filter((item) => item) as DataFrame<ReactNode>[];

      if (!transpose) {
        const dataFrame = new RowAggregatedDataFrame(
          statDataFrames
            .map((dataFrame) => {
              return dataFrame.row(dataFrame.rows[0], dataFrame.label);
            })
            .filter((item) => item) as Row<ReactNode>[]
        );
        return { dataFrame, statMap, currentMonth };
      } else {
        const dataFrame = new ColumnAggregatedDataFrame(
          statDataFrames
            .map((dataFrame) => {
              return dataFrame.column(dataFrame.columns[0], dataFrame.label);
            })
            .filter((item) => item) as Column<ReactNode>[]
        );
        return { dataFrame, statMap, currentMonth };
      }
    },
    [transpose, currentMonth]
  );
  // console.log("statResult", statResult);

  const properties = useMemo(() => {
    return [
      ...(questions?.properties || []),
      {
        propertyName: "lottery",
        title: "抽選結果",
        schema: {
          schemaType: "selector",
          readOnly: true,
          options: [
            { title: "当選", value: 1 },
            { title: "落選", value: 2 },
            { title: "重複", value: 3 },
          ],
        },
      },
      {
        propertyName: "count",
        title: "回答数",
        schema: {
          schemaType: "number",
        },
      },
    ] as PropertyType[];
  }, [questions]);

  const requestCurrentMonth = statResult.data?.currentMonth;

  const propertyMap = useMemo(() => {
    return Object.fromEntries(
      properties.map((property) => {
        return [property.propertyName, property];
      })
    ) as Record<string, PropertyType>;
  }, [properties]);

  const rowKeys = useMemo(() => {
    if (action === "dist") {
      return alignArray(
        Array.from(
          (function* () {
            for (const fieldName of data?.fields || []) {
              const schema = propertyMap[fieldName]?.schema;
              if (schema?.schemaType === "numberSelector") {
                for (let i = schema.minValue; i <= schema.maxValue; ++i) {
                  yield `${fieldName}-${i}`;
                }
                if (schema.naTitle) {
                  yield `${fieldName}-${schema.naValue}`;
                }
              } else if (
                schema?.schemaType === "selector" ||
                schema?.schemaType === "multipleSelector"
              ) {
                const ignoreValues = new Set(
                  ("ignoreValues" in schema && schema.ignoreValues) || [
                    99,
                    999,
                    "99",
                    "999",
                  ]
                );
                const naValues = new Set(schema.naValues || ["997", 997]);
                // const naTitle = schema.options.find((option) =>
                //   ignoreValues.has(option.value)
                // )?.title;
                // const ignoreTitle = schema.options.find((option) =>
                //   naValues.has(option.value)
                // )?.title;
                for (const option of schema.options) {
                  if (
                    !(
                      naValues.has(option.value) ||
                      ignoreValues.has(option.value)
                    ) ||
                    statResult.data?.dataFrame?.rows?.includes(
                      `${fieldName}-${option.value}`
                    )
                  )
                    yield `${fieldName}-${option.value}`;
                }
                yield `${fieldName}-empty`;
                yield `${fieldName}-na`;
              }
            }
          })()
        ),
        data?.reverse
      );
    } else {
      return alignArray(statResult.data?.dataFrame?.rows || [], data?.reverse);
    }
  }, [columns, propertyMap, action, data?.reverse, statResult]);

  const s = useStringIntl();

  const fieldMap = useMemo(() => {
    if (action === "rank") {
      return Object.fromEntries(
        shopList?.map((shop) => [
          shop.shopId,
          resolveStringIntl(shop.shopName),
        ]) || []
      );
    }
    return Object.fromEntries(
      (function* () {
        for (const property of properties || []) {
          const schema = property.schema;
          yield [
            property.propertyName,
            (property as { statShortName?: string }).statShortName ||
              resolveStringIntl(property.title)?.slice(0, 10),
          ];
          if (schema.schemaType === "numberSelector") {
            for (let i = schema.minValue; i <= schema.maxValue; ++i) {
              yield [`${property.propertyName}-${i}`, i];
            }
            yield [
              `${property.propertyName}-${schema.naValue}`,
              resolveStringIntl(schema.naTitle) || "未回答",
            ];
          } else if ("options" in schema) {
            for (const option of schema.options) {
              yield [
                `${property.propertyName}-${option.value}`,
                resolveStringIntl(option.title),
              ];
            }
            const defaultIgnoreValue = [99, 999, "99", "999"];
            const ignoreValues = new Set(
              (() => {
                if ("ignoreValues" in schema) {
                  return schema.ignoreValues || [];
                }
                if (schema.schemaType === "multipleSelector") {
                  return defaultIgnoreValue;
                }
                return [];
              })()
            );
            const naValues = new Set(schema.naValues || []);
            const ignoreTitle = resolveStringIntl(
              schema.options.find((option) => ignoreValues.has(option.value))
                ?.title
            );
            const naTitle = resolveStringIntl(
              schema.options.find((option) => naValues.has(option.value))?.title
            );
            if (ignoreTitle)
              yield [`${property.propertyName}-empty`, ignoreTitle];
            if (naTitle) yield [`${property.propertyName}-na`, naTitle];
          }
        }
        if (Array.isArray(requestCurrentMonth)) {
          for (const month of requestCurrentMonth) {
            yield [month, dayjs(month, "YYYYMM").format("YYYY-MM")];
          }
        }
      })()
    ) as Record<string, string>;
  }, [action, action === "rank" ? shopList : properties, requestCurrentMonth]);

  const validValueShop = useMemo(() => {
    const valid = data?.fields
      ?.map((fieldName) => {
        const rowKey = `${fieldName}-valid`;
        const columnKey = "shop-count-table";
        return statResult.data?.dataFrame?.pickValue(rowKey, columnKey);
      })
      .filter((item) => item != null)[0]; // 1件目のみを取得
    const validValue = React.Children.map(valid, (child) => {
      if (React.isValidElement(child)) {
        return child.props.children;
      }
      return child?.toString();
    })?.[0] as string;
    console.log("validValue (shop)", validValue);

    const all = data?.fields
      ?.map((fieldName) => {
        const rowKey = `${fieldName}-all`;
        const columnKey = "shop-count-table";
        return statResult.data?.dataFrame?.pickValue(rowKey, columnKey);
      })
      .filter((item) => item != null)[0]; // 1件目のみを取得
    const allValue = React.Children.map(all, (child) => {
      if (React.isValidElement(child)) {
        return child.props.children;
      }
      return child?.toString();
    })?.[0] as string;
    console.log("allValue (shop)", allValue);
    return validValue && allValue ? `${validValue} / ${allValue}` : undefined;
  }, [data?.fields, statResult]);
  const validValueClient = useMemo(() => {
    const valid = data?.fields
      ?.map((fieldName) => {
        const rowKey = `${fieldName}-valid`;
        const columnKey = "client-count-table";
        return statResult.data?.dataFrame?.pickValue(rowKey, columnKey);
      })
      .filter((item) => item != null)[0]; // 1件目のみを取得
    const validValue = React.Children.map(valid, (child) => {
      if (React.isValidElement(child)) {
        return child.props.children;
      }
      return child?.toString();
    })?.[0] as string;
    console.log("validValue (client)", validValue);

    const all = data?.fields
      ?.map((fieldName) => {
        const rowKey = `${fieldName}-all`;
        const columnKey = "client-count-table";
        return statResult.data?.dataFrame?.pickValue(rowKey, columnKey);
      })
      .filter((item) => item != null)[0]; // 1件目のみを取得
    const allValue = React.Children.map(all, (child) => {
      if (React.isValidElement(child)) {
        return child.props.children;
      }
      return child?.toString();
    })?.[0] as string;
    console.log("allValue (client)", allValue);
    return validValue && allValue ? `${validValue} / ${allValue}` : undefined;
  }, [data?.fields, statResult]);

  // 表示する際のスキーマ
  const columnSchemas =
    Array.isArray(requestCurrentMonth) && transpose
      ? requestCurrentMonth.map((month) => {
          return {
            value: month,
            title: dayjs(month, "YYYYMM").format("YYYY-MM"),
            graph: true,
          } as GraphSchema;
        })
      : statSchemas;
  const columnSchemaForGraph = columnSchemas.filter(
    (schema) => schema.graph
  ) as GraphSchema[];
  const columnSchemaForTable = columnSchemas.filter(
    (schema) => schema.table !== false
  );

  const {
    GraphComponent,
    datasetOption,
    graphOption: _graphOption,
  } = getGraphConfig({
    currentMonth: requestCurrentMonth,
    settings: data,
    propertyMap,
  });

  const graphData = (() => {
    const dataFrame = statResult.data?.dataFrame;
    if (!dataFrame) {
      return undefined;
    }
    if (!columnSchemaForGraph.length) {
      return undefined;
    }
    const rows = rowKeys
      .filter((rowName) => fieldMap[rowName] != null)
      .map((rowName) => {
        return {
          value: rowName,
          title: fieldMap[rowName],
        };
      });
    const columns = columnSchemaForGraph.map((schema) => {
      return {
        value: schema.id || schema.value,
        title: schema.title,
        graphColor: schema.graphColor,
      };
    });

    if (transpose) {
      return {
        labels: columns.map((columnItem) => columnItem.title),
        datasets: rows.map((rowItem, rowIndex) => {
          return {
            label: rowItem.title as string,
            data: columns
              .map((columnItem) => {
                return dataFrame.pickValue(rowItem.value, columnItem.value);
              })
              .map((v) => (v == null ? NaN : v)),
            ...datasetOption(rowIndex),
          };
        }),
      };
    } else {
      return {
        labels: rows.map((rowItem) => rowItem.title),
        datasets: columns.map((columnItem, columnIndex) => {
          return {
            label: columnItem.title as string,
            data: rows
              .map((rowItem) => {
                return dataFrame.pickValue(rowItem.value, columnItem.value);
              })
              .map((v) => (v == null ? NaN : v)),
            ...datasetOption(columnIndex),
            // statConfigで指定された色を優先する
            backgroundColor: columnItem.graphColor
              ? datasetOption(columnIndex, [columnItem.graphColor])
                  .backgroundColor
              : undefined,
            borderColor: columnItem.graphColor
              ? datasetOption(columnIndex, [columnItem.graphColor]).borderColor
              : undefined,
          };
        }),
      };
    }
  })();

  const graphDataMin = useMemo(() => {
    if (!graphData) return undefined;
    return Math.min(
      ...graphData.datasets.map((dataset) => {
        const numericData = dataset.data.map((value) =>
          parseFloat(value as string)
        );
        return Math.min(...numericData);
      })
    );
  }, [graphData]);
  const graphDataMax = useMemo(() => {
    if (!graphData) return undefined;
    return Math.max(
      ...graphData.datasets.map((dataset) => {
        const numericData = dataset.data.map((value) =>
          parseFloat(value as string)
        );
        return Math.max(...numericData);
      })
    );
  }, [graphData]);
  const parsedScaleMin = useMemo(() => {
    if (!scaleMin) return undefined;
    const parsedValue = parseFloat(scaleMin);
    // graphDataの最小値を下回る場合はautoにする
    if (graphDataMin && parsedValue > graphDataMin) return undefined;
    return parsedValue;
  }, [scaleMin, graphData]);
  const parsedScaleMax = useMemo(() => {
    if (!scaleMax) return undefined;
    const parsedValue = parseFloat(scaleMax);
    if (isNaN(parsedValue)) return undefined;
    // graphDataの最大値を超える場合はautoにする
    if (graphDataMax && parsedValue < graphDataMax) return undefined;
    return parsedValue;
  }, [scaleMax, graphData]);
  const graphOptions = useMemo(
    () => ({
      aspectRatio: 640 / 400,
      maintainAspectRatio: true,
      ..._graphOption?.({
        settings: data,
        propertyMap,
        graphParams: {
          parsedScaleMax,
          parsedScaleMin,
          graphDataMax,
          graphDataMin,
        },
      }),
    }),
    [
      _graphOption,
      parsedScaleMin,
      parsedScaleMax,
      graphDataMin,
      graphDataMax,
      action,
      transpose,
    ]
  );

  return (
    <>
      <div className="statComponent">
        {showTable && (
          <div className={classNames("statBlock", dualColumns && "statDual")}>
            {statResult.loading && <Loading></Loading>}
            {statResult.error && (
              <Alert variant="danger">
                <div>{statResult.error.message}</div>
              </Alert>
            )}
            {statResult.data?.dataFrame && (
              <>
                <table className="statTable">
                  <thead>
                    <tr>
                      {sequence && <th>社内順位</th>}
                      <th style={{ minWidth: "80px" }}></th>
                      {columnSchemaForTable.map((statSchema, statIndex) => (
                        <th
                          key={statIndex}
                          style={{
                            whiteSpace: "pre",
                            textAlign: "center",
                            ...(statSchema.margeRight && {
                              borderRightColor: "transparent",
                            }),
                          }}
                        >
                          {statSchema.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rowKeys
                      .filter((rowName) => fieldMap[rowName] != null)
                      .map((rowName, rowIndex) => (
                        <tr key={rowName}>
                          {/* thがindexの場合は右揃え */}
                          {sequence && (
                            <th style={{ textAlign: "right" }}>
                              {rowIndex + 1}
                            </th>
                          )}
                          <th
                            style={{ minWidth: "80px", whiteSpace: "nowrap" }}
                          >
                            {fieldMap[rowName]}
                          </th>
                          {columnSchemaForTable.map((statSchema, statIndex) => {
                            const element =
                              statResult.data?.dataFrame?.pickValue(
                                rowName,
                                statSchema.id || statSchema.value
                              );
                            if (
                              element &&
                              typeof element === "object" &&
                              "type" in element &&
                              element.type === "td"
                            ) {
                              return React.cloneElement(element, {
                                key: statIndex,
                              });
                            }
                            const newElement = isNumericString(
                              element as string
                            )
                              ? formatWithCommas(
                                  element as string,
                                  defaultDigits
                                )
                              : element;
                            return (
                              <td
                                key={statIndex}
                                style={{
                                  whiteSpace: "nowrap",
                                  textAlign: "right", // 右揃え固定
                                  ...(statSchema.margeRight && {
                                    borderRightColor: "transparent",
                                  }),
                                }}
                              >
                                {newElement}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    {validValueClient || validValueShop ? (
                      <tr>
                        <th className="sm">対象者数 / 回答者数</th>
                        {columnSchemaForTable.map((statSchema, statIndex) => {
                          const columnKey = statSchema.id || statSchema.value;
                          if (columnKey === "client-count-table") {
                            return (
                              <td
                                key={statIndex}
                                className="sm text-right"
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#f4f4f4",
                                  ...(statSchema.margeRight && {
                                    borderRightColor: "transparent",
                                  }),
                                }}
                              >
                                {validValueClient}
                              </td>
                            );
                          }
                          if (columnKey === "shop-count-table") {
                            return (
                              <td
                                key={statIndex}
                                className="sm text-right"
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#f4f4f4",
                                  ...(statSchema.margeRight && {
                                    borderRightColor: "transparent",
                                  }),
                                }}
                              >
                                {validValueShop}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={statIndex}
                              className="sm text-right"
                              style={{
                                whiteSpace: "nowrap",
                                backgroundColor: "#f4f4f4",
                                ...(statSchema.margeRight && {
                                  borderRightColor: "transparent",
                                }),
                              }}
                            ></td>
                          );
                        })}
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        )}
        {showGraph && graphData && (
          <div className={classNames("statBlock", dualColumns && "statDual")}>
            <GraphComponent
              height={"400px"}
              width={"640px"}
              data={graphData}
              options={graphOptions}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default TableToolComponent;

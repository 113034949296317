import React from "react";
import {
  SchemaComponent,
  useField,
  requiredValidator,
  BaseSchema,
} from "react-hook-schema-form";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import { Image, MediaSpecifier } from "./media";
import { Upload } from "./media/upload";

interface ImageSchema extends BaseSchema {
  schemaType: "image";
  required?: boolean;
  rootPath?: string;
  maxHeight?: number | string;
}

const ImageSchemaComponent: SchemaComponent<ImageSchema> = (props) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  const { schema } = props;
  const { rootPath, maxHeight } = schema;
  return (
    <>
      <FieldWrapper fieldState={fieldState}>
        <Upload
          {...registerProps}
          value={value}
          rootPath={rootPath}
          hasError={fieldState.invalid}
          accept="image/*"
        />
      </FieldWrapper>
      <Image style={{ border: "1px solid #ccc", maxHeight }} src={value} />
    </>
  );
};

ImageSchemaComponent.display = DisplayComponent(
  "ImageDisplayComponent",
  (value: MediaSpecifier, schema: ImageSchema) => {
    const { maxHeight } = schema;
    return <Image style={{ backgroundColor: "#fff", maxHeight }} src={value} />;
  }
);

export default ImageSchemaComponent;

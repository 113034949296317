export const valueColorTable: Record<string, string> = {
  "4": "#548235",
  "3": "#A9D08E",
  "2": "#C6E0B4",
  "1": "#E2EFDA",
  "0": "#FFFFFF",
  "-1": "#FCE4D6",
  "-2": "#F8CBAD",
  "-3": "#F4B084",
  "-4": "#C65911",
};

export const getValueColor = (
  value: number | null,
  standard: number,
  step: number
) => {
  const category = value != null ? Math.round((value - standard) / step) : 0;
  const boundedCategory = Math.max(Math.min(category, 4), -4);
  const color = valueColorTable[`${boundedCategory}`] || valueColorTable["0"];
  return color;
};

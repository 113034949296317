import { DateRange, Locale, StringIntl } from "./type";
import { defineCollection } from "../comodel-firestore";

export type clientData = {
  clientId: string;
  industryId?: string;
  clientName?: string;
  description?: string;
  logoUrl?: string;
  createdAt?: number;
  privacyPolicy?: StringIntl;
  invalidationNotificationText?: StringIntl;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questions?: any;
  hideIndustryReport?: boolean;
  dataId?: string;
  availableLocales?: Locale[];
  maintenanceMode?: boolean;
  maintenanceModeMessage?: StringIntl;
  manual?: string;
};

export const clientDefinition = defineCollection({
  collectionName: "client",
  idKey: "clientId",
  constraints: ["clientId"],
  queryDefs: {
    list: ({
      clientIds,
      createdAt,
      condition,
    }: {
      clientIds?: string[];
      createdAt?: DateRange;
      condition?: Record<string, unknown>;
    }) => {
      return {
        queryParams: clientIds
          ? {
              filter: { clientId: { $in: clientIds }, createdAt },
            }
          : {
              filter: { createdAt },
              orderBy: [["createdAt", "desc"]],
            },
        filterParams: condition,
      };
    },
  },
  defaultValue: {} as clientData,
  onGenerate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});

import React, { useEffect } from "react";
import classNames from "classnames";

import wheelImage from "./wheelImage";

import "./index.scss";

const wait = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

/*
  1: 17
  2: 16
  3: 14
  4: 13
  5: 18
 */

const positionTable = [undefined, 17, 16, 14, 13, 18];

const getPositions = (animationId: number | undefined) => {
  const position = positionTable[animationId || 0] as number;
  if (position) {
    return [position, position, position];
  }
  while (true) {
    const result = [
      Math.floor(Math.random() * 5) + 15,
      Math.floor(Math.random() * 5) + 15,
      Math.floor(Math.random() * 5) + 15,
    ];
    if (
      result[0] !== result[1] ||
      result[1] !== result[2] ||
      result[0] !== result[2]
    ) {
      return result;
    }
  }
};
const slip = 200;

const SlotMachine: React.FC<{
  animationId: number | undefined;
  onFinish: () => void;
}> = ({ animationId, onFinish }) => {
  const [isSpining, setIsSpining] = React.useState(true);
  const [positions, setPositions] = React.useState(() =>
    getPositions(animationId)
  );
  const [winingAnimation, setWiningAnimation] = React.useState(false);
  useEffect(() => {
    (async () => {
      await wait(4000);
      setIsSpining(false);
      await wait(2000);
      if (animationId) {
        setWiningAnimation(true);
      }
      await wait(200);
      onFinish();
    })();
  }, []);

  return (
    <div
      id="slot-page"
      className={classNames(winingAnimation && "slot-backface")}
    >
      <div id="slot-content" className="arm-down">
        <div id="slot-machine">
          <div id="slot-body">
            <div id="slot-block"></div>
            <div id="slot-frame"></div>
            <div id="slot-glaze-bottom"></div>
            {/* <div id="slot-display">
              <div id="slot-overlay"></div>
              <div id="slot-overlay-line"></div>
              <div id="slot-credits">15</div>
              <div id="slot-zeros">00000000000</div>
            </div> */}
            <div id="slot-wheels">
              <div id="wheel1" className="wheel">
                <div className="overlay"></div>
                <img
                  src={wheelImage}
                  className="wheel-finish"
                  style={{
                    top:
                      -(4 + 44 * positions[0]) - (isSpining ? slip : 0) + "px",
                  }}
                />
                <img
                  src={wheelImage}
                  className={classNames(
                    "wheel-spining",
                    classNames(isSpining && "is-spining")
                  )}
                />
              </div>
              <div id="wheel2" className="wheel">
                <div className="overlay"></div>
                <img
                  src={wheelImage}
                  className="wheel-finish"
                  style={{
                    top:
                      -(4 + 44 * positions[1]) - (isSpining ? slip : 0) + "px",
                  }}
                />
                <img
                  src={wheelImage}
                  className={classNames(
                    "wheel-spining",
                    classNames(isSpining && "is-spining")
                  )}
                />
              </div>
              <div id="wheel3" className="wheel">
                <div className="overlay"></div>
                <img
                  src={wheelImage}
                  className="wheel-finish"
                  style={{
                    top:
                      -(4 + 44 * positions[2]) - (isSpining ? slip : 0) + "px",
                  }}
                />
                <img
                  src={wheelImage}
                  className={classNames(
                    "wheel-spining",
                    classNames(isSpining && "is-spining")
                  )}
                />
              </div>
            </div>

            <div id="slot-trigger">
              <div className="arm">
                <div className="knob"></div>
              </div>
              <div className="arm-shadow"></div>
              <div className="ring1">
                <div className="shadow"></div>
              </div>
              <div className="ring2">
                <div className="shadow"></div>
              </div>
            </div>
          </div>
          <div id="slot-details">
            <div id="slot-top"></div>
            <div id="slot-bottom"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotMachine;

import { match } from "path-to-regexp";
import { QueryParams, DocumentData, WithFieldValue } from "./types";

// const match: <T>(s: string) => any = () => {};

type empty = { __dammy?: string };

export type QueryDefResult<Key extends string> = {
  queryParams?: QueryParams;
  constraint?: { [K in Key]?: string };
  filterParams?: Record<string, unknown>;
};

export type QueryDef<Args extends unknown[], Key extends string> = (
  ...args: Args
) => QueryDefResult<Key> | undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryDefsAny = Record<string, QueryDef<any, any>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataAny = any;

export type CollectionDefinitionAny =
  | CollectionDefinition<string, string, string, QueryDefsAny, DataAny>
  | CollectionDefinition<never, string, string, QueryDefsAny, DataAny>
  | CollectionDefinition<string, string, never, QueryDefsAny, DataAny>
  | CollectionDefinition<never, string, never, QueryDefsAny, DataAny>;

type DefinitionWithDocKey<T extends string> = CollectionDefinition<
  T,
  T,
  string | never
>;

export type PathKeyOf<C extends CollectionDefinitionAny> =
  C extends CollectionDefinition<infer U, string, string, QueryDefsAny, DataAny>
    ? U
    : never;

export type IdKeyOf<C extends CollectionDefinitionAny> =
  C extends CollectionDefinition<string, infer U, string, QueryDefsAny, DataAny>
    ? U
    : never;

export type DocKeysOf<C extends CollectionDefinitionAny> =
  C extends DefinitionWithDocKey<infer U> ? U : never;

type ConstraintLike<T extends string> =
  | T
  | CollectionDefinition<T, T, T, QueryDefsAny, DataAny>;

const getRandomHexString = (length: number) => {
  return Array.from(crypto.getRandomValues(new Uint8Array(length)))
    .map((d) => d.toString(16).padStart(2, "0"))
    .join("");
};

export const getUniqueId = () => {
  return `${getRandomHexString(16)}`;
};

export type DataOf<C extends CollectionDefinitionAny> =
  C extends CollectionDefinition<string, string, string, QueryDefsAny, infer U>
    ? U
    : never;

type PartialSpecificFields<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

export type SetDataOf<C extends CollectionDefinitionAny> =
  PartialSpecificFields<DataOf<C>, ConstraintKeyOf<C>>;

export type ConstraintKeyOf<C extends ConstraintLike<string>> =
  | (C extends string ? C : never)
  | (C extends CollectionDefinition<
      infer PathKeys,
      string,
      string,
      QueryDefsAny,
      DataAny
    >
      ? PathKeys
      : never)
  | (C extends CollectionDefinition<
      string,
      infer IdKey,
      string,
      QueryDefsAny,
      DataAny
    >
      ? IdKey
      : never)
  | (C extends CollectionDefinition<
      string,
      string,
      infer ConsraintKeys,
      QueryDefsAny,
      DataAny
    >
      ? ConsraintKeys
      : never);

export const cleanObject = <T>(value: T) => {
  if (!value || typeof value !== "object") {
    return;
  }
  (Object.keys(value) as (keyof T)[]).forEach((key) => {
    if (value[key] === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete value[key];
    } else if (typeof value[key] === "object") {
      cleanObject(value[key]);
    }
  });
  return value;
};
export type { CollectionDefinition };

/* eslint-disable @typescript-eslint/ban-types */

class CollectionDefinition<
  PathKeys extends string,
  IdKey extends string,
  AdditionalKeys extends string,
  QueryDefs extends QueryDefsAny = QueryDefsAny,
  Data extends object = object
> {
  collectionName: string;
  idKey: IdKey;
  additionalKeys: AdditionalKeys[];
  pathKeys: PathKeys[];
  docKeys: (PathKeys | IdKey)[];
  constraintKeys: (PathKeys | AdditionalKeys | IdKey)[];
  queryDefs: QueryDefs;
  parent?: CollectionDefinitionAny;
  parseDocPath: (path: string) => { [K in PathKeys | IdKey]: string } | false;
  onGenerateIdKey: (data: Data) => string;
  // Atomic
  onCreate?: (newData: Data) => Data;
  // Atomic
  onUpdate?: (newData: Data, oldData: Data) => Data;
  onAccess: (newData: Data) => Data;
  onGenerate: (newData: Data) => Data;
  onWrite: (newData: Data) => Data;
  onRead: (data: Data) => Data;
  requireTransaction() {
    return !!(this.onCreate || this.onUpdate);
  }

  defaultValue?: Data;
  constructor(params: {
    collectionName: string;
    idKey: IdKey;
    additionalKeys: AdditionalKeys[];
    pathKeys: PathKeys[];
    queryDefs: QueryDefs;
    parent?: CollectionDefinitionAny;
    onGenerateIdKey?: (data: Data) => string;
    defaultValue?: Data;
    onCreate?: (newData: Data) => Data;
    onAccess?: (newData: Data) => Data;
    onGenerate?: (newData: Data) => Data;
    onWrite?: (newData: Data) => Data;
    onUpdate?: (newData: Data, oldData: Data) => Data;
    onRead?: (data: Data) => Data;
  }) {
    this.collectionName = params.collectionName;
    this.idKey = params.idKey;
    this.additionalKeys = params.additionalKeys;
    this.pathKeys = params.pathKeys;
    this.queryDefs = params.queryDefs;
    this.parent = params.parent;
    this.onGenerateIdKey = params.onGenerateIdKey || (() => getUniqueId());
    this.defaultValue = params.defaultValue;
    this.onCreate = params.onCreate;
    this.onAccess = params.onAccess || ((o) => o);
    this.onGenerate = params.onGenerate || ((o) => o);
    this.onWrite = params.onWrite || ((o) => o);
    this.onUpdate = params.onUpdate;
    this.onRead = params.onRead || ((o) => o);

    this.docKeys = [this.idKey, ...this.pathKeys] as (PathKeys | IdKey)[];
    this.constraintKeys = [...this.docKeys, ...this.additionalKeys] as (
      | PathKeys
      | AdditionalKeys
      | IdKey
    )[];
    this.parseDocPath = (() => {
      const parametrizedKeys = Object.fromEntries(
        this.docKeys.map((key) => [key, `:${key}`])
      ) as { [K in PathKeys | IdKey]: string };
      const matchFunc = match<{ [K in PathKeys | IdKey]: string }>(
        this.docPath(parametrizedKeys) as string
      );
      return (path: string): { [K in PathKeys | IdKey]: string } | false => {
        const result = matchFunc(path);
        if (result === false) {
          return false;
        } else {
          return result.params;
        }
      };
    })();
  }

  private basePath(params: { [K in PathKeys]: string }): string | false {
    if (this.parent) {
      const docPath = this.parent.docPath(params);
      return docPath !== false ? `${docPath}/` : false;
    } else {
      return "";
    }
  }

  collectionPath(params: { [K in PathKeys]: string } & empty): string | false {
    const basePath = this.basePath(params);
    return basePath !== false ? `${basePath}${this.collectionName}` : false;
  }

  docPath(params: { [K in PathKeys | IdKey]: string }): string | false {
    const basePath = this.basePath(params);
    const idKey = params[this.idKey];
    return basePath !== false && idKey
      ? `${basePath}${this.collectionName}/${idKey}`
      : false;
  }

  private compareConstraint(
    obj1: { [K in AdditionalKeys | PathKeys | IdKey]?: string },
    obj2: { [K in AdditionalKeys | PathKeys | IdKey]?: string }
  ) {
    return this.constraintKeys.every(
      (key) => !obj2[key] || obj1[key] === obj2[key]
    );
  }

  mergeConstraint(
    data: WithFieldValue<Data>,
    constraint: { [K in IdKey | PathKeys | AdditionalKeys]?: string }
  ) {
    const documentData = { ...constraint, ...data } as Data;
    if (!this.compareConstraint(documentData, constraint)) {
      console.error("unsatisfied constraint", documentData, constraint);
      throw new Error("unsatisfied constraint");
    }
    return documentData;
  }

  toFirestore(data: WithFieldValue<Data>) {
    const documentData = { ...data } as Data;
    for (const key of this.docKeys) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (documentData as any)[key];
    }
    cleanObject(documentData);
    return documentData;
  }

  fromFirestore(documentData: DocumentData, path: string) {
    const data = {
      ...documentData,
      ...this.parseDocPath(path),
    } as Data;
    return this.onRead(data);
  }
}

const extractContraintKey = <C extends ConstraintLike<string>>(
  constraint: C
) => {
  return (
    typeof constraint === "string"
      ? [constraint]
      : (
          constraint as CollectionDefinition<
            string,
            string,
            string,
            QueryDefsAny,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any
          >
        ).docKeys
  ) as ConstraintKeyOf<C>[];
};

export const defineCollection = <
  IdKey extends string,
  Constraints extends ConstraintLike<string>,
  QueryDefs extends QueryDefsAny,
  Parent extends CollectionDefinitionAny,
  Data extends object
>(params: {
  collectionName: string;
  idKey: IdKey;
  constraints?: Constraints[];
  queryDefs: QueryDefs;
  parent?: Parent;
  defaultValue?: Data;
  onGenerateIdKey?: (data: Data) => string;
  onCreate?: (newData: Data) => Data;
  onAccess?: (newData: Data) => Data;
  onGenerate?: (newData: Data) => Data;
  onWrite?: (newData: Data) => Data;
  onUpdate?: (newData: Data, oldData: Data) => Data;
  onRead?: (data: Data) => Data;
}): CollectionDefinition<
  CollectionDefinitionAny extends Parent ? never : DocKeysOf<Parent>,
  IdKey,
  ConstraintLike<string> extends Constraints
    ? never
    : ConstraintKeyOf<Constraints>,
  QueryDefs,
  Data
> => {
  const {
    collectionName,
    idKey,
    constraints,
    parent,
    queryDefs,
    defaultValue,
    onGenerateIdKey,
    onCreate,
    onAccess,
    onGenerate,
    onWrite,
    onUpdate,
    onRead,
  } = params;
  type PathKeys = CollectionDefinitionAny extends Parent
    ? never
    : DocKeysOf<Parent>;
  type AdditionalKeys = ConstraintLike<string> extends Constraints
    ? never
    : ConstraintKeyOf<Constraints>;
  const additionalKeys = (
    constraints
      ? ([] as ConstraintKeyOf<Constraints>[]).concat(
          ...constraints.map((constraint) => extractContraintKey(constraint))
        )
      : []
  ) as AdditionalKeys[];
  const pathKeys = (parent?.docKeys || []) as PathKeys[];

  return new CollectionDefinition({
    collectionName,
    idKey,
    additionalKeys,
    pathKeys,
    queryDefs,
    parent,
    defaultValue,
    onGenerateIdKey,
    onCreate,
    onAccess,
    onGenerate,
    onWrite,
    onUpdate,
    onRead,
  });
};

// const userCollection = defineCollection({
//   idKey: "userId",
//   collectionName: "user",
//   queryDefs: {},
//   parent: undefined,
//   constraints: ["userGroupId"],
//   defaultValue: undefined as { userId: string } | undefined,
// });
// const clientCollection = defineCollection({
//   idKey: "clientId",
//   collectionName: "client",
//   queryDefs: {},
//   constraints: [],
// });
// const shopDef = defineCollection({
//   collectionName: "shop",
//   idKey: "shopId",
//   parent: clientCollection,
//   // constraints: [],
//   queryDefs: {
//     list: ({ clientId }: { clientId: string }) => ({
//       queryParams: {
//         filter: { clientId },
//         orderBy: [["createdAt", "asc"]],
//         constraint: {},
//       },
//       constraint: {},
//     }),
//   },
// });

// const enquete = defineCollection({
//   collectionName: "enquete",
//   idKey: "enqueteId",
//   parent: shopDef,
//   // constraints: [],
//   constraints: [userCollection, "dammyId", userCollection.idKey],
//   // constraints: ["dammyId"],
//   queryDefs: {},
//   // defaultValue: undefined as { userId: string } | undefined,
// });

// type p = ConstraintKeyOf<typeof enquete>;
// type q = { [K in ConstraintKeyOf<typeof enquete>]: string };

// userCollection.collectionPath({});
// enquete.collectionPath({ clientId: "string", shopId: "" });
// enquete.docPath({ clientId: "abc", shopId: "aa", enqueteId: "abc" });

// type r = IdKeyOf<typeof enquete>;

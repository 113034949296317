import { is } from "date-fns/locale";
import dayjs from "dayjs";
import { getPropertyList } from "hooks/condition/properties";
import { resolveRoute } from "pages";
import { ObjectSchema, PropertyType } from "schemaComponents";
import { PropertyParameter } from "schemaComponents/components/object";
import { clientDomain } from "utils/domain";
import { availableLocales, languageMapper } from "utils/locale";

const quoteString = (str: string) => {
  return str; // `"${str.replace(/"/g, '""')}"`;
};

const stringifyForCSV = (item: unknown, isQuoteString: boolean = true) => {
  if (typeof item === "string") {
    return isQuoteString ? quoteString(item) : item;
  } else if (typeof item === "number" || typeof item === "boolean") {
    return item;
  } else if (Array.isArray(item)) {
    if (item.every((v) => typeof v === "string" || typeof v === "number")) {
      return isQuoteString ? quoteString(item.join(",")) : item.join(",");
    } else {
      return isQuoteString
        ? quoteString(JSON.stringify(item))
        : JSON.stringify(item);
    }
  } else if (item) {
    return isQuoteString
      ? quoteString(JSON.stringify(item))
      : JSON.stringify(item);
  } else {
    return "";
  }
};

const formatDateForCSV = (value: unknown, format: string) => {
  return typeof value === "number" ? dayjs(value).format(format) : "";
};

const iterateField = function* (
  schema: ObjectSchema,
  accessor: (obj: unknown) => unknown,
  list: Record<string, unknown>[]
): Generator<
  [
    (
      | PropertyType
      | { propertyName: string; propertyId: string }
      | { propertyName: string }
    ),
    (obj: unknown) => unknown
  ]
> {
  // eslint-disable-next-line no-constant-condition
  if (schema.outputOptions?.useRealFields) {
    const fields = new Set<string>();
    for (const item of list) {
      for (const key of Object.keys(item)) {
        fields.add(key);
      }
    }
    for (const propertyName of Array.from(fields).sort()) {
      const propertyAccessor = (obj: unknown) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accessor(obj) as any)?.[propertyName];
      yield [{ propertyName }, propertyAccessor];
    }
  } else {
    for (const property of schema.properties) {
      const { propertyName, propertyId } = property;
      if (!propertyName && !propertyId) {
        continue;
      }
      const propertyNameOrId = (propertyName ?? propertyId) as string;
      const propertyAccessor = (obj: unknown) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accessor(obj) as any)?.[propertyNameOrId];
      if (property.schema.schemaType === "object") {
        yield* iterateField(
          property.schema,
          propertyAccessor,
          list.map(propertyAccessor)
        );
      } else {
        yield [property, propertyAccessor];
      }
    }
  }
};

const getCsvValue = (
  property: PropertyType,
  value: unknown,
  maxArrayLengthMap: Map<string, number>,
  parentValue: unknown
) => {
  const schema = "schema" in property ? property.schema : undefined;
  const propertyNameOrId = (
    "propertyName" in property ? property.propertyName : property.propertyId
  ) as string;
  if (schema?.schemaType === "datetime") {
    return formatDateForCSV(value, "YYYY/MM/DD HH:mm:ss");
  } else if (schema?.schemaType === "date") {
    return formatDateForCSV(value, "YYYY/MM/DD");
  } else if (schema?.schemaType === "url") {
    if (schema.calc) {
      return schema.calc?.(parentValue);
    } else {
      return stringifyForCSV(value);
    }
  } else if (
    schema?.schemaType === "textIntl" ||
    schema?.schemaType === "multilineTextIntl"
  ) {
    const multilangResults = availableLocales.map((key) => {
      if (typeof value === "object" && value !== null) {
        return key in value
          ? stringifyForCSV(value[key as keyof typeof value])
          : "";
      } else {
        return key === "ja" ? stringifyForCSV(value) : "";
      }
    });
    return multilangResults.join(",");
  } else if (schema?.schemaType === "array") {
    const arrayValue = Array.isArray(value) ? value : [];
    const subSchema = schema.item as ObjectSchema;
    const maxArrayLength = maxArrayLengthMap.get(propertyNameOrId) || 0;
    const arrayResults: unknown[] = Array.from(
      { length: maxArrayLength },
      (_, i) => {
        const subValue = arrayValue[i] ?? {};
        return subSchema.properties.flatMap((property) => {
          const propertyNameOrId = (
            "propertyName" in property
              ? property.propertyName
              : property.propertyId
          ) as string;
          return getCsvValue(
            property,
            subValue[propertyNameOrId],
            maxArrayLengthMap,
            subValue
          );
        });
      }
    );
    return arrayResults.join(",");
  } else {
    return stringifyForCSV(value);
  }
};

const getXlsxValue = (
  property: PropertyType,
  value: unknown,
  maxArrayLengthMap: Map<string, number>,
  parentValue: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertyList: any[],
  isExpandMultipleSelector: boolean,
  isQuoteString: boolean = true,
  isJoinMaster: boolean = false,
  isConvertSelector: boolean = false,
  isConvertId: boolean = false
) => {
  const schema = "schema" in property ? property.schema : undefined;
  const propertyNameOrId = (
    "propertyName" in property ? property.propertyName : property.propertyId
  ) as string;
  if (schema?.schemaType === "datetime") {
    return formatDateForCSV(value, "YYYY/MM/DD HH:mm:ss");
  } else if (schema?.schemaType === "date") {
    return formatDateForCSV(value, "YYYY/MM/DD");
  } else if (schema?.schemaType === "url") {
    if (schema.calc) {
      return schema.calc?.(parentValue);
    } else {
      return stringifyForCSV(value, isQuoteString);
    }
  } else if (
    schema?.schemaType === "textIntl" ||
    schema?.schemaType === "multilineTextIntl"
  ) {
    const multilangResults = availableLocales.map((key) => {
      if (typeof value === "object" && value !== null) {
        return key in value
          ? stringifyForCSV(value[key as keyof typeof value], isQuoteString)
          : "";
      } else {
        return key === "ja" ? stringifyForCSV(value, isQuoteString) : "";
      }
    });
    return multilangResults;
    // } else if (isExpandMultipleSelector && schema?.schemaType === "selector") {
    //   const { options } = schema;
    //   return options.map((option) => {
    //     if (value === undefined) return "";
    //     return option.value === value;
    //   });
    // } else if (isExpandMultipleSelector && schema?.schemaType === "numberSelector") {
    //   const { minValue, maxValue } = schema;
    //   return Array.from({ length: maxValue - minValue + 1 }, (_, index) => {
    //     if (value === undefined) return "";
    //     return minValue + index === value;
    //   });
  } else if (isConvertSelector && schema?.schemaType === "selector") {
    const { options } = schema;
    // optionsのvalueと一致するtitleを返す
    // eslint-disable-next-line eqeqeq
    const option = options.find((option) => option.value == value);
    const convertedValue =
      (typeof option?.title === "object" ? option.title.ja : option?.title) ??
      "";
    return convertedValue;
  } else if (
    isExpandMultipleSelector &&
    schema?.schemaType === "multipleSelector"
  ) {
    const { options } = schema;
    return options.map((option) => {
      const valueArray = Array.isArray(value) ? value : [];
      if (valueArray.length === 0) return "";
      return valueArray.includes(option.value) ? 1 : 0;
    });
  } else if (
    isConvertId &&
    (schema?.schemaType === "externalKey" ||
      schema?.schemaType === "multipleExternalKey")
  ) {
    const property = propertyList.find(
      (property) => property.propertyName === propertyNameOrId
    );
    const convertValue = property?.schema.options?.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (option: any) => option.value === value
    )?.title;
    return stringifyForCSV(convertValue, isQuoteString);
  } else if (schema?.schemaType === "array") {
    const arrayValue = Array.isArray(value) ? value : [];
    const subSchema = schema.item as ObjectSchema;
    const maxArrayLength = maxArrayLengthMap.get(propertyNameOrId) || 0;
    const arrayResults: unknown[] = Array.from(
      { length: maxArrayLength },
      (_, i) => {
        const subValue = arrayValue[i] ?? {};
        return subSchema.properties.flatMap((property) => {
          const propertyNameOrId = (
            "propertyName" in property
              ? property.propertyName
              : property.propertyId
          ) as string;
          return getXlsxValue(
            property,
            subValue[propertyNameOrId],
            maxArrayLengthMap,
            subValue,
            propertyList,
            isExpandMultipleSelector,
            isQuoteString,
            isJoinMaster,
            isConvertSelector,
            isConvertId
          );
        });
      }
    );
    return arrayResults.length !== 0 ? arrayResults.flat() : "";
  } else if (
    propertyNameOrId === "createdAt" ||
    propertyNameOrId === "updatedAt"
  ) {
    // createdAtとupdatedAtは日付型のフォーマットに変換する
    return formatDateForCSV(value, "YYYY/MM/DD HH:mm:ss");
  } else {
    return stringifyForCSV(value, isQuoteString);
  }
};

export const arrayToXlsx = async (
  list: Record<string, unknown>[],
  schema: ObjectSchema,
  isQuoteString: boolean,
  isJoinMaster: boolean,
  isExpandMultipleSelector: boolean,
  isConvertSelector: boolean,
  isConvertId: boolean,
  isMinHeader: boolean
) => {
  const propertyList = await getPropertyList(schema);
  console.log("propertyList", propertyList);

  const fields = Array.from(iterateField(schema, (v) => v, list));
  const maxArrayLengthMap = new Map<string, number>(); // arraySchemaの最大長を記録する
  // propertyNameまたはpropertyIdの行を追加
  let idArray = [];
  const idConvertPrefix = "#";
  idArray = fields
    .map(([field, accessor]) => {
      const property = field as PropertyType;
      const { propertyName, propertyId, schema } = property;
      const propertyNameOrId = (propertyName ?? propertyId) as string;
      if (
        schema?.schemaType === "textIntl" ||
        schema?.schemaType === "multilineTextIntl"
      ) {
        return availableLocales.map((key) => {
          return `${propertyNameOrId}.${key}`;
        });
        // } else if (isExpandMultipleSelector && schema?.schemaType === "selector") {
        //   const { options } = schema;
        //   return [
        //     propertyNameOrId,
        //     ...Array.from({ length: options.length - 1 }).fill(""),
        //   ];
        // } else if (isExpandMultipleSelector && schema?.schemaType === "numberSelector") {
        //   const { minValue, maxValue } = schema;
        //   return [
        //     propertyNameOrId,
        //     ...Array.from({ length: maxValue - minValue }).fill(""),
        //   ];
      } else if (isConvertSelector && schema?.schemaType === "selector") {
        return `${idConvertPrefix}${propertyNameOrId}`;
      } else if (
        isExpandMultipleSelector &&
        schema?.schemaType === "multipleSelector"
      ) {
        const { options } = schema;
        return [
          ...Array.from({ length: options.length }).fill(
            `${idConvertPrefix}${propertyNameOrId}`
          ),
        ];
      } else if (
        isConvertId &&
        (schema?.schemaType === "externalKey" ||
          schema?.schemaType === "multipleExternalKey")
      ) {
        return `${idConvertPrefix}${propertyNameOrId}`;
      } else if (schema?.schemaType === "array") {
        const subSchema = schema.item as ObjectSchema;
        const maxArrayLength = Math.max(
          ...list.map((item) => {
            const value = accessor(item);
            return Array.isArray(value) ? value.length : 0;
          })
        );
        maxArrayLengthMap.set(propertyNameOrId, maxArrayLength);
        return maxArrayLength > 0
          ? Array.from({ length: maxArrayLength }, (_, i) => {
              return subSchema.properties
                .map((property) => {
                  const { schema } = property;
                  if (
                    schema.schemaType === "textIntl" ||
                    schema.schemaType === "multilineTextIntl"
                  ) {
                    return availableLocales.map((_) => {
                      return "";
                    });
                    // } else if (
                    //   isExpandMultipleSelector &&
                    //   schema.schemaType === "selector"
                    // ) {
                    //   const { options } = schema;
                    //   return [
                    //     `${propertyNameOrId}.${i + 1}.${property.propertyName}`,
                    //     ...Array.from({ length: options.length - 1 }).fill(""),
                    //   ];
                    // } else if (
                    //   isExpandMultipleSelector &&
                    //   schema.schemaType === "numberSelector"
                    // ) {
                    //   const { minValue, maxValue } = schema;
                    //   return [
                    //     `${propertyNameOrId}.${i + 1}.${property.propertyName}`,
                    //     ...Array.from({ length: maxValue - minValue }).fill(""),
                    //   ];
                  } else if (
                    isExpandMultipleSelector &&
                    schema.schemaType === "multipleSelector"
                  ) {
                    const { options } = schema;
                    return [
                      `${propertyNameOrId}.${i + 1}.${property.propertyName}`,
                      ...Array.from({ length: options.length - 1 }).fill(""),
                    ];
                  }
                  return `${propertyNameOrId}.${i + 1}.${
                    property.propertyName
                  }`;
                })
                .flat();
            }).flat()
          : propertyNameOrId;
      } else {
        return propertyNameOrId;
      }
    })
    .flat();
  if (isJoinMaster || isExpandMultipleSelector) idArray.unshift("");
  // console.log("idArray", idArray);

  // titleの行を追加
  let titleArray = [];
  titleArray = fields
    .map(([field, accessor]) => {
      const property = field as PropertyType & {
        statName?: string;
        statShortName?: string;
      };
      const { propertyName, propertyId, schema, title, statShortName } =
        property;
      const propertyNameOrId = (propertyName ?? propertyId) as string;
      if (
        schema?.schemaType === "textIntl" ||
        schema?.schemaType === "multilineTextIntl"
      ) {
        return availableLocales.map((key) => {
          return `${title}(${languageMapper[key]})`;
        });
        // } else if (isExpandMultipleSelector && schema?.schemaType === "selector") {
        //   const { options } = schema;
        //   return [
        //     statName || title,
        //     ...Array.from({ length: options.length - 1 }).fill(""),
        //   ];
        // } else if (isExpandMultipleSelector && schema?.schemaType === "numberSelector") {
        //   const { minValue, maxValue } = schema;
        //   return [
        //     statName || title,
        //     ...Array.from({ length: maxValue - minValue }).fill(""),
        //   ];
      } else if (
        isExpandMultipleSelector &&
        schema?.schemaType === "multipleSelector"
      ) {
        const { options } = schema;
        return [
          ...Array.from({ length: options.length }).fill(
            statShortName || title
          ),
        ];
      } else if (schema?.schemaType === "array") {
        const subSchema = schema.item as ObjectSchema;
        const maxArrayLength = maxArrayLengthMap.get(propertyNameOrId) || 0;
        return maxArrayLength > 0
          ? Array.from({ length: maxArrayLength }, (_, i) => {
              return subSchema.properties
                .map((property) => {
                  const { schema } = property;
                  if (
                    schema?.schemaType === "textIntl" ||
                    schema?.schemaType === "multilineTextIntl"
                  ) {
                    return availableLocales.map((_) => {
                      return "";
                    });
                    // } else if (
                    //   isExpandMultipleSelector &&
                    //   schema.schemaType === "selector"
                    // ) {
                    //   const { options } = schema;
                    //   return [
                    //     `${title}.${i + 1}.${property.title}`,
                    //     ...Array.from({ length: options.length - 1 }).fill(""),
                    //   ];
                    // } else if (
                    //   isExpandMultipleSelector &&
                    //   schema.schemaType === "numberSelector"
                    // ) {
                    //   const { minValue, maxValue } = schema;
                    //   return [
                    //     `${title}.${i + 1}.${property.title}`,
                    //     ...Array.from({ length: maxValue - minValue }).fill(""),
                    //   ];
                  } else if (
                    isExpandMultipleSelector &&
                    schema.schemaType === "multipleSelector"
                  ) {
                    const { options } = schema;
                    return [
                      `${title}.${i + 1}.${property.title}`,
                      ...Array.from({ length: options.length - 1 }).fill(""),
                    ];
                  }
                  return `${title}.${i + 1}.${property.title}`;
                })
                .flat();
            }).flat()
          : statShortName || title;
      } else {
        return statShortName || title;
      }
    })
    .flat();
  titleArray.unshift("#title");
  // console.log("titleArray", titleArray);

  // optionを追加
  let optionArray = [];
  optionArray = fields
    .map(([field, accessor]) => {
      const property = field as PropertyType & { statName?: string };
      const { propertyName, propertyId, schema, title, statName } = property;
      const propertyNameOrId = (propertyName ?? propertyId) as string;
      if (
        schema?.schemaType === "textIntl" ||
        schema?.schemaType === "multilineTextIntl"
      ) {
        return availableLocales.map((_) => {
          return "";
        });
        // } else if (schema?.schemaType === "selector") {
        //   const { options } = schema;
        //   return options.map((option) => {
        //     return option.value;
        //   });
        // } else if (schema?.schemaType === "numberSelector") {
        //   const { minValue, maxValue } = schema;
        //   return Array.from(
        //     { length: maxValue - minValue + 1 },
        //     (_, index) => minValue + index
        //   );
      } else if (schema?.schemaType === "multipleSelector") {
        const { options } = schema;
        return options.map((option) => {
          return option.value;
        });
      } else if (schema?.schemaType === "array") {
        const subSchema = schema.item as ObjectSchema;
        const maxArrayLength = maxArrayLengthMap.get(propertyNameOrId) || 0;
        return maxArrayLength > 0
          ? Array.from({ length: maxArrayLength }, (_, i) => {
              return subSchema.properties
                .map((property) => {
                  const { schema } = property;
                  if (
                    schema?.schemaType === "textIntl" ||
                    schema?.schemaType === "multilineTextIntl"
                  ) {
                    return availableLocales.map((_) => {
                      return "";
                    });
                    // } else if (schema.schemaType === "selector") {
                    //   const { options } = schema;
                    //   return options.map((option) => {
                    //     return option.value;
                    //   });
                    // } else if (schema.schemaType === "numberSelector") {
                    //   const { minValue, maxValue } = schema;
                    //   return Array.from(
                    //     { length: maxValue - minValue + 1 },
                    //     (_, index) => minValue + index
                    //   );
                  } else if (schema.schemaType === "multipleSelector") {
                    const { options } = schema;
                    return options.map((option) => {
                      return option.value;
                    });
                  } else {
                    return "";
                  }
                })
                .flat();
            }).flat()
          : "";
      } else {
        return "";
      }
    })
    .flat();
  optionArray.unshift("#option");
  // console.log("optionArray", optionArray);

  // option.titleを追加
  let optionTitleArray = [];
  optionTitleArray = fields
    .map(([field, accessor]) => {
      const property = field as PropertyType & { statName?: string };
      const propertyNameOrId = (
        "propertyName" in property ? property.propertyName : property.propertyId
      ) as string;
      const { schema } = property;
      if (
        schema?.schemaType === "textIntl" ||
        schema?.schemaType === "multilineTextIntl"
      ) {
        return availableLocales.map((_) => {
          return "";
        });
        // } else if (schema?.schemaType === "selector") {
        //   const { options } = schema;
        //   return options.map((option) => {
        //     return option.title;
        //   });
        // } else if (schema?.schemaType === "numberSelector") {
        //   const { minValue, maxValue, textBefore, textAfter } = schema;
        //   return [
        //     textBefore || "",
        //     ...Array.from({ length: maxValue - minValue - 1 }).fill(""),
        //     textAfter || "",
        //   ];
      } else if (schema?.schemaType === "multipleSelector") {
        const { options } = schema;
        return options.map((option) => {
          return option.title;
        });
      } else if (schema?.schemaType === "array") {
        console.log("array", { propertyNameOrId, schema });

        const subSchema = schema.item as ObjectSchema;
        const maxArrayLength = maxArrayLengthMap.get(propertyNameOrId) || 0;
        return maxArrayLength > 0
          ? subSchema.properties
              .map((property) => {
                const { schema } = property;
                if (
                  schema?.schemaType === "textIntl" ||
                  schema?.schemaType === "multilineTextIntl"
                ) {
                  return availableLocales.map((_) => {
                    return "";
                  });
                  // } else if (
                  //   isExpandMultipleSelector &&
                  //   schema.schemaType === "selector"
                  // ) {
                  //   const { options } = schema;
                  //   return options.map((option) => {
                  //     return option.title;
                  //   });
                  // } else if (
                  //   isExpandMultipleSelector &&
                  //   schema.schemaType === "numberSelector"
                  // ) {
                  //   const { minValue, maxValue, textBefore, textAfter } = schema;
                  //   return [
                  //     textBefore || "",
                  //     ...Array.from({ length: maxValue - minValue - 1 }).fill(""),
                  //     textAfter || "",
                  //   ];
                } else if (
                  isExpandMultipleSelector &&
                  schema.schemaType === "multipleSelector"
                ) {
                  const { options } = schema;
                  return options.map((option) => {
                    return option.title;
                  });
                } else {
                  return "";
                }
              })
              .flat()
          : "";
      } else {
        return "";
      }
    })
    .flat();
  optionTitleArray.unshift("#option.title");
  // console.log("optionTitleArray", optionTitleArray);

  // title + option.titleの行を追加
  let titleAndOptionTitleArray = [];
  titleAndOptionTitleArray = fields
    .map(([field, accessor]) => {
      const property = field as PropertyType & {
        statName?: string;
        statShortName?: string;
      };
      const { propertyName, propertyId, schema, title, statShortName } =
        property;
      const propertyNameOrId = (propertyName ?? propertyId) as string;
      if (
        schema?.schemaType === "textIntl" ||
        schema?.schemaType === "multilineTextIntl"
      ) {
        return availableLocales.map((key) => {
          return `${title}(${languageMapper[key]})`;
        });
      } else if (
        isExpandMultipleSelector &&
        schema?.schemaType === "multipleSelector"
      ) {
        const { options } = schema;
        return options.map((option) => {
          const optionTitle =
            typeof option.title === "object" ? option.title.ja : option.title;
          return `${statShortName || title}_${optionTitle}`;
        });
      } else if (schema?.schemaType === "array") {
        const subSchema = schema.item as ObjectSchema;
        const maxArrayLength = maxArrayLengthMap.get(propertyNameOrId) || 0;
        return maxArrayLength > 0
          ? Array.from({ length: maxArrayLength }, (_, i) => {
              return subSchema.properties
                .map((property) => {
                  const { schema } = property;
                  if (
                    schema?.schemaType === "textIntl" ||
                    schema?.schemaType === "multilineTextIntl"
                  ) {
                    return availableLocales.map((_) => {
                      return "";
                    });
                  } else if (
                    isExpandMultipleSelector &&
                    schema.schemaType === "multipleSelector"
                  ) {
                    const { options } = schema;
                    return options.map((option) => {
                      const optionTitle =
                        typeof option.title === "object"
                          ? option.title.ja
                          : option.title;
                      return `${statShortName || title}.${i + 1}.${
                        property.title
                      }_${optionTitle}`;
                    });
                  }
                  return `${title}.${i + 1}.${property.title}`;
                })
                .flat();
            }).flat()
          : statShortName || title;
      } else {
        return statShortName || title;
      }
    })
    .flat();
  if (!isMinHeader) titleAndOptionTitleArray.unshift("#title_option.title");
  // console.log("titleAndOptionTitleArray", titleAndOptionTitleArray);

  // dataを追加
  const dataArray = [];
  for (const item of list) {
    const row = fields
      .map(([property, accessor]) => {
        const value = accessor(item);
        return getXlsxValue(
          property as PropertyType,
          value,
          maxArrayLengthMap,
          item,
          propertyList,
          isExpandMultipleSelector,
          isQuoteString,
          isJoinMaster,
          isConvertSelector,
          isConvertId
        );
      })
      .flat();
    if (!isMinHeader && (isJoinMaster || isExpandMultipleSelector))
      row.unshift("");
    dataArray.push(row);
  }
  // console.log("dataArray", dataArray);

  return [
    !isMinHeader ? idArray : [],
    !isMinHeader && isJoinMaster ? titleArray : [],
    !isMinHeader && isExpandMultipleSelector ? optionArray : [],
    !isMinHeader && isJoinMaster && isExpandMultipleSelector
      ? optionTitleArray
      : [],
    isMinHeader || (isJoinMaster && isExpandMultipleSelector)
      ? titleAndOptionTitleArray
      : [],
    ...dataArray,
  ].filter((array) => array.length !== 0);
};

export const arrayToCsv = (
  list: Record<string, unknown>[],
  schema: ObjectSchema
) => {
  console.log({ schema });
  const fields = Array.from(iterateField(schema, (v) => v, list));
  const maxArrayLengthMap = new Map<string, number>(); // arraySchemaの最大長を記録する
  // propertyNameまたはpropertyIdの行を追加
  let output = ",";
  output +=
    fields
      .map(([field, accessor]) => {
        const property = field as PropertyType;
        const { propertyName, propertyId, schema } = property;
        const propertyNameOrId = (propertyName ?? propertyId) as string;
        if (
          schema?.schemaType === "textIntl" ||
          schema?.schemaType === "multilineTextIntl"
        ) {
          return availableLocales
            .map((key) => {
              return `${propertyNameOrId}.${key}`;
            })
            .join(",");
        } else if (schema?.schemaType === "array") {
          const subSchema = schema.item as ObjectSchema;
          const maxArrayLength = Math.max(
            ...list.map((item) => {
              const value = accessor(item);
              return Array.isArray(value) ? value.length : 0;
            })
          );
          maxArrayLengthMap.set(propertyNameOrId, maxArrayLength);
          return maxArrayLength > 0
            ? Array.from({ length: maxArrayLength }, (_, i) =>
                subSchema.properties
                  .map(
                    (property) =>
                      `${propertyNameOrId}.${i + 1}.${property.propertyName}`
                  )
                  .join(",")
              ).join(",")
            : propertyNameOrId;
        }
        return propertyNameOrId;
      })
      .join(",") + "\n";
  // titleの行を追加
  output += "#title,";
  output +=
    fields
      .map(([field, accessor]) => {
        const property = field as PropertyType;
        const { propertyName, propertyId, schema, title } = property;
        const propertyNameOrId = (propertyName ?? propertyId) as string;
        if (
          schema?.schemaType === "textIntl" ||
          schema?.schemaType === "multilineTextIntl"
        ) {
          return availableLocales
            .map((key) => {
              return `${title}(${languageMapper[key]})`;
            })
            .join(",");
        } else if (schema?.schemaType === "array") {
          const subSchema = schema.item as ObjectSchema;
          const maxArrayLength = maxArrayLengthMap.get(propertyNameOrId) || 0;
          return maxArrayLength > 0
            ? subSchema.properties.map((property) => {
                return `${title}(${property.title})`;
              })
            : title;
        } else {
          return title;
        }
      })
      .join(",") + "\n";
  // dataを追加
  for (const item of list) {
    output += ",";
    output +=
      fields
        .map(([property, accessor]) => {
          const value = accessor(item);
          return getCsvValue(
            property as PropertyType,
            value,
            maxArrayLengthMap,
            item
          );
        })
        .join(",") + "\n";
  }
  return output;
};

import {
  FilterFieldParams,
  OperatorMap,
  QueryParams,
} from "common/comodel-firestore";

import {
  FieldPath,
  where,
  WhereFilterOp,
  documentId,
  limit,
  limitToLast,
  orderBy,
  QueryConstraint,
} from "firebase/firestore";

type EntriesResult<T> = [keyof T, T[keyof T]][];

const getQueryConstraintForKey = ({
  condition,
  key,
}: {
  condition: FilterFieldParams;
  key: FieldPath | string;
}) => {
  if (condition === undefined) {
    return [];
  } else if (typeof condition === "object") {
    const queries = [];
    for (const [operator, value] of Object.entries(condition) as EntriesResult<
      typeof condition
    >) {
      const firestoreOperator = OperatorMap[operator];
      if (!firestoreOperator) {
        console.error("You cannot use this query in firestore", condition);
        continue;
      }
      queries.push(where(key, firestoreOperator as WhereFilterOp, value));
    }
    return queries;
  } else {
    return [where(key, "==", condition)];
  }
};

export const getQueryConstraints = (params: QueryParams) => {
  const queries = [] as QueryConstraint[];
  const convertKey = (key: string | FieldPath) =>
    params.idKey === key ? documentId() : key;
  if (params.filter) {
    for (const key of Object.keys(params.filter)) {
      queries.push(
        ...getQueryConstraintForKey({
          key: convertKey(key),
          condition: params.filter[key],
        })
      );
    }
  }
  if (params.documentId) {
    queries.push(
      ...getQueryConstraintForKey({
        key: documentId(),
        condition: params.documentId,
      })
    );
  }
  if (params.orderBy) {
    queries.push(
      ...params.orderBy.map(([path, direction]) =>
        orderBy(convertKey(path), direction)
      )
    );
  }

  if (typeof params.limit === "number") {
    queries.push(limit(params.limit));
  }
  if (typeof params.limitToLast === "number") {
    queries.push(limitToLast(params.limitToLast));
  }
  return queries;
};

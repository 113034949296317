import React from "react";
import {
  SchemaComponent,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";

interface BooleanSchema extends BaseSchema {
  schemaType: "boolean";
  uiType?: "toggle" | "check";
  required?: boolean;
}

const BooleanSchemaComponent: SchemaComponent<BooleanSchema> = (props) => {
  const { value, registerProps, fieldState } = useField(props, [
    requiredValidator,
  ]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Check
        type={props.schema.uiType !== "check" ? "switch" : "checkbox"}
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        defaultChecked={value}
        {...registerProps}
      />
    </FieldWrapper>
  );
};

BooleanSchemaComponent.display = DisplayComponent(
  "BooleanDisplayComponent",
  (value) => (value ? "はい" : "いいえ")
);

export default BooleanSchemaComponent;

import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "総合満足度",
      propertyName: "comments.総合満足度",
      schema: {
        schemaType: "multilineText",
      },
    },
    {
      title: "個別評価項目",
      propertyName: "comments.個別評価項目",
      schema: {
        schemaType: "multilineText",
      },
    },

    {
      title: "その他",
      propertyName: "comments.その他",
      schema: {
        schemaType: "multilineText",
      },
    },
  ],
};
export default schema;

import { useCallback, useEffect, useMemo, useState } from "react";

import { getHashSHA256 } from "utils/crypto";
import { useLocalStorage } from "./storage";

export const searchParamContractId =
  new URLSearchParams(window.location.search).get("contractId") || undefined;

export const updateContractConfirmed = (
  {
    issuerId,
    email,
  }: {
    issuerId: string;
    email?: string;
  },
  value?: string
) => {
  const storageKey = `privacyPoricyConfirmed:${issuerId}:${email}`;
  if (value) {
    localStorage.setItem(storageKey, JSON.stringify(value));
  } else {
    localStorage.removeItem(storageKey);
  }
};

export const useContractConfirmed = (
  {
    contractTitle,
    contractContent,
    issuerId,
    email,
  }: {
    contractTitle: string;
    contractContent: string;
    issuerId: string;
    email?: string;
  },
  syncMode?: "readOnly" | "sync"
) => {
  const [storedContractId, setStoredContractId] = (() => {
    const storageKey = `privacyPoricyConfirmed:${issuerId}:${email}`;
    const [storedContractId, setStoredContractId] = useLocalStorage<
      string | null
    >(storageKey, searchParamContractId || null);
    if (email && syncMode === "sync") {
      useState<string | null>(null);
      return [storedContractId, setStoredContractId];
    } else if (email && syncMode === "readOnly") {
      return useState<string | null>(storedContractId);
    } else {
      return useState<string | null>(null);
    }
  })();

  const confirmedContractId = useMemo(() => {
    return getHashSHA256(`${issuerId}:${contractTitle}:${contractContent}`);
  }, [issuerId, contractTitle, contractContent]);

  const setContractConfirmed = useCallback(
    (contractConfirmed: boolean) => {
      const newValue = contractConfirmed ? confirmedContractId : null;
      setStoredContractId(newValue);
      return newValue || undefined;
    },
    [confirmedContractId]
  );
  const contractConfirmed = useMemo(() => {
    return storedContractId === confirmedContractId;
  }, [storedContractId, confirmedContractId]);

  return {
    contractConfirmed,
    setContractConfirmed,
    confirmedContractId: contractConfirmed ? confirmedContractId : undefined,
  };
};

import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useClientDoc, useRewardDoc } from "models/hook";
import { REWARD, REWARDS } from "../route";
import { resolveRoute } from "pages";
import { AdminLocaleSelector } from "hooks/localeSelector";
import { ObjectSchema } from "schemaComponents";
import { useAdminAuthentication } from "hooks/auth";
import { useAdminPageAuthorization } from "hooks/authAdminPage";
import { PreviewComponent } from "./preview";

export const UPDATE_REWARD = REWARD.sub("", Reward);
export const ADD_REWARD = REWARDS.sub("/new", Reward);

function Reward({
  rewardId,
  clientId,
}: {
  rewardId?: string;
  clientId: string;
}) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { data, loading, set, remove, isEditing } = useRewardDoc({
    rewardId,
    clientId,
  });
  const { data: clientData } = useClientDoc({ clientId });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "リワード詳細" : "リワード新規作成",
    edit: {
      title: `${isEditing ? "保存" : "保存"}`,
      confirm: `${isEditing ? "保存" : "保存"}しますか？`,
      handler: set,
      isDisabled: !isMaster,
    },
    remove: isEditing ? { handler: remove } : undefined,
    schema: getSchema({ isEditing, isMaster }),
    preview: {
      title: "プレビュー",
      component: ({ control }) => {
        return (
          <PreviewComponent
            control={control}
            clientId={clientId}
            rewardId={rewardId}
          />
        );
      },
    },
    backPath: resolveRoute("LIST_REWARDS", { clientId }),
    clipboard: {
      objectType: "reward",
      retainKeys: ["clientId", "rewardId"],
      isDisabled: !isMaster,
    },
  };
  return (
    <SchemaFormPage
      data={data}
      parameter={parameter}
      loading={isEditing && loading}
      header={<AdminLocaleSelector locales={clientData?.availableLocales} />}
      editModeEnabled={true}
    />
  );
}

export default Reward;

import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";
import { getMultiLocaleValues, StringIntl } from "utils/locale";
import { ObjectSchema } from "schemaComponents";
import { parse } from "papaparse";
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const parse = require("loose-json");

interface UploadCsvSchema extends BaseSchema {
  schemaType: "uploadCsv";
  required?: boolean;
  setFieldPath: string;
}

const parseCsv = (text: string, schema?: ObjectSchema) => {
  const parsedCsv = parse(text, { header: true, skipEmptyLines: "greedy" });
  const lines = parsedCsv.data;
  return lines;
};

const UploadCsvSchemaComponent: SchemaComponent<UploadCsvSchema> = (
  props: SchemaComponentInternalProps<UploadCsvSchema>
) => {
  const {
    value,
    registerProps: rawCsvRegisterProps,
    fieldState,
  } = useField(props, [requiredValidator]);
  const { registerProps } = useField(
    {
      ...props,
      fieldPath: props.schema.setFieldPath,
    },
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (e: any) => {
    const text = e.target.value as string;
    const value = parseCsv(text);
    console.log("UploadCsvSchemaComponent1", value);

    rawCsvRegisterProps.onChange(text); // csvデータを保存
    registerProps.onChange(value); // jsonデータを保存
    console.log("UploadCsvSchemaComponent2", text);
  };
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        as="textarea"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        style={{ height: "160px" }}
        value={value || ""}
        {...registerProps}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};

UploadCsvSchemaComponent.display = DisplayComponent(
  "UploadCsvDisplayComponent"
);

export default UploadCsvSchemaComponent;

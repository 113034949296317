import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Value } from "react-hook-schema-form";
import { Field, Schema } from "schemaComponents";
import isEqual from "lodash/isEqual";

export const SchemaInput = ({
  data,
  readOnly,
  schema,
  deepCompare,
  onValueChange,
}: {
  data: Value;
  schema: Schema;
  readOnly?: boolean;
  deepCompare?: boolean;
  onValueChange?: (value: Value) => void;
}) => {
  if (schema.schemaType === "object") {
    const { control, setValue, getValues } = useForm({
      defaultValues: data,
    });
    const value = useWatch({ control });
    if (onValueChange) {
      useEffect(() => {
        if (data !== value) {
          onValueChange?.(value);
        }
      }, [value]);
    }
    useEffect(() => {
      const values = getValues();
      for (const key of Object.keys(values)) {
        setValue(key, data);
      }
    }, [data]);
    return <Field control={control} schema={schema} readOnly={readOnly} />;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { control, setValue } = useForm<any>({
      defaultValues: { default: data },
    });
    const value = useWatch({ control });
    if (onValueChange) {
      useEffect(() => {
        if (
          deepCompare ? !isEqual(data, value.default) : data !== value.default
        ) {
          onValueChange?.(value.default);
        }
      }, [value.default]);
    }
    useEffect(() => {
      setValue("default", data);
    }, [data]);
    return (
      <Field
        control={control}
        schema={
          {
            schemaType: "object",
            properties: [
              {
                title: "default",
                propertyName: "default",
                noLabel: true,
                schema,
              },
            ],
          } as Schema
        }
        readOnly={readOnly}
      />
    );
  }
};

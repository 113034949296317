import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "レポートID",
      propertyName: "reportId",
      schema: { schemaType: "text" },
    },
    {
      title: "レポート名",
      propertyName: "title",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
    {
      title: "公開",
      propertyName: "isPublic",
      schema: {
        schemaType: "boolean",
      },
    },
  ],
};
export default schema;

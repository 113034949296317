import { defineCollection } from "../comodel-firestore";
import { DateRange } from "./type";

export type enqueteData = {
  enqueteId: string;
  campaignId?: string;
  createdAt?: number;
  userId?: string;
  movieFinished?: boolean;
  lottery?: string;
  couponId?: string;
  couponUrl: string;
  email?: string;
  shopId: string;
  surveyId: string;
  clientId: string;
  rewardId?: string;
  animationId?: number;
  userAgent?: string;
  skipEventHook?: boolean;
  cancelLottery?: boolean;
  lotteryExpiredAt?: number;
  updatedAt?: number;
  locale?: string;
  ignore?: boolean;
  data: {
    [key: string]: unknown;
  };
};

export const enqueteDefinition = defineCollection({
  collectionName: "enquete",
  idKey: "enqueteId",
  constraints: ["clientId"],
  queryDefs: {
    list: ({
      clientId,
      createdAt,
      condition,
    }: {
      clientId: string;
      createdAt?: DateRange;
      condition?: Record<string, unknown>;
    }) => {
      return {
        constraint: { clientId },
        queryParams: {
          filter: { clientId, createdAt },
          orderBy: [["createdAt", "desc"]],
        },
        filterParams: condition,
      };
    },
  },
  // queryDefs: {
  //   list: (
  //     params: {
  //       clientId: string;
  //       createdAt?: DateRange;
  //       condition?: Record<string, unknown>;
  //     } & Record<string, unknown>
  //   ) => {
  //     const { clientId, createdAt, ...condition } = params;
  //     return {
  //       constraint: { clientId },
  //       queryParams: {
  //         filter: { clientId, createdAt },
  //         orderBy: [["createdAt", "desc"]],
  //       },
  //       filterParams: condition,
  //     };
  //   },
  // },
  defaultValue: {} as enqueteData,
  onGenerate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});

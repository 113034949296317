import { useState, useCallback, useEffect } from "react";
import { useLocalStorage } from "./storage";

export const useClipboard = <T>() => {
  const [clipboardContent, setClipboardContent] = useState<T>();

  const [clipboardPermission, setClipboardPermission] = useState<
    "granted" | "denied" | "prompt" | undefined
  >(undefined);

  const [localClipboardContent, setLocalClipboardContent] = useLocalStorage<
    T | undefined
  >("__clipboardContent", undefined);

  const updatePermission = useCallback(async () => {
    const permission = await navigator.permissions.query({
      name: "clipboard-read" as PermissionName,
    });
    setClipboardPermission(permission.state);
    return permission.state;
  }, []);

  useEffect(() => {
    updatePermission();
  }, []);

  useEffect(() => {
    if (clipboardPermission !== "granted") return;
    console.log("start to observe clipboarde event change");
    const handler = async () => {
      console.log("clipboardchange event");
      const text = await navigator.clipboard.readText();
      setClipboardContent(JSON.parse(text));
    };
    navigator.clipboard.addEventListener("clipboardchange", handler);
    return () => {
      navigator.clipboard.removeEventListener("clipboardchange", handler);
    };
  }, [clipboardPermission]);

  const getContent = useCallback(async () => {
    if (!navigator.clipboard) {
      console.warn("Browser doesn't support clipboard API");
      return;
    }
    try {
      const text = await navigator.clipboard.readText();
      const content = JSON.parse(text) as T;
      setClipboardContent(content);
      return content || localClipboardContent;
    } catch (error) {
      console.error("Failed to read from clipboard:", error);
      return localClipboardContent;
    }
  }, []);

  const setContent = useCallback(async (content: T) => {
    setLocalClipboardContent(content);
    setClipboardContent(content);
    updatePermission();
    if (!navigator.clipboard) {
      console.warn("Browser doesn't support clipboard API");
      return;
    }
    try {
      await navigator.clipboard.writeText(JSON.stringify(content));
    } catch (error) {
      console.error("Failed to write to clipboard:", error);
    }
  }, []);

  return {
    content:
      clipboardPermission === "granted"
        ? clipboardContent
        : localClipboardContent,
    setContent,
    getContent,
    clipboardPermission,
  };
};

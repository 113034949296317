import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useEnqueteDoc, useSurveyDoc } from "models/hook";
import { ENQUETE, ENQUETES, ADMIN_ENQUETE, ADMIN_ENQUETES } from "../route";
import { resolveRoute } from "pages";
import { useAdminAuthentication } from "hooks/auth";
import { useAdminPageAuthorization } from "hooks/authAdminPage";
import NotFound from "pages/notFound";

export const UPDATE_ENQUETE = ENQUETE.sub("/edit", Enquete);
export const ADD_ENQUETE = ENQUETES.sub("/edit/new", Enquete);

export const UPDATE_ADMIN_ENQUETE = ADMIN_ENQUETE.sub("/edit", AdminEnquete);
export const ADD_ADMIN_ENQUETE = ADMIN_ENQUETES.sub("/edit/new", AdminEnquete);

function Enquete({
  enqueteId,
  clientId,
}: {
  enqueteId?: string;
  clientId: string;
}) {
  return (
    <EnqueteInternal
      enqueteId={enqueteId}
      clientId={clientId}
      backPath={resolveRoute("LIST_ENQUETES", { clientId })}
      isMaster={false}
    />
  );
}

function AdminEnquete({
  enqueteId,
  clientId,
}: {
  enqueteId?: string;
  clientId: string;
}) {
  const { role } = useAdminAuthentication();
  if (role !== "master") {
    return <NotFound></NotFound>;
  }
  return (
    <EnqueteInternal
      enqueteId={enqueteId}
      clientId={clientId}
      backPath={resolveRoute("LIST_ADMIN_ENQUETES", { clientId })}
      isMaster={true}
    />
  );
}

function EnqueteInternal({
  enqueteId,
  clientId,
  backPath,
  isMaster,
}: {
  enqueteId?: string;
  clientId: string;
  backPath?: string;
  isMaster: boolean;
}) {
  useAdminPageAuthorization(clientId);
  const { data, loading, isEditing, set } = useEnqueteDoc({
    enqueteId,
    clientId,
  });
  const { data: surveyData } = useSurveyDoc({
    surveyId: data?.surveyId,
    clientId,
  });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "アンケート詳細" : "アンケート新規作成",
    schema: getSchema({
      enquete: surveyData?.enquete || { schemaType: "object", properties: [] },
      clientId,
      isMaster,
    }),
    edit: {
      title: `${isEditing ? "保存" : "保存"}`,
      confirm: `${isEditing ? "保存" : "保存"}しますか？`,
      handler: set,
      isDisabled: !isMaster,
    },
    backPath,
    header: {
      isStacked: true,
    },
  };
  return (
    <div className={"wide-form"}>
      <SchemaFormPage
        data={data}
        parameter={parameter}
        loading={isEditing && loading}
        editModeEnabled={true}
      />
    </div>
  );
}

export default Enquete;

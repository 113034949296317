import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";
import { getMultiLocaleValues, StringIntl } from "utils/locale";
import { parseCsv } from "./enquete";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const parse = require("loose-json");

interface Enquete2Schema extends BaseSchema {
  schemaType: "enquete2";
  required?: boolean;
  setFieldPath: string;
}

const Enquete2SchemaComponent: SchemaComponent<Enquete2Schema> = (
  props: SchemaComponentInternalProps<Enquete2Schema>
) => {
  const {
    value,
    registerProps: rawCsvRegisterProps,
    fieldState,
  } = useField(props, [requiredValidator]);
  const { registerProps } = useField(
    {
      ...props,
      fieldPath: props.schema.setFieldPath,
    },
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (e: any) => {
    const text = e.target.value as string;
    const value = parseCsv(text);
    rawCsvRegisterProps.onChange(text); // csvデータを保存
    registerProps.onChange(value); // jsonデータを保存
  };
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        as="textarea"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        style={{ height: "160px" }}
        value={value || ""}
        {...registerProps}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};

Enquete2SchemaComponent.display = DisplayComponent("Enquete2DisplayComponent");

export default Enquete2SchemaComponent;

import React, { useCallback, useMemo } from "react";
import getSchema, {
  getAdditionalSchema,
  getClientConditionSchema,
} from "./schema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { useClientDoc, useEnqueteList } from "models/hook";
import { ADMIN_ENQUETES, ENQUETES } from "../route";
import { resolveRoute } from "pages";
import { enqueteData } from "common/models/enquete";
import { useConditionSelector } from "hooks/condition/conditionSelector";
import { useSchemaSelector } from "./schemaSelector";
import { ObjectSchema, PropertyType } from "schemaComponents";
import { useAdminPageAuthorization } from "hooks/authAdminPage";
import { useAdminAuthentication } from "hooks/auth";
import NotFound from "pages/notFound";
import { useQueryResource } from "utils/legacyFirestore";
import { SchemaInput } from "components/SchemaInput";
import { Form, Row } from "react-bootstrap";

export const LIST_ENQUETES = ENQUETES.sub("", Enquetes);
export const LIST_ADMIN_ENQUETES = ADMIN_ENQUETES.sub("", AdminEnquetes);

const mergeSchema = (
  schema: ObjectSchema,
  properties: undefined | PropertyType[]
) => {
  return {
    ...schema,
    properties: [...schema.properties, ...(properties || [])],
  };
};

function Enquetes({ clientId }: { clientId: string }) {
  return <EnquetesInternal clientId={clientId} isClient={true} />;
}

function AdminEnquetes({ clientId }: { clientId: string }) {
  const { role } = useAdminAuthentication();
  if (role !== "master") {
    return <NotFound></NotFound>;
  }
  return <EnquetesInternal clientId={clientId} />;
}

function EnquetesInternal({
  clientId,
  isClient,
}: {
  clientId: string;
  isClient?: boolean;
}) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const [excludeDuplicate, setExcludeDuplicate] = React.useState(false);
  const { data: client, loading: clientLoading } = useClientDoc({ clientId });
  const baseSchema = useMemo(() => getSchema({ clientId }), [clientId]);
  const additionalSchema = useMemo(
    () => getAdditionalSchema({ clientId, isMaster }),
    [clientId]
  );
  const enqueteProperties = useMemo(() => {
    return [
      {
        title: "data",
        propertyName: "data",
        schema: (client.questions as ObjectSchema) || {
          schemaType: "object",
          properties: [],
          outputOptions: { useRealFields: true },
        },
      },
    ];
  }, [client]);
  const enquetePropertiesRealFields = [
    {
      title: "data",
      propertyName: "data",
      schema: {
        schemaType: "object",
        properties: [],
        outputOptions: { useRealFields: true },
      } as ObjectSchema,
    },
  ];

  const { list: ignoredShopList } = useQueryResource({
    resourcePath: "/shop",
    titleKey: "shopName",
    query: { filter: { clientId } },
    filter: {
      ignore: true,
    },
  });
  const ignoredShopIds = ignoredShopList?.map((i) => i.value) || [];
  const { list: ignoreSurveyList } = useQueryResource({
    resourcePath: "/survey",
    titleKey: "surveyName",
    query: { filter: { clientId } },
    filter: {
      ignore: true,
    },
  });
  const ignoreSurveyIds = ignoreSurveyList?.map((i) => i.value) || [];

  const { fixedCondition, fixedProperties, clientDownloadSchemaEnquete } =
    useMemo(() => {
      const excludeDuplicateCondition = excludeDuplicate
        ? { lottery: { $ne: "duplicate" } }
        : {};
      if (!isClient) return { fixedCondition: excludeDuplicateCondition };
      const tagMap = {} as { [key: string]: string[] };
      const propertyMap = {} as { [key: string]: PropertyType };
      for (const property of (client?.questions?.properties ||
        []) as PropertyType[]) {
        if (!property.propertyName) continue;
        const key = "data." + property.propertyName;
        for (const tag of property.tags || []) {
          tagMap[tag] = tagMap[tag] || [];
          tagMap[tag].push(key);
        }
        propertyMap[key] = property;
      }
      return {
        fixedCondition: {
          ignore: {
            $ne: true,
          },
          shopId: {
            $nin: [...ignoredShopIds],
          },
          surveyId: {
            $nin: [...ignoreSurveyIds],
          },
          ...excludeDuplicateCondition,
        },
        fixedProperties: tagMap.display
          ?.map(
            (propertyName) =>
              propertyMap[propertyName] && {
                ...propertyMap[propertyName],
                propertyName,
                title:
                  (
                    propertyMap[propertyName] as PropertyType & {
                      statShortName: string;
                    }
                  ).statShortName || propertyMap[propertyName].title,
              }
          )
          .filter((i) => i),
        clientDownloadSchemaEnquete: [
          {
            propertyName: "data",
            title: "data",
            schema: {
              schemaType: "object",
              properties: tagMap.display?.map((propertyName) => {
                const property = propertyMap[propertyName] && {
                  ...propertyMap[propertyName],
                  propertyName: propertyName.replace("data.", ""),
                  title:
                    (
                      propertyMap[propertyName] as PropertyType & {
                        statShortName: string;
                      }
                    ).statShortName || propertyMap[propertyName].title,
                };
                return property;
              }),
            },
          },
        ],
      };
    }, [client, ignoredShopIds, ignoreSurveyIds]);

  const { selectedProperties, schemaSelector } = useSchemaSelector({
    schema: mergeSchema(additionalSchema, enqueteProperties),
  });
  const fullSchema = useMemo(
    () =>
      mergeSchema(baseSchema, [
        ...additionalSchema.properties,
        ...enqueteProperties,
      ]),
    [client, clientId]
  );
  const fullSchemaRealFields = useMemo(
    () =>
      mergeSchema(baseSchema, [
        ...additionalSchema.properties,
        ...enquetePropertiesRealFields,
      ]),
    [client, clientId]
  );
  const clientFilterSchema = useMemo(
    () => getClientConditionSchema({ clientId }),
    [clientId]
  );
  const conditionalSchema = useMemo(
    () => (isClient ? clientFilterSchema : fullSchema),
    [isClient, fullSchema, clientFilterSchema]
  );
  const {
    conditionSelector,
    condition: userCondition,
    timeCondition,
  } = useConditionSelector({
    schema: conditionalSchema,
    unwrapped: true,
  });

  const { list, loading, hasMore, get, setRange, refresher, fetching, reload } =
    useEnqueteList({
      clientId,
      createdAt: timeCondition,
      condition: { ...fixedCondition, ...userCondition },
    });
  const clientDownloadHandler = useCallback(async () => {
    const list = await get();
    // ignoreのものはDL対象から除外（紐づく店舗、調査の無視も含む）
    return list?.filter((enquete) => {
      return (
        !enquete.ignore &&
        !ignoredShopIds.includes(enquete.shopId) &&
        !ignoreSurveyIds.includes(enquete.surveyId)
      );
    });
  }, [get, ignoredShopIds, ignoreSurveyIds]);

  const clientDownloadSchema = useMemo(() => {
    const _enqueteProperties = clientDownloadSchemaEnquete?.[0] as
      | PropertyType
      | undefined;
    const _schema = _enqueteProperties?.schema as ObjectSchema | undefined;
    return mergeSchema(
      baseSchema,
      _schema?.properties
        ? (clientDownloadSchemaEnquete as PropertyType[])
        : undefined
    );
  }, [baseSchema, clientDownloadSchemaEnquete]);
  const parameter: SchemaListPageParameter<enqueteData> = useMemo(() => {
    return {
      title: "アンケート一覧",
      edit: {
        path: ({ enqueteId }) =>
          isClient
            ? resolveRoute("UPDATE_ENQUETE", { enqueteId, clientId })
            : resolveRoute("UPDATE_ADMIN_ENQUETE", { enqueteId, clientId }),
      },
      schema: mergeSchema(baseSchema, fixedProperties || selectedProperties),
      download: {
        schema: isMaster
          ? (forceRealFields) =>
              forceRealFields ? fullSchemaRealFields : fullSchema
          : clientDownloadSchema,
        handler: isClient ? clientDownloadHandler : get,
        filename: `enquetes-${clientId}`,
      },
      setRange,
    };
  }, [
    fullSchema,
    baseSchema,
    clientId,
    get,
    clientDownloadHandler,
    setRange,
    selectedProperties,
    fixedProperties,
    clientDownloadSchema,
  ]);

  const header = useMemo(
    () => (
      <Form.Group as={Row} style={{ padding: "10px 20px 20px 20px" }}>
        {conditionSelector}
        <label
          style={{
            flex: "2 0 100px",
            display: "flex",
          }}
          data-tooltip-id="tooltip"
          data-tooltip-html="抽選結果が「重複」であるアンケート<br>（指定期間以内に繰り返し抽選されているため抽選が行われなかったアンケート）を除きます。<br>内容的に重複しているアンケートがすべて除外されているわけではありません。"
        >
          重複を除く<span style={{ width: "5px" }}></span>
          <SchemaInput
            schema={{
              schemaType: "boolean",
            }}
            data={excludeDuplicate}
            onValueChange={(value) => {
              setExcludeDuplicate(value);
              console.log(value);
            }}
          />
        </label>

        {!isClient && schemaSelector}
      </Form.Group>
    ),
    [conditionSelector, schemaSelector]
  );
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading || clientLoading}
      refresher={refresher}
      header={header}
      hasMore={hasMore}
      fetching={fetching}
      reload={reload}
    ></SchemaListPage>
  );
}
export default Enquetes;

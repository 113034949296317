import React, { useEffect, useMemo, useState } from "react";

import schema from "./schema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { useClientList } from "models/hook";
import { CLIENTS } from "../route";
import { resolveRoute } from "pages";
import { useAdminAuthentication } from "hooks/auth";
import { clientData, clientDefinition } from "common/models/client";
import { useConditionSelector } from "hooks/condition/conditionSelector";
import { queryAccessor } from "comodel-firestore-web";
import stringify from "fast-json-stable-stringify";
import { Query } from "mingo";
import { recentPeriod } from "schemaComponents/components/searchDate";
import { objectToSearchParams } from "utils/url";

export const LIST_CLIENTS = CLIENTS.sub("", Clients);

function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

function Clients() {
  const { conditionSelector, condition, timeCondition } = useConditionSelector({
    schema,
    hideTimeCondition: true,
  });
  const conditionFilter = useMemo(() => {
    const mingoQuery = new Query({ ...condition } || {});
    return mingoQuery.test.bind(mingoQuery);
  }, [stringify(condition)]);

  const { user, role } = useAdminAuthentication();
  const isMaster = role === "master";
  // const { list, loading, get } = useClientList({
  //   clientIds: user?.role !== "master" ? user?.clientIds : undefined,
  //   createdAt: timeCondition,
  //   condition,
  // });

  const [list, setList] = useState<clientData[] | undefined>();
  const [loading, setLoading] = useState(true);
  const [get, setGet] = useState<() => Promise<clientData[] | undefined>>();

  const chunkedClientIds = useMemo(
    () => chunkArray(user?.clientIds || [], 20),
    [user?.clientIds]
  );

  useEffect(() => {
    (async () => {
      try {
        if (user?.role !== "master") {
          const listArray = await Promise.all(
            chunkedClientIds.map(async (clientIds) => {
              const filter = { clientId: { $in: clientIds } };
              const accessor = queryAccessor(clientDefinition, {}, { filter });
              return (await accessor.get()) || [];
            })
          );
          const list = listArray.flat().filter(conditionFilter);
          setList(list);
        } else {
          const accessor = queryAccessor(
            clientDefinition,
            {},
            { orderBy: [["createdAt", "desc"]] }
          );
          const list = ((await accessor.get()) || []).filter(conditionFilter);
          setList(list);
          setGet(accessor.get);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [chunkedClientIds, conditionFilter]);

  const parameter: SchemaListPageParameter<clientData> = useMemo(
    () => ({
      title: "クライアント一覧",
      edit: {
        path: ({ clientId }) =>
          resolveRoute("LIST_ENQUETES", { clientId }) +
          "?" +
          objectToSearchParams({
            t: recentPeriod("day", 30),
          }),
      },
      create: {
        path: () => resolveRoute("ADD_CLIENT") + "?edit=1",
        isDisabled: !isMaster,
      },
      download: {
        isDisabled: !isMaster,
        filename: `clients`,
        handler: get,
      },
      schema,
    }),
    []
  );
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
      header={conditionSelector}
    ></SchemaListPage>
  );
}

export default Clients;

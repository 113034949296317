import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { pushGtm } from "./gtm";

let firstTime = true;

export const useTracker = () => {
  const location = useLocation();
  const pagePath = location.pathname + location.search;
  useEffect(() => {
    if (firstTime) {
      firstTime = false;
      return;
    }
    pushGtm({
      event: "page_view",
      pagePath,
    });
  }, [pagePath]);
};

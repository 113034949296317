import React, { useEffect, useMemo, useState } from "react";
import { COMMENT, COMMENTS } from "../route";
import { useTypedNavigate } from "pages";
import { useReportCommentDoc } from "models/hook";
import { Button, Spinner } from "react-bootstrap";
import { SchemaInput } from "components/SchemaInput";

import {
  DateRange,
  relativeMonth,
} from "schemaComponents/components/searchDate";
import dayjs from "dayjs";
import { useSearchParamAccessor } from "utils/url";
import { useFieldAccessor } from "hooks/accessor";
import { useAlert, useConfirm, useModalForm } from "hooks/dialogs";
import schema from "./schema";
import { Loading } from "components/Loading";
import { useStateWithDeps } from "./util";
import { useAdminPageAuthorization } from "hooks/authAdminPage";
import { useAdminAuthentication } from "hooks/auth";
import { SchemaForm } from "components/SchemaFormPage";
import { useGenerateReport } from "functions";
import { useLocalStorage } from "hooks/storage";

export const UPDATE_COMMENT = COMMENTS.sub("", Comment);

function Comment({ clientId }: { clientId?: string }) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";

  const searchParamAccessor = useSearchParamAccessor<{
    shopId?: string;
    clientId?: string;
    date?: DateRange;
  }>();
  const { value: shopId, setValue: setShopId } = useFieldAccessor(
    searchParamAccessor,
    "shopId"
  );

  const [editMode, setEditMode] = useStateWithDeps(false, [clientId]);

  const confirm = useConfirm();

  const { value: _dateRange, setValue: setDateRange } = useFieldAccessor(
    searchParamAccessor,
    "date"
  );

  const dateRange = _dateRange || relativeMonth(null, -1);

  const month = useMemo(() => {
    return dayjs(dateRange.$gte).format("YYYYMM");
  }, [dateRange]);

  const {
    data,
    loading,
    set,
    error: reportCommentDocError,
  } = useReportCommentDoc({
    shopId,
    month,
  });

  const { response, loading: contextLoading } = useGenerateReport({
    clientId,
    month,
  });

  const messages = useMemo(() => {
    if (response && shopId) {
      return response.filter((r) => r.shopId === shopId)[0];
    } else {
      return undefined;
    }
  }, [response, shopId]);

  const [result, setResult] = useState<{
    個別評価項目?: string;
    主要評価項目?: string;
    その他?: string;
    finished?: boolean;
  }>({});

  const [OPENAI_API_KEY] = useLocalStorage("OPENAI_API_KEY", "");

  useEffect(() => {
    if (!OPENAI_API_KEY) {
      setResult({});
      return;
    }
    (async () => {
      setResult({});
      for (const key of ["主要評価項目", "個別評価項目", "その他"] as const) {
        if (!messages?.[key]) continue;
        console.log("OPENAI_API_KEY", OPENAI_API_KEY);
        const response = await fetch(
          "https://api.openai.com/v1/chat/completions",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${OPENAI_API_KEY}`,
            },
            body: JSON.stringify({
              model: "gpt-4o",
              messages: messages[key],
            }),
          }
        );
        const data = await response.json();
        console.log(data);
        setResult((prev) => {
          return {
            ...prev,
            [key]: data.choices[0].message?.content as string,
          };
        });
      }
      setResult((prev) => {
        return {
          ...prev,
          finished: true,
        };
      });
    })();
  }, [messages, OPENAI_API_KEY]);

  return (
    <div>
      <>
        <section
          className="hero"
          style={{
            position: "fixed",
            zIndex: 10,
            background: "#eef4f4",
          }}
        >
          <div
            className="hero-body"
            style={{
              display: "flex",
              alignItems: "start",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                width: "240px",
                zIndex: 999,
                minWidth: "180px",
                marginTop: "2px",
              }}
            >
              <SchemaInput
                data={shopId}
                schema={{
                  schemaType: "externalKey",
                  resourcePath: "/shop",
                  uiType: "normal",
                  titleKey: "shopName",
                  query: {
                    filter: { clientId },
                  },
                  filter: {
                    ignore: { $truthy: false },
                  },
                  staticOptions: [
                    {
                      title: "全社",
                      value: null,
                    },
                  ],
                  order: "asc",
                }}
                onValueChange={setShopId}
              ></SchemaInput>
            </div>
            <div
              style={{
                marginLeft: "10px",
                zIndex: 998,
                minWidth: "180px",
                marginTop: "2px",
              }}
            >
              <SchemaInput
                data={dateRange}
                schema={{
                  schemaType: "month",
                }}
                onValueChange={setDateRange}
                deepCompare={true}
              ></SchemaInput>
            </div>

            {editMode && (
              <div
                style={{
                  marginLeft: "10px",
                  marginTop: "2px",
                }}
              >
                <Button
                  style={{ marginLeft: "5px" }}
                  variant="secondary"
                  onClick={async () => {
                    const result = await confirm({
                      message: "破棄しますか？",
                    });
                    if (!result) return;
                    setEditMode(false);
                  }}
                  data-tooltip-id="tooltip"
                  data-tooltip-content="破棄して閲覧画面に戻る"
                >
                  <i className="mdi mdi-window-close"></i>
                </Button>
              </div>
            )}
            {isMaster && !editMode && (
              <div
                style={{
                  marginLeft: "10px",
                  marginTop: "2px",
                }}
              >
                <Button
                  variant="secondary"
                  onClick={async () => {
                    setEditMode(true);
                  }}
                  data-tooltip-id="tooltip"
                  data-tooltip-content="編集"
                >
                  <i className="mdi mdi-pencil"></i>
                </Button>
              </div>
            )}
          </div>
        </section>
      </>
      <div
        style={{
          paddingTop: "60px",
        }}
        className="dualColumns"
      >
        <section>
          {loading ? (
            <div style={{ padding: "20px" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <SchemaForm
              data={data}
              parameter={{
                title: "コメント",
                schema,
                edit: {
                  title: "保存",
                  confirm: "保存しますか？",
                  handler: set,
                },
              }}
            />
          )}
        </section>
        <section>
          <div className="tile">
            <div className="card tile">
              <div className="card-content">
                {!contextLoading && (
                  <SchemaInput
                    key={Object.keys(result).length}
                    data={{
                      comments: {
                        個別評価項目: result.個別評価項目,
                        総合満足度: result.主要評価項目,
                        その他: result.その他,
                      },
                    }}
                    schema={schema}
                    // readOnly={true}
                    deepCompare={true}
                  ></SchemaInput>
                )}
                {contextLoading && (
                  <>
                    <Loading />
                    レポートデータの分析中。
                    1分程度かかります。クライアント/月ごとに1度だけ分析します。
                  </>
                )}
                {!contextLoading && !result?.finished && (
                  <>
                    <Loading />
                    コメントの生成中
                  </>
                )}
                {/* {!contextLoading && ( */}
                <div style={{ padding: "10px 0px" }}>
                  右側にAIによるコメント生成結果を表示します。
                  左側がアナリストによるコメントです。
                  右側の生成結果を参考に、左側にコメントを追加し、保存してください。
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Comment;

import React from "react";
import { formatNumber } from "../statSchemaUtil";
import { StatConfig } from "./type";

export const cross = ({
  isClientData,
}: {
  isClientData: boolean;
}): StatConfig =>
  isClientData
    ? {
        action: "cross",
        statSchemas: [
          {
            value: "cross-client-value",
            title: "全社",
            graph: true,
            // table: false,
          },
        ],
      }
    : {
        action: "cross",
        statSchemas: [
          {
            value: "cross-shop-value",
            title: "事業所",
            graph: true,
            // table: false,
          },
          {
            value: "cross-client-value",
            title: "全社",
            graph: true,
            // table: false,
          },
        ],
      };

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useFormatter } from "hooks/intl";
import NotFoudImage from "assets/404.gif";
import classes from "./NotFound.module.scss";
import Button from "react-bootstrap/Button";
import { resolveRoute } from "pages";
import { ROOT } from "pages/root";

export const NOT_FOUND = ROOT.sub("*", NotFound);

function NotFound() {
  const location = useLocation();
  const isAdminPath = location.pathname.startsWith("/admin");
  const userPath = isAdminPath ? resolveRoute("ADMIN_HOME") : "/";
  const { formatMessage } = useFormatter();
  return (
    <div className="consumer-container">
      <section>
        <div className="hero-body" style={{ paddingTop: "calc(50vh - 200px)" }}>
          <section className={`section ${classes.section}`}>
            <div className={classes.columns}>
              <div className={classes.column}>
                <h1 className="title">{formatMessage("NotFound.404")}</h1>
                <p className="subtitle">
                  {formatMessage("NotFound.url", { url: location.pathname })}
                </p>
                <Link
                  to={userPath}
                  style={{ padding: "30px 0", display: "block" }}
                >
                  <Button>{formatMessage("NotFound.back")}</Button>
                </Link>
              </div>
              <div className={classes.column}>
                <img src={NotFoudImage} alt="404 error" />
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}

export default NotFound;

import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  collection,
  doc,
  DocumentReference,
  getFirestore,
  query,
  DocumentData,
  getDocs,
} from "firebase/firestore";

import {
  QueryResult,
  createDocumentRefHook,
  getQueryCache,
  setVerbose,
} from "@smart-hook/react-smart-hook-firebase";

import {
  createMultipleStoreCacheHook,
  createStoreCacheHook,
} from "@smart-hook/react-hook-retention-cache";

import firebaseApp from "firebaseApp";
import type { FilterParams, QueryParams } from "common/comodel-firestore";
import { getQueryConstraints } from "comodel-firestore-web/queryUtil";
import { resolveStringIntl } from "hooks/intl";
import { Query } from "mingo";
import { useMemo } from "react";

import stringify from "fast-json-stable-stringify";

const firestore = getFirestore(firebaseApp);

type MingoParams = Record<string, unknown>;

const useDocumentData = createDocumentRefHook();
export const useDocResource = (resourcePath: string, id?: string) => {
  return useDocumentData<Record<string, string>>(
    id
      ? (doc(firestore, `${resourcePath}/${id}`) as DocumentReference<{
          id: string;
          [key: string]: string;
        }>)
      : undefined
  );
};

const optionConverter = (
  titleKey: string,
  filter?: MingoParams
): FirestoreDataConverter<{
  value: string;
  title: string;
  disabled?: boolean;
}> => {
  const mingoFilter = filter ? new Query(filter) : undefined;
  return {
    toFirestore: (d) => d,
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>) => {
      const data = snapshot.data();
      return {
        title: resolveStringIntl(data[titleKey]) || "",
        value: snapshot.id,
        disabled: mingoFilter ? !mingoFilter.test(data) : false,
      };
    },
  };
};

const queryCache = getQueryCache(
  ({
    resourcePath,
    titleKey,
    query: _query,
    filter,
  }: {
    resourcePath: string;
    titleKey: string;
    query?: QueryParams;
    filter?: MingoParams;
  }) => {
    return query(
      collection(firestore, resourcePath),
      ...getQueryConstraints(_query || {})
    ).withConverter(optionConverter(titleKey, filter));
  },
  { withData: true, retentionTime: 1000 }
);

async function resolveObject<T, S>(
  request: Record<string, T>,
  converter: (input: T) => Promise<S>
): Promise<Record<string, S>> {
  const entries = Object.entries(request);
  const resolvedEntries = await Promise.all(
    entries.map(async ([key, value]) => [key, await converter(value)])
  );
  return Object.fromEntries(resolvedEntries) as Record<string, S>;
}

export const getListFromMultipleQueries = async (
  resourceRequests: Record<
    string,
    {
      resourcePath: string;
      titleKey: string;
      query?: QueryParams;
    }
  >
) => {
  const resolve = resolveObject(resourceRequests, async (value) => {
    const { resourcePath, titleKey, query: _query } = value;
    try {
      const snapShot = await getDocs(
        query(
          collection(firestore, resourcePath),
          ...(_query ? getQueryConstraints(_query) : [])
        ).withConverter(optionConverter(titleKey))
      );
      const list = snapShot.docs.map((doc) => doc.data());
      return { list };
    } catch (error) {
      console.error(error, {
        error,
        resourcePath,
        titleKey,
        query: _query,
      });
      throw error;
    }
  });
  return resolve;
};

type emptyObject = { [P in string]: never };
type Result =
  | emptyObject
  | QueryResult<{
      value: string;
      title: string;
      disabled?: boolean | undefined;
    }>
  | undefined;
type ResultRecord = Record<string, Result>;
export const _useQueryResource = createStoreCacheHook(
  queryCache,
  {} as emptyObject
);
export const _useMultipleQueryResource = createMultipleStoreCacheHook(
  queryCache,
  {} as emptyObject
);
export const useQueryResource = (
  params:
    | {
        resourcePath: string;
        titleKey: string;
        query?: QueryParams;
        filter?: MingoParams;
      }
    | null
    | undefined
) => {
  const result = _useQueryResource(params);

  const list = useMemo(
    () => result?.list?.filter((item) => !item.disabled),
    [result?.list]
  );
  return {
    ...result,
    list,
  };
};
export const useMultipleQueryResource = (
  params: Record<
    string,
    {
      resourcePath: string;
      titleKey: string;
      query?: QueryParams | undefined;
      filter?: MingoParams | undefined;
    }
  >
) => {
  const result = _useMultipleQueryResource(params);

  const record: ResultRecord = {};
  Object.fromEntries(
    Object.entries(result).map(([key, value]) => {
      const list = value?.list?.filter((item) => !item.disabled);
      record[key] = { ...value, list } as Result;
      return [key, list];
    })
  );
  return record;
};

const cache = new WeakMap<
  { title: string; value: string }[],
  Map<string, string>
>();
export const toMap = (list: { title: string; value: string }[]) => {
  if (!cache.has(list)) {
    cache.set(list, new Map(list.map((item) => [item.value, item.title])));
  }
  return cache.get(list);
};

// setVerbose(true);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).legacyQueryCache = queryCache;

import React from "react";
import ConsumerLayout from "components/ConsumerLayout";
import { Outlet } from "react-router-dom";
import { ROOT } from "pages/root";
import { useClientDoc, useShopDoc } from "models/hook";
import LocaleProvider from "components/LocaleProvider";

export const CONSUMER = ROOT.sub("/shop/:shopId", Consumer, {
  parent: true,
});

function Consumer({ shopId }: { shopId: string }) {
  const { data: shopData } = useShopDoc({ shopId });
  const { data: clientData } = useClientDoc({ clientId: shopData?.clientId });
  return (
    <LocaleProvider availableLocales={clientData?.availableLocales}>
      <Outlet />
    </LocaleProvider>
  );
}

import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";

const Link = ({
  children,
  to,
  className,
  onClick,
  activeStyle,
  matchSearchParams,
  style,
}: React.PropsWithChildren<{
  className?: string;
  activeStyle?: boolean;
  to: string;
  matchSearchParams?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  style?: React.CSSProperties;
}>) => {
  const location = useLocation();
  return (
    <NavLink
      to={to}
      style={style}
      className={({ isActive }) => {
        const active =
          activeStyle != null
            ? activeStyle
            : matchSearchParams
            ? to === location.pathname + location.search
            : isActive;
        return classNames(
          className != null ? className : "navbar-item",
          active && "is-active"
        );
      }}
      onClick={onClick}
      end
    >
      {children}
    </NavLink>
  );
};

export default Link;

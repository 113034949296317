import React from "react";
import { ObjectSchema } from "schemaComponents";
import schema from "./schema";
import getSchema from "../item/schema";
import { timestampsProperties, mergeSchema } from "schemaComponents/util";

export default ({
  clientId,
  isMaster,
}: {
  clientId: string;
  isMaster: boolean;
}): ObjectSchema => {
  return mergeSchema({ schemaType: "object", properties: [] }, [
    {
      title: "調査ID",
      propertyName: "surveyId",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
    ...schema.properties,
    ...(getSchema({ isEditing: false, clientId, isMaster }) as ObjectSchema)
      .properties,
    ...timestampsProperties,
  ]);
};

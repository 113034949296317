import React from "react";
import {
  SchemaComponent,
  useField,
  requiredValidator,
  BaseSchema,
  useValue,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";
import { emailValidator } from "utils/validators";

interface EmailSchema extends BaseSchema {
  schemaType: "email";
  required?: boolean;
}

const EmailSchemaComponent: SchemaComponent<EmailSchema> = (props) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
    emailValidator,
  ]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        type="email"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        value={value || ""}
        {...registerProps}
      />
    </FieldWrapper>
  );
};

EmailSchemaComponent.display = DisplayComponent("EmailDisplayComponent");

export default EmailSchemaComponent;

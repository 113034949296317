import { resolveRoute } from "pages";
import { ObjectSchema } from "schemaComponents";
import { clientDomain } from "utils/domain";

export default ({ clientId }: { clientId: string }): ObjectSchema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "事業所名",
        propertyName: "shopName",
        schema: {
          schemaType: "textIntl",
          required: true,
        },
      },
      {
        title: "ロゴ",
        propertyName: "logoURL",
        schema: {
          schemaType: "image",
          maxHeight: 30,
        },
      },
      {
        title: "調査",
        propertyName: "surveyId",
        schema: {
          uiType: "normal",
          schemaType: "externalKey",
          resourcePath: "/survey",
          titleKey: "surveyName",
          query: { filter: { clientId } },
        },
      },
      {
        title: "アンケート開始日時",
        propertyName: "enqueteStartAt",
        schema: {
          schemaType: "datetime",
        },
      },
      {
        title: "アンケート終了日時",
        propertyName: "enqueteEndAt",
        schema: {
          schemaType: "datetime",
        },
      },
      {
        title: "URL",
        propertyId: "url",
        schema: {
          schemaType: "url",
          readOnly: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          calc: (item: any) => {
            const { shopId, isAuthSkipped } = item;
            return isAuthSkipped
              ? shopId &&
                  `${clientDomain}${resolveRoute("CONSUMER_PUBLIC_ENQUETE", {
                    shopId,
                  })}`
              : shopId &&
                  `${clientDomain}${resolveRoute("CONSUMER_ENTRY", {
                    shopId,
                  })}`;
          },
        },
      },
    ],
  };
};

/**
 * 数値の桁数を取得する
 * @param value
 * @returns
 */
export const getNumberDigits = (value: number) => {
  const absValue = Math.abs(value);
  if (absValue < 1 && absValue > 0) {
    const decimalPart = absValue % 1;
    return -Math.ceil(-Math.log10(decimalPart));
  } else if (absValue >= 1) {
    return Math.floor(Math.log10(absValue));
  }
  return 0;
};

import { PropertyType, Schema } from "schemaComponents";

export default ({ isEditing }: { isEditing: boolean }): Schema => {
  return {
    schemaType: "object",
    properties: [
      ...(!isEditing
        ? ([
            {
              title: "初期E-MAIL",
              propertyName: "initialEmail",
              schema: { schemaType: "text", readOnly: isEditing },
            },
          ] as PropertyType[])
        : []),
      {
        title: "権限",
        propertyName: "role",
        schema: {
          schemaType: "selector",
          options: [
            {
              title: "マスター",
              value: "master",
            },
            {
              title: "クライアント",
              value: "client",
            },
          ],
        },
      },
      {
        title: "クライアント",
        propertyName: "clientIds",
        schema: {
          schemaType: "multipleExternalKey",
          uiType: "normal",
          resourcePath: "/client",
          titleKey: "clientName",
        },
      },
      {
        title: "氏名",
        propertyName: "name",
        schema: { schemaType: "text" },
      },
      {
        title: "所属",
        propertyName: "organization",
        schema: { schemaType: "text" },
      },
      {
        title: "エラーコード",
        propertyName: "errorCode",
        schema: { schemaType: "text", readOnly: true },
      },
    ],
  };
};

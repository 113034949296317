import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "公開",
      propertyName: "isPublic",
      schema: {
        schemaType: "boolean",
      },
    },
    {
      title: "2カラム",
      propertyName: "dualColumns",
      schema: {
        schemaType: "boolean",
      },
    },
    {
      title: "優先順位（文字列）",
      propertyName: "priority",
      schema: {
        schemaType: "text",
      },
    },
  ],
};
export default schema;

import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";

interface MultilineTextSchema extends BaseSchema {
  schemaType: "multilineText";
  required?: boolean;
  rows?: number;
}

const MultilineTextSchemaComponent: SchemaComponent<MultilineTextSchema> = (
  props: SchemaComponentInternalProps<MultilineTextSchema>
) => {
  const { value, registerProps, fieldState } = useField(props, [
    requiredValidator,
  ]);
  const { schema } = props;
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        as="textarea"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        rows={schema.rows || 6}
        value={value || ""}
        {...registerProps}
      />
    </FieldWrapper>
  );
};

MultilineTextSchemaComponent.display = DisplayComponent(
  "MultilineTextDisplayComponent",
  (value) => {
    return <div style={{ whiteSpace: "pre-wrap" }}>{value}</div>;
  }
);

export default MultilineTextSchemaComponent;

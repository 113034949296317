import React, { useEffect, useMemo } from "react";
import { ControllerFieldState } from "react-hook-form";
import {
  BaseSchema,
  SchemaComponent,
  requiredValidator,
  useField,
} from "react-hook-schema-form";
import Form from "react-bootstrap/Form";
import classNames from "classnames";

import { DisplayComponent } from "./common/displayWrapper";
import { FieldWrapper } from "./common/fieldWrapper";
import { useContextLocale } from "components/LocaleProvider";
import { useContract } from "hooks/dialogs";
import { useContractConfirmed } from "hooks/contract";
import { useFormatter } from "hooks/intl";

interface ContractConfirmSchema extends BaseSchema {
  schemaType: "contractConfirm";
  required?: boolean;
  issuerId: string;
  contractTitle: string;
  contractContent: string;
  email?: string;
  syncMode?: "readOnly" | "sync";
}

const ContractConfirmSchemaComponent: SchemaComponent<ContractConfirmSchema> = (
  props
) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  const { contractTitle, contractContent, issuerId, syncMode, email } =
    props.schema;
  const [locale] = useContextLocale();
  const { formatMessage, formatString } = useFormatter();
  const contract = useContract();
  const { contractConfirmed, setContractConfirmed, confirmedContractId } =
    useContractConfirmed(
      { contractTitle, contractContent, issuerId, email },
      syncMode
    );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setContractConfirmed(newValue);
  };
  const handleClickLink = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const isApproved = await contract({
      title: contractTitle,
      notice: formatMessage("PrivacyPolicy.Text"),
      message: contractContent,
      buttonLabelOK: formatMessage("Contract.OK"),
      buttonLabelCancel: formatMessage("Contract.Cancel"),
    });
    setContractConfirmed(isApproved);
  };
  const errorMessage = useMemo(() => {
    return (
      !contractConfirmed &&
      !value && {
        type: "needsPrivacyPolicy",
        message: formatMessage("validation/contract"),
      }
    );
  }, [value, contractConfirmed, locale]);

  useEffect(() => {
    if (confirmedContractId !== value)
      registerProps.onChange(confirmedContractId);
  }, [confirmedContractId, value]);

  return (
    <FieldWrapper
      fieldState={
        { error: errorMessage || fieldState.error } as ControllerFieldState
      }
    >
      <Form.Check
        type="checkbox"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        style={{ display: "inline-block" }}
        label={formatMessage("Contract.Approve")}
        checked={value || false}
        {...registerProps}
        onChange={onChange}
      />
      &nbsp;
      <a href="#" onClick={handleClickLink}>
        {formatMessage("Contract.Check")}
      </a>
    </FieldWrapper>
  );
};

ContractConfirmSchemaComponent.display = DisplayComponent(
  "ContractConfirmDisplayComponent"
);

export default ContractConfirmSchemaComponent;

export const projectId = process.env.REACT_APP_FIRE_BASE_PROJECT_ID;

export const environmentType =
  {
    "roi-tatemono-shop-enquete-dev": "dev",
    "fancrew-in-shop": "production",
    unknown: "unknown",
  }[projectId || "unknown"] || projectId;

export const environmentName =
  {
    "roi-tatemono-shop-enquete-dev": "開発",
    "fancrew-in-shop": "本番",
    unknown: "未指定",
  }[projectId || "unknown"] || projectId;

export const isProductionEnv = projectId === "fancrew-in-shop";

import React from "react";
import {
  SchemaComponent,
  useField,
  requiredValidator,
  BaseSchema,
  useValue,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";

interface TextSchema extends BaseSchema {
  schemaType: "text";
  required?: boolean;
}

const TextSchemaComponent: SchemaComponent<TextSchema> = (props) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        type="text"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        value={value || ""}
        {...registerProps}
      />
    </FieldWrapper>
  );
};

TextSchemaComponent.display = DisplayComponent("TextDisplayComponent");

export default TextSchemaComponent;

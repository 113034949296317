import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";

interface TsvSchema extends BaseSchema {
  schemaType: "tsv";
  required?: boolean;
}

const TsvSchemaComponent: SchemaComponent<TsvSchema> = (
  props: SchemaComponentInternalProps<TsvSchema>
) => {
  const { value, registerProps, fieldState } = useField(props, [
    requiredValidator,
  ]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (e: any) => {
    const text = e.target.value as string;
    const lines = text.split("\n").map((line) => line.split("\t"));
    const header = lines.shift();
    if (!header) throw new Error();

    const allData = lines.map((line) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const lineData = {} as Record<string, any>;
      line.forEach((item, index) => {
        if (item) {
          const path = header[index];
          const keys = path.split(".");
          keys.reduce((prev, key, index) => {
            if (index < keys.length - 1) {
              if (!prev[key]) {
                prev[key] = {};
              }
              return prev[key];
            } else {
              prev[key] = item;
              return item;
            }
          }, lineData);
        }
      });
      return lineData;
    });

    console.log({ lines, allData });
    registerProps.onChange(allData);
  };
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        as="textarea"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        style={{ height: "160px" }}
        defaultValue={""}
        {...registerProps}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};

TsvSchemaComponent.display = DisplayComponent("TsvDisplayComponent");

export default TsvSchemaComponent;

import React from "react";
import { MediaWithMimeType, MediaSpecifier } from "./media";
import { Overwrite } from "./types";
import { MultimediaElement, MultiMediaElementProps } from "./multimediaElement";

type ElementProps = MultiMediaElementProps;

export const Multimedia = (
  props: Overwrite<ElementProps, { src: MediaSpecifier }>
) => {
  return (
    <MediaWithMimeType<ElementProps>
      {...props}
      elementType={MultimediaElement}
    />
  );
};

import React, { useMemo } from "react";
import schema from "./schema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { useIndustryReportList, useReportList } from "models/hook";

import { REPORTS } from "../route";
import { resolveRoute } from "pages";
import { reportData } from "common/models/report";
import { useConditionSelector } from "hooks/condition/conditionSelector";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const LIST_REPORTS = REPORTS.sub("", Reports);

function Reports({
  clientId,
  industryId,
}: {
  clientId?: string;
  industryId?: string;
}) {
  useAdminPageAuthorization(clientId);
  const { conditionSelector, condition, timeCondition } = useConditionSelector({
    schema,
    hideTimeCondition: true,
  });
  const { list, loading, get } = (() => {
    if (clientId) {
      return useReportList({ clientId, createdAt: timeCondition, condition });
    } else if (industryId) {
      return useIndustryReportList({
        industryId,
        createdAt: timeCondition,
        condition,
      });
    }
    throw new Error();
  })();
  const parameter: SchemaListPageParameter<reportData> = useMemo(() => {
    if (clientId) {
      return {
        title: "レポート一覧",
        edit: {
          path: ({ reportId }) =>
            resolveRoute(
              "UPDATE_REPORT",
              { reportId, clientId },
              { retainSearchParams: true }
            ),
        },
        create: {
          path: () =>
            resolveRoute(
              "ADD_REPORT",
              { clientId },
              { retainSearchParams: true }
            ),
        },
        download: {
          filename: `reports-${clientId}`,
          handler: get,
        },
        schema,
      };
    } else if (industryId) {
      return {
        title: "レポート一覧",
        edit: {
          path: ({ reportId }) =>
            resolveRoute(
              "UPDATE_INDUSTRY_REPORT",
              { reportId, industryId },
              { retainSearchParams: true }
            ),
        },
        create: {
          path: () =>
            resolveRoute(
              "ADD_INDUSTRY_REPORT",
              { industryId },
              { retainSearchParams: true }
            ),
        },
        schema,
      };
    }
    throw new Error();
  }, [clientId, industryId]);
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
      header={conditionSelector}
    ></SchemaListPage>
  );
}

export default Reports;

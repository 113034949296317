import React, { useCallback, useMemo } from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";
import { Slider } from "./common/slider";

interface NumberSchema extends BaseSchema {
  schemaType: "number";
  required?: boolean;
  uiType?: "number" | "slider";
  minValue?: number;
  maxValue?: number;
}

const NumberSchemaComponent: SchemaComponent<NumberSchema> = (
  props: SchemaComponentInternalProps<NumberSchema>
) => {
  const { schema } = props;
  const { uiType, minValue, maxValue } = schema;
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  const options = useMemo(() => {
    const options: Array<{ title: string; value: number }> = [];
    if (minValue === undefined || maxValue === undefined) return options;
    for (let i = minValue; i <= maxValue; i++) {
      options.push({
        title: i.toString(),
        value: i,
      });
    }
    return options;
  }, [minValue, maxValue]);
  const sliderValue = useMemo(() => {
    const index = options.findIndex((option) => option.value === value);
    return index === -1 ? undefined : index;
  }, [options, value]);
  const sliderOnChange = useCallback(
    (index?: number) => {
      const option = index != null ? options[index] : undefined;
      registerProps.onChange(option?.value);
    },
    [registerProps.onChange, options]
  );

  if (uiType === "slider" && (minValue === undefined || maxValue === undefined))
    return <div>Set minValue and maxValue when uiType is slider.</div>;

  return (
    <FieldWrapper fieldState={fieldState}>
      {uiType === "slider" ? (
        <Slider
          options={options}
          onChange={sliderOnChange}
          value={sliderValue}
          hasError={fieldState.invalid}
        />
      ) : (
        <Form.Control
          type="number"
          pattern="\d*"
          min={minValue}
          max={maxValue}
          className={classNames({
            "has-error": fieldState.invalid,
          })}
          value={typeof value === "number" ? value : ""}
          {...registerProps}
          onChange={(e) => {
            registerProps.onChange(parseInt(e.target.value));
          }}
        />
      )}
    </FieldWrapper>
  );
};

NumberSchemaComponent.display = DisplayComponent("NumberDisplayComponent");

export default NumberSchemaComponent;

import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";

interface NumberSchema extends BaseSchema {
  schemaType: "float";
  required?: boolean;
}
//
const NumberSchemaComponent: SchemaComponent<NumberSchema> = (
  props: SchemaComponentInternalProps<NumberSchema>
) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        type="number"
        pattern="[\d\.]*"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        value={typeof value === "number" ? value : ""}
        {...registerProps}
        onChange={(e) => {
          registerProps.onChange(parseFloat(e.target.value));
        }}
        step={0.1}
      />
    </FieldWrapper>
  );
};

NumberSchemaComponent.display = DisplayComponent("NumberDisplayComponent");

export default NumberSchemaComponent;

import { useFormatter } from "hooks/intl";
import { Schema } from "schemaComponents";

const getSchema = ({
  issuerId,
  contractTitle,
  contractContent,
}: {
  issuerId: string;
  contractTitle: string;
  contractContent: string;
}): Schema => {
  const { formatMessage } = useFormatter();
  return {
    schemaType: "object",
    properties: [
      {
        propertyName: "email",
        title: formatMessage("Field.Email"),
        schema: {
          schemaType: "email",
          required: true,
        },
      },
      {
        propertyName: "contractConfirm",
        title: formatMessage("Field.ContractConfirm"),
        schema: {
          schemaType: "contractConfirm",
          required: true,
          issuerId,
          contractTitle,
          contractContent,
        },
      },
    ],
  };
};

export default getSchema;

import React from "react";
import { Schema } from "schemaComponents";

export default ({
  isEditing,
  clientId,
  isMaster,
}: {
  isEditing: boolean;
  clientId: string;
  isMaster: boolean;
}): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "調査名",
        propertyName: "surveyName",
        schema: {
          schemaType: "text",
          required: true,
        },
      },

      {
        title: "連続抽選無効化日数",
        propertyName: "invalidationDays",
        schema: {
          schemaType: "number",
          required: true,
        },
      },
      ...(isMaster
        ? [
            {
              title: "アンケート",
              propertyName: "enquete",
              schema: {
                schemaType: "json",
              },
            },
            {
              title: "アンケート(CSV)",
              propertyName: "enqueteCsv",
              schema: {
                schemaType: "enquete2",
                setFieldPath: "enquete",
              },
            },
          ]
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([] as any[])),
      ...(isMaster
        ? [
            {
              title: "リワード条件",
              propertyName: "rewordCondition",
              schema: {
                schemaType: "array",
                defaultValue: {
                  probability: null,
                  rewardId: null,
                  animationId: null,
                },
                required: true,
                item: {
                  schemaType: "object",
                  // uiType: "dialog",
                  properties: [
                    {
                      title: "リワード",
                      propertyName: "rewardId",
                      schema: {
                        schemaType: "externalKey",
                        uiType: "normal",
                        resourcePath: "/reward",
                        titleKey: "rewardName",
                        query: { filter: { clientId } },
                      },
                    },
                    {
                      title: "確率",
                      propertyName: "probability",
                      schema: {
                        schemaType: "float",
                      },
                    },

                    {
                      title: "当選アニメーションパターン",
                      propertyName: "animationId",
                      schema: {
                        schemaType: "selector",
                        uiType: "normal",
                        options: [
                          {
                            title: "1等 (ラッキーセブン)",
                            value: 1,
                          },
                          {
                            title: "2等 (赤い果実)",
                            value: 2,
                          },
                          {
                            title: "3等 (ベル)",
                            value: 3,
                          },
                          {
                            title: "4等 (青い果実)",
                            value: 4,
                          },
                          {
                            title: "5等 (オレンジ)",
                            value: 5,
                          },
                          {
                            title: "はずれ（そろわない）",
                            value: 0,
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            },
            {
              title: "無視",
              propertyName: "ignore",
              schema: { schemaType: "boolean" },
            },
          ]
        : []),
      {
        title: "surveyId",
        propertyName: "surveyId",
        schema: {
          schemaType: "text",
          readOnly: true,
        },
      },
    ],
  };
};

import {
  createResolver,
  SchemaOfComponent,
  createSelector,
} from "../react-hook-schema-form";

import * as schemaComponents from "./modules";
export { schemaComponents };
export type SchemaTypes = keyof typeof schemaComponents;
export type Schema = SchemaOfComponent<(typeof schemaComponents)[SchemaTypes]>;

export const resolve = createResolver(schemaComponents);
export const Field = createSelector(schemaComponents);

export type ObjectSchema = SchemaOfComponent<typeof schemaComponents.object>;

type itemOfArray<T> = T extends Array<infer U> ? U : never;
export type PropertyType = itemOfArray<
  SchemaOfComponent<typeof schemaComponents.object>["properties"]
>;

export type SchemaOfType<T extends keyof typeof schemaComponents> =
  SchemaOfComponent<(typeof schemaComponents)[T]>;

import { ObjectSchema } from "schemaComponents";

const getSchema = ({ clientId }: { clientId: string }): ObjectSchema => ({
  schemaType: "object",
  properties: [
    // {
    //   title: "アンケートID",
    //   propertyName: "enqueteId",
    //   schema: { schemaType: "text", required: true },
    // },
    {
      title: "事業所名",
      propertyName: "shopId",
      schema: {
        schemaType: "externalKey",
        resourcePath: "/shop",
        titleKey: "shopName",
        query: { filter: { clientId } },
        readOnly: true,
      },
    },
    // {
    //   title: "調査",
    //   propertyName: "surveyId",
    //   schema: {
    //     uiType: "normal",
    //     schemaType: "externalKey",
    //     resourcePath: "/survey",
    //     titleKey: "surveyName",
    //     query: { filter: { clientId } },
    //     readOnly: true,
    //   },
    // },
    // {
    //   title: "E-Mail",
    //   propertyName: "email",
    //   schema: { schemaType: "text", required: true },
    // },
    // {
    //   title: "抽選動画視聴完了",
    //   propertyName: "movieFinished",
    //   schema: { schemaType: "boolean" },
    // },
    // {
    //   title: "抽選結果",
    //   propertyName: "lottery",
    //   schema: {
    //     schemaType: "selector",
    //     options: [
    //       { title: "当選", value: "win" },
    //       { title: "落選", value: "lose" },
    //       { title: "重複", value: "duplicate" },
    //     ],
    //   },
    // },
    {
      title: "作成日時",
      propertyName: "createdAt",
      schema: { schemaType: "datetime" },
    },
  ],
});
export default getSchema;

export const getAdditionalSchema = ({
  clientId,
  isMaster,
}: {
  clientId: string;
  isMaster: boolean;
}): ObjectSchema => ({
  schemaType: "object",
  properties: [
    {
      title: "アンケートID",
      propertyName: "enqueteId",
      schema: { schemaType: "text", required: true },
    },
    {
      title: "調査",
      propertyName: "surveyId",
      schema: {
        uiType: "normal",
        schemaType: "externalKey",
        resourcePath: "/survey",
        titleKey: "surveyName",
        query: { filter: { clientId } },
        readOnly: true,
      },
    },
    ...(isMaster
      ? [
          {
            title: "E-Mail",
            propertyName: "email",
            schema: { schemaType: "text", required: true },
          },
        ]
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ([] as any[])),
    {
      title: "キャンペーンID",
      propertyName: "campaignId",
      schema: { schemaType: "text" },
    },
    {
      title: "抽選アニメーション視聴完了",
      propertyName: "movieFinished",
      schema: { schemaType: "boolean" },
    },
    {
      title: "抽選結果",
      propertyName: "lottery",
      schema: {
        schemaType: "selector",
        options: [
          { title: "当選", value: "win" },
          { title: "落選", value: "lose" },
          { title: "重複", value: "duplicate" },
        ],
      },
    },
    {
      title: "無視",
      propertyName: "ignore",
      schema: { schemaType: "boolean" },
    },
    ...(isMaster
      ? [
          {
            title: "Coupon URL",
            propertyName: "couponUrl",
            schema: {
              schemaType: "url",
              readOnly: true,
            },
          },
        ]
      : []),
  ],
});

export const getClientConditionSchema = ({
  clientId,
}: {
  clientId: string;
}): ObjectSchema => ({
  schemaType: "object",
  properties: [
    {
      title: "事業所名",
      propertyName: "shopId",
      schema: {
        schemaType: "externalKey",
        resourcePath: "/shop",
        titleKey: "shopName",
        query: { filter: { clientId } },
        readOnly: true,
        filter: {
          ignore: { $truthy: false },
        },
      },
    },
  ],
});

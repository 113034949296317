import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useClientDoc } from "models/hook";
import { AdminLocaleSelector } from "hooks/localeSelector";
import { SYSTEM_SETTING } from "..";
import { availableLocales } from "utils/locale";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const UPDATE_SYSTEM_SETTING = SYSTEM_SETTING.sub("/edit", Client);
// export const ADD_CLIENT = CLIENTS.sub("/edit/new", Client);

function Client() {
  useAdminPageAuthorization();
  const clientId = "default";
  console.log("Client", { clientId });

  const { data, loading, set, remove, isEditing, ref } = useClientDoc({
    clientId,
  });
  const parameter: SchemaFormParameter = {
    title: "システム設定",
    schema: getSchema(),
    edit: {
      title: `${isEditing ? "保存" : "保存"}`,
      confirm: `${isEditing ? "保存" : "保存"}しますか？`,
      handler: set,
    },
    header: {
      isStacked: true,
    },
    // remove: isEditing ? { handler: remove } : undefined,
  };
  console.log("Client", { isEditing, ref, clientId, data, parameter });
  return (
    <SchemaFormPage
      data={data}
      parameter={parameter}
      loading={isEditing && loading}
      header={<AdminLocaleSelector locales={availableLocales} />}
      editModeEnabled={true}
    />
  );
}

export default Client;

import React, { useMemo, useState } from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";
import {
  availableLocaleOptions,
  availableLocales,
  getMultiLocaleValues,
} from "utils/locale";
import { DescreteSelector } from "./selector";
import { useSessionStorage } from "hooks/storage";
import { Button } from "react-bootstrap";
import {
  AdminDropdownLocaleSelector,
  useEditingLocale,
} from "hooks/localeSelector";
import { resolveStringIntl } from "hooks/intl";

interface MultilineTextSchema extends BaseSchema {
  schemaType: "multilineTextIntl";
  required?: boolean;
  rows?: number;
}

const MultilineTextSchemaComponent: SchemaComponent<MultilineTextSchema> = (
  props: SchemaComponentInternalProps<MultilineTextSchema>
) => {
  const { value, registerProps, fieldState } = useField(props, [
    requiredValidator,
  ]);
  const { schema } = props;
  const [lang, setLang] = useEditingLocale();
  const multiLangValue = useMemo(() => {
    if (typeof value === "object") {
      return value || {};
    }
    return {
      ja: value,
    };
  }, [value]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        as="textarea"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        rows={schema.rows || 6}
        value={multiLangValue[lang] || ""}
        {...registerProps}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue) {
            registerProps.onChange(
              getMultiLocaleValues({
                ...multiLangValue,
                [lang]: newValue,
              })
            );
          } else {
            registerProps.onChange(
              getMultiLocaleValues({
                ...multiLangValue,
                [lang]: undefined,
              }) || null
            );
          }
        }}
      />
      <AdminDropdownLocaleSelector
        locales={availableLocales}
      ></AdminDropdownLocaleSelector>
    </FieldWrapper>
  );
};

MultilineTextSchemaComponent.display = DisplayComponent(
  "MultilineTextDisplayComponent",
  (value) => {
    if (typeof value === "object") {
      const [lang] = useEditingLocale();
      return (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {resolveStringIntl(value, lang)}
        </div>
      );
    }
    return <div style={{ whiteSpace: "pre-wrap" }}>{value}</div>;
  }
);

export default MultilineTextSchemaComponent;

import React, { useMemo } from "react";
import classNames from "classnames";
import styles from "./slider.module.scss";
import { StringIntl } from "utils/locale";

const sqrLength = (a: number[], b: number[]) => {
  const length = Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2);
  return length;
};

export const Slider = ({
  options,
  onChange,
  value,
  hasError,
  showMiddleLabel,
  showLabelForSelectedValue,
  children,
  naTitles,
  naValues,
  ignoreTitles,
  ignoreValues,
  valueIncludeNA,
}: {
  options: {
    title: string;
    value: string | number | null;
  }[];
  onChange: (value: number | undefined) => void;
  value?: number;
  hasError?: boolean;
  showMiddleLabel?: boolean;
  showLabelForSelectedValue?: boolean;
  children?: React.ReactNode;
  naTitles?: StringIntl[];
  naValues?: (string | number | null)[];
  ignoreTitles?: StringIntl[];
  ignoreValues?: (string | number | null)[];
  valueIncludeNA?: number;
}) => {
  const minLabel = useMemo(() => options[0]?.title, [options]);
  const maxLabel = useMemo(() => options[options.length - 1]?.title, [options]);
  const middleLabel = useMemo(
    () => showMiddleLabel && options[Math.floor(options.length / 2)]?.title,
    [options]
  );
  const initialValue = useMemo(() => {
    return value == null ? Math.floor(options.length / 2) : value;
  }, [value, options]);
  const lastMousePosition = React.useRef<
    { x: number; y: number; index: number } | undefined
  >(undefined);
  const labelForSelectedValue = useMemo(() => {
    // naValuesが選択された場合の表示
    if (valueIncludeNA && naValues) {
      const foundNaValue = naValues.find((naValue, index) => {
        return valueIncludeNA === naValue;
      });
      if (foundNaValue != null) {
        const index = naValues.indexOf(foundNaValue);
        return naTitles?.[index] || foundNaValue.toString();
      }
    }
    // ignoreValuesが選択された場合の表示
    if (valueIncludeNA && ignoreValues) {
      const foundIgnoreValue = ignoreValues.find((ignoreValue, index) => {
        return valueIncludeNA === ignoreValue;
      });
      if (foundIgnoreValue != null) {
        const index = ignoreValues.indexOf(foundIgnoreValue);
        return ignoreTitles?.[index] || foundIgnoreValue.toString();
      }
    }
    return value != null
      ? showLabelForSelectedValue
        ? options[value]?.title
        : options[value]?.value
      : "未選択";
  }, [
    value,
    valueIncludeNA,
    options,
    showLabelForSelectedValue,
    naTitles,
    naValues,
    ignoreTitles,
    ignoreValues,
  ]);
  return (
    <div
      className={classNames(
        styles.range__wrapper,
        hasError && styles.range__wrapper__error
      )}
    >
      <div className={styles.range__label__top}>
        <span>{minLabel}</span>
        <span>{maxLabel}</span>
      </div>
      <div
        className={classNames(styles.range, hasError && styles.range__error)}
      >
        <input
          type="range"
          className={classNames(
            styles.range__input,
            value == null && styles.range__input__unselected,
            hasError && styles.range__input__error
          )}
          value={initialValue}
          min={0}
          max={options.length - 1}
          step={1}
          onChange={(e) => {
            const index = parseInt(e.target.value);
            onChange(index);
          }}
          onMouseDown={(e) => {
            lastMousePosition.current = {
              x: e.clientX,
              y: e.clientY,
              index: parseInt(e.currentTarget.value),
            };
          }}
          onMouseUp={(e) => {
            const currentMousePosition = [e.clientX, e.clientY];
            const mouseNotMoved =
              lastMousePosition.current &&
              sqrLength(
                [lastMousePosition.current.x, lastMousePosition.current.y],
                currentMousePosition
              ) < 10;
            const newIndex = parseInt(e.currentTarget.value);
            const indexChanged = lastMousePosition.current?.index !== newIndex;
            if (mouseNotMoved && !indexChanged) {
              if (value == null) {
                onChange(Math.floor(options.length / 2));
              } else {
                onChange(undefined);
              }
            }
            lastMousePosition.current = undefined;
          }}
        />
      </div>
      <div className={styles.range__label__bottom}>
        <span>{middleLabel}</span>
      </div>
      {children}
      <div>
        <span
          className={classNames(
            styles.range__label__selected,
            value == null && !valueIncludeNA && styles.range__input__unselected
          )}
        >
          {labelForSelectedValue}
        </span>
      </div>
    </div>
  );
};

import React from "react";
import { Button, Form } from "react-bootstrap";

import { shopData } from "common/models/shop";
import { InjectedHTML } from "components/InjectedHTML";
import { useFormatter, useStringIntl } from "hooks/intl";

import classes from "./style.module.scss";

export const ConsumerReview = ({
  template,
  shopData,
  commentData,
}: {
  template: string;
  shopData?: Partial<shopData> | null;
  commentData?: string;
}) => {
  const s = useStringIntl();
  const { formatMessage } = useFormatter();
  return (
    <InjectedHTML
      value={template}
      params={{
        shopName: s(shopData?.shopName) || "",
      }}
      elements={{
        comment: (
          <div className={classes.comment}>
            <Form.Control
              as="textarea"
              value={commentData || ""}
              style={{
                display: "inline-block",
                verticalAlign: "top",
                resize: "none",
                background: "#fff",
                borderTop: "1px solid #CED4DA",
                borderLeft: "1px solid #CED4DA",
                borderBottom: "1px solid #CED4DA",
                borderRight: "none",
                borderRadius: "6px 0 0 6px",
                fontSize: "16px",
                padding: "8px 16px",
              }}
              readOnly
              disabled
            />
            <Button
              variant="outline-secondary"
              style={{
                display: "inline-block",
                verticalAlign: "top",
                backgroundColor: "#F3F3F3",
                borderTop: "1px solid #CED4DA",
                borderLeft: "none",
                borderBottom: "1px solid #CED4DA",
                borderRight: "1px solid #CED4DA",
                borderRadius: "0 6px 6px 0",
              }}
              onClick={() => {
                navigator.clipboard.writeText(commentData || "");
              }}
              data-tooltip-content="クリップボードにコピー"
            >
              <i className="mdi mdi-content-copy" />
            </Button>
          </div>
        ),
        links: (
          <div className={classes.links}>
            {shopData?.enqueteCompleteLinks?.map((item, index) => {
              if (item.type === "tripAdvisor") {
                return (
                  <div key={index} className={classes.button}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.button_link}
                    >
                      <img
                        style={{ maxWidth: "24px" }}
                        src="https://static.tacdn.com/img2/brand_refresh/Tripadvisor_logoset_solid_green.svg"
                        alt="Tripadvisor"
                      />
                      <span className={classes.button_heading}>
                        {formatMessage("Review.Tripadvisor")}
                      </span>
                    </a>
                  </div>
                );
              } else if (item.type === "googleMap") {
                return (
                  <div key={index} className={classes.button}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.button_link}
                    >
                      <img
                        style={{ maxWidth: "24px" }}
                        src="https://lh3.googleusercontent.com/gYxrWReRGr9RV-g5UHKe6k-ylOPylohrcQ-hyBSMnEcZYpXgLtrqnRt9vDroWQpl2F_dztC4M4svFnxpGcGASETOru6C8RGDSvzR"
                        alt=""
                      />
                      <span className={classes.button_heading}>
                        {formatMessage("Review.GoogleMap")}
                      </span>
                    </a>
                  </div>
                );
              } else if (item.type === "tabelog") {
                return (
                  <div key={index} className={classes.button}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.button_link}
                    >
                      <img
                        style={{ maxWidth: "24px" }}
                        src="https://tblg.k-img.com/images/smartphone/icon/app_icon_tabelog_flat_3x_re1.png"
                        alt=""
                      />
                      <span className={classes.button_heading}>
                        {formatMessage("Review.Tabelog")}
                      </span>
                    </a>
                  </div>
                );
              } else {
                return <> </>;
              }
            })}
          </div>
        ),
      }}
    ></InjectedHTML>
  );
};

import React, { useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
import NavLink from "components/Navigation/Link";
import { useAdminAuthentication } from "hooks/auth";
import {
  useIndustryDoc,
  usePublicIndustryReportList,
  usePublicReportList,
} from "models/hook";
import { resolveRoute } from "pages";
import { stagingAlertName } from "utils/domain";
import { objectToSearchParams, useSearchParamAccessor } from "utils/url";
import { DateRange } from "schemaComponents/components/searchDate";
import { isProductionEnv } from "utils/project";

export const IndustryMenu = ({ industryId }: { industryId: string }) => {
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { data } = useIndustryDoc({ industryId });
  const location = useLocation();
  if (!industryId) return <div>Internal error</div>;

  const { value: searchState } = useSearchParamAccessor<{
    date: DateRange;
    shopId?: string;
    r: unknown;
  }>();
  const reportSearchParam = useMemo(() => {
    const searchString = objectToSearchParams({
      date: searchState.date,
      shopId: searchState.shopId,
      r: searchState.r,
    }).toString();
    return searchString ? "?" + searchString : "";
  }, [searchState]);

  const { list: reports, loading: loadingReportList } =
    usePublicIndustryReportList({
      industryId,
    });

  return (
    <>
      {stagingAlertName && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            color: "#fff",
            background: isProductionEnv ? "#f00c" : "#fff9",
            padding: "0 10px",
            zIndex: 1000,
          }}
        >
          {stagingAlertName}
        </div>
      )}
      <Link to={resolveRoute("ADMIN_HOME")} className="aside-tools">
        <div className="aside-tools-label">{data?.industryName}</div>
      </Link>
      <div className="menu is-menu-main">
        <ul className="menu-list">
          <li>
            <NavLink to={resolveRoute("LIST_INDUSTRIES")}>
              <span className="icon">
                <i className="mdi mdi-backburger" />
              </span>
              <span className="menu-item-label">戻る</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={
                resolveRoute("LIST_INDUSTRY_REPORTS", { industryId }) +
                reportSearchParam
              }
            >
              <span className="icon">
                <i className="mdi mdi-chart-areaspline" />
              </span>
              <span className="menu-item-label">業種レポート一覧</span>
            </NavLink>
            <ul className="nestedNavContainer" style={{}}>
              {reports?.map((report) => {
                const { reportId } = report;
                return (
                  <li key={report.reportId}>
                    <NavLink
                      to={
                        resolveRoute("UPDATE_INDUSTRY_REPORT", {
                          industryId,
                          reportId,
                        }) + reportSearchParam
                      }
                    >
                      <span className="icon">
                        <i className="mdi mdi-chart-areaspline" />
                      </span>
                      <span className="menu-item-label">{report.title}</span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </li>

          {isMaster && (
            <li>
              <NavLink to={resolveRoute("UPDATE_INDUSTRY", { industryId })}>
                <span className="icon">
                  <i className="mdi mdi-pencil" />
                </span>
                <span className="menu-item-label">業種情報編集</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

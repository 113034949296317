import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const parse = require("loose-json");

interface Json extends BaseSchema {
  schemaType: "json";
  required?: boolean;
  rows?: number;
}

const JsonComponent: SchemaComponent<Json> = (
  props: SchemaComponentInternalProps<Json>
) => {
  const { schema } = props;
  const { value, registerProps, fieldState } = useField(props, [
    requiredValidator,
  ]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        as="textarea"
        rows={schema.rows || 6}
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        value={JSON.stringify(value, null, 4)}
        {...registerProps}
        onChange={(e) => {
          try {
            registerProps.onChange(parse(e.currentTarget.value));
          } catch (e) {
            registerProps.onChange("");
          }
        }}
      />
    </FieldWrapper>
  );
};

JsonComponent.display = DisplayComponent("MultilineDisplayComponent");

export default JsonComponent;

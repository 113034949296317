import { useAdminAuthentication } from "./auth";

class AuthorizationError extends Error {
  constructor() {
    super("Unauthorized");
    this.name = "AuthorizationError";
  }
}

export const useAdminPageAuthorization = (clientId?: string) => {
  const { role, user, loading } = useAdminAuthentication();
  const isMaster = role === "master";

  if (loading) return;
  if (!clientId) {
    // マスターのみ表示できるページ（例：業種一覧）
    if (!isMaster) throw new AuthorizationError();
  } else {
    // クライアント一覧以下のページ（例：クライアント詳細）
    if (!(isMaster || user?.clientIds?.includes(clientId)))
      throw new AuthorizationError();
  }
};

import React, { useMemo } from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  BaseSchema,
} from "react-hook-schema-form";

import "./selector.scss";
import { DisplayComponent } from "./common/displayWrapper";
import { toMap, useQueryResource } from "utils/legacyFirestore";
import type { FilterParams, QueryParams } from "common/comodel-firestore";

import MultipleSelectorSchemaComponent from "./multipleSelector";
import { useStringIntl } from "hooks/intl";

interface MultipleExternalKeySchema extends BaseSchema {
  schemaType: "multipleExternalKey";
  uiType?: "normal" | "vertical" | "horizontal";
  resourcePath: string;
  titleKey: string;
  query?: QueryParams;
  filter?: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href?: (value: any) => string;
}

const MultipleExternalKeySchemaComponent: SchemaComponent<
  MultipleExternalKeySchema
> = (props: SchemaComponentInternalProps<MultipleExternalKeySchema>) => {
  const { schema: originalSchema } = props;
  const { list: options } = useQueryResource(originalSchema);
  const schema = useMemo(() => {
    return {
      ...originalSchema,
      schemaType: "multipleSelector" as const,
      options: options || [],
    };
  }, [options, originalSchema]);
  return (
    <MultipleSelectorSchemaComponent
      {...props}
      schema={schema}
    ></MultipleSelectorSchemaComponent>
  );
};

MultipleExternalKeySchemaComponent.display = DisplayComponent(
  "MultipleExternalKeyDisplayComponent",
  (value, schema) => {
    const s = useStringIntl();
    const { list: options } = useQueryResource(schema);
    const valueSet = useMemo(() => {
      try {
        return new Set(Array.isArray(value) ? value : [value]);
      } catch (e) {
        return new Set();
      }
    }, [value]);
    const optionMap = options && toMap(options);
    if (schema.href) {
      return value ? (
        <a href={schema.href(value)}>{s(optionMap?.get(value))}</a>
      ) : (
        s(optionMap?.get(value)) || ""
      );
    } else {
      return (
        <div style={{ lineHeight: "1.8em" }}>
          {options
            ?.filter((option) => valueSet.has(option.value))
            .map((option, index, array) => {
              return (
                <span
                  key={index}
                  style={{
                    background: "#ddd",
                    borderRadius: "7px",
                    padding: "4px 10px",
                    margin: "3px",
                    fontSize: "0.8em",
                    whiteSpace: "nowrap",
                  }}
                >
                  {option.title}
                </span>
              );
            })}
        </div>
      );
    }
  }
);

export default MultipleExternalKeySchemaComponent;

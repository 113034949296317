import React, { useEffect, useMemo, useRef, useState } from "react";
import { REPORT, REPORTS } from "../route";
import { resolveRoute, useTypedNavigate } from "pages";
import { Editor } from "./editor/editor";
import EditorJS from "@editorjs/editorjs";
import {
  useClientDoc,
  useIndustryDoc,
  useIndustryReportDoc,
  useReportDoc,
  useShopDoc,
} from "models/hook";
import { Alert, Button, Spinner } from "react-bootstrap";
import { SchemaInput } from "components/SchemaInput";
import { ReportContext } from "./context";
import {
  DateRange,
  relativeMonth,
  timeZone,
} from "schemaComponents/components/searchDate";
import dayjs from "dayjs";
import {
  FieldCondition,
  useConditionSelector,
} from "hooks/condition/conditionSelector";
import { useSearchParamAccessor } from "utils/url";
import { useFieldAccessor } from "hooks/accessor";
import { useAlert, useConfirm, useModalForm } from "hooks/dialogs";
import getConditionSchema from "./conditionSchema";
import schema from "./schema";
import { reportData } from "common/models/report";
import { ObjectSchema } from "schemaComponents";
import "./index.scss";
import { Loading } from "components/Loading";
import { useStateWithDeps } from "./util";
import { useCopyPaste } from "hooks/copyPaste";
import { useAdminPageAuthorization } from "hooks/authAdminPage";
import { useAdminAuthentication } from "hooks/auth";

export const UPDATE_REPORT = REPORT.sub("", Report);
export const ADD_REPORT = REPORTS.sub("/new", Report);

function Report({
  reportId,
  clientId,
  industryId,
}: {
  reportId?: string;
  clientId?: string;
  industryId?: string;
}) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const {
    data: reportData,
    loading,
    set,
    remove,
    isEditing,
  } = (() => {
    if (clientId) {
      return useReportDoc({
        reportId,
        clientId,
      });
    } else if (industryId) {
      return useIndustryReportDoc({
        reportId,
        industryId,
      });
    }
    throw new Error();
  })();
  const { data: parentData } = (() => {
    if (clientId) {
      return useClientDoc({ clientId });
    } else if (industryId) {
      return useIndustryDoc({ industryId });
    }
    throw new Error();
  })();

  const effecientIndustryId = industryId || parentData.industryId;

  const conditionSchema = useMemo(
    () => getConditionSchema({ enquete: parentData?.questions, clientId }),
    [parentData, clientId]
  );

  const { condition, conditionSelector } = useConditionSelector({
    schema: conditionSchema,
    hideTimeCondition: true,
    searchParamKey: "r",
    unwrapped: true,
  });

  const searchParamAccessor = useSearchParamAccessor<{
    shopId?: string;
    clientId?: string;
    date?: DateRange;
  }>();
  const { value: shopId, setValue: setShopId } = useFieldAccessor(
    searchParamAccessor,
    "shopId"
  );
  const { value: variableClientId, setValue: setClientId } = useFieldAccessor(
    searchParamAccessor,
    "clientId"
  );

  const editorRef = useRef<EditorJS>();
  const editorRefSub = useRef<EditorJS>();
  const [editMode, setEditMode] = useStateWithDeps(false, [reportId, clientId]);

  const parseNumber = (value: string | number | boolean) => {
    if (typeof value === "string" && value.match(/^[0-9]+$/)) {
      return parseInt(value);
    } else {
      return value;
    }
  };

  const confirm = useConfirm();

  const backToList = () => {
    if (clientId) {
      navigate("LIST_REPORTS", { clientId }, { retainSearchParams: true });
    } else if (industryId) {
      navigate(
        "LIST_INDUSTRY_REPORTS",
        { industryId },
        { retainSearchParams: true }
      );
    }
  };
  const moveToUpdate = (reportId: string) => {
    if (clientId)
      navigate(
        "UPDATE_REPORT",
        { clientId, reportId },
        { retainSearchParams: true }
      );
    else if (industryId) {
      navigate(
        "UPDATE_INDUSTRY_REPORT",
        { industryId, reportId },
        { retainSearchParams: true }
      );
    }
  };

  const clickDelete = async () => {
    const result = await confirm({
      message: "削除しますか？",
    });
    if (!result) return;
    try {
      await remove();
      backToList();
    } catch (e) {
      console.error(e);
    }
  };

  const _condition = useMemo(() => {
    return (condition &&
      Object.fromEntries(
        Object.entries(condition).map(([conditionKey, fieldCondition]) => {
          if (typeof fieldCondition === "object") {
            return [
              conditionKey.replace(/^data\./, ""),
              Object.fromEntries(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Object.entries(fieldCondition as any).map(
                  ([opName, opCondition]) => {
                    if (Array.isArray(opCondition)) {
                      return [
                        opName,
                        opCondition.map((item) => parseNumber(item)),
                      ];
                    } else {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      return [opName, parseNumber(opCondition as any)];
                    }
                  }
                )
              ),
            ];
          } else {
            return [
              conditionKey.replace(/^data\./, ""),
              parseNumber(fieldCondition),
            ];
          }
        })
      )) as Record<string, FieldCondition> | undefined;
  }, [condition]);

  const { value: _dateRange, setValue: setDateRange } = useFieldAccessor(
    searchParamAccessor,
    "date"
  );

  const [_setting, setSetting] = useStateWithDeps<{
    isPublic?: boolean;
    dualColumns?: boolean;
    priority?: string;
  }>(undefined, [reportId, clientId]);

  const setting = _setting || {
    isPublic: reportData.isPublic,
    dualColumns: reportData.dualColumns,
    priority: reportData.priority,
  };

  const dateRange = _dateRange || relativeMonth(null, -1);
  const questionRef = useRef<ObjectSchema>();
  const reportContext = useMemo(() => {
    return {
      shopId,
      month: dayjs.tz(dateRange.$gte, timeZone).format("YYYYMM"),
      condition: _condition,
      clientId: clientId || variableClientId,
      questions: parentData?.questions,
      questionRef,
      dualColumns: setting?.dualColumns,
      industryId: effecientIndustryId,
      statParams: {
        clientId,
        industryId,
        dataId: parentData.dataId,
      },
    };
  }, [
    dateRange,
    shopId,
    clientId || variableClientId,
    parentData,
    _condition,
    setting?.dualColumns,
    effecientIndustryId,
  ]);
  useEffect(() => {
    questionRef.current = parentData.questions;
  }, [parentData]);
  const navigate = useTypedNavigate();

  const getEditorData = async () => {
    console.log("editorRef", editorRef);
    const editorData = await (async () => {
      if (!editMode) return undefined;
      else {
        return {
          value: await editorRef.current?.save(),
          valueSub: await editorRefSub.current?.save?.(),
        };
      }
    })();
    return {
      ...reportData,
      ...setting,
      ...editorData,
      title: editorData?.value?.blocks[0].data?.text,
    } as reportData;
  };
  const saveData = async () => {
    return await set(await getEditorData());
  };

  const modalForm = useModalForm();
  const alert = useAlert();

  const onOpenSetting = async () => {
    if (!reportId) {
      alert({ message: "先に保存してください" });
      return;
    }

    const result = await modalForm({
      params: {
        schema,
        title: "レポート設定",
      },
      value: { ...reportData, ...setting },
    });
    setSetting(result);
  };

  const isEditable = !(
    (clientId && reportData.industryId) ||
    (industryId && reportData.clientId)
  );

  const { copy, paste } = useCopyPaste<reportData>({
    objectType: "report",
  });

  return (
    <ReportContext.Provider value={reportContext}>
      <div>
        <>
          <section
            className="hero"
            style={{
              position: "fixed",
              zIndex: 10,
              background: "#eef4f4",
            }}
          >
            <div
              className="hero-body"
              style={{
                display: "flex",
                alignItems: "start",
                flexWrap: "wrap",
              }}
            >
              {isMaster ? (
                <div style={{ marginLeft: "0px", marginTop: "2px" }}>
                  <Button
                    variant="secondary"
                    onClick={backToList}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="戻る"
                  >
                    <i className="mdi mdi-arrow-left"></i>
                  </Button>
                </div>
              ) : (
                <></>
              )}
              {!clientId && (
                <div
                  style={{
                    marginLeft: "10px",
                    width: "240px",
                    zIndex: 1000,
                    minWidth: "180px",
                    marginTop: "2px",
                  }}
                >
                  <SchemaInput
                    data={variableClientId}
                    schema={{
                      schemaType: "externalKey",
                      resourcePath: "/client",
                      uiType: "normal",
                      titleKey: "clientName",
                      query: {
                        filter: {
                          industryId,
                        },
                      },
                      filter: {
                        ignore: { $truthy: false },
                      },
                    }}
                    onValueChange={(clientId) => {
                      setClientId(clientId);
                      setShopId(undefined);
                    }}
                  ></SchemaInput>
                </div>
              )}
              <div
                style={{
                  marginLeft: "10px",
                  width: "240px",
                  zIndex: 999,
                  minWidth: "180px",
                  marginTop: "2px",
                }}
              >
                <SchemaInput
                  data={shopId}
                  schema={{
                    schemaType: "externalKey",
                    resourcePath: "/shop",
                    uiType: "normal",
                    titleKey: "shopName",
                    query: {
                      filter: { clientId: clientId || variableClientId },
                    },
                    filter: {
                      ignore: { $truthy: false },
                    },
                    staticOptions: [
                      {
                        title: "全社",
                        value: null,
                      },
                    ],
                    order: "asc",
                  }}
                  onValueChange={setShopId}
                ></SchemaInput>
              </div>
              <div
                style={{
                  marginLeft: "10px",
                  zIndex: 998,
                  minWidth: "180px",
                  marginTop: "2px",
                }}
              >
                <SchemaInput
                  data={dateRange}
                  schema={{
                    schemaType: "month",
                  }}
                  onValueChange={setDateRange}
                  deepCompare={true}
                ></SchemaInput>
              </div>
              {isMaster && (
                <div
                  style={{
                    flex: "1",
                    maxWidth: "320px",
                    marginLeft: "10px",
                    minWidth: "240px",
                    zIndex: 997,
                    marginTop: "2px",
                  }}
                >
                  {conditionSelector}
                </div>
              )}

              {clientId && reportData.industryId && (
                <div
                  style={{
                    marginLeft: "10px",
                    height: "36px",
                    lineHeight: "36px",
                    color: "#00c",
                    marginTop: "2px",
                  }}
                >
                  業種共通
                </div>
              )}
              {editMode && (
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                  }}
                >
                  <Button
                    style={{ marginLeft: "5px" }}
                    variant="secondary"
                    onClick={async () => {
                      const result = await confirm({
                        message: "破棄しますか？",
                      });
                      if (!result) return;
                      setEditMode(false);
                    }}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="破棄して閲覧画面に戻る"
                  >
                    <i className="mdi mdi-window-close"></i>
                  </Button>
                </div>
              )}
              {isMaster && !editMode && isEditable && (
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                  }}
                >
                  <Button
                    variant="secondary"
                    onClick={async () => {
                      setEditMode(true);
                    }}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="編集"
                  >
                    <i className="mdi mdi-pencil"></i>
                  </Button>
                </div>
              )}
              {isMaster && (
                <div style={{ marginLeft: "10px", marginTop: "2px" }}>
                  <Button
                    onClick={async () => {
                      copy(await getEditorData());
                    }}
                    variant="outline-secondary"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="コピー"
                  >
                    <i className="mdi mdi-content-copy" />
                  </Button>
                </div>
              )}
              {editMode && (
                <div style={{ marginLeft: "10px", marginTop: "2px" }}>
                  <Button
                    onClick={async () => {
                      const currentValue = await getEditorData();
                      const retainedValue = Object.fromEntries(
                        (["reportId", "clientId", "industryId"] as const).map(
                          (key) => [key, currentValue?.[key]]
                        ) || []
                      );
                      const clipBoardData = await paste();
                      if (!clipBoardData) return;
                      const newData = {
                        ...clipBoardData,
                        ...retainedValue,
                      };
                      console.log({ newData });
                      if (newData.value) {
                        editorRef.current?.render(newData.value);
                      } else {
                        editorRef.current?.clear();
                      }
                      if (newData.valueSub) {
                        editorRefSub.current?.render(newData.valueSub);
                      } else {
                        editorRefSub.current?.clear();
                      }
                      setSetting(newData);
                    }}
                    variant="outline-secondary"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="貼り付け"
                  >
                    <i className="mdi mdi-content-paste" />
                  </Button>
                </div>
              )}

              {parentData.dataId && (
                <div
                  style={{
                    height: "36px",
                    lineHeight: "36px",
                    marginLeft: "10px",
                    marginTop: "2px",
                  }}
                >
                  {dayjs(parentData.dataId).format("YYYY-MM-DD HH:mm")}
                  取得
                </div>
              )}
              {/* <h1 className="title">{title}</h1>
            <h2 className="subtitle">{subtitle}</h2> */}
              {editMode && (
                <div style={{ marginLeft: "10px", marginTop: "2px" }}>
                  <Button
                    variant="secondary"
                    onClick={onOpenSetting}
                    data-tooltip-id="tooltip"
                    data-tooltip-content="設定"
                  >
                    <i className="mdi mdi-cog"></i>
                  </Button>
                </div>
              )}
              {editMode && (
                <>
                  <div style={{ marginLeft: "10px", marginTop: "2px" }}>
                    <Button
                      onClick={async () => {
                        const { reportId: newReportId } = await saveData();
                        setEditMode(false);
                        if (newReportId !== reportId) {
                          moveToUpdate(newReportId);
                        }
                      }}
                    >
                      保存
                    </Button>
                  </div>
                  <div style={{ marginLeft: "10px", marginTop: "2px" }}>
                    <Button
                      onClick={clickDelete}
                      type="button"
                      variant="danger"
                    >
                      削除
                    </Button>
                  </div>
                </>
              )}
            </div>
          </section>
          {!effecientIndustryId && parentData && (
            <div style={{ paddingTop: 100 }}>
              <Alert>
                クライントの業種が指定されていないためレポートを表示できません。
                「クライアント詳細」から業種を指定してください。
              </Alert>
            </div>
          )}
          {!effecientIndustryId && !parentData && (
            <div style={{ paddingTop: 100 }}>
              <Loading></Loading>
            </div>
          )}
          {effecientIndustryId && (
            <div
              style={{ padding: "90px 20px 0", background: "#fff" }}
              className={setting?.dualColumns ? "dualColumns" : undefined}
            >
              <section>
                {loading ? (
                  <div style={{ padding: "20px" }}>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <Editor
                    value={reportData.value}
                    ref={editorRef}
                    readOnly={!editMode}
                    questionRef={questionRef}
                  ></Editor>
                )}
              </section>
              <section
                style={{ display: setting?.dualColumns ? "block" : "none" }}
              >
                {loading ? (
                  <div style={{ padding: "20px" }}>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <Editor
                    value={reportData.valueSub || reportData.value}
                    ref={editorRefSub}
                    readOnly={!editMode}
                    questionRef={questionRef}
                  ></Editor>
                )}
              </section>
            </div>
          )}

          {/* {editMode && (
            <div style={{ marginLeft: "20px", marginTop: "2px" }}>
              <Button
                onClick={async () => {
                  const { reportId: newReportId } = await saveData();
                  setEditMode(false);
                  if (newReportId !== reportId) {
                    moveToUpdate(newReportId);
                  }
                }}
              >
                保存
              </Button>
            </div>
          )}
          {editMode && (
            <div style={{ marginLeft: "10px", marginTop: "2px" }}>
              <Button
                variant="danger"
                onClick={clickDelete}
                data-tooltip-id="tooltip"
                data-tooltip-content="削除"
              >
                <i className="mdi mdi-delete"></i>
              </Button>
            </div>
          )} */}
        </>
      </div>
    </ReportContext.Provider>
  );
}

export default Report;

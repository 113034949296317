import React from "react";
import { ADMIN as BASE } from "..";

export const ADMIN_HOME = BASE.child("", Home);

function Home() {
  return (
    <>
      <section className="hero">
        <div className="hero-body">
          <h1 className="title">Home</h1>
        </div>
      </section>
      <section className="main-section">
        <div>ファンくる CR</div>
      </section>
    </>
  );
}
export default Home;

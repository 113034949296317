import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useModal,
  ModalComponentProps,
  useCancellableModal,
} from "react-hooks-async-modal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./modal.scss";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { Value } from "react-hook-schema-form";
import { modalCancelEvent } from "./modalCancelEvent";
import QRCode from "qrcode";
import { useLocalStorage } from "./storage";
import { FieldValues, useForm } from "react-hook-form";
import { useFormatter } from "./intl";
import { Locale, StringIntl, getMultiLocaleValues } from "utils/locale";
import { stagingAlertName } from "utils/domain";
import { Field, ObjectSchema } from "schemaComponents";
import { Tab, Tabs } from "react-bootstrap";

import { parse } from "papaparse";
import { getPropertyList } from "./condition/properties";
import { PropertyParameter } from "schemaComponents/components/object";
import {
  isNumberLike,
  parseExcelDate,
} from "components/SchemaListPage/parseCsv";

type ValidatedValue = {
  value: string;
  errors: string[];
};

type ValidatedValueArray = {
  [key: string]: ValidatedValue;
}[];

export const useModalWithRegister = <ModalPropsT, ReturnedType = void>(
  ModalComponent: React.ComponentType<
    ModalComponentProps<ReturnedType> & ModalPropsT
  >
): ((props: ModalPropsT) => Promise<ReturnedType>) => {
  const callCancelableModal = useCancellableModal<ModalPropsT, ReturnedType>(
    ModalComponent
  );
  return (props) => {
    const [promise, canceller] = callCancelableModal(props);
    modalCancelEvent.on(canceller);
    return (async () => {
      try {
        const response = await promise;
        return response;
      } finally {
        modalCancelEvent.off(canceller);
      }
    })();
  };
};

const confirm = ({
  onResolve,
  title,
  message,
}: ModalComponentProps<boolean> & {
  message: string | JSX.Element;
  title: string;
}) => {
  return (
    <Modal show={true} onHide={() => onResolve(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onResolve(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onResolve(true)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const useConfirm = () => {
  const { formatMessage } = useFormatter();
  const confirmFunc = useModalWithRegister<
    { message: string | JSX.Element; title: string },
    boolean
  >(confirm);
  return ({
    message,
    title,
  }: {
    message: string | JSX.Element;
    title?: string;
  }) => {
    return confirmFunc({
      message,
      title: title || formatMessage("Form.ConfirmTitle"),
    });
  };
};

const alert = ({
  onResolve,
  title,
  message,
}: ModalComponentProps<void> & {
  message: string | JSX.Element;
  title: string;
}) => {
  return (
    <Modal show={true} onHide={() => onResolve()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => onResolve()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const useAlert = () => {
  const { formatMessage } = useFormatter();
  const alertFunc = useModalWithRegister<{
    message: string | JSX.Element;
    title: string;
  }>(alert);
  return ({
    message,
    title,
  }: {
    message: string | JSX.Element;
    title?: string;
  }) => {
    return alertFunc({
      message,
      title: title || formatMessage("Form.ConfirmTitle"),
    });
  };
};

const contract = ({
  title,
  notice,
  message,
  buttonLabelOK,
  buttonLabelCancel,
  onResolve,
}: ModalComponentProps<boolean> & {
  title: StringIntl;
  notice: string;
  message: StringIntl;
  buttonLabelOK: string;
  buttonLabelCancel: string;
}) => {
  const { formatString } = useFormatter();
  return (
    <Modal show={true} className="policy">
      <Modal.Header>
        <Modal.Title>
          {title}
          <p style={{ fontSize: "0.7em" }}>{notice}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        <p style={{ whiteSpace: "pre-wrap" }}>{formatString(message)}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Button
            variant="light"
            onClick={() => onResolve(false)}
            style={{ border: "1px solid #ccc" }}
          >
            {buttonLabelCancel}
          </Button>
          <Button variant="primary" onClick={() => onResolve(true)}>
            {buttonLabelOK}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const useContract = () => {
  return useModalWithRegister<
    {
      title: StringIntl;
      notice: string;
      message: StringIntl;
      buttonLabelOK: string;
      buttonLabelCancel: string;
    },
    boolean
  >(contract);
};

const documentBrowser = ({
  title,
  message,
  onResolve,
}: ModalComponentProps<boolean> & {
  title: StringIntl;
  message: StringIntl;
}) => {
  const { formatString } = useFormatter();
  return (
    <Modal show={true} onHide={() => onResolve(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        <p style={{ whiteSpace: "pre-wrap" }}>{formatString(message)}</p>
      </Modal.Body>
    </Modal>
  );
};
export const useDocumentBrowser = () => {
  return useModalWithRegister<
    {
      title: StringIntl;
      message: StringIntl;
    },
    boolean
  >(documentBrowser);
};

const download = ({
  onResolve,
  message,
  url,
  filename,
}: ModalComponentProps<boolean> & {
  message: string | JSX.Element;
  url: string;
  filename?: string;
}) => {
  return (
    <Modal show={true} onHide={() => onResolve(false)}>
      <Modal.Header closeButton>
        <Modal.Title>確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onResolve(false)}>
          Cancel
        </Button>
        <a
          className="btn btn-primary"
          href={url}
          onClick={(e) => onResolve(true)}
          download={filename}
        >
          OK
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export const useDownload = () => {
  const dialog = useModalWithRegister<
    { message: string | JSX.Element; url: string; filename?: string },
    boolean
  >(download);
  return async (
    params: (
      | {
          blob: Blob;
        }
      | {
          data: BlobPart[];
          mimeType: string;
        }
    ) & { filename?: string }
  ) => {
    const blob = (() => {
      if ("blob" in params) {
        return params.blob;
      } else {
        return new Blob(params.data, { type: params.mimeType });
      }
    })();
    const url = URL.createObjectURL(blob);
    const filename = params.filename;
    await dialog({ message: "ダウンロード", url, filename });
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 1000);
  };
};

const modalForm = ({
  onResolve,
  params,
  value,
}: ModalComponentProps<Value> & {
  params: SchemaFormParameter;
  value: Value;
}) => {
  return (
    <Modal show={true} className="policy" onHide={() => onResolve(undefined)}>
      <Modal.Header closeButton>
        {params.title && <Modal.Title>{params.title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        <SchemaFormPage
          data={value || {}}
          parameter={{
            ...params,
            title: undefined,
            edit: {
              ...params.edit,
              confirm: false,
              handler: async (value) => {
                onResolve(value);
              },
            },
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
export const useModalForm = (): (<T = Value>(props: {
  params: SchemaFormParameter;
  value: Value;
}) => Promise<T>) => {
  return useModalWithRegister<
    { params: SchemaFormParameter; value: Value },
    Value
  >(modalForm);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ModelResolverContext = React.createContext<ModalComponentProps<any>>({
  onReject: () => {},
  onResolve: () => {},
});

const parseCsv = (text: string, schema?: ObjectSchema) => {
  const parsedCsv = parse(text, { header: true, skipEmptyLines: "greedy" });
  const lines = parsedCsv.data;
  return lines;
};

// 確認画
const Table = ({ valueArray }: { valueArray?: ValidatedValueArray }) => {
  return (
    <div className="preview-table-wrapper">
      {valueArray ? (
        <table className="statTable headerSticky stickyTop stickyLeft">
          <thead>
            <tr>
              <th
                style={{
                  fontSize: "0.9em",
                }}
              >
                &nbsp;
              </th>
              {Object.keys(valueArray[0] || {}).map((k) => {
                if (k === "rowNumber") return <></>;
                return (
                  <th
                    key={k}
                    style={{
                      fontSize: "0.9em",
                    }}
                  >
                    {k}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {valueArray.map((v, i) => {
              const hasRowError = Object.values(v).some(
                (v) => v.errors && v.errors.length > 0
              );
              return (
                <tr key={i}>
                  {hasRowError ? (
                    <th
                      style={{
                        color: "red",
                        background: "#FFEEED",
                        border: "1px solid #ff3860",
                        fontSize: "0.9em",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {v.rowNumber}
                    </th>
                  ) : (
                    <th
                      style={{
                        fontSize: "0.9em",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {v.rowNumber}
                    </th>
                  )}
                  {Object.keys(v).map((k) => {
                    if (k === "rowNumber") return <></>;
                    const errors = v[k].errors;
                    return errors && errors.length > 0 ? (
                      <td
                        key={k}
                        style={{
                          width: "100px",
                          whiteSpace: "pre",
                          background: "#FFEEED",
                          border: "1px solid #ff3860",
                          // borderColor: "#ff3860",
                          fontSize: "0.9em",
                        }}
                        data-tooltip-id="tooltip-modal"
                        data-tooltip-content={errors.join("\n")}
                      >
                        {v[k].value}
                      </td>
                    ) : (
                      <td
                        key={k}
                        style={{
                          width: "100px",
                          whiteSpace: "pre",
                          fontSize: "0.9em",
                        }}
                      >
                        {v[k].value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </div>
  );
};

const modalFormFullScreen = ({
  onResolve,
  onReject,
  title,
  children,
}: ModalComponentProps<Value> & {
  title: StringIntl;
  children?: React.ReactNode;
}) => {
  return (
    <Modal
      show={true}
      className="policy"
      onHide={() => onResolve(undefined)}
      dialogClassName="fullscreen-modal"
    >
      <Modal.Header closeButton>
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <ModelResolverContext.Provider value={{ onResolve, onReject }}>
        <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
          {children}
        </Modal.Body>
      </ModelResolverContext.Provider>
    </Modal>
  );
};
export const useModalFormFullScreen = (): (<T = Value>(props: {
  title: StringIntl;
  children?: React.ReactNode;
}) => Promise<T>) => {
  return useModalWithRegister<
    { title: StringIntl; children?: React.ReactNode },
    Value
  >(modalFormFullScreen);
};

const upload = ({
  onResolve,
  params,
  value,
  // children,
  uploadSchema,
  propertyList,
  clientId,
}: // ,
ModalComponentProps<Value> & {
  params: SchemaFormParameter;
  value: Value;
  // children?: React.ReactNode;
  uploadSchema?: ObjectSchema;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertyList?: any[];
  clientId?: string;
}) => {
  const [pageStatus, setPageStatus] = useState<"input" | "confirm">("input"); // 画面ステータス
  const { handleSubmit, control } = useForm({ defaultValues: value }); // 入力画面のフォーム
  const [text, setText] = useState<string>(); // 入力画面のCSV
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [valueArray, setValueArray] = useState<ValidatedValueArray>(); // 確認画面で表示する形式のデータ（バリデーション済み）
  const [hasClientIdError, setHasClientIdError] = useState<boolean>(false); // クライアントIDの特殊チェックでエラーになったかどうか

  const clickSubmit = async (value: FieldValues) => {
    setPageStatus("confirm");
    setHasClientIdError(false);
    const _text = value.uploadCsv as string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _valueArray = parseCsv(_text) as any[];
    let rowNumber = 1;

    // validation
    const properties = uploadSchema?.properties;
    const _validatedValueArray: ValidatedValueArray = _valueArray.map(
      (data) => {
        // クライアントIDの特殊チェック
        if (!("clientId" in data)) {
          setHasClientIdError(true);
        }

        // データ列ごとのスキーマチェック
        const validatedData = { ...data };
        for (const key in data) {
          let obj = {};
          const keyComponents = key.split(".");
          const errors: string[] = [];
          const item = data[key];
          const property = properties?.find(
            (property) => property.propertyName === keyComponents[0]
          );
          if (!property) continue;

          if (
            property.schema.schemaType === "datetime" ||
            property.schema.schemaType === "date" ||
            property.schema.schemaType === "month"
          ) {
            if (!item) {
              obj = { ...obj, [key]: null };
            } else if (typeof item === "string") {
              const value = parseExcelDate(item, property.schema.schemaType);
              if (value !== null) {
                obj = { ...obj, [key]: value };
              } else {
                errors.push("日付のフォーマットが不正です");
              }
            } else {
              errors.push("日付のフォーマットが不正です");
            }
          } else if (property.schema.schemaType === "boolean") {
            const str = `${item}`;
            if (!item) {
              obj = { ...obj, [key]: null };
            } else if (str.toLowerCase() === "false" || str === "0") {
              obj = { ...obj, [key]: false };
            } else if (str.toLowerCase() === "true" || str === "1") {
              obj = { ...obj, [key]: true };
            } else {
              errors.push("論理値のフォーマットが不正です");
            }
          } else if (property.schema.schemaType === "selector") {
            if (!item) {
              obj = { ...obj, [key]: null };
            } else {
              const option = property.schema.options.find(
                // 数値と文字列を区別しないで比較する
                // eslint-disable-next-line eqeqeq
                (option) => option.value == item
              );
              if (option) {
                obj = { ...obj, [key]: item };
              } else {
                errors.push("選択肢のフォーマットが不正です");
              }
            }
          } else if (
            property.schema.schemaType === "text" ||
            property.schema.schemaType === "multilineText" ||
            // property.schema.schemaType === "externalKey" ||
            property.schema.schemaType === "image"
          ) {
            if (property.schema.required && !item) {
              errors.push("この項目は必須入力です");
            } else {
              obj = { ...obj, [key]: item || null };
            }
          } else if (
            property.schema.schemaType === "externalKey" ||
            property.schema.schemaType === "multipleExternalKey"
          ) {
            const property = propertyList?.find(
              (property) => property.propertyName === keyComponents[0]
            );
            const convertValue = property?.schema.options?.find(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (option: any) => option.value === item
            );
            if (item && !convertValue) {
              errors.push("クライアントに紐づかない外部キーが指定されています");
            }

            obj = { ...obj, [key]: item || null };
          } else if (
            property.schema.schemaType === "number" ||
            property.schema.schemaType === "float"
          ) {
            if (isNumberLike(item)) {
              const value = Number(item);
              obj = { ...obj, [key]: value };
            } else {
              errors.push("数値のフォーマットが不正です");
            }
          } else if (
            property.schema.schemaType === "textIntl" ||
            property.schema.schemaType === "multilineTextIntl"
          ) {
            const locale = keyComponents[1] || "ja";
            const key = keyComponents[0] as keyof typeof obj;
            const oldValue = obj[key];
            if (oldValue || item) {
              const newValue = getMultiLocaleValues({
                ...(typeof oldValue === "string" ? { ja: oldValue } : oldValue),
                [locale]: item,
              });
              obj = { ...obj, [key]: newValue };
            } else {
              obj = { ...obj, [key]: null };
            }
          } else if (property.schema.schemaType === "array") {
            if (!item) continue;
            const subSchema = property.schema.item as ObjectSchema;
            const keyValue = keyComponents[0];
            const arrayIndex = (keyComponents[1] as unknown as number) - 1; // 1originなので-1する
            const subKeyValue = keyComponents[2];
            const subSchemaProperty = subSchema.properties?.find(
              (property) => property.propertyName === subKeyValue
            );
            const oldValue = (obj[keyComponents[0] as keyof typeof obj] ||
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              []) as any[];

            if (
              subSchemaProperty?.schema.schemaType === "datetime" ||
              subSchemaProperty?.schema.schemaType === "date" ||
              subSchemaProperty?.schema.schemaType === "month"
            ) {
              if (!item) {
                obj = { ...obj, [key]: null };
              } else if (typeof item === "string") {
                const value = parseExcelDate(
                  item,
                  subSchemaProperty?.schema.schemaType
                );
                if (value !== null) {
                  obj = { ...obj, [key]: value };
                } else {
                  errors.push("日付のフォーマットが不正です");
                }
              } else {
                errors.push("日付のフォーマットが不正です");
              }
            } else if (subSchemaProperty?.schema.schemaType === "boolean") {
              const str = `${item}`;
              if (!item) {
                obj = { ...obj, [key]: null };
              } else if (str.toLowerCase() === "false" || str === "0") {
                obj = { ...obj, [key]: false };
              } else if (str.toLowerCase() === "true" || str === "1") {
                obj = { ...obj, [key]: true };
              } else {
                errors.push("論理値のフォーマットが不正です");
              }
            } else if (subSchemaProperty?.schema.schemaType === "selector") {
              if (!item) {
                obj = { ...obj, [key]: null };
              } else {
                const option = subSchemaProperty?.schema.options.find(
                  // 数値と文字列を区別しないで比較する
                  // eslint-disable-next-line eqeqeq
                  (option) => option.value == item
                );
                if (option) {
                  obj = { ...obj, [key]: item };
                } else {
                  errors.push("選択肢のフォーマットが不正です");
                }
              }
            } else if (
              subSchemaProperty?.schema.schemaType === "text" ||
              subSchemaProperty?.schema.schemaType === "multilineText" ||
              // subSchemaProperty?.schema.schemaType === "externalKey" ||
              subSchemaProperty?.schema.schemaType === "image"
            ) {
              if (subSchemaProperty?.schema.required && !item) {
                errors.push("この項目は必須入力です");
              } else {
                obj = { ...obj, [key]: item || null };
              }
            } else if (
              subSchemaProperty?.schema.schemaType === "externalKey" ||
              subSchemaProperty?.schema.schemaType === "multipleExternalKey"
            ) {
              const property = propertyList?.find(
                (property) => property.propertyName === subKeyValue
              );
              const convertValue = property?.schema.options?.find(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (option: any) => option.value === item
              )?.title;
              if (item && !convertValue) {
                errors.push(
                  "クライアントに紐づかない外部キーが指定されています"
                );
              }

              obj = { ...obj, [key]: item || null };
            } else if (
              subSchemaProperty?.schema.schemaType === "number" ||
              subSchemaProperty?.schema.schemaType === "float"
            ) {
              if (isNumberLike(item)) {
                const value = Number(item);
                obj = { ...obj, [key]: value };
              } else {
                errors.push("数値のフォーマットが不正です");
              }
            } else if (
              subSchemaProperty?.schema.schemaType === "textIntl" ||
              subSchemaProperty?.schema.schemaType === "multilineTextIntl"
            ) {
              const locale = keyComponents[1] || "ja";
              const key = keyComponents[0] as keyof typeof obj;
              const oldValue = obj[key];
              if (oldValue || item) {
                const newValue = getMultiLocaleValues({
                  ...(typeof oldValue === "string"
                    ? { ja: oldValue }
                    : oldValue),
                  [locale]: item,
                });
                obj = { ...obj, [key]: newValue };
              } else {
                obj = { ...obj, [key]: null };
              }
            }

            if (Array.isArray(oldValue) && oldValue[arrayIndex]) {
              const newValue = oldValue.map((oldItem, oldIndex) => {
                if (oldIndex === arrayIndex) {
                  return {
                    ...oldItem,
                    [subKeyValue]:
                      subSchemaProperty?.schema.schemaType === "number" ||
                      subSchemaProperty?.schema.schemaType === "float"
                        ? Number(item)
                        : item,
                  };
                }
                return oldItem;
              });
              obj = {
                ...obj,
                [keyValue]: newValue,
              };
            } else {
              obj = {
                ...obj,
                [keyValue]: [
                  ...oldValue,
                  {
                    [subKeyValue]:
                      subSchemaProperty?.schema.schemaType === "number" ||
                      subSchemaProperty?.schema.schemaType === "float"
                        ? Number(item)
                        : item,
                  },
                ],
              };
            }
          }
          // clientIdの特殊チェック
          if (key === "clientId") {
            if (clientId && item && item !== clientId) {
              errors.push(
                "クライアントに紐づかないクライアントIDが指定されています"
              );
            }
          }

          validatedData[key] = { value: item, errors };
        }
        return { ...validatedData, rowNumber: rowNumber++ };
      }
    );
    setText(_text);
    setValueArray(_validatedValueArray);
  };

  const schemaInput = {
    schemaType: "object",
    properties: [
      {
        title: "アップロード(CSV)",
        propertyName: "uploadCsv",
        schema: {
          schemaType: "uploadCsv",
          setFieldPath: "uploadPreview",
          required: true,
        },
      },
    ],
  };

  const hasError = useMemo(
    () =>
      valueArray?.some((v) =>
        Object.values(v).some((v) => v.errors && v.errors.length > 0)
      ) || hasClientIdError,
    [valueArray, hasClientIdError]
  );
  const valueArrayOnlyError = useMemo(
    () =>
      valueArray?.filter((v) =>
        Object.values(v).some((v) => v.errors && v.errors.length > 0)
      ),
    [valueArray]
  );

  const confirmTabs = (
    <Tabs>
      {!hasError ? (
        <></>
      ) : (
        <Tab eventKey="tabInputOnlyError" title="入力内容（エラーのみ）">
          <Table valueArray={valueArrayOnlyError} />
        </Tab>
      )}
      <Tab eventKey="tabInput" title="入力内容">
        <Table valueArray={valueArray} />
      </Tab>
    </Tabs>
  );

  return (
    <Modal
      show={true}
      className="policy"
      onHide={() => onResolve(undefined)}
      dialogClassName="fullscreen-modal"
    >
      <Modal.Header closeButton>
        {params.title && <Modal.Title>{params.title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        {pageStatus !== "confirm" ? (
          <div>
            <form onSubmit={handleSubmit(clickSubmit)}>
              <div className="header">
                <h2
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                  }}
                >
                  入力画面
                </h2>
              </div>
              <div className="main">
                <Field control={control} schema={schemaInput} />
              </div>
              <div
                className="footer"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <Button type="submit" variant="primary" className="me-2">
                  確認
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className="">
            <div className="header">
              <h2
                style={{
                  fontSize: "1.2em",
                  fontWeight: "bold",
                }}
              >
                確認画面
              </h2>
            </div>
            <div
              className=""
              style={{
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              {hasError ? (
                <div
                  className=""
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  {hasClientIdError
                    ? "アップロードデータにエラーがあります。（データ列にclientIdが存在しません）"
                    : "アップロードデータにエラーがあります。内容を確認の上、入力画面に戻り修正してください。"}
                </div>
              ) : (
                <div className="">
                  入力内容に問題はありません。アップロードボタンを押してアップロードを実行してください。
                </div>
              )}
            </div>
            <div className="main">
              {/* <Field control={control} schema={schemaConfirm} /> */}
              {confirmTabs}
            </div>
            <div
              className="footer"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => setPageStatus("input")}
                type="button"
                variant="outline-secondary"
                className="me-2"
              >
                戻る
              </Button>
              <Button
                onClick={() => onResolve(text)}
                type="button"
                variant="primary"
                className="me-2"
                disabled={hasError}
              >
                アップロード
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export const useUpload = ({
  uploadSchema,
}: {
  uploadSchema?: ObjectSchema;
}) => {
  const dialog = useModalWithRegister<
    {
      params: SchemaFormParameter;
      value: Value;
      children?: React.ReactNode;
      uploadSchema?: ObjectSchema;
      propertyList?: (PropertyParameter & {
        propertyName: string;
      })[];
      clientId?: string;
    },
    Value
  >(upload);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (modalParams: {
    params: SchemaFormParameter;
    value: Value;
    children?: React.ReactNode;
    uploadSchema?: ObjectSchema;
    propertyList?: (PropertyParameter & {
      propertyName: string;
    })[];
    clientId?: string;
  }) => {
    const propertyList = await getPropertyList(uploadSchema as ObjectSchema);
    return dialog({ ...modalParams, propertyList });
  };
};

const toost = ({
  onResolve,
  message,
  duration,
}: ModalComponentProps<void> & { message: string; duration: number }) => {
  useEffect(() => {
    setTimeout(onResolve, duration);
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        textAlign: "center",
        background: "#fccc",
        zIndex: 1000,
      }}
    >
      {message}
    </div>
  );
};
export const useToost = () => {
  return useModal<{ message: string; duration: number }>(toost);
};

const modalQrCode = ({
  onResolve,
  value,
}: ModalComponentProps<Value> & {
  value: Value;
}) => {
  const url = value && new URL(value);
  const [additionalSearchParams, setAdditionalSearchParams] =
    useLocalStorage<string>("qrCodeAdditionalSearchParams", "");
  const mergedSearchParams = new URLSearchParams(
    Object.fromEntries(
      new Map([
        ...new URLSearchParams(url.searchParams).entries(),
        ...new URLSearchParams(additionalSearchParams).entries(),
      ])
    )
  ).toString();
  const mergedUrl =
    url &&
    new URL(
      `${url.origin}${url.pathname}${
        mergedSearchParams ? "?" + mergedSearchParams : ""
      }`
    ).toString();
  return (
    <Modal show={true} className="policy" onHide={() => onResolve(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>QR Code</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        <div>
          <input
            type="text"
            value={additionalSearchParams}
            onChange={(e) => setAdditionalSearchParams(e.target.value)}
          />
        </div>
        <div>
          <QRCodeComponent value={mergedUrl} />
        </div>
        <div>
          <a
            style={{ wordBreak: "break-all" }}
            href={mergedUrl}
            target="_blank"
            rel="noreferrer"
          >
            {mergedUrl}
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const useModalQrCode = (): (<T = Value>(props: {
  value: Value;
}) => Promise<T>) => {
  return useModalWithRegister<{ value: Value }, Value>(modalQrCode);
};

export const QRCodeComponent = ({ value }: { value: string }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      QRCode.toCanvas(canvas, value);

      if (stagingAlertName) {
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // キャンバスの大きさを取得
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const text = "Sample";
        const fontSize = 20; // 例: 30px
        ctx.font = `${fontSize}px Arial`;
        ctx.fillStyle = "black";

        // テキストの幅と高さを取得
        const textMetrics = ctx.measureText(text);
        const textWidth = textMetrics.width;
        const textHeight = fontSize; // フォントサイズを直接利用

        const rectWidth = textWidth * 1.2;
        const rectHeight = textHeight * 1.2;

        // 矩形の開始位置を計算
        const rectX = (canvasWidth - rectWidth) / 2;
        const rectY = (canvasHeight - rectHeight) / 2;

        // 矩形を描画
        ctx.fillStyle = "white";
        ctx.fillRect(rectX, rectY, rectWidth, rectHeight);

        // テキストの開始位置を計算
        const startX = (canvasWidth - textWidth) / 2;
        const startY = (canvasHeight - textHeight) / 2 + textHeight * 0.8; // ベースラインの調整のため0.8を乗算

        // テキストを描画
        ctx.fillStyle = "black";
        ctx.fillText(text, startX, startY);
      }
    }
  }, [value]);
  return <canvas ref={canvasRef}></canvas>;
};

import React from "react";
import { useFormatter } from "hooks/intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ErrorMessage: React.FC<{ error: any }> = ({ error }) => {
  // const { formatMessage } = useFormatter();
  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const { id, values }: { id: string; values: any } = (() => {
  //   const message = error.message;
  //   if (typeof message === "string") {
  //     return { id: message };
  //   } else {
  //     return message;
  //   }
  // })();
  // const message = formatMessage(id, values);
  return <span>{error.message}</span>;
};

export default ErrorMessage;

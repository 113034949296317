import {
  createProgressiveQueryHook,
  createDocumentHook,
  createQueryHook,
} from "../comodel-firestore-react";
import { couponDefinition } from "../common/models/coupon";
import { enqueteDefinition } from "../common/models/enquete";
import { shopDefinition } from "../common/models/shop";
import { rewardDefinition } from "../common/models/reward";
import { surveyDefinition } from "../common/models/survey";
import { adminUserDefinition } from "../common/models/adminUser";
import { mailTemplateDefinition } from "../common/models/mailTemplate";
import { clientDefinition } from "../common/models/client";
import { industryDefinition } from "../common/models/industry";
import {
  industryReportDefinition,
  reportDefinition,
} from "common/models/report";
import { reportCommentDefinition } from "common/models/reportComment";

export const useEnqueteDoc = createDocumentHook(enqueteDefinition);
export const useCouponDoc = createDocumentHook(couponDefinition);
export const useShopDoc = createDocumentHook(shopDefinition);
export const useSurveyDoc = createDocumentHook(surveyDefinition);
export const useRewardDoc = createDocumentHook(rewardDefinition);
export const useAdminUserDoc = createDocumentHook(adminUserDefinition);
export const useMailTemplateDoc = createDocumentHook(mailTemplateDefinition);
export const useClientDoc = createDocumentHook(clientDefinition);
export const useIndustryDoc = createDocumentHook(industryDefinition);
export const useReportDoc = createDocumentHook(reportDefinition);
export const useIndustryReportDoc = createDocumentHook(
  industryReportDefinition
);
export const useReportCommentDoc = createDocumentHook(reportCommentDefinition);

export const useCouponList = createProgressiveQueryHook(
  couponDefinition,
  couponDefinition.queryDefs.list
);
export const useEnqueteList = createProgressiveQueryHook(
  enqueteDefinition,
  enqueteDefinition.queryDefs.list
);
export const useShopList = createQueryHook(
  shopDefinition,
  shopDefinition.queryDefs.list
);
export const useSurveyList = createQueryHook(
  surveyDefinition,
  surveyDefinition.queryDefs.list
);
export const useRewardList = createQueryHook(
  rewardDefinition,
  rewardDefinition.queryDefs.list
);
export const useAdminUserList = createProgressiveQueryHook(
  adminUserDefinition,
  adminUserDefinition.queryDefs.list
);

export const useClientList = createQueryHook(
  clientDefinition,
  clientDefinition.queryDefs.list
);

export const useIndustryList = createQueryHook(
  industryDefinition,
  industryDefinition.queryDefs.list
);

export const useReportList = createQueryHook(
  reportDefinition,
  reportDefinition.queryDefs.list
);

export const usePublicReportList = createQueryHook(
  reportDefinition,
  reportDefinition.queryDefs.listPublic
);

export const useIndustryReportList = createProgressiveQueryHook(
  industryReportDefinition,
  industryReportDefinition.queryDefs.list
);

export const usePublicIndustryReportList = createQueryHook(
  industryReportDefinition,
  industryReportDefinition.queryDefs.listPublic
);

export const useMailTemplateList = createQueryHook(
  mailTemplateDefinition,
  mailTemplateDefinition.queryDefs.list
);

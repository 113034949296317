import {
  firstEmphasized,
  orange,
  generateCustomBarGraphColors,
  orange2,
} from "./colorThemas";
import { Bar, Doughnut, Line, Pie, Radar } from "react-chartjs-2";
import { Chart, registerables, TooltipItem } from "chart.js";
import { settingType } from "./settingSchema";
import { PropertyType, Schema } from "schemaComponents";
import { getNumberDigits } from "utils/number";

Chart.register(...registerables);

export type GraphComponentType =
  | typeof Bar
  | typeof Doughnut
  | typeof Line
  | typeof Pie
  | typeof Radar;

export const getGraphConfig = ({
  currentMonth,
  settings,
  propertyMap,
}: {
  currentMonth: string | string[] | undefined;
  settings?: settingType;
  propertyMap: Record<string, PropertyType>;
}) => {
  const [GraphComponent, datasetOption, graphOption, _colorTable] = (() => {
    if (settings?.graphType) {
      return graphConfigs[settings?.graphType as keyof typeof graphConfigs];
    }
    if (Array.isArray(currentMonth)) {
      return graphConfigs.line;
    }
    if (settings?.statType === "value" || settings?.statType === "count") {
      if (settings?.fields?.length >= 3) {
        return graphConfigs.radar;
      } else {
        return graphConfigs.bar;
      }
    } else if (settings?.statType === "dist") {
      const schema = propertyMap[settings.fields[0]]?.schema as
        | Schema
        | undefined;
      if (schema && settings.colorThema === "rating" && settings.transpose) {
        const step = (() => {
          if (schema.schemaType === "numberSelector") {
            return (schema.maxValue || 0) - (schema.minValue || 0) + 1;
          } else if (schema.schemaType === "selector") {
            return schema.options.length || 0;
          }
          return 1;
        })();
        const colorTable = generateCustomBarGraphColors(step);
        if (settings?.reverse) {
          colorTable.reverse();
        }
        return [...graphConfigs.proption, colorTable];
      } else if (settings.transpose && settings.fields.length === 1) {
        return graphConfigs.proption;
      } else {
        return graphConfigs.proption;
      }
    }
    return graphConfigs.line;
  })();
  return {
    GraphComponent,
    datasetOption: (datasetIndex: number, colorTable?: string[]) => {
      return datasetOption(datasetIndex, colorTable || _colorTable);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    graphOption: graphOption as (params: graphOptionParams) => any,
  };
};

type graphOptionParams = {
  graphParams: {
    parsedScaleMin?: number;
    parsedScaleMax?: number;
    graphDataMax?: number;
    graphDataMin?: number;
  };
  settings?: settingType;
  propertyMap: Record<string, PropertyType>;
};

const getCyclicColor = (
  datasetIndex: number,
  colorTable: string[],
  opacity?: string
) => {
  return colorTable[datasetIndex % colorTable.length] + (opacity || "");
};

const graphConfigs = {
  radar: [
    Radar,
    (datasetIndex: number, colorTable: string[] = orange2) => ({
      borderColor: getCyclicColor(datasetIndex, colorTable),
      backgroundColor: getCyclicColor(datasetIndex, colorTable, "22"),
    }),
    ({
      graphParams: { parsedScaleMin, parsedScaleMax },
    }: graphOptionParams) => ({
      r: {
        min: parsedScaleMin,
        max: parsedScaleMax,
      },
    }),
  ] as const,
  bar: [
    Bar,
    (datasetIndex: number, colorTable: string[] = firstEmphasized) => ({
      borderColor: getCyclicColor(datasetIndex, colorTable),
      backgroundColor: getCyclicColor(datasetIndex, colorTable, "cc"),
    }),
    ({
      graphParams: { parsedScaleMin, parsedScaleMax },
    }: graphOptionParams) => ({
      scales: {
        y: {
          min: parsedScaleMin,
          max: parsedScaleMax,
        },
      },
    }),
  ] as const,
  line: [
    Line,
    (datasetIndex: number, colorTable: string[] = firstEmphasized) => ({
      borderColor: getCyclicColor(datasetIndex, colorTable),
      backgroundColor: getCyclicColor(datasetIndex, colorTable),
      borderWidth: 2,
    }),
    ({
      graphParams: { parsedScaleMin, parsedScaleMax },
    }: graphOptionParams) => ({
      scales: {
        y: {
          min: parsedScaleMin,
          max: parsedScaleMax,
        },
      },
    }),
  ] as const,
  proption: [
    Bar,
    (datasetIndex: number, colorTable: string[] = orange2) => ({
      borderColor: getCyclicColor(datasetIndex, colorTable),
      backgroundColor: getCyclicColor(datasetIndex, colorTable, "cc"),
      fill: true,
    }),
    ({
      settings,
      graphParams: {
        parsedScaleMin,
        parsedScaleMax,
        graphDataMin,
        graphDataMax,
      },
    }: graphOptionParams) => ({
      ...(!settings?.transpose
        ? {
            maintainAspectRatio: false,
            responsive: true,
            aspectRatio: null,
          }
        : {}),
      indexAxis: "y",
      scales: settings?.transpose
        ? {
            x: {
              min: 0,
              max: 1,
              stacked: true,
              ticks: {
                callback: function (value: number) {
                  return `${value * 100}%`;
                },
              },
            },
            y: { stacked: true },
          }
        : {
            x: {
              min: parsedScaleMin,
              max: parsedScaleMax,
              ticks: {
                callback: function (value: number) {
                  // 軸のスケール範囲から軸ラベルの小数点以下桁数を決定する
                  const scaleRange =
                    (parsedScaleMax || graphDataMax || 1) -
                    (parsedScaleMin || graphDataMin || 0);
                  const scaleRangeMagnitude = getNumberDigits(scaleRange);
                  return `${(value * 100).toFixed(
                    Math.max(0, -scaleRangeMagnitude - 1)
                  )}%`;
                },
              },
            },
          },
      plugins: {
        tooltip: {
          callbacks: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            label: function (context: any) {
              const value = context.parsed.x || 0; // 値がない場合はnullで渡されるので0にする
              const formattedValue = new Intl.NumberFormat("ja-JP", {
                style: "percent",
                minimumFractionDigits: 1,
              }).format(value);
              return `${context.dataset.label}: ${formattedValue}`;
            },
          },
        },
        legend: settings?.transpose
          ? {
              onClick: function () {
                return false;
              },
            }
          : undefined,
      },
    }),
  ],
  pie: [
    Pie,
    (datasetIndex: number, colorTable: string[] = orange) => ({
      borderColor: getCyclicColor(datasetIndex, colorTable),
      backgroundColor: getCyclicColor(datasetIndex, colorTable, "22"),
    }),
    () => ({
      plugins: {
        tooltip: {
          title: (
            context: TooltipItem<
              "radar" | "line" | "bar" | "doughnut" | "pie"
            >[]
          ) => {
            return `${context[0]?.dataset.label}`;
          },
        },
      },
    }),
  ] as const,
} as const;

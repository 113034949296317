import { useMemo, useRef, useState } from "react";
import isEqual from "lodash/isEqual";

const bindField = <T>(obj: AccessorObject<T>): AccessorObject<T> => {
  obj.setValue = obj.setValue.bind(obj);
  return obj;
};

export interface AccessorObject<T> {
  value: T;
  setValue(value: T): void;
}

export interface PartialAccessorObject<T> {
  value: T | undefined;
  setValue(value: T): void;
}

// useStateのAccessorObjectバージョン

export function useStateAccessor<T>(initialValue: T): AccessorObject<T>;
export function useStateAccessor<T>(): PartialAccessorObject<T>;
export function useStateAccessor<T>(initialValue?: T) {
  return useAccessorFromArray(useState(initialValue));
}

// useState形式のAccessorを AccessorObjectに変換する

export function useAccessorFromArray<T>(
  source: readonly [T, (value: T) => void]
) {
  const obj = useMemo(
    () =>
      bindField({
        value: source[0],
        setValue(value: T) {
          this.value = value;
          source[1](value);
        },
      }),
    [source[1]]
  );
  useMemo(() => {
    obj.value = source[0];
  }, [source[0]]);
  return obj;
}

// useState形式のAccessorを AccessorObjectに変換する

export function useAccessor<T>(value: T, setValue: (value: T) => void) {
  return useAccessorFromArray([value, setValue]);
}

export function useFieldAccessor<
  T extends Record<string, unknown>,
  Key extends keyof T = keyof T
>(accessor: AccessorObject<T>, key: Key): AccessorObject<T[Key]>;

export function useFieldAccessor<
  T extends Record<string, unknown>,
  Key extends keyof T = keyof T
>(
  accessor: AccessorObject<T | undefined>,
  key: Key
): PartialAccessorObject<T[Key]>;

export function useFieldAccessor<
  T extends Record<string, unknown>,
  Key extends keyof T = keyof T
>(parent: AccessorObject<T | undefined>, key: Key) {
  return useMemo(() => {
    return bindField({
      get value() {
        return parent.value?.[key];
      },
      setValue(newValue: T[Key]) {
        const value = parent.value?.[key];
        if (newValue !== value) {
          // ここで isEqualにするより、いったんparent.setValueを呼び出して、そこで解決してもらった方が良い（無駄な更新を避けられる）
          // このためには、setValueが更新後の値を返す必要があり、useState形式との互換性がなくなる。
          if (!isEqual(this.value, newValue)) {
            if (newValue !== undefined) {
              parent.setValue({ ...parent.value, [key]: newValue } as T);
            } else {
              const newParentValue = { ...parent.value } as T;
              delete newParentValue[key];
              parent.setValue(newParentValue);
            }
          }
        }
      },
    });
  }, [parent]);
}

// const exmaple = () => {
//   const accessor = useStateAccessor<{ condition: { a: string } }>();
//   const childAccessor = useFieldAccessor(accessor, "condition");
//   childAccessor.setValue({ a: "10" });
//   const x = childAccessor.value;
//   const grandchildAccessor = useFieldAccessor(childAccessor, "a");
// };

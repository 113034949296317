import React from "react";
import { Schema } from "schemaComponents";
import { couponUrl } from "utils/coupon";

export default ({
  isEditing,
  clientId,
  isMaster,
}: {
  isEditing: boolean;
  clientId: string;
  isMaster: boolean;
}): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "事業所名",
        propertyName: "shopId",
        schema: {
          schemaType: "externalKey",
          resourcePath: "/shop",
          titleKey: "shopName",
          query: { filter: { clientId } },
          readOnly: isEditing,
        },
      },
      {
        title: "リワード",
        propertyName: "rewardId",
        schema: {
          schemaType: "externalKey",
          uiType: "normal",
          resourcePath: "/reward",
          titleKey: "rewardName",
          query: { filter: { clientId } },
        },
      },
      {
        title: "状態",
        propertyName: "status",
        schema: {
          schemaType: "selector",
          options: [
            {
              title: "未使用",
              value: "valid",
            },
            {
              title: "使用済み",
              value: "used",
            },
            {
              title: "無効",
              value: "invalid",
            },
          ],
        },
      },
      {
        title: "クーポンID",
        propertyName: "couponId",
        schema: {
          schemaType: "text",
          readOnly: true,
        },
      },
      ...(isMaster
        ? [
            {
              title: "E-Mail",
              propertyName: "email",
              schema: {
                schemaType: "email",
                readOnly: isEditing,
                required: true,
              },
            },
          ]
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([] as any[])),
      ...(isMaster
        ? [
            {
              title: "Coupon Link",
              propertyId: "couponLink",
              schema: {
                schemaType: "message",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                text: ({ value }: { value: any }) => {
                  const url =
                    value.couponId && couponUrl(value.shopId, value.couponId);
                  return url ? (
                    <a
                      style={{ wordBreak: "break-all" }}
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {url}
                    </a>
                  ) : (
                    ""
                  );
                },
              },
            },
          ]
        : []),

      {
        title: "発行日時",
        propertyName: "createdAt",
        schema: { schemaType: "datetime", readOnly: true },
      },
      {
        title: "有効期限",
        propertyName: "expiredAt",
        schema: { schemaType: "datetime" },
      },
      {
        title: "利用日時",
        propertyName: "updatedAt",
        schema: { schemaType: "datetime" },
      },
      ...(isMaster
        ? [
            {
              title: "備考",
              propertyName: "description",
              schema: {
                schemaType: "multilineText",
              },
            },
          ]
        : []),
      ...(isMaster
        ? [
            {
              title: "clientId",
              propertyName: "clientId",
              schema: {
                schemaType: "text",
                readOnly: true,
              },
            },
          ]
        : []),
    ],
  };
};

import { ObjectSchema } from "schemaComponents";

const schema = ({
  enquete,
  clientId,
}: {
  enquete?: ObjectSchema;
  clientId?: string;
}): ObjectSchema => ({
  schemaType: "object",
  properties: [
    // {
    //   title: "事業所名",
    //   propertyName: "shopId",
    //   schema: {
    //     schemaType: "externalKey",
    //     resourcePath: "/shop",
    //     titleKey: "shopName",
    //     filter: { clientId },
    //     readOnly: true,
    //   },
    // },
    // {
    //   title: "調査",
    //   propertyName: "surveyId",
    //   schema: {
    //     uiType: "normal",
    //     schemaType: "externalKey",
    //     resourcePath: "/survey",
    //     titleKey: "surveyName",
    //     filter: { clientId },
    //     readOnly: true,
    //   },
    // },
    // {
    //   title: "抽選アニメーション視聴完了",
    //   propertyName: "movieFinished",
    //   schema: { schemaType: "boolean" },
    // },
    {
      title: "抽選結果",
      propertyName: "lottery",
      schema: {
        schemaType: "selector",
        options: [
          { title: "当選", value: 1 },
          { title: "落選", value: 2 },
          { title: "重複", value: 3 },
        ],
      },
    },
    {
      title: "データ",
      propertyName: "data",
      schema: enquete || {
        schemaType: "object",
        properties: [],
        outputOptions: { useRealFields: true },
      },
    },
  ],
});
export default schema;

import { DependencyList, useEffect, useMemo, useRef } from "react";

/**
 * @description useMemoWithPrev is a function that returns a memoized value
 * This function is similar to useMemo, but factory function also receives the previous value.
 * @param factory
 * @param deps
 * @returns
 */

export const useMemoWithPrev = <T>(
  factory: (prev: T | undefined) => T,
  deps: DependencyList | undefined
): T => {
  const prevRef = useRef<T>();
  const next = useMemo(() => {
    const next = factory(prevRef.current);
    prevRef.current = next;
    return next;
  }, deps);
  return next;
};

/**
 * @description useNormalizedMemo is a function that returns a memoized value
 * This function is similar to useMemoWithPrev, but the return value is normalized.
 * @param factory
 * @param deps
 * @returns
 */

export const useNormalizedMemo = <T>(
  factory: (prev: T | undefined) => T,
  deps: DependencyList | undefined
): T => {
  return useMemoWithPrev((prev: T | undefined) => {
    const next = factory(prev) as T;
    return normalizeDifference(next, prev)[0];
  }, deps);
};

const normalizeDifference = <T>(
  newObject: T,
  oldObject: T | undefined
): [T, boolean] => {
  if (newObject && oldObject && typeof newObject === "object") {
    if (!Array.isArray(newObject)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = {} as any;
      let changed = false;
      for (const [key, value] of Object.entries(newObject)) {
        const [field, _changed] = normalizeDifference(
          value,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (oldObject as any)[key]
        );
        response[key] = field;
        changed = changed || _changed;
      }
      for (const key of Object.keys(oldObject)) {
        if (!(key in newObject)) {
          changed = true;
        }
      }
      return [changed ? response : oldObject, changed];
    } else {
      let changed =
        !Array.isArray(oldObject) || newObject.length !== oldObject.length;
      const response = newObject.map((item, index) => {
        const [field, _changed] = normalizeDifference(
          item,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (oldObject as any)[index]
        );
        changed = changed || _changed;
        return field;
      });
      return [(changed ? response : oldObject) as T, changed];
    }
  }
  return [newObject, newObject !== oldObject];
};

/**
 * @description This function is similar to useEffect, but it runs only once.
 * This function was created to support the breaking changes of useEffect announced in the React documentation,
 * and to serve as a replacement for useEffect
 * @param effect
 * @param deps
 */

export const useEffectOnce = (
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined
) => {
  let ignore = false;
  const cleanUpFn = useRef<ReturnType<typeof effect>>();
  useEffect(() => {
    if (ignore) return;
    ignore = true;
    if (cleanUpFn.current) {
      cleanUpFn.current();
    }
    cleanUpFn.current = effect();
    return () => {
      if (cleanUpFn.current) {
        cleanUpFn.current();
        cleanUpFn.current = undefined;
      }
    };
  }, deps);
};

/**
 * @description useRunAsync is a function that runs a function only once asynchronously.
 * This function is similar to useRun, but it runs asynchronously.
 * This function was created to support the breaking changes in useEffect that were announced in the React documentation,
 * and to serve as a replacement for useEffect
 * @param fn
 * @param deps
 */
export const useRunAsync = (
  fn: () => void,
  deps?: React.DependencyList | undefined
) => {
  useRun(() => {
    setTimeout(fn, 0);
  }, deps);
};

/**
 * @description useRun is a function that runs a function only once synchronously.
 * This function is similar to useMemo, but it does not return a value.
 * This function was created to support the breaking changes in useEffect that were announced in the React documentation,
 * and to serve as a replacement for useEffect
 * @param fn
 * @param deps
 */

export const useRun = (
  fn: () => void,
  deps?: React.DependencyList | undefined
) => {
  useMemoOnce(fn, deps);
};

/**
 * @description compareDeps is a function that compares two dependency lists.
 * If the dependency lists are equal, it returns true, otherwise it returns false.
 * @param a
 * @param b
 * @returns true if the dependency lists are equal, false otherwise
 */

const compareDeps = (
  a: DependencyList | undefined,
  b: DependencyList | undefined
) => {
  if (a === b) return true;
  if (a === undefined || b === undefined) return false;
  if (a.length !== b.length) return false;
  return a.every((item, index) => item === b[index]);
};

/**
 * @description useMemoOnce is a function that returns a memoized value
 * This function was created to support the breaking changes of useMemo announced in the React documentation,
 * and to serve as a replacement for useMemo
 * @param factory
 * @param deps
 * @returns memoized value
 */

export const useMemoOnce = <T>(
  factory: () => T,
  deps: DependencyList | undefined
): T => {
  const ref = useRef<{ value?: T; deps?: DependencyList | undefined }>();
  if (ref.current === undefined || compareDeps(deps, ref.current.deps)) {
    ref.current = {
      value: factory(),
      deps,
    };
  }
  return ref.current.value as T;
};

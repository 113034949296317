import React, { useCallback, useEffect, useRef, useState } from "react";
import EditorJS, {
  BlockTool,
  BlockToolConstructable,
  BlockToolConstructorOptions,
  ToolSettings,
  BlockToolData,
} from "@editorjs/editorjs";
import { SchemaFormParameter } from "components/SchemaFormPage";
import ScoreToolComponent from "./component";
import { settingSchema, settingType } from "./settingSchema";
import { getContentId } from "../contentId";
import { ObjectSchema } from "schemaComponents";
import { useToost } from "hooks/dialogs";
import ErrorBoundary from "components/ErrorBoundary";
import { getObjectId } from "utils/objectId";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ModalForm = <T = any>(props: {
  params: SchemaFormParameter;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}) => Promise<T>;

type Toost = (props: { message: string; duration: number }) => Promise<void>;

type ToolConfig = {
  setPortal: (id: string, dom: React.ReactElement) => void;
  modalForm: ModalForm;
  toost: Toost;
  questionRef: React.MutableRefObject<ObjectSchema | undefined>;
};

export class ScoreTool implements BlockTool {
  static isReadOnlySupported = true;
  contentId: string;
  modalForm: ModalForm;
  toost: Toost;
  data: settingType;
  setPortal: (id: string, dom: React.ReactElement) => void;
  questionRef: React.MutableRefObject<ObjectSchema | undefined>;
  // Avoid a bug of editor.js type definition
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(params: any) {
    const _params = params as BlockToolConstructorOptions<
      settingType,
      ToolConfig
    >;
    const setPortal = _params?.config?.setPortal;
    if (!setPortal) {
      throw new Error("configulation error");
    }
    const modalForm = _params?.config?.modalForm;
    if (!modalForm) {
      throw new Error("configulation error");
    }
    const questionRef = _params?.config?.questionRef;
    if (!questionRef) {
      throw new Error("configulation error");
    }
    const toost = _params?.config?.toost;
    if (!toost) {
      throw new Error("configulation error");
    }
    const { data, api } = _params;
    this.data = data;
    this.setPortal = setPortal;
    this.modalForm = modalForm;
    this.toost = toost;
    this.questionRef = questionRef;
    this.contentId = getContentId();
  }

  static get toolbox() {
    return {
      title: "スコア明細",
      icon: '<i class="mdi mdi-table" />',
    };
  }

  save(block: HTMLElement): BlockToolData {
    console.log("save", { block });
    return this.data;
  }

  renderReact() {
    this.setPortal(
      this.contentId,
      <ErrorBoundary recovoryKey={getObjectId(this.data)}>
        {/* <h3>{this.data?.title}</h3> */}
        <ScoreToolComponent data={this.data} />
      </ErrorBoundary>
    );
  }

  render(): HTMLElement {
    const element = document.createElement("div");
    element.id = this.contentId;
    setTimeout(() => {
      this.renderReact();
    });
    return element;
  }

  renderSettings() {
    const settings = [
      {
        tooltip: "設定",
        name: "setting",
        icon: '<i class="mdi mdi-cog-outline" style="font-size: 1.5rem" />',
        onclick: async () => {
          const question = this.questionRef.current;
          if (!question) {
            this.toost({
              message: "業種にアンケートの設定がされていません set",
              duration: 3000,
            });
            return;
          }
          const data = await this.modalForm<settingType>({
            params: {
              schema: settingSchema({
                question,
              }),
              title: "グラフ設定",
            },
            value: this.data,
          });
          if (data) {
            this.data = Object.assign(data, {
              statType: "dist", // スコア明細はdist固定
            });
            this.renderReact();
          }
        },
      },
    ];
    const wrapper = document.createElement("div");
    settings.forEach((tune) => {
      const button = document.createElement("div");
      button.classList.add("cdx-settings-button");
      button.setAttribute("data-tooltip-id", "tooltip");
      button.setAttribute("data-tooltip-content", tune.tooltip);
      button.innerHTML = tune.icon;
      button.onclick = tune.onclick;
      wrapper.appendChild(button);
    });
    return wrapper;
  }
}

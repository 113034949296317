import firebaseApp from "../firebaseApp";
import {
  HttpsCallableResult,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { getAuth, UserInfo } from "firebase/auth";
import { useEffect, useMemo, useState } from "react";
export { type StatRequest, type StatResponse, useStat } from "./stat";
export {
  type AggregationRequest,
  type AggregationResponse,
  useAggregation,
} from "./aggregation";

const functions = getFunctions(firebaseApp, "asia-northeast1");

const registerConsumerCallable = httpsCallable(
  functions,
  "registerConsumer_v2"
);
export const registerConsumer = async (params: {
  email: string;
  path: string;
  shopId: string;
  campaignId?: string;
  locale?: string;
  contractId?: string;
}) => {
  await registerConsumerCallable(params);
};

export const getAdminUserCallable = httpsCallable(functions, "getAdminUser_v2");
export const getAdminUser = async (
  params?: { uid: string } | { email: string } | undefined
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: any = await getAdminUserCallable(params);
  return response.data as AdminUserResponse;
};

type AdminUserResponse = {
  user: UserInfo | undefined;
  uid: string | undefined;
};

export const useGetAdminUserRecord = (
  params?: { uid: string } | { email: string } | undefined
) => {
  const [response, setResponse] = useState<AdminUserResponse | undefined>(
    undefined
  );
  useMemo(async () => {
    if (!params) {
      setResponse(undefined);
    } else {
      const response = await getAdminUser(params);
      setResponse(response);
    }
  }, [JSON.stringify(params)]);
  return [response?.user, !response] as [UserInfo | undefined, boolean];
};

const requestLotteryV2Callbale = httpsCallable(functions, "requestLottery_v2");
// eslint-disable-next-line camelcase
export const requestLottery_v2 = async (params: { enqueteId: string }) => {
  return (await requestLotteryV2Callbale(params))?.data as {
    isDoubleExecution: boolean;
  };
};

export const useRequestLotteryV2 = ({ enqueteId }: { enqueteId: string }) => {
  const [response, setResponse] = useState<{
    isDoubleExecution: boolean;
  } | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    (async () => {
      if (!enqueteId) return;
      try {
        const response = await requestLottery_v2({ enqueteId });
        setResponse(response);
        setError(null);
      } catch (e) {
        setResponse(null);
        setError(e as Error);
      }
    })();
  }, [enqueteId]);

  return [response, error] as const;
};

const finishLotteryV2Callbale = httpsCallable(functions, "finishLottery_v2");
// eslint-disable-next-line camelcase
export const finishLottery_v2 = async (params: { enqueteId: string }) => {
  await finishLotteryV2Callbale(params);
};

const generateReportCallable = httpsCallable<
  {
    clientId: string;
    month: string;
  },
  {
    shopId: string;
    個別評価項目: string;
    主要評価項目: string;
    その他: string;
  }[]
>(functions, "generate_report");

const generateReport = async (params: { clientId: string; month: string }) => {
  const result = await generateReportCallable(params);
  return result.data;
};

export const useGenerateReport = (params: {
  clientId?: string;
  month: string;
}) => {
  const [response, setResponse] = useState<
    | {
        shopId: string;
        個別評価項目: string;
        主要評価項目: string;
        その他: string;
      }[]
    | undefined
  >(undefined);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (params.clientId && params.month) {
          const response =
            params.clientId && params.month
              ? await generateReport({
                  clientId: params.clientId,
                  month: params.month,
                })
              : undefined;
          setResponse(response);
        } else {
          setResponse(undefined);
        }
        setError(null);
        setLoading(false);
      } catch (e) {
        setResponse([]);
        setError(e as Error);
      }
    })();
  }, [params.clientId, params.month]);

  return {
    response,
    error,
    loading,
  };
};

// 数値文字列かどうかの判定
export const isNumericString = (value: string) => {
  return !isNaN(Number(value));
};

// 小数を含む数値文字列かどうかの判定
export const isDecimalString = (value: string) => {
  return isNumericString(value) && /^-?\d*\.\d+/.test(value);
};

// 数値文字列をカンマ区切りにする
export const formatWithCommas = (value: string | number, decimal?: number) => {
  const parsedNumber = typeof value === "string" ? parseFloat(value) : value;
  if (!value || isNaN(parsedNumber)) {
    return value;
  }
  const decimalPlaces =
    decimal !== undefined && isNaN(decimal) ? undefined : decimal;
  return parsedNumber.toLocaleString("en-US", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

import * as routes from "pages/modules";
import {
  createResolver,
  createRoutes,
  createNavigateHook,
  createNamedOutletHook,
} from "utils/typedRoute";

export const Routes = createRoutes(routes);
export const resolveRoute = createResolver(routes);
export const useTypedNavigate = createNavigateHook(routes);
export const useNamedOutlet = createNamedOutletHook(routes);

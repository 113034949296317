import firebaseApp from "../firebaseApp";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import {
  createStoreCacheHook,
  AsyncState,
  createAsyncCache,
} from "@smart-hook/react-hook-retention-cache";
import { PDataFrame, PDataFrameData } from "../utils/PTable/PDataFrame";

const auth = getAuth(firebaseApp);

const callLocalFunction =
  <Request, Response>(name: string) =>
  async (params: Request): Promise<{ data: Response }> => {
    const accessToken = await auth.currentUser?.getIdToken();
    const response = await fetch(`http://127.0.0.1:5000/${name}`, {
      method: "POST",
      mode: "cors",
      // cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ data: params }),
    });
    if (response.status !== 200) throw new Error(await response.text());
    return (await response.json()) as { data: Response };
  };

export type AggregationRequest = {
  action: "coupon" | "enquete";
  startDate?: string;
  endDate?: string;
  unit?: "day" | "week" | "month";
  reward?: boolean;
  clientId?: string;
  includeIgnore?: boolean;
  total?: boolean;
};

export type AggregationResponse = PDataFrameData;

const forceRemoteApi = true;

export const aggregation: (params: AggregationRequest) => Promise<{
  data: AggregationResponse;
}> = (() => {
  if (location.hostname === "localhost" && !forceRemoteApi) {
    return callLocalFunction<AggregationRequest, AggregationResponse>(
      "aggregation"
    );
  } else {
    return httpsCallable<AggregationRequest, AggregationResponse>(
      getFunctions(firebaseApp, "asia-northeast1"),
      "aggregation"
    );
  }
})();

const aggregationCache = createAsyncCache(
  async (params: AggregationRequest) => {
    const response = await aggregation(params);
    return response.data ? new PDataFrame(response.data) : false;
  }
);

export const useAggregation = createStoreCacheHook(
  aggregationCache,
  {} as AsyncState<PDataFrame>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).aggregationCache = aggregationCache;

import React from "react";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { resolveRoute } from "pages";
import { useClientDoc, useShopDoc } from "models/hook";
import { SHOPS, SHOP } from "../route";
import getSchema from "./schema";
import { PreviewComponent } from "./preview";
import { AdminLocaleSelector } from "hooks/localeSelector";
import { ObjectSchema } from "schemaComponents";
import { useAdminAuthentication } from "hooks/auth";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const UPDATE_SHOP = SHOP.sub("/edit", Shop);
export const ADD_SHOP = SHOPS.sub("/edit/new", Shop);

function Shop({ shopId, clientId }: { shopId?: string; clientId: string }) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { data, loading, set, remove, isEditing } = useShopDoc({
    shopId,
    clientId,
  });
  const { data: clientData } = useClientDoc({ clientId });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "事業所詳細" : "事業所新規作成",
    schema: getSchema({
      isEditing,
      clientId,
      shopId,
      isMaster,
      isAuthSkipped: data.isAuthSkipped,
    }),
    preview: {
      title: "プレビュー",
      component: ({ control }) => {
        return <PreviewComponent control={control} clientId={clientId} />;
      },
      // isDisabled: data.isAuthSkipped,
    },
    edit: {
      title: `${isEditing ? "保存" : "保存"}`,
      confirm: `${isEditing ? "保存" : "保存"}しますか？`,
      handler: set,
      isDisabled: !isMaster,
    },
    remove: isEditing ? { handler: remove } : undefined,
    backPath: resolveRoute("LIST_SHOPS", { clientId }),
    clipboard: {
      objectType: "shop",
      retainKeys: ["clientId", "shopId"],
      isDisabled: !isMaster,
    },
  };
  return (
    <SchemaFormPage
      data={data}
      parameter={parameter}
      loading={isEditing && loading}
      header={<AdminLocaleSelector locales={clientData?.availableLocales} />}
      editModeEnabled={true}
    />
  );
}

export default Shop;

import React, { useCallback, useMemo } from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";

import "./numberSelector.scss";
import { DisplayComponent } from "./common/displayWrapper";
import { useStringIntl } from "hooks/intl";
import { Slider } from "./common/slider";

interface NumberSelectorSchema extends BaseSchema {
  schemaType: "numberSelector";
  textBefore?: string;
  textCenter?: string;
  textAfter?: string;
  minValue: number;
  maxValue: number;
  naValue: number;
  naTitle: string;
  uiType?: "selector" | "slider";
}

const NumberSelectorSchemaComponent: SchemaComponent<NumberSelectorSchema> = (
  props: SchemaComponentInternalProps<NumberSelectorSchema>
) => {
  const { readOnly, schema } = props;
  const {
    textBefore,
    textCenter,
    textAfter,
    maxValue,
    minValue,
    naValue,
    naTitle,
    uiType,
  } = schema;
  const {
    registerProps: { onChange },
    value,
    fieldState,
  } = useField(props, [requiredValidator]);

  const list = [...Array(maxValue - minValue + 1)].map((_, i) => i + minValue);

  const s = useStringIntl();
  const options = useMemo(() => {
    const options: Array<{ title: string; value: number }> = [];
    if (minValue === undefined || maxValue === undefined) return options;
    const middleValue = Math.floor((maxValue - minValue) / 2) + minValue;
    for (let i = minValue; i <= maxValue; i++) {
      let title = i.toString();
      if (i === minValue) title = s(textBefore) || title;
      if (i === middleValue) title = s(textCenter) || title;
      if (i === maxValue) title = s(textAfter) || title;
      options.push({
        title,
        value: i,
      });
    }
    return options;
  }, [minValue, maxValue]);
  const sliderValue = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    const index = options.findIndex((option) => option.value == value);
    return index === -1 ? undefined : index;
  }, [options, value]);
  const sliderOnChange = useCallback(
    (index?: number) => {
      const option = index != null ? options[index] : undefined;
      onChange(option?.value);
    },
    [onChange, options]
  );

  const NotApplicableComponent = useMemo(() => {
    return naTitle ? (
      <div className="number-selector-except">
        <span
          className={classNames(
            "numberSelectorOptionExcept",
            // eslint-disable-next-line eqeqeq
            value != null && naValue == value && "active"
          )}
          onClick={(e) => {
            // eslint-disable-next-line eqeqeq
            onChange(naValue != value ? naValue : null);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (document.activeElement as any)?.blur?.();
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          {s(naTitle)}
        </span>
      </div>
    ) : (
      <></>
    );
  }, [naTitle, naValue, value, onChange, s]);

  return (
    <FieldWrapper fieldState={fieldState}>
      {uiType === "slider" ? (
        <Slider
          options={options}
          onChange={sliderOnChange}
          value={sliderValue}
          hasError={fieldState.invalid}
          showMiddleLabel={true}
          naTitles={[naTitle]}
          naValues={[naValue]}
          valueIncludeNA={value}
        >
          {NotApplicableComponent}
        </Slider>
      ) : (
        <>
          <div className="selector-horizontal">
            <div
              style={{
                display: "table",
              }}
              className={classNames({
                "has-error": fieldState.invalid,
              })}
            >
              {textBefore && (
                <div className="text" style={{ textAlign: "left" }}>
                  {s(textBefore)}
                </div>
              )}
              <div>
                {list.map((optionValue) => (
                  <span
                    key={optionValue}
                    className={classNames(
                      "numberSelectorOption",
                      value != null &&
                        // eslint-disable-next-line eqeqeq
                        value != naValue &&
                        optionValue <= value &&
                        "active"
                    )}
                    onClick={(e) => {
                      onChange(optionValue !== value ? optionValue : null);
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (document.activeElement as any)?.blur?.();
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {optionValue}
                  </span>
                ))}
              </div>
              {textAfter && (
                <div className="text" style={{ textAlign: "right" }}>
                  {s(textAfter)}
                </div>
              )}
            </div>
          </div>
          {NotApplicableComponent}
        </>
      )}
    </FieldWrapper>
  );
};

NumberSelectorSchemaComponent.display = DisplayComponent(
  "NumberSelectorDisplayComponent"
);

export default NumberSelectorSchemaComponent;

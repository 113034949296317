import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const parse = require("loose-json");

interface UploadPreview extends BaseSchema {
  schemaType: "uploadPreview";
  required?: boolean;
  rows?: number;
}

const UploadPreviewComponent: SchemaComponent<UploadPreview> = (
  props: SchemaComponentInternalProps<UploadPreview>
) => {
  const { schema } = props;
  const { value, registerProps, fieldState } = useField(props, [
    requiredValidator,
  ]);
  console.log("UploadPreviewComponent", value, registerProps, fieldState);
  // valueが配列型の場合は、配列の要素を表示する
  const valueArray = Array.isArray(value) ? value : [];

  return (
    // <FieldWrapper fieldState={fieldState}>
    <div
      className=""
      style={{
        overflowX: "scroll",
      }}
    >
      <table className="statTable">
        <thead>
          <tr>
            {Object.keys(valueArray[0] || {}).map((k) => (
              <th key={k}>{k}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {valueArray.map((v, i) => (
            <tr key={i}>
              {Object.keys(v).map((k) => (
                <td key={k} style={{ width: "100px", whiteSpace: "pre" }}>
                  {v[k]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    // </FieldWrapper>
  );
};

UploadPreviewComponent.display = DisplayComponent("MultilineDisplayComponent");

export default UploadPreviewComponent;

import React, { useMemo } from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import Select from "react-select";

import "./selector.scss";
import { DisplayComponent } from "./common/displayWrapper";
import { useOptionIntl } from "./common/selectorOption";
import { StringIntl } from "utils/locale";

interface MultipleSelectorOption {
  title: string;
  value: string | number | null;
}

interface MultipleSelectorOptionIntl {
  title: StringIntl;
  value: string | number | null;
}

interface MultipleSelectorSchema extends BaseSchema {
  schemaType: "multipleSelector";
  uiType?: "vertical" | "horizontal" | "normal";
  options: MultipleSelectorOptionIntl[];
  ignoreValues?: (string | number | null)[];
  naValues?: (string | number | null)[];
  randomize?: boolean;
}
const NormalMultipleSelector = ({
  options,
  onChange,
  value,
  ignoreValues,
}: {
  options: MultipleSelectorOption[];
  onChange: (value: (string | number | null)[] | undefined | null) => void;
  value?: (string | number | null)[];
  ignoreValues?: Set<string | number | null>;
}) => {
  const convertedOptions = useMemo(
    () =>
      options.map(({ title: label, value }) => ({
        value,
        label,
      })),
    [options]
  );
  const convertedOptionMap = useMemo(() => {
    return new Map(convertedOptions.map((item) => [item.value, item]));
  }, [convertedOptions]);
  return (
    <Select
      isMulti={true}
      options={convertedOptions}
      value={value?.map?.((value) => convertedOptionMap.get(value))}
      onChange={(options, meta) => {
        if (
          meta.action === "select-option" &&
          ignoreValues?.has(meta.option?.value || null)
        ) {
          onChange([meta.option?.value || null]);
        } else {
          const newValue = options
            .map((option) => option?.value)
            .filter((value) => value != null && !ignoreValues?.has(value)) as (
            | string
            | number
          )[];
          onChange(newValue.length > 0 ? newValue : null);
        }
      }}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
    />
  );
};

const DescreteMultipleSelector = ({
  options,
  onChange,
  value,
  uiType,
  hasError,
  ignoreValues,
}: {
  options: MultipleSelectorOption[];
  onChange: (value: (string | number | null)[] | undefined | null) => void;
  value?: (string | number | null)[];
  uiType?: string;
  hasError?: boolean;
  ignoreValues?: Set<string | number | null>;
}) => {
  const valueSet = useMemo(
    () => new Set(Array.isArray(value) ? value : []),
    [value]
  );
  const optionMap = useMemo(() => {
    return new Map(options.map((item) => [item.value, item]));
  }, [options]);
  return (
    <div
      style={{ margin: "5px 0" }}
      className={classNames(
        {
          "has-error": hasError,
        },
        uiType && `selector-${uiType}`
      )}
    >
      {options.map(({ title, value: optionValue }, index) => (
        <span
          key={index}
          className={classNames(
            "selectorOption",
            valueSet.has(optionValue) && "active"
          )}
          onClick={(e) => {
            if (ignoreValues?.has(optionValue)) {
              if (optionValue != null) {
                if (valueSet.has(optionValue)) {
                  valueSet.delete(optionValue);
                  onChange(undefined);
                } else {
                  valueSet.add(optionValue);
                  onChange(Array.from([optionValue]));
                }
              }
            } else {
              if (optionValue != null) {
                if (valueSet.has(optionValue)) {
                  valueSet.delete(optionValue);
                } else {
                  valueSet.add(optionValue);
                }
              }
              (document.activeElement as HTMLInputElement)?.blur?.();
              const newValue = Array.from(valueSet).filter(
                (value) => !ignoreValues?.has(value)
              );
              onChange(newValue.length > 0 ? newValue : null);
            }
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          {title}
        </span>
      ))}
    </div>
  );
};

const defaultIgnoreValues = new Set([
  99,
  999,
  "99",
  "999",
  97,
  997,
  "97",
  "997",
]);

const MultipleSelectorSchemaComponent: SchemaComponent<
  MultipleSelectorSchema
> = (props: SchemaComponentInternalProps<MultipleSelectorSchema>) => {
  const { schema } = props;
  const { options, uiType } = schema;
  const {
    registerProps: { onChange },
    value,
    fieldState,
  } = useField(props, [requiredValidator]);
  const ignoreValues = useMemo(() => {
    return schema.ignoreValues || schema.naValues
      ? new Set([...(schema.ignoreValues || []), ...(schema.naValues || [])])
      : defaultIgnoreValues;
  }, [schema]);
  const convertedOption = useOptionIntl(options);
  const sortedOptions = useMemo(() => {
    if (schema.randomize) {
      return convertedOption.sort((a, b) => {
        const aIsIgnore = ignoreValues?.has(a.value);
        const bIsIgnore = ignoreValues?.has(b.value);
        if (aIsIgnore && bIsIgnore) {
          return 0;
        } else if (aIsIgnore) {
          return 1;
        } else if (bIsIgnore) {
          return -1;
        } else {
          return Math.random() - 0.5;
        }
      });
    } else {
      return convertedOption;
    }
  }, [convertedOption, schema.randomize]);
  return (
    <FieldWrapper fieldState={fieldState}>
      {uiType === "normal" && (
        <NormalMultipleSelector
          options={sortedOptions}
          value={value}
          onChange={onChange}
          ignoreValues={ignoreValues}
        ></NormalMultipleSelector>
      )}
      {uiType !== "normal" && (
        <DescreteMultipleSelector
          options={sortedOptions}
          value={value}
          onChange={(e) => {
            console.log("e", e);
            onChange(e);
          }}
          uiType={uiType}
          hasError={fieldState.invalid}
          ignoreValues={ignoreValues}
        ></DescreteMultipleSelector>
      )}
    </FieldWrapper>
  );
};

MultipleSelectorSchemaComponent.display = DisplayComponent(
  "MultipleSelectorDisplayComponent",
  (value, schema) => {
    const { options } = schema;
    const valueSet = useMemo(() => {
      try {
        return new Set(Array.isArray(value) ? value : [value]);
      } catch (e) {
        return new Set();
      }
    }, [value]);
    const convertedOption = useOptionIntl(options);
    return (
      <div style={{ lineHeight: "1.8em" }}>
        {convertedOption
          .filter((option) => valueSet.has(option.value))
          .map((option, index, array) => {
            return (
              <span
                key={index}
                style={{
                  background: "#ddd",
                  borderRadius: "7px",
                  padding: "4px 10px",
                  margin: "3px",
                  fontSize: "0.8em",
                  whiteSpace: "nowrap",
                }}
              >
                {option.title}
              </span>
            );
          })}
      </div>
    );
  }
);

export default MultipleSelectorSchemaComponent;

import React, { useMemo } from "react";
import schema from "./schema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { useAdminUserList } from "models/hook";
import { resolveRoute } from "pages";
import { ADMIN_USERS } from "..";
import { adminUserData } from "common/models/adminUser";
import { useConditionSelector } from "hooks/condition/conditionSelector";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const LIST_ADMIN_USER = ADMIN_USERS.sub("", AdminUsers);

function AdminUsers() {
  useAdminPageAuthorization();
  const { conditionSelector, condition, timeCondition } = useConditionSelector({
    schema,
    hideTimeCondition: true,
  });
  const { list, loading, hasMore, refresher, fetching, reload, setRange, get } =
    useAdminUserList({
      createdAt: timeCondition,
      condition,
    });
  const parameter: SchemaListPageParameter<adminUserData> = useMemo(
    () => ({
      title: "管理ユーザ一覧",
      edit: {
        path: ({ adminUserId }) =>
          resolveRoute("UPDATE_ADMIN_USER", { adminUserId }),
      },
      create: { path: () => resolveRoute("CREATE_ADMIN_USER") + "?edit=1" },
      download: {
        filename: `adminUsers`,
        handler: get,
      },
      schema,
      setRange,
    }),
    [schema, setRange]
  );
  return (
    <>
      <SchemaListPage
        parameter={parameter}
        list={list}
        loading={loading}
        hasMore={hasMore}
        fetching={fetching}
        refresher={refresher}
        header={conditionSelector}
        reload={reload}
      ></SchemaListPage>
    </>
  );
}

export default AdminUsers;

import React from "react";
import classNames from "classnames";
import { useNamedOutlet } from "pages";

const Aside = ({ asideMobileActive }: { asideMobileActive: boolean }) => {
  const Outlet = useNamedOutlet("menu");
  return (
    <aside className={classNames("aside", asideMobileActive && "has-aside")}>
      <Outlet />
    </aside>
  );
};

export default Aside;

import { cleanObject } from "comodel-firestore-react";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import firebaseApp from "firebaseApp";
import { useAdminAuthentication } from "hooks/auth";
import { useMemo, useRef } from "react";

const firestore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

const getRandomHexString = (length: number) => {
  return Array.from(crypto.getRandomValues(new Uint8Array(length)))
    .map((d) => d.toString(16).padStart(2, "0"))
    .join("");
};

export const getLogId = () => {
  return getRandomHexString(16);
};

const getWithDefault = (
  storage: Storage,
  key: string,
  generator: () => string
) => {
  const value = storage.getItem(key);
  if (value) {
    return value;
  } else {
    const defaultValue = generator();
    storage.setItem(key, defaultValue);
    return defaultValue;
  }
};

const pageId = getLogId();

const deviceId = (() => {
  return getWithDefault(localStorage, "deviceId", getLogId);
})();

const searchParamSessionId =
  new URLSearchParams(window.location.search).get("sessionId") || undefined;

let sessionId = (() => {
  if (searchParamSessionId) {
    sessionStorage.setItem("sessionId", searchParamSessionId);
    return searchParamSessionId;
  } else {
    return getWithDefault(sessionStorage, "sessionId", getLogId);
  }
})();

const resetSessionId = () => {
  sessionId = searchParamSessionId || getLogId();
  sessionStorage.setItem("sessionId", sessionId);
};

const searchParamCampaignId =
  new URLSearchParams(window.location.search).get("campaignId") || undefined;

export const campaignId = (() => {
  if (searchParamCampaignId) {
    sessionStorage.setItem("campaignId", searchParamCampaignId);
    return searchParamCampaignId;
  } else {
    return sessionStorage.getItem("campaignId");
  }
})();

export const getLogParams = () => {
  return {
    pageId,
    sessionId,
    deviceId,
    campaignId: campaignId || undefined,
  };
};

export const errorLog = async (
  logId: string,
  data: Record<string, unknown>
) => {
  const claims = (await auth.currentUser?.getIdTokenResult())?.claims;
  await setDoc(doc(firestore, `/errorLogs/${logId}`), {
    type: "react",
    createdAt: Date.now(),
    userAgent: navigator.userAgent,
    location: location.href,
    userId: auth.currentUser?.uid,
    email: auth.currentUser?.email,
    deviceId,
    sessionId,
    claims,
    ...data,
  });
};

export const trackingLog = async (
  type: string,
  data: Record<string, unknown>
) => {
  try {
    const logId = getLogId();
    // prevLogIdは、ログ自体の整合性の評価のために使われる
    const prevLogId = sessionStorage.getItem("prevLogId");
    sessionStorage.setItem("prevLogId", logId);
    await setDoc(
      doc(firestore, `/trackingLog/${logId}`),
      cleanObject({
        type,
        createdAt: Date.now(),
        userAgent: navigator.userAgent,
        location: location.href,
        userId: auth.currentUser?.uid,
        email: auth.currentUser?.email,
        deviceId,
        sessionId,
        prevLogId,
        campaignId,
        pageId,
        ...data,
      })
    );
  } catch (e) {
    console.error("error in trackingLog");
    console.error(e);
  }
};

export const useTrackingLog = (
  type: string,
  data: Record<string, unknown>,
  disable?: boolean
  // initializeSession?: boolean
) => {
  const { user, loading } = useAdminAuthentication();
  const isFirstTime = useRef(true);
  useMemo(() => {
    if (!disable && !loading && isFirstTime.current) {
      isFirstTime.current = false;
      // if (initializeSession) {
      //   resetSessionId();
      // }
      trackingLog(type, {
        userId: user?.uid,
        email: user?.email,
        ...data,
      });
    }
  }, [loading]);
};

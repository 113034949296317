import React from "react";
import { ObjectSchema } from "schemaComponents";
import schema from "./schema";
import getSchema from "../item/schema";
import { timestampsProperties, mergeSchema } from "schemaComponents/util";

export default ({ isMaster }: { isMaster: boolean }): ObjectSchema => {
  return mergeSchema({ schemaType: "object", properties: [] }, [
    {
      title: "リワードID",
      propertyName: "rewardId",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
    ...schema.properties,
    ...(getSchema({ isEditing: false, isMaster }) as ObjectSchema).properties,
    ...timestampsProperties,
  ]);
};

import React from "react";
import { ExtendedColumn } from "components/Table";
import { DocumentData } from "firebase/firestore";

import { resolve, SchemaTypes, PropertyType } from "schemaComponents";
import { resolveStringIntl } from "hooks/intl";

const getByPath = (
  obj: Record<string, unknown>,
  paths: string[],
  pathIndex = 0
): unknown => {
  if (pathIndex === paths.length - 1) {
    return obj[paths[pathIndex]];
  } else {
    const target = obj[paths[pathIndex]];
    return getByPath(target as Record<string, unknown>, paths, pathIndex + 1);
  }
};

const propertyToColumn = ({
  propertyName,
  propertyId,
  schema,
  title,
}: PropertyType): ExtendedColumn<DocumentData> | undefined => {
  const { display: Display } = resolve(schema.schemaType as SchemaTypes);
  if (Display) {
    return {
      accessor: propertyName || propertyId || "",
      Header: resolveStringIntl(title),
      Cell: ({ row }) => {
        return (
          <Display
            value={
              propertyName
                ? getByPath(row.original, propertyName.split("."))
                : row.original
            }
            schema={schema}
          />
        );
      },
    };
  } else if (schema.schemaType !== "object") {
    return {
      Header: resolveStringIntl(title),
      accessor: propertyName || propertyId || "",
    };
  }
};

export const propertiesToColumns = (
  properties: PropertyType[]
): ExtendedColumn<DocumentData>[] =>
  properties
    .map(propertyToColumn)
    .filter((v) => v) as ExtendedColumn<DocumentData>[];

import React from "react";
import { formatDist, formatNumber } from "../statSchemaUtil";
import { StatConfig } from "./type";

export const dist: StatConfig = {
  action: "dist",
  statSchemas: [
    {
      value: "shop-freq",
      title: "事業所",
      graph: true,
      table: false,
    },
    {
      value: "client-freq",
      title: "全社",
      graph: true,
      table: false,
    },
    {
      value: "all-freq",
      title: "全体",
      graph: true,
      table: false,
    },
    {
      value: "shop",
      title: "事業所",
      multiple: {
        source: ["shop-freq", "shop-count"],
        element: (values) => {
          return formatDist(values["shop-freq"], values["shop-count"]);
        },
      },
    },
    {
      value: "client",
      title: "全社",
      multiple: {
        source: ["client-freq", "client-count"],
        element: (values) => {
          return formatDist(values["client-freq"], values["client-count"]);
        },
      },
    },
    {
      value: "all",
      title: "全体",
      multiple: {
        source: ["all-freq", "all-count"],
        element: (values) => {
          return formatDist(values["all-freq"], values["all-count"]);
        },
      },
    },
  ],
};

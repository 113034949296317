export const termsOfServiceText = `ファンくるCR アンケート利用規約

「ファンくるCR アンケート利用規約」（以下「本規約」といいます。）は、株式会社ファンくる（以下「当社」といいます。）が提供するサービス「ファンくるCR」の利用に関する条件について定めるものです。当社は、「ファンくるCR」の利用者がアンケートに回答したことをもって、利用者が本規約に同意したものとみなします。（以下「ファンくるCR」の利用者を「利用者」といいます。）
なお、利用者は、本規約の他、「ファンくるCR」のサービス上または当社のホームページ上に掲示される注意事項等も遵守し、当該注意事項等は本規約の一部を構成することをあらかじめ承諾するものとします。

第１条（ファンくるCR）
「ファンくるCR」とは、当社が提供するアンケート・レビュー等の促進・管理サービス並びにこれに付随する各種サービスの総称をいいます（以下「本サービス」といいます。）。当社はアンケート・レビュー等の対象となるサービスの運営企業（以下「運営企業」といいます。）より委託を受け、本サービスを提供いたします。

第２条（本サービスの利用）
本サービスを利用しようとする者（以下「利用希望者」といいます。）は、本サービスの仕組み及び内容並びに本規約を理解・承諾のうえ、利用を開始するものとします。

第３条（個人情報の取扱い）
当社は、本サービスにおいて、当社が別途定めるプライバシーポリシーに基づき、適切に、利用者及び利用希望者の個人情報を取り扱うものとします。

第４条（利用者情報）
利用者は、本サービスで入力又は提供した利用者に関する情報（以下「利用者情報」といいます。）の内容につき、正確性・真実性を保証し、自ら責任を負うものとします。

第５条（アンケート回答）
１ 利用者は、アンケート回答画面での評価（５段階評価など、文章、画像等の投稿を含まない回答をいい、以下「評価情報」といいます。）の内容につき、正確性・真実性を保証し、自ら責任を負うものとします。
２ 利用者は、以下の各号に掲げる評価を行わないものとし、評価情報の内容に起因して発生した一切の損害について賠償責任を負うものとします。
（１）   他人になりすました評価
（２）   来店実績を偽る評価
（３）   虚偽事実を伝える評価
（４）   その他、不適切な内容の評価

第６条（投稿）
１ 利用者が、アンケート回答画面で文章、画像等を投稿する場合（以下当該投稿された文章等を「利用者投稿」といいます。）、利用者は、利用者投稿に関して、著作権法27条及び28条の権利を含む著作権法上の全ての権利を当社に対して無償で譲渡したものとし、当社もしくは当社から使用許諾を受けた第三者が無制限に利用できるものとします。また、利用者は当該利用者投稿につき、当社及び当社から使用許諾を受けた第三者に対して著作者人格権（公表権、氏名表示権、同一性保持権）を一切行使しないものとします。
２ 利用者は、利用者投稿の内容につき、正確性・真実性を保証し、自ら責任を負うものとします。
３ 利用者が、著作権その他の権利の全部又は一部が第三者に帰属する画像、文章等（以下「第三者情報等」といいます。）の投稿を希望する場合、利用者は、当該第三者が本利用規約において利用者が負担する義務と同等の義務を負担すること及び利用者または運営企業が当該第三者情報等を利用することについて、当該第三者から許諾を得た上で、当該第三者情報等を投稿するものとします。
４ 利用者が前項の規定に違反し、当社又は他の利用者と第三者情報等の権利者との間にトラブルが発生した場合、利用者は、自己の責任と費用において当該トラブルの解決を図るものとし、当社に一切の迷惑をかけないものとします。
５ 利用者は、以下の各号に掲げる投稿又はその恐れのある投稿を行わないものとし、利用者投稿の内容に起因して発生した一切の損害について賠償責任を負うものとします。
（１）   当社又は第三者の著作権、商標権その他の知的財産権、プライバシー権、名誉等、権利を侵害する内容の投稿
（２）   他人になりすました投稿
（３）   来店実績、または購入内容を偽る投稿
（４）   本サービスの内容及び機能や本サービスにおいて提供される情報の正確性に関するお問合せやクレームの投稿
（５）   当社又は第三者を誹謗、中傷し、名誉、プライバシー、信用を傷つける、又は差別発言するなどの不適切な表現（伏字での発言も含める。）を含む内容の投稿
（６）   差別的表現、ナンセンス、グロテスクな内容の投稿
（７）   法令、公序良俗に反する内容の投稿
（８）   その他、利用者投稿として不適切な内容、表現の投稿
６ 当社は、以下の各号に掲げる場合には、利用者投稿を第三者に開示することができるものとし、かかる開示に起因して発生したいかなる損害についても、賠償責任を負いません。
（１）   利用者が承諾した場合
（２）   本サービスの技術的不具合の原因を解明し、解消するために必要な場合
（３）   裁判所や警察などの公的機関から、法令に基づく正式な照会を受けた場合
（４）   人の生命、身体及び財産などに差し迫った危険があり、緊急の必要性があると当社が判断した場合
（５）   法令に定める開示の要件が充足されたと当社が判断した場合
（６）   その他本サービスを適切に提供するために当社が必要と判断した場合

第７条（知的財産権等）
本サービスにおいて当社が提供する文章、画像、プログラムその他のデータ等のコンテンツ（以下「本コンテンツ」といいます。）についての一切の権利（所有権、知的財産権、肖像権、パブリシティー権等）は、当社又は当該権利を有する第三者に帰属するものとし、利用者は、方法又は形態の如何を問わず、これらを当社に無断で複製、複写、転載、転送、蓄積、販売、出版その他利用者個人の私的利用の範囲を超えて使用してはならないものとします。

第８条（委託）
当社は、本サービスの提供業務の一部または全部を外部委託することがあります。

第９条（注意事項）
１        景品の提供については以下の通りとします。
（１）   当社は本サービスの利用者に対して、運営企業より景品が提供される場合、本サービス上にて景品内容の記載を行います。
（２）   利用者に提供された景品は、当該利用者のみが景品引換の権利を有し、転売その他の行為が発覚した場合、権利を取り消させて頂くことがございます。
（３）   利用者情報に誤りがある場合等により景品が利用者にお届けできない場合、景品引換の権利は無効とさせて頂きます。
（４）   いかなる理由であっても、景品の有効期限の経過に関し、当社は責任を負いかねます。
２ 利用者が18歳未満の方の場合には、保護者の同意を得た上でご利用ください。本サービスをご利用された場合、当該利用者は保護者の方の同意を得ているものとみなします。
３ 当社は、利用者が本規約に違反した場合など、本サービスの利用を認めるのが不適当と判断した場合には、当該利用者の本サービスの利用資格及び付与された景品等を一時的に停止し、或いは取り消す権限を有するものとします。また、当社は本サービスの利用資格及び付与された景品を停止又は取り消された利用者に対して、その理由を説明する義務を一切負わないものとし、当該利用者に対して景品の当選、提供は行いません。

第１０条（禁止事項）
利用者は、本サービスの利用に関し、以下の各行為又はその恐れのある行為をしてはならないものとします。
（１）   犯罪行為若しくは犯罪行為に結びつく行為
（２）   本サービスを営利又は商業目的で利用する行為（但し、当社が認める場合を除きます。）
（３）   本サービスの提供のためのシステムへの不正アクセス等、本サービスの運営を妨げる行為
（４） 本サービスのアンケート回答ページのURLについて、当社の許可なく第三者に対して公開する行為
（５）   本コンテンツの全部又は一部を、当社に無断で、複製、複写、転載、転送、蓄積、販売、出版、その他利用者個人の私的利用の範囲を超えて利用する行為
（６）   リバースエンジニアリング、逆コンパイル、逆アセンブル、その他これらに準じる行為
（７）   当社又は第三者の信用を損なう行為
（８）   他人になりすまして、本サービスを利用する行為
（９）   同一のアンケートに対して、複数のメールアドレスを用いて本サービスを利用する行為
（１０）使い捨てメールアドレスまたはワンタイムメールを用いて本サービスを利用する行為
（１１）   法令、公序良俗若しくは本利用規約に違反する行為
（１２）   当社が承認する以外の方法により、本サービスを利用する行為
（１３）当社又は第三者に対する迷惑行為
（１４）その他当社が不適切と判断する行為

第１１条（当社の免責等）
１ 当社は、利用者の端末が、本サービスの利用に適さない場合であっても、本ソフトウェア及び本サービスの変更、改変等、当該利用に適するように対応する義務を負わないものとします。
２ 当社は、アクセス過多、その他本サービスの表示速度の低下や障害等に起因して発生した損害について、当社に故意又は重過失がない限り、損害賠償責任を負いません。
３ 当社は、利用者が本サイトを利用することにより第三者に対して与えた損害、及び利用者が本サイトを利用することにより被った損害について、当社に故意又は重過失がない限り、損害賠償責任を負いません。

第１２条（本サービスの変更、停止又は廃止）
当社は、本サービスの全部又は一部を、利用者への予告なく改訂、追加、変更又は廃止することができ、これに起因して利用者又は第三者に損害が発生した場合であっても、当社は、当社に故意又は重過失がない限り賠償責任を負わないものとします。

第１３条（反社会的勢力）
１ 利用者は、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下これらを「反社会的勢力」といいます。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
（１）   反社会的勢力が経営を支配又は実質的に関与していると認められる団体その他これらに準ずる者と関係を有すること
（２）   自己もしくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力を利用していると認められる関係を有すること
（３）   反社会的勢力に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
２ 利用者は、自ら又は第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします。
（１）   暴力的な要求行為
（２）   法的な責任を超えた不当な要求行為
（３）   取引に関して、脅迫的な言動をし、又は暴力を用いる行為
（４）   風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為
（５）   その他前各号に準ずる行為

第１４条（本利用規約の変更）
１ 当社は、当社の判断により、本利用規約をいつでも任意の理由で変更することができるものとします。
２ 変更後の本利用規約は、当社が別途定める場合を除いて、当社のサービスページ画面上で表示された時点より効力を生じるものとします。
３ 利用者は、変更後の本利用規約に同意できない場合、本サービスの利用を終了するものとし、変更後の本利用規約の効力が生じた後に、利用者が本サービスを継続して利用した場合には、変更後の本利用規約の内容に同意したものとみなされます。

第１５条（有効性）
１ 本利用規約の一部の規定が法令に基づいて無効と判断されても、本利用規約のその他の規定は有効に存続するものとします。
２ 本利用規約の全部又は一部の規定が、ある利用者との関係で無効とされ、又は取り消された場合でも、本利用規約はその他の利用者との関係では有効とします。

第１６条（権利義務の譲渡）
利用者は、本利用規約に別段の定めがある場合を除いて、当社の事前の書面による承諾なしに、本利用規約により生じた権利義務を、第三者に譲渡し、貸与し、又は担保に供することはできないものとします。
又は分割会社となる合併又は会社分割等による包括承継を含むものとします。

第１７条（準拠法及び管轄裁判所）
１ 本利用規約の準拠法は、日本法とします。
２ 利用者と当社の間で本利用規約に関して訴訟の必要が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。

附則
2024年4月23日 作成・施行
`;

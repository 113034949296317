import { ObjectSchema, PropertyType } from "schemaComponents";

const propertyCreatedAt: PropertyType = {
  title: "作成日時",
  propertyName: "createdAt",
  schema: { schemaType: "datetime" },
};
const propertyUpdatedAt: PropertyType = {
  title: "更新日時",
  propertyName: "updatedAt",
  schema: { schemaType: "datetime" },
};
export const timestampsProperties: PropertyType[] = [
  propertyCreatedAt,
  propertyUpdatedAt,
];

export const mergeSchema = (
  schema: ObjectSchema,
  properties: PropertyType[]
) => {
  return {
    ...schema,
    properties: [...schema.properties, ...properties].filter(
      (obj, index, array) => {
        const objPropertyNameOrId = obj.propertyName ?? obj.propertyId;
        return (
          array.findIndex((item) => {
            const itemPropertyNameOrId = item.propertyName ?? item.propertyId;
            return itemPropertyNameOrId === objPropertyNameOrId;
          }) === index
        );
      }
    ),
  };
};

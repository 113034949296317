import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import {
  useTable,
  useSortBy,
  usePagination,
  Column,
  UseSortByColumnProps,
  UsePaginationState,
  TableInstance,
  HeaderGroup,
  UsePaginationInstanceProps,
  UseSortByOptions,
  TableState,
  UsePaginationOptions,
  UseTableOptions,
} from "react-table";
import Pagination from "react-bootstrap/Pagination";

import { useFormatter } from "hooks/intl";
import classes from "./Table.module.scss";
import "./TableMobile.scss";
import Form from "react-bootstrap/Form";
import { Spinner, Stack } from "react-bootstrap";
import { useSearchParamAccessor } from "utils/url";
import { useFieldAccessor } from "hooks/accessor";

type DefaultTableContentType = Column extends Column<infer U> ? U : never;

export type ExtendedColumn<D extends DefaultTableContentType> = Column<D> &
  UseSortByOptions<D>;

const Table = React.memo(function Table<D extends DefaultTableContentType>({
  columns,
  data,
  hasMore,
  fetching,
  onSetRange,
  refresher,
}: {
  columns: ExtendedColumn<D>[];
  data: D[];
  hasMore?: boolean;
  fetching?: boolean;
  onSetRange?: (params: { min: number; max: number }) => void;
  refresher?: unknown;
}) {
  const searchParamAccessor = useSearchParamAccessor<{
    paged?: number;
  }>();
  const { value: paged, setValue: setPaged } = useFieldAccessor(
    searchParamAccessor,
    "paged"
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable<D>(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        pageSize: 20,
        pageIndex: paged || 0,
      },
    } as UseTableOptions<D> & UsePaginationOptions<D>,
    useSortBy,
    usePagination
  ) as TableInstance<D> &
    UsePaginationInstanceProps<D> & {
      state: TableState<D> & UsePaginationState<D>;
    };
  // console.log({ columns, data, hasMore, fetching, onSetRange, refresher });
  const { formatMessage } = useFormatter();
  const perPage = formatMessage("Table.perPage");
  const range = {
    min: pageIndex * pageSize,
    max: (pageIndex + 1) * pageSize - 1,
  };
  useEffect(() => {
    onSetRange?.(range);
  }, [range.min, range.max]);
  useEffect(() => {
    gotoPage(paged || 0);
  }, [refresher]);
  useEffect(() => {
    setPaged(pageIndex || undefined);
  }, [pageIndex]);

  return (
    <div className="table-wrapper">
      <CustomPagination
        {...{
          perPage,
          pageSize,
          setPageSize,
          pageIndex,
          gotoPage,
          previousPage,
          nextPage,
          canPreviousPage,
          canNextPage,
          pageCount,
          data,
          range,
          hasMore,
          fetching,
        }}
      />
      <div className="table-main">
        <table
          className="common-table table is-striped is-hoverable"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                {(
                  headerGroup.headers as (HeaderGroup<D> &
                    UseSortByColumnProps<D>)[]
                ).map((column, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    className={classNames(
                      { [classes.isCurrentSort]: column.isSorted },
                      { [classes.isSortable]: column.canSort && !hasMore }
                    )}
                    {...(hasMore
                      ? { key: index }
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                  >
                    <div>
                      {column.render("Header")}
                      {column.isSorted && (
                        <span className="icon">
                          <i
                            className={classNames(
                              "mdi",
                              classes.tableIcon,
                              { "mdi-arrow-down": column.isSortedDesc },
                              { "mdi-arrow-up": !column.isSortedDesc }
                            )}
                          />
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        data-label={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CustomPagination
        {...{
          perPage,
          pageSize,
          setPageSize,
          pageIndex,
          gotoPage,
          previousPage,
          nextPage,
          canPreviousPage,
          canNextPage,
          pageCount,
          data,
          range,
          hasMore,
          fetching,
        }}
      />
    </div>
  );
});

const CustomPagination = ({
  perPage,
  pageSize,
  setPageSize,
  pageIndex,
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  data,
  range,
  hasMore,
  fetching,
}: {
  perPage: string;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  gotoPage: (pageIndex: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  data: unknown[];
  range: { min: number; max: number };
  hasMore?: boolean;
  fetching?: boolean;
}) => {
  return (
    <Stack direction="horizontal" style={{ padding: "20px" }}>
      <div>
        <Form.Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 30, 50, 100].map((size) => (
            <option key={size} value={size}>
              {size} {perPage}
            </option>
          ))}
        </Form.Select>
      </div>
      <div className="ms-auto">
        <Pagination>
          <div style={{ padding: "0.375rem 1rem" }}>
            {range.min < range.max ? (
              <>
                {range.min + 1}-{range.max + 1}
              </>
            ) : (
              <>0</>
            )}
            /{data.length}
            {hasMore ? "+" : ""}
            {fetching && <Spinner animation="border" size="sm"></Spinner>}
          </div>

          <Pagination.First
            disabled={pageIndex === 0}
            onClick={() => gotoPage(0)}
          />
          <Pagination.Prev
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          {pageIndex !== 0 && (
            <Pagination.Item onClick={() => gotoPage(0)}>{1}</Pagination.Item>
          )}
          {pageIndex > 3 && <Pagination.Ellipsis disabled />}
          {pageIndex === 3 && (
            <Pagination.Item onClick={() => gotoPage(1)}>{2}</Pagination.Item>
          )}
          {pageIndex - 1 > 0 && (
            <Pagination.Item onClick={() => previousPage()}>
              {pageIndex}
            </Pagination.Item>
          )}
          <Pagination.Item active>{pageIndex + 1}</Pagination.Item>
          {canNextPage && (
            <Pagination.Item onClick={() => nextPage()}>
              {pageIndex + 2}
            </Pagination.Item>
          )}
          {pageCount - pageIndex === 4 && (
            <Pagination.Item onClick={() => gotoPage(pageCount - 2)}>
              {pageCount - 1}
            </Pagination.Item>
          )}
          {pageCount - pageIndex > 4 && <Pagination.Ellipsis disabled />}
          {pageIndex + 2 < pageCount && (
            <Pagination.Item onClick={() => gotoPage(pageCount - 1)}>
              {pageCount}
            </Pagination.Item>
          )}
          <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
          <Pagination.Last
            disabled={pageIndex + 1 >= pageCount}
            onClick={() => gotoPage(pageCount - 1)}
          />
        </Pagination>
      </div>
    </Stack>
  );
};

export default Table;

import React, { useCallback, useMemo } from "react";
import Select, { GroupBase, StylesConfig } from "react-select";
import { Col, Form, Row } from "react-bootstrap";
import { ObjectSchema, PropertyType } from "schemaComponents";
import { useFieldAccessor } from "hooks/accessor";
import { useSearchParamAccessor } from "utils/url";
import { usePropertyListStatic } from "hooks/condition/properties";
import { resolveStringIntl, useStringIntl } from "hooks/intl";

type IntrinsicOption = { label: string; value?: string };
type ExtendedOption = IntrinsicOption & { property: PropertyType };

export const useSchemaSelector = ({ schema }: { schema: ObjectSchema }) => {
  const properties = usePropertyListStatic(schema);
  const searchParamAccessor = useSearchParamAccessor<{ p?: string[] }>();
  const valueAccessor = useFieldAccessor(searchParamAccessor, "p");

  const { optionSource, optionMap } = useMemo(() => {
    const optionSource =
      (properties
        ?.filter(
          (property) => (property.schema.schemaType as string) !== "conversion"
        )
        ?.map((property) => ({
          label: resolveStringIntl(property.title),
          value: property.propertyName,
          property,
        }))
        .filter((property) => property.value) as ExtendedOption[]) || [];
    const optionMap = Object.fromEntries(
      optionSource.map((option) => [option.value, option])
    ) as Record<string, ExtendedOption>;
    return { optionSource, optionMap };
  }, [properties]);

  const selectedOptions = useMemo(() => {
    return (
      valueAccessor.value
        ?.map((propertyName) => optionMap[propertyName])
        .filter((option) => option) || []
    );
  }, [valueAccessor.value, optionMap]);

  const selectedProperties = useMemo(() => {
    return (
      (valueAccessor.value
        ?.map((propertyName) => {
          const property = optionMap[propertyName]?.property as
            | PropertyType
            | undefined;
          if (!property?.propertyName) {
            return undefined;
          } else {
            return property;
          }
        })
        .filter((option) => option) as PropertyType[]) || []
    );
  }, [valueAccessor.value, optionMap]);

  const onChange = useCallback(
    (
      options: readonly {
        label: string;
        value?: string;
      }[]
    ) => {
      const newValue = options
        .map((option) => option.value)
        .filter((value) => value) as string[];
      valueAccessor.setValue(newValue.length > 0 ? newValue : undefined);
    },
    [valueAccessor.setValue]
  );

  const styles: StylesConfig<
    ExtendedOption,
    true,
    GroupBase<ExtendedOption>
  > = useMemo(
    () => ({
      multiValueLabel: (provided, state) => {
        return { ...provided, whiteSpace: "inherit" };
      },
    }),
    []
  );

  const schemaSelector = useMemo(
    () => (
      <>
        <Form.Group as={Row} style={{ padding: "10px 20px 20px 20px" }}>
          <Form.Label column sm="1">
            追加表示
          </Form.Label>
          <Col sm="11">
            <Select
              className="conditionHeader"
              isMulti={true}
              options={optionSource}
              value={selectedOptions}
              onChange={onChange}
              styles={styles}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
            />
          </Col>
        </Form.Group>
      </>
    ),
    [optionSource, selectedOptions, onChange]
  );
  return { schemaSelector, selectedProperties };
};

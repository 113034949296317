import React, { useMemo, useState } from "react";
import { SUMMARIES } from "../route";
import { Form, Stack, Tab, Tabs } from "react-bootstrap";
import { useAdminPageAuthorization } from "hooks/authAdminPage";
import { useAdminAuthentication } from "hooks/auth";
import { useSearchParamAccessor } from "utils/url";
import {
  DateRange,
  relativeMonth,
} from "schemaComponents/components/searchDate";
import { useFieldAccessor } from "hooks/accessor";
import { SchemaInput } from "components/SchemaInput";
import Select from "react-select";
import dayjs from "dayjs";
import { StringIntl } from "utils/locale";
import { resolveStringIntl } from "hooks/intl";
import { useAggregation } from "functions";
import { useClientList, useRewardList, useShopList } from "models/hook";
import { Formatters, PPivotTable, PTable } from "utils/PTable/PTable";
import { PDataFrame } from "utils/PTable/PDataFrame";

export const LIST_SUMMARIES = SUMMARIES.sub("", Summaries);

type TimeUnit = "month" | "week" | "day";
type Action = "enquete" | "coupon";

type LabelItem = [string, string];

const toFormatter = <T,>(
  list: T[],
  idKey: keyof T,
  labelKey: keyof T,
  labels?: LabelItem[]
) => {
  const entries = list
    .map(
      (item) =>
        [
          item[idKey] as string,
          resolveStringIntl(item[labelKey] as StringIntl),
        ] as const
    )
    .sort((a, b) => (a[1] || "").localeCompare(b[1] || ""));
  return Object.fromEntries([...(labels || []), ...entries]);
};

const getOrderedIds = <T, V extends keyof T>(
  list: T[],
  idKey: V,
  labelKey: keyof T,
  labels?: LabelItem[]
) => {
  return [
    ...(labels?.map(([id]) => id) || []),
    ...list
      .sort((a, b) =>
        (resolveStringIntl(a[labelKey] as StringIntl) || "")?.localeCompare(
          resolveStringIntl(b[labelKey] as StringIntl) || ""
        )
      )
      .map((item) => item[idKey]),
  ];
};

function Summaries({ clientId }: { clientId: string }) {
  const title = "件数集計";
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const searchParamAccessor = useSearchParamAccessor<{
    shopId?: string;
    clientId?: string;
    date?: DateRange;
    tabKey?: string;
  }>();
  const { value: shopId, setValue: setShopId } = useFieldAccessor(
    searchParamAccessor,
    "shopId"
  );
  const { value: variableClientId, setValue: setClientId } = useFieldAccessor(
    searchParamAccessor,
    "clientId"
  );
  const { value: tabKey, setValue: setTabKey } = useFieldAccessor(
    searchParamAccessor,
    "tabKey"
  );
  const { value: _dateRange, setValue: setDateRange } = useFieldAccessor(
    searchParamAccessor,
    "date"
  );

  const dateRange = _dateRange || relativeMonth(null, -1);
  // const handleOnChangeShop = useCallback(
  //   (shopId: string) => {
  //     setShopId(shopId);
  //   },
  //   [setShopId]
  // );

  return (
    <>
      <section className="hero">
        <div className="hero-body">
          <Stack direction="horizontal" gap={3}>
            <h1 className="title">{title}</h1>
            <div className="ms-auto"></div>
          </Stack>
        </div>
      </section>
      <section>
        <div className="card">
          <div className="tab-wrapper">
            <Tabs
              activeKey={tabKey}
              onSelect={(_key) => {
                if (_key) setTabKey(_key);
              }}
            >
              <Tab eventKey="enquete" title="アンケート集計" mountOnEnter>
                <TabEnquete
                  clientId={clientId === "default" ? undefined : clientId}
                  shopId={shopId}
                  action="enquete"
                />
              </Tab>
              <Tab eventKey="coupon" title="クーポン集計" mountOnEnter>
                <TabCoupon
                  clientId={clientId === "default" ? undefined : clientId}
                  shopId={shopId}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  action="coupon"
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
}

function TabEnquete({
  clientId,
  shopId,
  action,
}: {
  clientId?: string;
  shopId: string | undefined;
  action: Action;
}) {
  const [unit, setUnit] = useState<TimeUnit>("week");
  const [showAll, setShowAll] = useState(!!clientId);
  const options = [
    { value: "month", label: "月次" },
    { value: "week", label: "週次" },
    { value: "day", label: "日次" },
  ] as { value: TimeUnit; label: string }[];

  const headerEnquete = useMemo(() => {
    return (
      <div
        className=""
        style={{
          position: "relative",
          display: "flex",
          gap: "10px",
          zIndex: "2",
        }}
      >
        {/* <div
          style={{
            width: "240px",
            zIndex: 1000,
            minWidth: "180px",
          }}
        >
          <SchemaInput
            data={shopId}
            schema={{
              schemaType: "externalKey",
              resourcePath: "/shop",
              uiType: "normal",
              titleKey: "shopName",
              filter: { clientId: clientId || variableClientId },
              staticOptions: [
                {
                  title: "全社",
                  value: null,
                },
              ],
            }}
            onValueChange={handleOnChangeShop}
          ></SchemaInput>
        </div> */}
        {/* CSSで上下中央揃えにする */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Select
            options={options}
            value={options.find((option) => option.value === unit)}
            onChange={(option) => {
              const _unit = option?.value;
              if (_unit) setUnit(_unit);
            }}
          />
          <span style={{ marginLeft: "10px", marginRight: "3px" }}>
            すべて表示
          </span>
          <Form.Check
            type={"switch"}
            defaultChecked={showAll}
            onChange={(e) => {
              setShowAll(e.target.checked);
            }}
          />
        </div>
      </div>
    );
  }, [shopId, clientId, unit, options]);

  const startDate = useMemo(() => {
    if (unit === "month") {
      // 月次集計は12ヶ月前から表示
      return dayjs()
        .subtract(12, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
    }
    if (unit === "week") {
      // 週次集計は10週間前から表示
      return dayjs().subtract(10, "week").startOf("week").format("YYYY-MM-DD");
    }
    if (unit === "day") {
      // 日次集計は当月の月初日から表示
      return dayjs().startOf("day").subtract(31, "day").format("YYYY-MM-DD");
    }
    return undefined;
  }, [unit]);

  const { value: dataFrame, error } = useAggregation({
    action,
    unit,
    reward: Boolean(action === "coupon" && clientId),
    clientId,
    startDate,
    total: true,
  });
  console.log("dataFrame enquete", dataFrame);

  const { list: clients, loading: clientLoading } = useClientList({});
  const { list: rewards, loading: rewardLoading } = useRewardList({
    clientId: clientId ?? "",
  });
  const { list: _shops, loading: shopLoading } = useShopList({
    clientId,
  });

  const shops = _shops?.filter((shop) => {
    return !shop.ignore;
  });

  const formatters = useMemo(() => {
    if (clientId && (shopLoading || rewardLoading)) return undefined;
    if (!clientId && clientLoading) return undefined;
    return {
      client: toFormatter(clients || [], "clientId", "clientName", [
        ["total", "システム合計"],
      ]),
      shop: toFormatter(shops || [], "shopId", "shopName", [
        ["total", "全社合計"],
      ]),
      reward: toFormatter(rewards || [], "rewardId", "rewardName"),
      date: (value: string) => {
        if (unit === "day") {
          return dayjs(value).format("MM/DD");
        } else if (unit === "week") {
          return dayjs(value).format("MM/DD") + "〜";
        } else if (unit === "month") {
          return dayjs(value).format("YYYY-MM");
        } else {
          return dayjs(value).format("YYYY-MM");
        }
      },
      used_ratio: (value: number) => `${(value * 100).toFixed(0)}%`,
      label: {
        clientId: "クライアント",
        shopId: "事業所",
        rewardId: "リワード",
        date: "年月",
        expired_by_create: "発行数（期限切れ)",
        used_by_create: "発行数（使用済み)",
        valid_by_create: "発行数（未使用）",
        created: "発行数",
        used: "利用数",
        used_ratio: "利用率",
      },
    } as Formatters;
  }, [clients, clientId, unit, shopLoading, rewardLoading, shops, rewards]);
  console.log("formatters enquete", formatters);

  useMemo(() => {
    if (!dataFrame || dataFrame.rowCount === 0 || (shops || []).length === 0)
      return undefined;
    // TODO: reorderIndexesで発生のエラーを暫定的に回避
    try {
      dataFrame.reorderIndexes({
        shopId: getOrderedIds(shops || [], "shopId", "shopName", [
          ["total", "全社合計"],
        ]),
        clientId: getOrderedIds(clients || [], "clientId", "clientName", [
          ["total", "システム合計"],
        ]),
        rewardId: getOrderedIds(rewards || [], "rewardId", "rewardName"),
      });
    } catch (error) {
      console.error("error", error);
    }
  }, [dataFrame, shops, clients, rewards]);

  return (
    <div
      style={{
        marginTop: "10px",
      }}
    >
      {headerEnquete}
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <TabEnqueteInternal
          dataFrame={dataFrame}
          formatters={formatters}
          showAll={showAll}
        />
      </div>
    </div>
  );
}

function TabEnqueteInternal({
  dataFrame,
  formatters,
  showAll,
}: {
  dataFrame: PDataFrame | false | undefined;
  formatters: Formatters | undefined;
  showAll: boolean;
}) {
  if (!dataFrame || !formatters) return <div>loading...</div>;

  if (dataFrame?.rowCount === 0) {
    return <div>データがありません</div>;
  }

  return (
    <PPivotTable
      dataFrame={dataFrame.pivot({
        columns: ["date"],
        value: "value",
      })}
      showAll={showAll}
      formatters={formatters}
      fix="both"
      scrollToEnd="column"
    />
  );
}

function TabCoupon({
  clientId,
  shopId,
  dateRange,
  setDateRange,
  action,
}: {
  clientId?: string;
  shopId: string | undefined;
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  action: Action;
}) {
  const headerCoupon = useMemo(() => {
    return (
      <div
        className=""
        style={{
          position: "relative",
          display: "flex",
          gap: "10px",
          zIndex: "2",
        }}
      >
        {/* <div
          style={{
            width: "240px",
            zIndex: 1000,
            minWidth: "180px",
          }}
        >
          <SchemaInput
            data={shopId}
            schema={{
              schemaType: "externalKey",
              resourcePath: "/shop",
              uiType: "normal",
              titleKey: "shopName",
              filter: { clientId: clientId || variableClientId },
              staticOptions: [
                {
                  title: "全社",
                  value: null,
                },
              ],
            }}
            onValueChange={setShopId}
          ></SchemaInput>
        </div> */}
        <SchemaInput
          data={dateRange}
          schema={{
            schemaType: "month",
          }}
          onValueChange={setDateRange}
        ></SchemaInput>
      </div>
    );
  }, [shopId, clientId, dateRange]);

  // dateRangeをstartDateに変換
  const startDate = useMemo(() => {
    return dayjs(dateRange.$gte).format("YYYY-MM-DD");
  }, [dateRange]);
  const endDate = useMemo(() => {
    return dayjs(dateRange.$lt).subtract(1, "day").format("YYYY-MM-DD");
  }, [dateRange]);
  console.log("startDate", startDate);
  console.log("endDate", endDate);

  const { value: dataFrame, error } = useAggregation({
    action,
    // unit,
    reward: Boolean(action === "coupon" && clientId),
    clientId,
    startDate,
    endDate,
    total: true,
  });
  console.log("dataFrame coupon", dataFrame);

  const { list: clients, loading: clientLoading } = useClientList({});
  const { list: rewards, loading: rewardLoading } = useRewardList({
    clientId: clientId ?? "",
  });
  const { list: shops, loading: shopLoading } = useShopList({
    clientId,
  });

  const formatters = useMemo(() => {
    if (clientId && (shopLoading || rewardLoading)) return undefined;
    if (!clientId && clientLoading) return undefined;
    return {
      client: toFormatter(clients || [], "clientId", "clientName", [
        ["total", "システム合計"],
      ]),
      shop: toFormatter(shops || [], "shopId", "shopName", [
        ["total", "全社合計"],
      ]),
      reward: toFormatter(rewards || [], "rewardId", "rewardName"),
      date: (value: string) => dayjs(value).format("YYYY-MM"),
      used_ratio: (value: number) => `${(value * 100).toFixed(0)}%`,
      label: {
        clientId: "クライアント",
        shopId: "事業所",
        rewardId: "リワード",
        date: "年月",
        expired_by_create: "発行数（期限切れ)",
        used_by_create: "発行数（使用済み)",
        valid_by_create: "発行数（未使用）",
        created: "発行数",
        used: "利用数",
        used_ratio: "利用率",
      },
    } as Formatters;
  }, [clients, clientId, shopLoading, rewardLoading, shops, rewards]);
  console.log("formatters coupon", formatters);

  useMemo(() => {
    if (!dataFrame || dataFrame.rowCount === 0 || (shops || []).length === 0)
      return undefined;
    // TODO: reorderIndexesで発生のエラーを暫定的に回避
    try {
      dataFrame.reorderIndexes({
        shopId: getOrderedIds(shops || [], "shopId", "shopName", [
          ["total", "全社合計"],
        ]),
        clientId: getOrderedIds(clients || [], "clientId", "clientName", [
          ["total", "システム合計"],
        ]),
        rewardId: getOrderedIds(rewards || [], "rewardId", "rewardName"),
      });
    } catch (error) {
      console.error("error", error);
    }
  }, [dataFrame, shops, clients, rewards]);

  return (
    <div
      style={{
        marginTop: "10px",
      }}
    >
      {headerCoupon}
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <TabCouponInternal dataFrame={dataFrame} formatters={formatters} />
      </div>
    </div>
  );
}

function TabCouponInternal({
  dataFrame,
  formatters,
}: {
  dataFrame: PDataFrame | false | undefined;
  formatters: Formatters | undefined;
}) {
  if (!dataFrame || !formatters) return <div>loading...</div>;

  if (dataFrame?.rowCount === 0) {
    return <div>データがありません</div>;
  }

  return <PTable dataFrame={dataFrame} formatters={formatters} fix="both" />;
}

export default Summaries;

import React, { useMemo } from "react";

import schema from "./schema";
import getSchema from "../item/schema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { useIndustryList } from "models/hook";
import { INDUSTRIES } from "../route";
import { resolveRoute } from "pages";
import { useAdminAuthentication } from "hooks/auth";
import { industryData } from "common/models/industry";
import { useConditionSelector } from "hooks/condition/conditionSelector";
import { ObjectSchema } from "schemaComponents";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const LIST_INDUSTRIES = INDUSTRIES.sub("", Industries);

function Industries() {
  useAdminPageAuthorization();
  const { user } = useAdminAuthentication();
  const { conditionSelector, condition, timeCondition } = useConditionSelector({
    schema: getSchema({ isEditing: false }) as ObjectSchema,
    hideTimeCondition: true,
  });
  const { list, loading } = useIndustryList({
    createdAt: timeCondition,
    condition,
  });
  const parameter: SchemaListPageParameter<industryData> = useMemo(
    () => ({
      title: "業種一覧",
      edit: {
        path: ({ industryId }) =>
          resolveRoute("UPDATE_INDUSTRY", { industryId }),
      },
      create: { path: () => resolveRoute("ADD_INDUSTRY") + "?edit=1" },
      download: {
        filename: `industries`,
      },
      schema,
    }),
    []
  );
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
      header={conditionSelector}
    ></SchemaListPage>
  );
}

export default Industries;

import { DocKeysOf, defineCollection } from "../comodel-firestore";
import { industryDefinition } from "./industry";
import { DateRange } from "./type";

export type reportData = {
  reportId: string;
  clientId?: string;
  createdAt: number;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueSub: any;
  isPublic?: boolean;
  dualColumns?: boolean;
  priority?: string;
  industryId?: string;
};

export const reportDefinition = defineCollection({
  collectionName: "report",
  idKey: "reportId",
  constraints: ["clientId"],
  queryDefs: {
    list: ({
      clientId,
      createdAt,
      condition,
    }: {
      clientId: string;
      createdAt?: DateRange;
      condition?: Record<string, unknown>;
    }) => {
      return {
        constraint: { clientId },
        queryParams: {
          filter: { clientId, createdAt },
          orderBy: [["createdAt", "desc"]],
        },
        filterParams: condition,
      };
    },
    listPublic: ({ clientId }: { clientId: string }) => {
      return clientId === undefined
        ? undefined
        : {
            constraint: { clientId },
            queryParams: {
              filter: { clientId },
              orderBy: [["createdAt", "desc"]],
            },
            filterParams: {
              isPublic: true,
            },
          };
    },
  },
  defaultValue: {} as reportData,
  onGenerate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});

export const industryReportDefinition = defineCollection({
  collectionName: "report",
  idKey: "reportId",
  constraints: ["industryId"],
  queryDefs: {
    list: ({
      industryId,
      createdAt,
      condition,
    }: {
      industryId: string;
      createdAt?: DateRange;
      condition?: Record<string, unknown>;
    }) => {
      return {
        constraint: { industryId },
        queryParams: {
          filter: { industryId, createdAt },
          orderBy: [["createdAt", "desc"]],
        },
        filterParams: condition,
      };
    },
    listPublic: ({ industryId }: { industryId?: string }) => {
      return industryId === undefined
        ? undefined
        : {
            constraint: { industryId },
            queryParams: {
              filter: { industryId, isPublic: true },
              orderBy: [["createdAt", "desc"]],
            },
          };
    },
  },
  defaultValue: {} as reportData,
  onGenerate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});

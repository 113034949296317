import React from "react";
import {
  BaseSchema,
  DisplayComponentProps,
  Value,
} from "react-hook-schema-form";

type JSXElementLike = string | number | JSX.Element;

export const DisplayWrapper = ({ content }: { content?: JSXElementLike }) => {
  if (typeof content === "object" && (!content?.type || !content.props)) {
    return <></>;
  }
  return <>{content}</>;
};
const defaultConverter = (value: Value) => value;

export const DisplayComponent = <S extends BaseSchema>(
  displayName: string,
  converter?: (value: Value, schema: S) => JSXElementLike
) => {
  const DisplayComponent: React.ComponentType<DisplayComponentProps<S>> = ({
    value,
    schema,
  }: DisplayComponentProps<S>) => {
    return (
      <DisplayWrapper
        content={(converter || defaultConverter)(value, schema)}
      />
    );
  };
  DisplayComponent.displayName = displayName;
  return DisplayComponent;
};

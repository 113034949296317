import ErrorMessage from "components/ErrorMessage";
import React from "react";
import { ControllerFieldState } from "react-hook-form";

import "./fieldWrapper.scss";

export const FieldWrapper = ({
  fieldState,
  children,
}: {
  fieldState?: ControllerFieldState;
  children: React.ReactNode;
}) => {
  return (
    <>
      <div className="field-body">
        <div className="field">{children}</div>
      </div>
      {fieldState?.error && (
        <div className="field-error-message">
          <ErrorMessage error={fieldState.error} />
        </div>
      )}
    </>
  );
};

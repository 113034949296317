import EventEmitter from "eventemitter3";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const emitter = new EventEmitter();

export const modalCancelEvent = {
  on: emitter.on.bind(emitter, "cancelModal"),
  off: emitter.off.bind(emitter, "cancelModal"),
  emit: emitter.emit.bind(emitter, "cancelModal"),
};

export const useModalCancel = () => {
  const location = useLocation();
  const pagePath = location.pathname;
  useEffect(() => {
    modalCancelEvent.emit();
  }, [pagePath]);
};

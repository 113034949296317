import React from "react";
import { useContextLocale } from "components/LocaleProvider";
import { AvailableLocale, Locale, messages } from "utils/locale";
import { DescreteSelector } from "schemaComponents/components/selector";
import { useSessionStorage } from "./storage";
import { Dropdown } from "react-bootstrap";

const LocaleSelector = ({
  locale,
  locales: _locales,
  onChange,
}: {
  locale?: Locale;
  locales?: Locale[];
  onChange: (locale: Locale) => void;
}) => {
  const locales = _locales || [];
  if (locales.length <= 1) {
    return <> </>;
  }
  return (
    <DescreteSelector
      value={locale as Locale}
      options={locales.map((locale) => ({
        value: locale,
        title: messages[locale as AvailableLocale]?.Language || locale,
      }))}
      onChange={(value) => {
        onChange(value as Locale);
      }}
    />
  );
};

export const ConsumerLocaleSelector = ({ locales }: { locales: Locale[] }) => {
  const [locale, setLocale] = useContextLocale();
  return (
    <LocaleSelector
      locale={locale}
      locales={locales}
      onChange={(locale) => {
        setLocale?.(locale);
      }}
    />
  );
};

export const AdminLocaleSelector = ({ locales }: { locales?: Locale[] }) => {
  const [locale, setLocale] = useEditingLocale();
  return (
    <div style={{ fontSize: "14px" }}>
      <LocaleSelector
        locale={locale}
        locales={locales}
        onChange={(locale) => {
          setLocale?.(locale);
        }}
      />
    </div>
  );
};

export const LocaleDropdown = ({
  locale: _locale,
  locales = [],
  onChange,
  children,
}: {
  locale?: Locale;
  locales?: Locale[];
  onChange: (locale: Locale) => void;
  children?: React.ReactNode;
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" as="div">
        {children}
      </Dropdown.Toggle>{" "}
      <Dropdown.Menu>
        {locales?.map((locale) => {
          return (
            <Dropdown.Item
              key={locale}
              onClick={() => {
                onChange(locale);
              }}
            >
              <span
                style={{
                  fontWeight: _locale === locale ? "bold" : "normal",
                }}
              >
                {messages[locale as AvailableLocale]?.Language}
              </span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const AdminDropdownLocaleSelector = ({
  locales = [],
}: {
  locales?: Locale[];
}) => {
  const [locale, setLocale] = useEditingLocale();
  return (
    <LocaleDropdown locale={locale} locales={locales} onChange={setLocale}>
      <span style={{ fontSize: "0.8em", cursor: "pointer", color: "#0d6efd" }}>
        <i className="mdi mdi-earth"></i>
        {locale}
      </span>
    </LocaleDropdown>
  );
};

export const ConsumerDropdownLocaleSelector = ({
  locales = [],
  children,
}: {
  locales?: Locale[];
  children?: React.ReactNode;
}) => {
  const [locale, setLocale] = useContextLocale();
  return (
    <LocaleDropdown
      locale={locale}
      locales={locales}
      onChange={setLocale || (() => {})}
    >
      {children}
    </LocaleDropdown>
  );
};

export const editingLocaleStorgeKey = "textIntl-editing-locale";

export const useEditingLocale = () => {
  return useSessionStorage(editingLocaleStorgeKey, "ja" as Locale);
};

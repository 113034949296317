import React from "react";
import { SchemaComponent, BaseSchema } from "react-hook-schema-form";
import { DisplayComponent } from "./common/displayWrapper";
import type { OptionDefinition } from "hooks/condition/schema";
import { StringIntlResolver } from "hooks/intl";

interface CustomConditionSchema extends BaseSchema {
  schemaType: "customCondition";
  conditions:
    | OptionDefinition[]
    | ((
        schema: CustomConditionSchema,
        s: StringIntlResolver,
        searchInput?: string
      ) => (OptionDefinition | undefined)[]);

  required?: boolean;
}

const CustomConditionSchemaComponent: SchemaComponent<CustomConditionSchema> = (
  props
) => {
  console.error(
    "このコンポーネントは検索専用です。このメッセージが表示されている場合は設定に問題がある可能性があるため、管理者に連絡してください"
  );
  return (
    <>
      このコンポーネントは検索専用です。このメッセージが表示されている場合は設定に問題がある可能性があるため、管理者に連絡してください
    </>
  );
};

CustomConditionSchemaComponent.display = DisplayComponent(
  "CustomConditionDisplayComponent"
);

export default CustomConditionSchemaComponent;

import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { CLIENT, CLIENTS } from "../route";
import { resolveRoute } from "pages";
import { useClientDoc } from "models/hook";
import { AdminLocaleSelector } from "hooks/localeSelector";
import { useAdminAuthentication } from "hooks/auth";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const UPDATE_CLIENT = CLIENT.sub("/edit", Client);
export const ADD_CLIENT = CLIENTS.sub("/edit/new", Client);

function Client({ clientId }: { clientId?: string }) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { data, loading, set, remove, isEditing, ref } = useClientDoc({
    clientId,
  });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "クライアント詳細" : "クライアント新規作成",
    schema: getSchema({ isEditing, clientId, isMaster }),
    edit: {
      title: `${isEditing ? "保存" : "保存"}`,
      confirm: `${isEditing ? "保存" : "保存"}しますか？`,
      handler: set,
      path: ({ clientId }) => resolveRoute("UPDATE_CLIENT", { clientId }),
      isDisabled: !isMaster,
    },
    clipboard: {
      objectType: "client",
      retainKeys: ["clientId"],
      isDisabled: !isMaster,
    },
    // remove: isEditing ? { handler: remove } : undefined,
  };
  return (
    <SchemaFormPage
      data={data}
      parameter={parameter}
      loading={isEditing && loading}
      header={<AdminLocaleSelector locales={data?.availableLocales} />}
      editModeEnabled={true}
    />
  );
}

export default Client;

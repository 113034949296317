import React, { useCallback, useEffect, useMemo, useState } from "react";
import Table, { ExtendedColumn } from "components/Table";
import { propertiesToColumns } from "./converter";
import { Link } from "react-router-dom";
import { Value } from "react-hook-schema-form";
import { ObjectSchema } from "schemaComponents";
import Button from "react-bootstrap/Button";

import Spinner from "react-bootstrap/Spinner";
import { Stack } from "react-bootstrap";
import { useModalForm, useToost, useUpload } from "hooks/dialogs";
import { arrayToXlsx } from "./csvUtil";
import * as XLSX from "xlsx";
import { parseCsv } from "./parseCsv";
import dayjs from "dayjs";
import { useAdminAuthentication } from "hooks/auth";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SchemaListPageParameter<Data = any> {
  title: string;
  edit: { path: (o: Data) => string };
  create?: {
    path: () => string;
    isDisabled?: boolean;
  };
  download?: {
    schema?: ObjectSchema | ((forceRealFields: boolean) => ObjectSchema);
    handler?: () => Promise<Value[] | undefined>;
    isDisabled?: boolean;
    filename?: string;
  };
  upload?: {
    schema?: ObjectSchema;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handler?: (values: any) => Promise<void | unknown> | undefined;
    isDisabled?: boolean;
  };
  schema: ObjectSchema;
  setRange?: (params: { min: number; max: number }) => void;
  tooltip?: string;
  clientId?: string;
}

const SchemaListPage = React.memo(function SchemaListPage({
  parameter,
  list,
  header,
  loading,
  hasMore,
  refresher,
  fetching,
  reload,
}: {
  parameter: SchemaListPageParameter;
  list?: Value[];
  header?: JSX.Element;
  loading?: boolean;
  fetching?: boolean;
  hasMore?: boolean;
  refresher?: unknown;
  reload?: () => void;
}) {
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { schema, title, edit, create, download, upload, setRange, tooltip } =
    parameter;
  const editPath = edit.path;
  const createPath = create?.path;
  const modalForm = useModalForm();
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [fileFormat, setFileFormat] = useState<"xlsx" | "csv">("xlsx");
  const toost = useToost();

  useEffect(() => {
    if (!downloadUrl) return;
    const currentDate = dayjs().format("YYYYMMDDHHmmss");
    const downloadFilename = download?.filename
      ? `${download?.filename}-${currentDate}.${fileFormat}`
      : `${downloadUrl.split("/").pop()}.${fileFormat}`;
    const _a = document.createElement("a");
    _a.href = downloadUrl;
    _a.download = downloadFilename;
    _a.click();
    setDownloadUrl(null);
    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
    }, 1000);
  }, [downloadUrl]);
  const downloadHandler = useCallback(
    async (e) => {
      e.preventDefault();
      const result = await modalForm({
        params: {
          title: "ダウンロード",
          subtitle:
            "ダウンロードボタンを押すと、ファイルがダウンロードされます。",
          edit: {
            title: "ダウンロード",
          },
          schema: {
            schemaType: "object",
            properties: [
              ...(isMaster
                ? [
                    {
                      title: "出力形式",
                      propertyName: "fileFormat",
                      schema: {
                        schemaType: "selector",
                        required: true,
                        options: [
                          {
                            title: "Excel",
                            value: "xlsx",
                          },
                          {
                            title: "CSV",
                            value: "csv",
                          },
                        ],
                      },
                    },
                    {
                      title: "データを変換して出力する",
                      propertyName: "isConvert",
                      schema: {
                        schemaType: "boolean",
                      },
                    },
                    {
                      title: "ヘッダーを1行のみ出力する",
                      propertyName: "isMinHeader",
                      schema: {
                        schemaType: "boolean",
                      },
                    },
                    {
                      title: "すべての項目をダウンロードする",
                      propertyName: "forceUseRealFields",
                      schema: {
                        schemaType: "boolean",
                      },
                    },
                  ]
                : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ([] as any[])),
            ],
          },
        },
        value: {},
      });
      console.log("downloadHandler", result);
      if (!result) return;

      const {
        fileFormat: _fileFormat,
        isConvert,
        isMinHeader: _isMinHeader,
        forceUseRealFields: _forceUseRealFields,
      } = result;

      const fileFormat = isMaster ? _fileFormat : "xlsx"; // クライアント権限の場合、ファイル形式はxlsx固定
      // クライアント権限の場合、変換オプションは固定で有効にする
      const isJoinMaster = isMaster ? isConvert : true; // 表示名を追加する
      const isExpandMultipleSelector = isMaster ? isConvert : true; // 複数選択を展開する
      const isConvertSelector = isMaster ? isConvert : true; // 単数選択を変換する
      const isConvertId = isMaster ? isConvert : true; // IDを変換する
      const isMinHeader = isMaster ? _isMinHeader : true;
      const forceUseRealFields = isMaster ? _forceUseRealFields : false;

      const _list =
        (download?.handler ? await download?.handler() : list) || [];
      const downloadSchema =
        typeof download?.schema === "function"
          ? download?.schema(forceUseRealFields)
          : download?.schema;
      const _schema = downloadSchema || schema;
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      console.log("downloadHandler", _list, _schema);

      // 中間ファイルを作成する
      let dataArray = [] as unknown[][];
      try {
        dataArray = await arrayToXlsx(
          _list || [],
          downloadSchema || schema,
          fileFormat === "csv",
          isJoinMaster,
          isExpandMultipleSelector,
          isConvertSelector,
          isConvertId,
          isMinHeader
        );
      } catch (error) {
        console.error("arrayToXlsx", error, { error });
      }

      // ファイル生成（excelとcsvで分岐）
      let blob = null;
      const workSheet = XLSX.utils.aoa_to_sheet(dataArray);
      if (fileFormat === "xlsx") {
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, workSheet, "Sheet1");
        const excelUnit8Array = XLSX.write(workbook, { type: "array" });
        blob = new Blob([excelUnit8Array], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      } else if (fileFormat === "csv") {
        const csv = XLSX.utils.sheet_to_csv(workSheet);
        blob = new Blob([bom, csv], { type: "text/csv" });
      }
      if (blob === null) {
        console.warn("blob is null");
        return;
      }
      setFileFormat(fileFormat);
      const url = URL.createObjectURL(blob);
      setDownloadUrl(url);
    },
    [list]
  );

  const _upload = useUpload({
    uploadSchema: upload?.schema,
  });
  const uploadHandler = useCallback(
    async (e) => {
      e.preventDefault();

      const result = await _upload({
        params: {
          title: "アップロード",
          edit: {
            title: "アップロード",
          },
          schema: {
            schemaType: "object",
            properties: [
              {
                title: "アップロード(CSV)",
                propertyName: "uploadCsv",
                schema: {
                  schemaType: "uploadCsv",
                  setFieldPath: "uploadPreview",
                  required: true,
                },
              },
              // {
              //   title: "アップロード(Preview)",
              //   propertyName: "uploadPreview",
              //   schema: {
              //     schemaType: "uploadPreview",
              //   },
              // },
            ],
          },
        },
        value: {},
        uploadSchema: upload?.schema,
        clientId: parameter.clientId,
      });
      if (!result) return;

      try {
        const parsed = parseCsv(result, upload?.schema);
        console.log("parsed", parsed);

        // パースに失敗した場合（ヘッダー無しデータなど）
        if (parsed.length === 0) {
          toost({
            message: "アップロードデータのフォーマットが不正です",
            duration: 10000,
          });
          return;
        }

        // for (const value of parsed) {
        //   await upload?.handler?.(value);
        // }
        await Promise.all(parsed.map((value) => upload?.handler?.(value)));
        toost({
          message: "アップロードが完了しました",
          duration: 10000,
        });
      } catch (e) {
        if (e instanceof Error && e.name === "ParseError") {
          toost({
            message: e.message,
            duration: 10000,
          });
          return;
        } else {
          toost({
            message: "データのフォーマットが不正です",
            duration: 10000,
          });
        }
        throw e;
      }

      reload?.();
    },
    [list]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "",
        id: "actions",
        accessor: "actions",
        Cell: ({ row }) => {
          return (
            <Link to={editPath(row.original)}>
              <Button size="sm" style={{ whiteSpace: "nowrap" }}>
                <i className="mdi mdi-eye-outline" />
              </Button>
            </Link>
          );
        },
        disableSortBy: true,
      } as ExtendedColumn<Value>,
      ...propertiesToColumns(schema.properties),
    ];
  }, [schema.properties, editPath]);

  // const performDownload = useDownload();
  return (
    <>
      <section className="hero">
        <div className="hero-body">
          <Stack direction="horizontal" gap={3}>
            <h1 className="title">{title}</h1>

            <div className="ms-auto">
              {!create?.isDisabled && createPath && (
                <Link to={createPath()}>
                  <Button
                    variant="outline-secondary"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="新規作成"
                  >
                    {" "}
                    <span className="mdi mdi-plus"></span>
                  </Button>
                </Link>
              )}{" "}
              {!loading && !download?.isDisabled && (
                <Button
                  onClick={downloadHandler}
                  variant="outline-secondary"
                  data-tooltip-id="tooltip"
                  data-tooltip-content="ダウンロード"
                >
                  <span className="mdi mdi-download"></span>
                </Button>
              )}
              {!loading && upload && !upload?.isDisabled && (
                <>
                  {" "}
                  <Button
                    onClick={uploadHandler}
                    variant="outline-secondary"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="アップロード"
                  >
                    <span className="mdi mdi-upload"></span>
                  </Button>
                </>
              )}
              {!loading && tooltip && (
                <>
                  {" "}
                  <Button
                    variant="secondary"
                    data-tooltip-id="tooltip-click"
                    data-tooltip-html={
                      tooltip && `<div class="tooltip-content">${tooltip}</div>`
                    }
                    style={{
                      borderRadius: "50%",
                      width: "26px",
                      height: "26px",
                      padding: "0",
                    }}
                    // size="sm"
                  >
                    <span className="mdi mdi-information-variant"></span>
                  </Button>
                </>
              )}
            </div>
          </Stack>
        </div>
      </section>
      <section>
        {loading || !list ? (
          <div style={{ padding: "20px" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="card">
            {header}
            <div className="b-table">
              <Table
                columns={columns}
                data={list}
                hasMore={hasMore}
                fetching={fetching}
                onSetRange={setRange}
                refresher={refresher}
              />
            </div>
          </div>
        )}
      </section>
    </>
  );
});
export default SchemaListPage;

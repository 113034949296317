import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { ADMIN_COUPON, ADMIN_COUPONS, COUPON, COUPONS } from "../route";
import { resolveRoute } from "pages";
import { useCouponDoc } from "models/hook";
import { PreviewComponent } from "./preview";
import { useAdminAuthentication } from "hooks/auth";
import { useAdminPageAuthorization } from "hooks/authAdminPage";
import NotFound from "pages/notFound";

export const UPDATE_COUPON = COUPON.sub("", Coupon);
export const ADD_COUPON = COUPONS.sub("/new", Coupon);

export const UPDATE_ADMIN_COUPON = ADMIN_COUPON.sub("", AdminCoupon);
export const ADD_ADMIN_COUPON = ADMIN_COUPONS.sub("/new", AdminCoupon);

function Coupon({
  couponId,
  clientId,
}: {
  couponId?: string;
  clientId: string;
}) {
  return (
    <CouponInternal
      couponId={couponId}
      clientId={clientId}
      backPath={resolveRoute("LIST_COUPONS", { clientId })}
      isMaster={false}
    />
  );
}

function AdminCoupon({
  couponId,
  clientId,
}: {
  couponId?: string;
  clientId: string;
}) {
  const { role } = useAdminAuthentication();
  if (role !== "master") {
    return <NotFound></NotFound>;
  }
  return (
    <CouponInternal
      couponId={couponId}
      clientId={clientId}
      backPath={resolveRoute("LIST_ADMIN_COUPONS", { clientId })}
      isMaster={true}
    />
  );
}

function CouponInternal({
  couponId,
  clientId,
  backPath,
  isMaster,
}: {
  couponId?: string;
  clientId: string;
  backPath?: string;
  isMaster: boolean;
}) {
  useAdminPageAuthorization(clientId);
  const { data, loading, set, isEditing } = useCouponDoc({
    couponId,
    clientId,
  });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "クーポン詳細" : "クーポン新規作成",
    schema: getSchema({ isEditing, clientId, isMaster }),
    edit: {
      title: `${isEditing ? "保存" : "発行"}`,
      confirm: `${isEditing ? "保存" : "発行"}しますか？`,
      handler: set,
      isDisabled: !isMaster,
    },
    preview: {
      title: "クーポンプレビュー",
      component: PreviewComponent,
      isDisabled: !isMaster,
    },
    backPath,
    header: {
      isStacked: true,
    },
  };
  return (
    <SchemaFormPage
      data={data}
      parameter={parameter}
      loading={isEditing && loading}
      editModeEnabled={true}
    />
  );
}

export default Coupon;

import React, { useEffect } from "react";

import { useForm } from "react-hook-form";
import { Field, Schema } from "schemaComponents";
import { Value } from "react-hook-schema-form";

export const DynamicField = ({
  data,
  schema,
}: {
  data: Value;
  schema: Schema;
}) => {
  const { control, setValue, getValues } = useForm({
    defaultValues: data,
  });
  useEffect(() => {
    const currentValue = getValues();
    for (const key of currentValue ? Object.keys(currentValue) : []) {
      if (!(key in data)) {
        setValue(key, undefined);
      }
    }
    for (const key of data ? Object.keys(data) : []) {
      if (currentValue[key] !== data[key]) {
        setValue(key, data[key]);
      }
    }
  }, [data]);
  return <Field control={control} schema={schema} />;
};

import { Schema } from "schemaComponents";

const getSchema = (isEditing: boolean): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "ID",
        propertyName: "mailTemplateId",
        schema: {
          schemaType: "text",
          readOnly: true,
        },
      },
      {
        title: "Key",
        propertyName: "mailTemplateKey",
        schema: {
          schemaType: "text",
          readOnly: true,
        },
      },
      {
        title: "件名",
        propertyName: "subject",
        schema: {
          schemaType: "textIntl",
          required: true,
        },
      },
      {
        title: "本文",
        propertyName: "text",
        schema: {
          schemaType: "multilineTextIntl",
          required: true,
        },
      },
    ],
  };
};

export default getSchema;

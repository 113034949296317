import { useMemo } from "react";
import { useClipboard } from "./clipboard";
import { useToost } from "./dialogs";

const defaultIgnoreKeys = ["createdAt", "updatedAt", "id"];
const dataType = process.env.REACT_APP_FIRE_BASE_PROJECT_ID as string;

export const useCopyPaste = <T>({
  objectType,
  ignoreKeys = [],
}: {
  objectType?: string;
  ignoreKeys?: string[];
}) => {
  const toost = useToost();
  const { content, setContent, getContent, clipboardPermission } =
    useClipboard<{ objectType: string; dataType: string; data: T }>();

  const paste = async () => {
    const content = await getContent();
    console.log({ content });
    if (!objectType || !dataType) return;
    if (content?.dataType !== dataType) {
      toost({
        message: "この形式のデータは貼り付けられません。不明なデータ形式です。",
        duration: 3000,
      });
      return;
    }

    if (content?.objectType !== objectType) {
      toost({
        message: `この形式のデータは貼り付けられません。${content?.objectType}形式のデータが貼り付けられました。`,
        duration: 3000,
      });
      return;
    }

    const data = { ...content.data };
    [...defaultIgnoreKeys, ...ignoreKeys].forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (data as any)[key];
    });
    toost({
      message: "貼り付けました",
      duration: 3000,
    });
    return data;
  };
  const copy = (data: T) => {
    if (!objectType) return;
    setContent({
      objectType,
      data,
      dataType,
    });
    toost({
      message: "コピーしました",
      duration: 3000,
    });
  };

  const canPaste = useMemo(() => {
    try {
      if (!clipboardPermission || clipboardPermission === "prompt") return true;
      return (
        objectType &&
        content?.objectType === objectType &&
        content?.dataType === dataType
      );
    } catch (e) {
      return false;
    }
  }, [content, clipboardPermission]);

  return {
    copy,
    paste,
    canPaste,
  };
};

import { DateRange } from "./type";
import { defineCollection } from "../comodel-firestore";

export type surveyData = {
  surveyId: string;
  createdAt: number;
  surveyName: string;
  clientId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enquete: any;
  invalidationDays: number;
  rewordCondition?: {
    rewardId: string;
    probability: number;
    animationId: number;
  }[];
};
export const surveyDefinition = defineCollection({
  collectionName: "survey",
  idKey: "surveyId",
  constraints: ["clientId"],
  queryDefs: {
    list: ({
      clientId,
      createdAt,
      condition,
    }: {
      clientId: string;
      createdAt?: DateRange;
      condition?: Record<string, unknown>;
    }) => {
      return {
        constraint: { clientId },
        queryParams: {
          filter: { clientId, createdAt },
          orderBy: [["createdAt", "desc"]],
        },
        filterParams: condition,
      };
    },
  },
  defaultValue: {} as surveyData,
  onGenerate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});

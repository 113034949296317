import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import { createPortal } from "react-dom";
import { useModalForm, useToost } from "hooks/dialogs";
import DragDrop from "editorjs-drag-drop";
import "./editor.scss";
import { TableTool } from "./plugins/table";
import { ObjectSchema } from "schemaComponents";
import { ScoreTool } from "./plugins/score";
import { useCopyPaste } from "hooks/copyPaste";
import { ClopboardTool } from "./plugins/clipboard";

// TODO データが空のときは readOnly: falseにすればdefaultDataが不要なのでは？

const defaultData = {
  blocks: [{ type: "header", data: { text: "タイトル" } }],
};

export const Editor = React.forwardRef<
  EditorJS | undefined,
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
    readOnly?: boolean;
    questionRef?: React.MutableRefObject<ObjectSchema | undefined>;
    // children?: ReactNode;
  }
>(function Editor({ value, readOnly, questionRef }, forwardedRef) {
  const [portals, setPortals] = useState<Record<string, React.ReactElement>>(
    {}
  );

  const editorRef = useRef<EditorJS>();
  const toost = useToost();
  const modalForm = useModalForm();
  const setPortal = useCallback((id: string, dom: React.ReactElement) => {
    setPortals((value) => ({ ...value, [id]: dom }));
  }, []);
  const { copy, paste } = useCopyPaste({ objectType: "report_block" });
  const holderRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentData = useRef<any>();
  useEffect(() => {
    if (holderRef.current) {
      currentData.current = value?.blocks?.length ? value : defaultData;
      const editor = new EditorJS({
        // initialBlock: "text",
        data: currentData.current,
        readOnly,
        holder: holderRef.current,
        onReady: () => {
          console.log("onReady");
          // eslint-disable-next-line no-new
          new DragDrop(editor);
        },
        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          table: {
            class: TableTool,
            config: { setPortal, modalForm, toost, questionRef },
          },
          score: {
            class: ScoreTool,
            config: { setPortal, modalForm, toost, questionRef },
          },
          clipboard: {
            class: ClopboardTool,
            config: { copy, paste },
          },
        },
        tunes: ["clipboard"],
      });
      editorRef.current = editor;
      console.log("setEditoRef", editorRef);
      if (forwardedRef) {
        if (typeof forwardedRef === "function") {
          forwardedRef(editor);
        } else {
          forwardedRef.current = editor;
        }
      }
      return () => editor?.destroy?.();
    }
  }, [readOnly]);
  // useEffect(() => {
  //   editorRef.current?.readOnly?.toggle(!!readOnly);
  // }, [readOnly]);
  useEffect(() => {
    (async () => {
      const newData = value?.blocks?.length ? value : defaultData;
      await editorRef.current?.isReady;
      if (newData !== currentData.current) {
        currentData.current = newData;
        editorRef.current?.render(newData);
      }
    })();
  }, [value]);
  // React.useEffect(() => {
  //   (async () => {
  //     await editorRef.current?.isReady;
  //     console.log({ readOnly });
  //     editorRef.current?.readOnly?.toggle(!!readOnly);
  //     // if (!readOnly) {
  //     //   setTimeout(() => {
  //     //     // eslint-disable-next-line no-new
  //     //     new DragDrop(editorRef.current);
  //     //   }, 0);
  //     // }
  //   })();
  // }, [readOnly]);
  return (
    <>
      <div ref={holderRef} className="editorJsWrapper"></div>
      {Object.entries(portals)
        .map(([id, dom]) => {
          const element = document.getElementById(id);
          return element && createPortal(dom, element);
        })
        .filter((e) => e)}
    </>
  );
});

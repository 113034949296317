import React from "react";
import {
  SchemaComponentInternalProps,
  SchemaComponent,
  joinFieldPath,
  BaseSchema,
} from "react-hook-schema-form";

import { Schema, Field } from "..";
import { useFormatter } from "hooks/intl";

interface ElementParameter {
  title: string;
  schema: Schema;
  noLabel?: boolean;
}

interface TupleSchema extends BaseSchema {
  schemaType: "tuple";
  elements: ElementParameter[];
}

const Property = ({
  fieldPath,
  readOnly,
  control,
  property,
}: SchemaComponentInternalProps<BaseSchema> & {
  property: ElementParameter;
}) => {
  const { title, schema, noLabel } = property;
  const { formatMessage } = useFormatter();
  const content = (
    <Field
      control={control}
      fieldPath={fieldPath}
      readOnly={readOnly}
      schema={schema}
      label={title}
    />
  );
  if (noLabel) {
    return content;
  } else
    return (
      <div className="field-pair" key={0}>
        <div className="field-label">
          <label className="label">
            <span
              style={{
                verticalAlign: "middle",
              }}
            >
              {title}
            </span>
            {schema.required && !readOnly && !schema.readOnly && (
              <span
                style={{
                  verticalAlign: "middle",
                  marginLeft: "0.2em",
                  border: "1px solid #999",
                  padding: "0em 0.3em",
                  color: "#999",
                  fontSize: "0.8em",
                  borderRadius: "3px",
                  whiteSpace: "nowrap",
                }}
              >
                {formatMessage("Field.Required")}
              </span>
            )}
          </label>
        </div>
        <div className="field-content">{content}</div>
      </div>
    );
};

const TupleSchemaComponent: SchemaComponent<TupleSchema> = ({
  fieldPath,
  readOnly,
  control,
  schema,
}: SchemaComponentInternalProps<TupleSchema>) => {
  const properties = schema.elements;
  return (
    <div className="wide-form">
      {properties?.map((property, index) => (
        <Property
          schema={property.schema}
          control={control}
          readOnly={readOnly}
          fieldPath={joinFieldPath(fieldPath, String(index))}
          key={index}
          property={property}
        />
      ))}
    </div>
  );
};

export default TupleSchemaComponent;

import { FieldCondition } from "hooks/condition/conditionSelector";
import { createContext } from "react";
import { ObjectSchema } from "schemaComponents";

export type ReportContextType = {
  shopId?: string;
  month?: string;
  clientId?: string;
  industryId?: string;
  questionRef?: React.MutableRefObject<ObjectSchema | undefined>;
  questions?: ObjectSchema;
  condition?: Record<string, FieldCondition>;
  dualColumns?: boolean;
  statParams?: {
    clientId?: string;
    industryId?: string;
    dataId?: string;
  };
};

export const ReportContext = createContext<ReportContextType>({});

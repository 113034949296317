import React, { useMemo } from "react";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { useClientDoc, useEnqueteList } from "models/hook";
import { ENQUETES } from "../route";
import { resolveRoute } from "pages";
import { enqueteData } from "common/models/enquete";
import { useConditionSelector } from "hooks/condition/conditionSelector";
import { ObjectSchema, PropertyType } from "schemaComponents";
import { objectFromSearchParams, objectToSearchParams } from "utils/url";
import { Loading } from "components/Loading";
import { Navigate } from "react-router-dom";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const LIST_COMMENTS = ENQUETES.sub("/comment", Comments);

function Comments({ clientId }: { clientId: string }) {
  useAdminPageAuthorization(clientId);
  /*
{
    "c": {
        "data.00030": {
            "$nin": [
                null,
                ""
            ]
        }
    },
    "p": [
        "data.00030",
        "data.00002",
        "data.00003"
    ]
} */
  const { data: client, loading: clientLoading } = useClientDoc({ clientId });
  const params = useMemo(() => {
    if (clientLoading) {
      return null;
    }
    const tagMap = {} as { [key: string]: string[] };
    for (const property of (client?.questions?.properties ||
      []) as PropertyType[]) {
      for (const tag of property.tags || []) {
        tagMap[tag] = tagMap[tag] || [];
        tagMap[tag].push("data." + property.propertyName);
      }
    }
    return objectToSearchParams({
      c: Object.fromEntries(
        tagMap.comment?.map((propertyName) => [
          propertyName,
          {
            $nin: [null, ""],
          },
        ]) || []
      ),
      p: tagMap.display,
    });
  }, [client]);

  console.log("###", client, params?.toString());

  if (clientLoading) {
    return <Loading></Loading>;
  }

  return (
    <Navigate
      replace
      to={
        resolveRoute("LIST_ENQUETES", { clientId }) + "?" + params?.toString()
      }
    ></Navigate>
  );
}
export default Comments;

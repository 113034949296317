import React, { useMemo } from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  BaseSchema,
} from "react-hook-schema-form";

import "./selector.scss";
import { DisplayComponent } from "./common/displayWrapper";

import { toMap, useDocResource, useQueryResource } from "utils/legacyFirestore";
import type { QueryParams } from "common/comodel-firestore";

import SelectorSchemaComponent from "./selector";
import { useStringIntl } from "hooks/intl";
import { StringIntl } from "utils/locale";
import Link from "components/Navigation/Link";

interface ExternalKeySchema extends BaseSchema {
  schemaType: "externalKey";
  uiType?: "normal" | "vertical" | "horizontal";
  resourcePath: string;
  titleKey: string;
  order?: "asc" | "desc";
  query?: QueryParams;
  filter?: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href?: (value: any, data: Record<string, string>) => string;
  staticOptions?: {
    title: StringIntl;
    value: string | number | null;
  }[];
}

const ExternalKeyComponent: SchemaComponent<ExternalKeySchema> = (
  props: SchemaComponentInternalProps<ExternalKeySchema>
) => {
  const { schema: originalSchema } = props;
  const { staticOptions, order, ...originalSchemaExceptStaticOptions } =
    originalSchema;
  const { list: options } = useQueryResource(originalSchema);
  // optionsのtitleでソートする
  const sortedOptions = useMemo(() => {
    if (!options || !order) {
      return options;
    }
    const sortedOptions = [...options];
    sortedOptions.sort((a, b) => {
      if (a.title < b.title) {
        return order === "asc" ? -1 : 1;
      }
      if (a.title > b.title) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    return sortedOptions;
  }, [options]);
  const mergedOptions = useMemo(() => {
    if (!staticOptions) {
      return sortedOptions;
    }
    return [...staticOptions, ...(sortedOptions || [])];
  }, [sortedOptions, staticOptions]);
  const schema = useMemo(() => {
    return {
      ...originalSchemaExceptStaticOptions,
      schemaType: "selector" as const,
      options: mergedOptions || [],
    };
  }, [originalSchemaExceptStaticOptions, mergedOptions]);
  return (
    <>
      <SelectorSchemaComponent
        {...props}
        schema={schema}
      ></SelectorSchemaComponent>
    </>
  );
};

ExternalKeyComponent.display = DisplayComponent(
  "ExternalKeyComponent",
  (value, schema) => {
    const s = useStringIntl();
    const { resourcePath, titleKey } = schema;
    const { data } = useDocResource(resourcePath, value);
    const { list: options } = useQueryResource(schema);
    const optionMap = options && toMap(options);
    if (!value) {
      return "未設定";
    }
    if (schema.href) {
      return value && data ? (
        <Link className="" to={schema.href(value, data)}>
          {s(data?.[titleKey])}
        </Link>
      ) : (
        s(optionMap?.get(value)) || ""
      );
    } else {
      return s(optionMap?.get(value)) || "";
    }
  }
);

export default ExternalKeyComponent;

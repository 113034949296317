import { ObjectSchema } from "schemaComponents";

const getSchema = ({
  clientId,
  isMaster,
}: {
  clientId: string;
  isMaster: boolean;
}): ObjectSchema => ({
  schemaType: "object",
  properties: [
    {
      title: "クーポンID",
      propertyName: "couponId",
      schema: { schemaType: "text" },
    },
    ...(isMaster
      ? [
          {
            title: "email",
            propertyName: "email",
            schema: { schemaType: "text" },
          },
        ]
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ([] as any[])),
    {
      title: "状態",
      propertyName: "status",
      schema: {
        schemaType: "selector",
        options: [
          {
            title: "未使用",
            value: "valid",
          },
          {
            title: "使用済み",
            value: "used",
          },
          {
            title: "無効",
            value: "invalid",
          },
        ],
      },
    },
    {
      title: "事業所名",
      propertyName: "shopId",
      schema: {
        schemaType: "externalKey",
        resourcePath: "/shop",
        titleKey: "shopName",
        readOnly: true,
        query: { filter: { clientId } },
      },
    },
    {
      title: "リワード名",
      propertyName: "rewardId",
      schema: {
        schemaType: "externalKey",
        resourcePath: "/reward",
        titleKey: "rewardName",
        readOnly: true,
        query: { filter: { clientId } },
      },
    },
    {
      title: "発行日時",
      propertyName: "createdAt",
      schema: { schemaType: "datetime" },
    },
    {
      title: "有効期限",
      propertyName: "expiredAt",
      schema: { schemaType: "datetime" },
    },
    {
      title: "利用日時",
      propertyName: "updatedAt",
      schema: { schemaType: "datetime" },
    },
  ],
});

export const getClientConditionSchema = ({
  clientId,
}: {
  clientId: string;
}): ObjectSchema => ({
  schemaType: "object",
  properties: [
    {
      title: "事業所名",
      propertyName: "shopId",
      schema: {
        schemaType: "externalKey",
        resourcePath: "/shop",
        titleKey: "shopName",
        readOnly: true,
        query: { filter: { clientId } },
        filter: {
          ignore: { $truthy: false },
        },
      },
    },
  ],
});

export default getSchema;

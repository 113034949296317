import React from "react";
import { formatNumber, TableSchema, withSign } from "../statSchemaUtil";
import { StatConfig } from "./type";

export const count: StatConfig = {
  action: "count",
  columns: ["count"],
  statSchemas: [
    {
      value: "count-shop",
      title: "事業所",
      graph: true,
    },
    {
      value: "count-shop",
      id: "count-shop-mom",
      title: "前月比",
      compareMonth: (value: number | null, prev: number | null | undefined) => {
        if (typeof value === "number" && typeof prev === "number") {
          const content = withSign(formatNumber(value - prev, 2));
          const isNegative = content?.[0] === "-";
          return (
            <span style={isNegative ? { color: "red" } : undefined}>
              {content}
            </span>
          );
        } else {
          return undefined;
        }
      },
    },
    {
      value: "count-client",
      title: "全社",
      graph: true,
    },
    {
      value: "count-all",
      title: "全体",
      graph: true,
    },
    {
      value: "count-rank-shop-in-client",
      title: "社内順位",
      multiple: {
        source: [
          "count-rank-shop-in-client",
          "count-groupcount-shop-in-client",
        ],
        element: (values) => {
          if (
            values["count-rank-shop-in-client"] == null ||
            values["count-groupcount-shop-in-client"] == null
          ) {
            return "";
          }
          return `${
            values["count-groupcount-shop-in-client"] -
            values["count-rank-shop-in-client"] +
            1
          } / ${values["count-groupcount-shop-in-client"]}`;
        },
      },
    },
    {
      value: "count-grouptscore-shop-in-client",
      title: "社内偏差値",
    },
  ],
};

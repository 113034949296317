import { useContextLocale } from "components/LocaleProvider";
import { useStringIntl } from "hooks/intl";
import { useMemo } from "react";
import { StringIntl } from "utils/locale";

export const useOptionIntl = (
  options: {
    title: StringIntl;
    value: string | number | null;
  }[]
) => {
  const s = useStringIntl();
  const [locale] = useContextLocale();
  const convertedOptions = useMemo(
    () =>
      options.map(({ title, value }) => ({
        value,
        title: s(title),
      })),
    [options, locale]
  );
  return convertedOptions;
};

import { defineCollection } from "../comodel-firestore";

export type mailTemplateData = {
  clientId: string;
  mailTemplateKey: string;
  mailTemplateId: string;
  subject: string;
  text: string;
};

export const mailTemplateDefinition = defineCollection({
  collectionName: "mailTemplate",
  idKey: "mailTemplateId",
  constraints: ["clientId", "mailTemplateKey"],
  queryDefs: {
    list: ({ clientId }: { clientId: string }) => {
      return {
        constraint: { clientId },
        queryParams: { filter: { clientId } },
      };
    },
  },
  defaultValue: {} as mailTemplateData,
  // Emulate virtual idKey (write)
  onAccess: (o) => ({
    ...o,
    mailTemplateId: `${o.clientId}:${o.mailTemplateKey}`,
  }),
  // Emulate virtual idKey (read)
  onRead: (o) => ({ ...o, mailTemplateKey: o.mailTemplateId.split(":")[1] }),
  // Atomic existence check to write createdAt
  onCreate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});

import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  FirestoreDataConverter,
  getDocs,
  getFirestore,
  query,
  runTransaction,
  setDoc,
  WithFieldValue,
} from "firebase/firestore";
import firebaseApp from "firebaseApp";
import {
  CollectionDefinitionAny,
  ConstraintKeyOf,
  DataOf,
  IdKeyOf,
  PartialWithFieldValue,
  QueryParams,
  SetDataOf,
} from "../common/comodel-firestore";
import { getQueryConstraints } from "./queryUtil";

type Display<T> = {
  [K in keyof T]: T[K];
};

type PartialSpecificFields<T, K extends keyof T> = Display<
  Omit<T, K> & Partial<Pick<T, K>>
>;

const firestore = getFirestore(firebaseApp);

const getDocRef = <C extends CollectionDefinitionAny>(
  collectionDef: C,
  data: WithFieldValue<DataOf<C>>
) => {
  const path = collectionDef.docPath(data);
  return path
    ? doc(firestore, path).withConverter(getConverter(collectionDef))
    : undefined;
};

const set = async <C extends CollectionDefinitionAny>(
  collectionDef: C,
  constraint: { [K in ConstraintKeyOf<C>]?: string },
  data: PartialWithFieldValue<DataOf<C>>,
  {
    merge,
    onUpdate,
  }: {
    merge?: boolean;
    onUpdate?: (newData: DataOf<C>, oldData?: DataOf<C>) => DataOf<C>;
  } = {}
) => {
  let _data = collectionDef.mergeConstraint(data, constraint);
  _data = collectionDef.onAccess(_data);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(_data as any)[collectionDef.idKey]) {
    _data = {
      ..._data,
      [collectionDef.idKey]: collectionDef.onGenerateIdKey(_data),
    };
    _data = collectionDef.onGenerate(_data);
  }
  _data = collectionDef.onWrite(_data);
  const ref = getDocRef(collectionDef, _data);
  if (!ref) {
    console.error("unsatisfied constraint", _data);
    throw new Error("unsatisfied constraint");
  }
  if (collectionDef.onUpdate || collectionDef.onCreate) {
    await runTransaction(firestore, async (transaction) => {
      const snapshot = await transaction.get(ref);
      if (snapshot.exists()) {
        if (collectionDef.onUpdate) {
          _data = collectionDef.onUpdate(_data, snapshot.data());
        }
      } else {
        if (collectionDef.onCreate) {
          _data = collectionDef.onCreate(_data);
        }
      }
      if (onUpdate) {
        _data = onUpdate(_data, snapshot.data());
      }
      if (merge) {
        transaction.set(ref, _data, { merge: true });
      } else {
        transaction.set(ref, _data);
      }
    });
  } else {
    if (merge) {
      await setDoc(ref, _data, { merge: true });
    } else {
      await setDoc(ref, _data);
    }
  }
  return { [collectionDef.idKey]: _data[collectionDef.idKey] } as {
    [K in IdKeyOf<C>]: string;
  };
};

export const documentAccessor = <C extends CollectionDefinitionAny>(
  collectionDef: C,
  constraint: { [K in ConstraintKeyOf<C>]?: string }
) => {
  const _constraint = collectionDef.onAccess(constraint) as DataOf<C>;
  const ref = getDocRef(collectionDef, _constraint);
  const defaultValue = {
    ..._constraint,
    ...collectionDef.defaultValue,
  } as DataOf<C>;
  return {
    defaultValue,
    ref,
    set: (
      data: WithFieldValue<SetDataOf<C>>,
      onUpdate?: (newData: DataOf<C>, oldData?: DataOf<C>) => DataOf<C>
    ) => {
      return set(
        collectionDef,
        _constraint,
        data as WithFieldValue<DataOf<C>>,
        { onUpdate }
      );
    },
    upsert: (
      data: PartialWithFieldValue<DataOf<C>>,
      onUpdate?: (newData: DataOf<C>, oldData?: DataOf<C>) => DataOf<C>
    ) => {
      return set(collectionDef, _constraint, data, { merge: true, onUpdate });
    },
    remove: () => {
      if (ref) return deleteDoc(ref);
    },
  };
};

export const queryAccessor = <C extends CollectionDefinitionAny>(
  collectionDef: C,
  constraint: { [K in ConstraintKeyOf<C>]?: string },
  queryParams: QueryParams,
  group?: boolean
) => {
  const ref = (() => {
    const collectionRef = (() => {
      if (group) {
        return collectionGroup(firestore, collectionDef.collectionName);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const path = collectionDef.collectionPath(constraint as any);
      if (path) {
        return collection(firestore, path);
      }
    })();
    return (
      collectionRef &&
      query(
        collectionRef,
        ...getQueryConstraints({ ...queryParams, idKey: collectionDef.idKey })
      ).withConverter(getConverter(collectionDef))
    );
  })();
  return {
    ref,
    set: (
      data: PartialWithFieldValue<DataOf<C>>,
      onUpdate?: (newData: DataOf<C>, oldData?: DataOf<C>) => DataOf<C>
    ) => {
      return set(collectionDef, constraint, data, { onUpdate });
    },
    upsert: (
      data: PartialWithFieldValue<DataOf<C>>,
      onUpdate?: (newData: DataOf<C>, oldData?: DataOf<C>) => DataOf<C>
    ) => {
      return set(collectionDef, constraint, data, { merge: true, onUpdate });
    },
    get: async () => {
      if (!ref) {
        return;
      }
      const snapshot = await getDocs(ref);
      return snapshot.docs.map((item) => item.data());
    },
  };
};

export const getConverter = <C extends CollectionDefinitionAny>(
  collectionDef: C
) => {
  type Data = DataOf<C>;
  const converter: FirestoreDataConverter<Data> = {
    toFirestore: (original) => {
      return collectionDef.toFirestore(original);
    },
    fromFirestore: (snapshot) => {
      return collectionDef.fromFirestore(
        snapshot.data(),
        snapshot.ref.path
      ) as Data;
    },
  };
  return converter;
};

/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useMemo } from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import Select from "react-select";
import { FieldWrapper } from "./common/fieldWrapper";

import "./selector.scss";
import { DisplayComponent } from "./common/displayWrapper";
import { StringIntl } from "utils/locale";
import { useStringIntl } from "hooks/intl";
import { useOptionIntl } from "./common/selectorOption";
import { Slider } from "./common/slider";

interface SelectorSchema extends BaseSchema {
  schemaType: "selector";
  uiType?: "vertical" | "horizontal" | "normal" | "slider";
  options: {
    title: StringIntl;
    value: string | number | null;
  }[];
  naValues?: (string | number | null)[];
  ignoreValues?: (string | number | null)[];
}

const NormalSelector = ({
  options,
  onChange,
  value,
}: {
  options: {
    title: string;
    value: string | number | null;
  }[];
  onChange: (value: string | number | null | undefined) => void;
  value?: string;
}) => {
  const convertedOptions = useMemo(
    () =>
      options.map(({ title: label, value }) => ({
        value,
        label,
      })),
    [options]
  );
  return (
    <>
      <Select
        options={convertedOptions}
        value={
          convertedOptions.find((option) => option.value == value) || {
            value: undefined,
          }
        }
        onChange={(option) => onChange(option?.value)}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
      />
    </>
  );
};

export const DescreteSelector = ({
  options,
  onChange,
  value,
  uiType,
  hasError,
}: {
  options: {
    title: string;
    value: string | number | null;
  }[];
  onChange: (value: string | number | null) => void;
  value?: string;
  uiType?: string;
  hasError?: boolean;
}) => {
  return (
    <div
      style={{ margin: "5px 0" }}
      className={classNames(
        {
          "has-error": hasError,
        },
        uiType && `selector-${uiType}`
      )}
    >
      {options.map(({ title, value: optionValue }, index) => (
        <span
          key={index}
          className={classNames(
            "selectorOption",
            // eslint-disable-next-line eqeqeq
            optionValue == value && "active"
          )}
          onClick={(e) => {
            onChange(optionValue !== value ? optionValue : null);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (document.activeElement as any)?.blur?.();
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          {title}
        </span>
      ))}
    </div>
  );
};

const SelectorSchemaComponent: SchemaComponent<SelectorSchema> = (
  props: SchemaComponentInternalProps<SelectorSchema>
) => {
  const { schema } = props;
  const { options, uiType, naValues, ignoreValues } = schema;
  const naTitles = useMemo(() => {
    return naValues?.map((naValue) => {
      return options.find((option) => option.value == naValue)?.title;
    }) as StringIntl[];
  }, [naValues, options]);
  const ignoreTitles = useMemo(() => {
    return ignoreValues?.map((ignoreValue) => {
      return options.find((option) => option.value == ignoreValue)?.title;
    }) as StringIntl[];
  }, [ignoreValues, options]);
  const convertedOptions = useOptionIntl(options);
  const convertedOptionsValidValues = useMemo(() => {
    return convertedOptions.filter((option) => {
      return (
        !naValues?.find((naValue) => naValue == option.value) &&
        !ignoreValues?.find((ignoreValue) => ignoreValue == option.value)
      );
    });
  }, [convertedOptions, naValues, ignoreValues]);
  const {
    registerProps: { onChange },
    value,
    fieldState,
  } = useField(props, [requiredValidator]);
  const sliderValue = useMemo(() => {
    const index = options.findIndex((option) => option.value == value);
    return index === -1 ? undefined : index;
  }, [options, value]);
  const sliderOnChange = useCallback(
    (index?: number) => {
      const option = index != null ? options[index] : undefined;
      onChange(option?.value);
    },
    [onChange, options]
  );
  const s = useStringIntl();

  const NotApplicableComponent = useMemo(() => {
    return naValues || ignoreValues ? (
      <div className="number-selector-except">
        {ignoreValues?.map((ignoreValue, index) => {
          const ignoreTitle = options.find(
            (option) => option.value == ignoreValue
          )?.title;
          return (
            <span
              key={ignoreValue}
              className={classNames(
                "numberSelectorOptionExcept",
                // eslint-disable-next-line eqeqeq
                value != null && ignoreValue == value && "active"
              )}
              onClick={(e) => {
                // eslint-disable-next-line eqeqeq
                onChange(ignoreValue != value ? ignoreValue : null);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (document.activeElement as any)?.blur?.();
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              {s(ignoreTitle)}
            </span>
          );
        })}

        {naValues?.map((naValue, index) => {
          const naTitle = options.find(
            (option) => option.value == naValue
          )?.title;
          return (
            <span
              key={naValue}
              className={classNames(
                "numberSelectorOptionExcept",
                // eslint-disable-next-line eqeqeq
                value != null && naValue == value && "active"
              )}
              onClick={(e) => {
                // eslint-disable-next-line eqeqeq
                onChange(naValue != value ? naValue : null);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (document.activeElement as any)?.blur?.();
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              {s(naTitle)}
            </span>
          );
        })}
      </div>
    ) : (
      <></>
    );
  }, [naValues, ignoreValues, value, onChange, s]);

  return (
    <FieldWrapper fieldState={fieldState}>
      {uiType === "normal" && (
        <NormalSelector
          options={convertedOptions}
          value={value}
          onChange={onChange}
        ></NormalSelector>
      )}
      {uiType === "slider" && (
        <Slider
          options={convertedOptionsValidValues}
          onChange={sliderOnChange}
          value={sliderValue}
          hasError={fieldState.invalid}
          showMiddleLabel={true}
          showLabelForSelectedValue={true}
          valueIncludeNA={value}
          naTitles={naTitles}
          naValues={naValues}
          ignoreTitles={ignoreTitles}
          ignoreValues={ignoreValues}
        >
          {NotApplicableComponent}
        </Slider>
      )}
      {uiType !== "normal" && uiType !== "slider" && (
        <DescreteSelector
          options={convertedOptions}
          value={value}
          onChange={onChange}
          uiType={uiType}
          hasError={fieldState.invalid}
        ></DescreteSelector>
      )}
    </FieldWrapper>
  );
};

SelectorSchemaComponent.display = DisplayComponent(
  "SelectorDisplayComponent",
  (value, schema) => {
    const { options } = schema;
    const s = useStringIntl();
    // eslint-disable-next-line eqeqeq
    const selectedOption = options.find((option) => option.value == value);
    return selectedOption ? s(selectedOption?.title) : "未設定";
  }
);

export default SelectorSchemaComponent;

import React from "react";
import {
  SchemaComponent,
  useField,
  requiredValidator,
  BaseSchema,
  useValue,
  DisplayComponent,
  Value,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
// import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";
import { Button, InputGroup } from "react-bootstrap";
import { useModalQrCode } from "hooks/dialogs";

interface UrlSchema extends BaseSchema {
  schemaType: "url";
  required?: boolean;
  calc?: (value: Value) => string | undefined;
}

const UrlSchemaComponent: SchemaComponent<UrlSchema> = (props) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  const url = (props.schema.calc ? props.schema.calc(value) : value) || "";
  const modalQrCode = useModalQrCode();
  return (
    <InputGroup className="mb-3">
      <Form.Control
        type="url"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        value={url}
        {...registerProps}
      />
      <Button
        variant="outline-secondary"
        style={{ position: "relative" }}
        onClick={() => {
          modalQrCode({ value: url });
        }}
      >
        <i className="mdi mdi-qrcode" onClick={() => {}} />
      </Button>
      <Button
        variant="outline-secondary"
        style={{ position: "relative" }}
        onClick={() => {
          window.open(url, "_blank");
        }}
      >
        <i className="mdi mdi-link" />
      </Button>
      <Button
        variant="outline-secondary"
        style={{ position: "relative" }}
        onClick={() => {
          navigator.clipboard.writeText(url);
        }}
      >
        <i className="mdi mdi-content-copy" />
      </Button>
    </InputGroup>
  );
};

const UrlSchemaDisplayComponent: DisplayComponent<UrlSchema> = (props) => {
  const { value } = props;
  const url = (props.schema.calc ? props.schema.calc(value) : value) || "";
  const modalQrCode = useModalQrCode();
  return (
    <InputGroup className="mb-3">
      <Form.Control
        type="url"
        className={classNames({})}
        value={url}
        readOnly={true}
      />
      <Button
        variant="outline-secondary"
        style={{ position: "relative" }}
        onClick={() => {
          modalQrCode({ value: url });
        }}
      >
        <i className="mdi mdi-qrcode" onClick={() => {}} />
      </Button>
      <Button
        variant="outline-secondary"
        style={{ position: "relative" }}
        onClick={() => {
          window.open(url, "_blank");
        }}
      >
        <i className="mdi mdi-link" />
      </Button>
      <Button
        variant="outline-secondary"
        style={{ position: "relative" }}
        onClick={() => {
          navigator.clipboard.writeText(url);
        }}
      >
        <i className="mdi mdi-content-copy" />
      </Button>
    </InputGroup>
  );
};

UrlSchemaComponent.display = UrlSchemaDisplayComponent;

export default UrlSchemaComponent;

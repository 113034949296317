import React, { useEffect, useMemo, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import SlotMachine from "components/SlotMachine";
import {
  useClientDoc,
  useEnqueteDoc,
  useRewardDoc,
  useShopDoc,
  useSurveyDoc,
} from "models/hook";

import { CONSUMER } from "..";
import ConsumerLayout from "components/ConsumerLayout";
import { applyToTemplate } from "utils/textTemplate";
import { useLocation } from "react-router-dom";
import { shopData } from "common/models/shop";
import { useFormatter, useStringIntl } from "hooks/intl";
import { ConsumerReview } from "components/ConsumerReview";
import { trackingLog, useTrackingLog } from "utils/log";
import {
  // eslint-disable-next-line camelcase
  finishLottery_v2,
  useRequestLotteryV2,
} from "functions";
import { v2Mode } from "utils/v2Migration";

const wait = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const CONSUMER_GACHA = CONSUMER.child(
  "/gacha/:enqueteId",
  ConsumerGacha
);

function ConsumerGacha({
  shopId,
  enqueteId,
  shopData: injectedShopData,
}: {
  shopId?: string;
  enqueteId: string;
  shopData?: Partial<shopData> | null;
}) {
  useTrackingLog("lotteryOpen", { shopId }, !!injectedShopData);

  const location = useLocation();

  if (v2Mode) {
    const [requestLotteryResponse, requestLotteryError] = useRequestLotteryV2({
      enqueteId,
    });
    if (requestLotteryError) {
      throw requestLotteryError;
    }
  }

  const {
    data,
    loading: loadingDocument,
    upsert,
  } = useEnqueteDoc({ enqueteId });
  const { data: rewardData, loading: loadingReward } = useRewardDoc({
    rewardId: data?.rewardId || undefined,
  });
  const { data: survey, loading: loadingSurvey } = useSurveyDoc({
    surveyId: data?.surveyId,
    clientId: data?.clientId,
  });
  const { data: client, loading: loadingClient } = useClientDoc({
    clientId: data?.clientId,
  });

  const { data: fetchedShopData, loading: shopDataFetching } = useShopDoc({
    shopId,
  });
  const shopData = injectedShopData || fetchedShopData;
  const shopDataLoading = injectedShopData ? false : shopDataFetching;
  // const { data: clientData } = useClientDoc({ clientId: shopData?.clientId });
  const commentData: string | undefined = location.state?.commentData;
  console.log("state", location.state);
  const { formatMessage } = useFormatter();
  const s = useStringIntl();

  const loading = loadingDocument || loadingReward || !data?.lottery;
  const [finished, setFinished] = useState(false);
  const onEnded = () => {
    setFinished(true);
    (async () => {
      if (data?.email && enqueteId) {
        if (v2Mode) {
          await finishLottery_v2({ enqueteId });
        } else {
          await upsert({
            movieFinished: true,
          });
        }
      }
      trackingLog("lotteryComplete", { shopId, subtype: data?.lottery });
    })();
  };
  const state = (() => {
    if (loading) {
      return "loading";
    }
    if (data?.lotteryExpiredAt && data.lotteryExpiredAt < Date.now()) {
      trackingLog("lotteryComplete", { shopId, subtype: "expired" });
      return "expired";
    }
    if (data?.lottery === "duplicate") {
      trackingLog("lotteryComplete", { shopId, subtype: "duplicate" });
      return "duplicate";
    }
    return "lottery";
  })();
  // 以下のユースケースでは通常、formatString が使えるが、formatStringだと文字列が{{}}を含むためエラーになる。
  const template = !shopDataLoading
    ? s(shopData?.enqueteCompletedText || "") ||
      formatMessage("EnqueteComplete.Text")
    : "";
  return (
    <ConsumerLayout shopId={shopId} skipAuthentication={true}>
      {state === "loading" && (
        <div className={"wide-form"} style={{ padding: "30px" }}>
          <div style={{ padding: "20px" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )}
      {state === "expired" && (
        <div className={"wide-form"}>
          <section className="hero">
            <div className="hero-body">
              <h1 className="title">
                <div>{formatMessage("Lottery.Status.Expired")}</div>
              </h1>
            </div>
          </section>
        </div>
      )}
      {state === "duplicate" && (
        <div className={"wide-form"}>
          <section className="hero">
            <div className="hero-body">
              <h1 className="title">
                <div>
                  {applyToTemplate(s(client?.invalidationNotificationText), {
                    invalidationDays: survey?.invalidationDays,
                  }) || formatMessage("Lottery.Status.Duplicate")}
                </div>
              </h1>
            </div>
          </section>
        </div>
      )}
      {state === "lottery" && (
        <SlotMachine animationId={data?.animationId} onFinish={onEnded} />
      )}
      {state === "lottery" && finished && (
        <div className={"wide-form"}>
          <section className="hero">
            <div className="hero-body">
              <h1 className="title">
                {data?.lottery === "win" && (
                  <span>{formatMessage("Lottery.Status.Win")}</span>
                )}
                {data?.lottery === "lose" && (
                  <span>{formatMessage("Lottery.Status.Lose")}</span>
                )}
              </h1>
            </div>
          </section>
          <section className="main-section adjust-width">
            {data?.lottery === "win" && rewardData && (
              <div>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {formatMessage("Lottery.Message.Win", {
                    rewardName: s(rewardData.rewardName),
                    email: data?.email || "",
                  })}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <section className="review">
                    <ConsumerReview
                      template={template}
                      shopData={shopData}
                      commentData={commentData}
                    />
                  </section>
                </div>
              </div>
            )}
            {data?.lottery === "lose" && (
              <div>
                <div>{formatMessage("Lottery.Message.Lose")}</div>
                <div
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <section className="review">
                    <ConsumerReview
                      template={template}
                      shopData={shopData}
                      commentData={commentData}
                    />
                  </section>
                </div>
              </div>
            )}
          </section>
        </div>
      )}
    </ConsumerLayout>
  );
}
export default ConsumerGacha;

import React from "react";
import { formatNumber, TableSchema, withSign } from "../statSchemaUtil";
import { getValueColor } from "../valueColor";
import { StatConfig } from "./type";

export const value = ({
  isClientData,
}: {
  isClientData: boolean;
}): StatConfig =>
  isClientData
    ? {
        action: "value",
        statSchemas: [
          {
            value: "client-value",
            title: "全社",
            graph: true,
            table: false,
            graphColor: "#14AFF4",
          },
          {
            value: "client-value-table",
            title: "全社",
            multiple: {
              source: ["client-value"],
              element: ({ "client-value": value }) => {
                return <span>{formatNumber(value, 2)}</span>;
              },
            },
          },
          {
            value: "client-value",
            id: "client-value-mom",
            title: "前月比",
            compareMonth: (
              value: number | null,
              prev: number | null | undefined
            ) => {
              if (typeof value === "number" && typeof prev === "number") {
                const content = withSign(formatNumber(value - prev, 2));
                const isNegative = content?.[0] === "-";
                return (
                  <span style={isNegative ? { color: "red" } : undefined}>
                    {content}
                  </span>
                );
              } else {
                return undefined;
              }
            },
          },
        ],
      }
    : {
        action: "value",
        statSchemas: [
          {
            value: "shop-value",
            title: "事業所",
            graph: true,
            table: false,
            graphColor: "#1C6F71",
          },
          {
            value: "shop-value-table",
            title: "事業所",
            multiple: {
              source: ["shop-value"],
              element: ({ "shop-value": value }) => {
                return <span>{formatNumber(value, 2)}</span>;
              },
            },
          },
          {
            value: "shop-value",
            id: "shop-value-mom",
            title: "前月比",
            compareMonth: (
              value: number | null,
              prev: number | null | undefined
            ) => {
              if (typeof value === "number" && typeof prev === "number") {
                const content = withSign(formatNumber(value - prev, 2));
                const isNegative = content?.[0] === "-";
                return (
                  <span style={isNegative ? { color: "red" } : undefined}>
                    {content}
                  </span>
                );
              } else {
                return undefined;
              }
            },
          },
          {
            value: "client-value",
            title: "全社",
            graph: true,
            table: false,
            graphColor: "#14AFF4",
          },
          {
            value: "client-value-table",
            title: "全社",
            multiple: {
              source: ["client-value"],
              element: ({ "client-value": value }) => {
                return <span>{formatNumber(value, 2)}</span>;
              },
            },
          },
          {
            value: "shop-rank-in-client",
            title: "社内順位",
            multiple: {
              source: ["shop-rank-in-client", "shop-groupcount-in-client"],
              element: (values) => {
                if (
                  values["shop-rank-in-client"] == null ||
                  values["shop-groupcount-in-client"] == null
                ) {
                  return "";
                }
                return `${
                  values["shop-groupcount-in-client"] -
                  values["shop-rank-in-client"] +
                  1
                } / ${values["shop-groupcount-in-client"]}`;
              },
            },
          },
          {
            value: "shop-tscore-in-all",
            title: "社内偏差値",
            multiple: {
              source: ["shop-tscore-in-all"],
              element: ({ "shop-tscore-in-all": value }) => {
                return (
                  <td
                    style={{
                      background: getValueColor(value, 50, 2),
                      textAlign: "right",
                    }}
                  >
                    {formatNumber(value, 1)}
                  </td>
                );
              },
            },
          },
        ],
      };

const valueColorTable: Record<string, string> = {
  "4": "#548235",
  "3": "#A9D08E",
  "2": "#C6E0B4",
  "1": "#E2EFDA",
  "0": "#FFFFFF",
  "-1": "#FCE4D6",
  "-2": "#F8CBAD",
  "-3": "#F4B084",
  "-4": "#C65911",
};

import React from "react";
import NavLink from "components/Navigation/Link";
import { resolveRoute } from "pages";
import { useAdminAuthentication } from "hooks/auth";
import { stagingAlertName } from "utils/domain";
import { isProductionEnv } from "utils/project";
import { Link, Navigate } from "react-router-dom";

export const AdminMenu = () => {
  const { role, user } = useAdminAuthentication();
  const { clientIds } = user || {};
  const isMaster = role === "master";
  const clientId = clientIds?.[0];
  const hasMultipleClients = (clientIds?.length || 0) > 1 || isMaster;

  // 1つのクライアントのみに属している場合は、クライアントページへリダイレクト
  if (clientId && !hasMultipleClients) {
    return (
      <Navigate to={resolveRoute("LIST_ENQUETES", { clientId })} replace />
    );
  }

  return (
    <>
      {stagingAlertName && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            color: "#fff",
            background: isProductionEnv ? "#f00c" : "#fff9",
            padding: "0 10px",
            zIndex: 1000,
          }}
        >
          {stagingAlertName}
        </div>
      )}
      <Link to={resolveRoute("ADMIN_HOME")} className="aside-tools">
        <div className="aside-tools-label">
          <span>ファンくる CR</span>
        </div>
      </Link>
      <div className="menu is-menu-main">
        <ul className="menu-list">
          <li>
            <NavLink to={resolveRoute("LIST_CLIENTS")}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">クライアント一覧</span>
            </NavLink>
          </li>
          {isMaster && (
            <li>
              <NavLink to={resolveRoute("LIST_INDUSTRIES")}>
                <span className="icon">
                  <i className="mdi mdi-account-supervisor" />
                </span>
                <span className="menu-item-label">業種一覧</span>
              </NavLink>
            </li>
          )}
          {isMaster && (
            <li>
              <NavLink to={resolveRoute("LIST_ADMIN_USER")}>
                <span className="icon">
                  <i className="mdi mdi-account-supervisor" />
                </span>
                <span className="menu-item-label">管理ユーザ一覧</span>
              </NavLink>
            </li>
          )}
          {isMaster && (
            <li>
              <NavLink to={resolveRoute("UPDATE_SYSTEM_SETTING")}>
                <span className="icon">
                  <i className="mdi mdi-tune" />
                </span>
                <span className="menu-item-label">システム設定</span>
              </NavLink>
            </li>
          )}
          {isMaster && (
            <li>
              <NavLink
                to={resolveRoute("LIST_SYSTEM_MAIL_TEMPLATES", {
                  clientId: "default",
                })}
              >
                <span className="icon">
                  <i className="mdi mdi-email" />
                </span>
                <span className="menu-item-label">メールテンプレート</span>
              </NavLink>
            </li>
          )}
          {isMaster && (
            <li>
              <NavLink
                to={resolveRoute("LIST_CLIENTS_SUMMARIES", {
                  clientId: "default",
                })}
              >
                <span className="icon">
                  <i className="mdi mdi-table" />
                </span>
                <span className="menu-item-label">件数集計</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

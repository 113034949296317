import firebaseApp from "../firebaseApp";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { ArrayDataFrame, DataFrame } from "./dataFrame";
import { retentionCache } from "@smart-hook/react-hook-retention-cache";

const auth = getAuth(firebaseApp);

const callLocalFunction =
  <Request, Response>(name: string) =>
  async (params: Request): Promise<{ data: Response }> => {
    const accessToken = await auth.currentUser?.getIdToken();
    const response = await fetch(`http://127.0.0.1:5000/${name}`, {
      method: "POST",
      mode: "cors",
      // cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ data: params }),
    });
    return (await response.json()) as { data: Response };
  };

export type StatRequest = {
  action: "cross" | "dist" | "value" | "count" | "rank";
  month?: string;
  industryId?: string;
  clientId?: string;
  shopId?: string;
  columns: string | string[];
  column?: string;
  stats: string[];
  label?: string;
  industryDataId?: string;
  clientDataId?: string;
  unique?: "month" | "week" | "day";
};

export type StatResponse = {
  columns: string[];
  columnsReverse: { [K in string]: number };
  index: string[];
  indexReverse: { [K in string]: number };
  data: (number | null)[];
  label?: string;
};

const forceRemoteApi = true;

export const stat: (params: StatRequest) => Promise<{
  data: StatResponse;
}> = (() => {
  if (location.hostname === "localhost" && !forceRemoteApi) {
    return callLocalFunction<StatRequest, StatResponse>("stat");
  } else {
    return httpsCallable<StatRequest, StatResponse>(
      getFunctions(firebaseApp, "asia-northeast1"),
      "stat_v2"
    );
  }
})();

const statCache = retentionCache({
  generator: (params: StatRequest) => stat(params),
  cleanUp: () => {},
  retentionTime: 600000, // 10min
});

const wait = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

class MultipleStatsSession<T> {
  data?: T;
  loading = false;
  error?: Error;
  closed = false;
  unsubscribers: (() => void)[] = [];
  constructor(
    paramsList: StatRequest[] | undefined,
    onUpdate: () => void,
    handler: (dataFrames: DataFrame<number | null>[]) => T | Error
  ) {
    const dataFrames: DataFrame<number | null>[] = [];
    const update = () => {
      const result = handler(dataFrames);
      if (result instanceof Error) {
        this.error = result;
      } else {
        this.data = result;
      }
      onUpdate();
    };

    paramsList &&
      (async () => {
        const delay = 30;
        setTimeout(() => {
          if (!this.data && !this.error) {
            this.loading = true;
            // console.log("#onUpdate timeout", dataFrames.length);
            update();
          }
        }, 1000 + (paramsList.length - 1) * delay);
        await Promise.allSettled(
          paramsList.map((params, index) => {
            return (async () => {
              try {
                const cachedItem = statCache.getCachedItem(params);
                if (!cachedItem) {
                  await wait((paramsList.length - 1 - index) * delay);
                }
                if (this.closed) {
                  return;
                }
                const retentionItem = cachedItem || statCache.getItem(params);
                this.unsubscribers.push(retentionItem.subscribe());
                const apiResponse = await retentionItem.value;
                if (apiResponse.data) {
                  apiResponse.data.label = params.label;
                  const currentDataFrame = new ArrayDataFrame(apiResponse.data);
                  dataFrames.push(currentDataFrame);
                } else {
                  console.error("backend error", apiResponse);
                  this.error = new Error("Backend Error");
                }
              } catch (e) {
                console.error(e);
                this.error = e as Error;
              }
              if (this.loading) {
                // console.log("#onUpdate progressive", dataFrames.length);
                update();
              }
            })();
          })
        );
        this.loading = false;
        // console.log("#onUpdate finished", dataFrames.length);
        update();
      })();
  }

  close() {
    this.closed = true;
    for (const unsubscriber of this.unsubscribers) {
      unsubscriber();
    }
  }
}

export const useStat = <T>(
  params: StatRequest[] | undefined,
  handler: (dataFrames: DataFrame<number | null>[]) => T | Error,
  dependencies: unknown[]
) => {
  const [response, setResponse] = useState<{
    data?: T;
    loading?: boolean;
    error?: Error;
  }>({ loading: false });
  useEffect(() => {
    let canceled = false;
    const statSession = (() => {
      if (params) {
        const statSession = new MultipleStatsSession(
          params,
          () => {
            if (!canceled) {
              setResponse({
                data: statSession.data,
                loading: statSession.loading,
                error: statSession.error,
              });
            }
          },
          handler
        );
        return statSession;
      }
    })();
    return () => {
      canceled = true;
      statSession?.close();
    };
  }, [JSON.stringify(params), ...dependencies]);
  return response;
};

// (window as any).statCache = statCache;

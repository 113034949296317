import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "リワード名",
      propertyName: "rewardName",
      schema: {
        schemaType: "textIntl",
        required: true,
      },
    },
    {
      title: "有効期間（日）",
      propertyName: "expirationPeriod",
      schema: {
        schemaType: "number",
        required: true,
      },
    },
  ],
};
export default schema;

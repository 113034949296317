import React from "react";
import { couponUrl } from "utils/coupon";
import { Control, useWatch } from "react-hook-form";
import { couponData } from "common/models/coupon";

export const PreviewComponent = ({ control }: { control: Control }) => {
  const { couponId, shopId } = useWatch({
    control: control as unknown as Control<couponData>,
  });
  return couponId ? (
    <div className="preview">
      <iframe
        className="preview"
        style={{ border: "none" }}
        src={couponUrl(shopId, couponId)}
      ></iframe>
    </div>
  ) : (
    <div />
  );
};

import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "権限",
      propertyName: "role",
      schema: {
        schemaType: "selector",
        options: [
          {
            title: "マスター",
            value: "master",
          },
          {
            title: "クライアント",
            value: "client",
          },
        ],
      },
    },
    {
      title: "氏名",
      propertyName: "name",
      schema: { schemaType: "text" },
    },
    {
      title: "クライアント",
      propertyName: "clientIds",
      schema: {
        schemaType: "multipleExternalKey",
        uiType: "normal",
        resourcePath: "/client",
        titleKey: "clientName",
      },
    },
    {
      title: "所属",
      propertyName: "organization",
      schema: { schemaType: "text" },
    },
    {
      title: "初期E-MAIL",
      propertyName: "initialEmail",
      schema: { schemaType: "text" },
    },
  ],
};
export default schema;

import { useFormatter } from "hooks/intl";
import {
  useController,
  UseFormRegisterReturn,
  useWatch,
} from "react-hook-form";
import {
  Validator,
  SchemaComponentInternalProps,
  BaseSchema,
  ValidationResult,
} from "../types";
import { validate } from "../validate";
import { MessageId } from "utils/locale";

const useErrorFormatter = () => {
  const { formatMessage } = useFormatter();
  return async (promise: Promise<ValidationResult>) => {
    const result = await promise;
    if (typeof result === "string") {
      return result;
    } else if (result && result.id) {
      return formatMessage(result.id as MessageId, result.values);
    } else if (result == null || typeof result === "boolean") {
      return undefined;
    } else {
      console.log("unknown error", result);
      return "";
    }
  };
};

export const useRegister = <S extends BaseSchema>(
  props: SchemaComponentInternalProps<S>,
  validators: Validator<S>[]
) => {
  const formatError = useErrorFormatter();
  const { fieldPath, control } = props;
  return control.register(fieldPath, {
    validate: (value) => formatError(validate(value, validators, props)),
  });
};

export const useField = <S extends BaseSchema>(
  props: SchemaComponentInternalProps<S>,
  validators: Validator<S>[]
) => {
  const formatError = useErrorFormatter();
  const { fieldPath, control } = props;
  const { field, fieldState } = useController({
    control,
    name: fieldPath,
    rules: {
      validate: (value) => formatError(validate(value, validators, props)),
    },
  });
  const { value, ref, onChange, onBlur, name } = field;
  return {
    value,
    ref,
    registerProps: { onChange, onBlur, name },
    fieldState,
  };
};

export const useDOMField = <S extends BaseSchema>(
  props: SchemaComponentInternalProps<S>,
  validators: Validator<S>[]
) => {
  const formatError = useErrorFormatter();
  const { fieldPath, control } = props;
  const { fieldState, field } = useController({
    control,
    name: fieldPath,
  });
  const registerProps = control.register(fieldPath, {
    validate: (value) => formatError(validate(value, validators, props)),
  }) as UseFormRegisterReturn;
  const { value } = field;
  return { value, registerProps, fieldState };
};

export const useValue = (props: SchemaComponentInternalProps) => {
  const { control, fieldPath } = props;
  if (fieldPath) {
    return useWatch({
      control,
      name: fieldPath,
    });
  } else {
    return useWatch({
      control,
    });
  }
};

export const useFieldState = (props: SchemaComponentInternalProps) => {
  const { fieldPath, control } = props;
  const { fieldState } = useController({
    control,
    name: fieldPath,
  });
  return {
    fieldState,
  };
};

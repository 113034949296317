import React, { useCallback, useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
import NavLink from "components/Navigation/Link";
import { useAdminAuthentication } from "hooks/auth";
import {
  useClientDoc,
  usePublicIndustryReportList,
  usePublicReportList,
} from "models/hook";
import { resolveRoute } from "pages";
import { stagingAlertName } from "utils/domain";
import { objectToSearchParams, useSearchParamAccessor } from "utils/url";
import {
  DateRange,
  recentPeriod,
} from "schemaComponents/components/searchDate";
import { PropertyType } from "schemaComponents";
import { isProductionEnv } from "utils/project";

export const ClientMenu = ({ clientId }: { clientId: string }) => {
  const { role, user } = useAdminAuthentication();
  const { clientIds } = user || {};
  const isMaster = role === "master";
  const hasMultipleClients = (clientIds?.length || 0) > 1 || isMaster;
  const { data, loading } = useClientDoc({ clientId });
  const location = useLocation();

  if (!clientId) return <div>Internal error</div>;

  const currentPath = location.pathname + location.search;

  const commentParams = useMemo(() => {
    if (loading) {
      return null;
    }
    const tagMap = {} as { [key: string]: string[] };
    for (const property of (data?.questions?.properties ||
      []) as PropertyType[]) {
      for (const tag of property.tags || []) {
        tagMap[tag] = tagMap[tag] || [];
        tagMap[tag].push("data." + property.propertyName);
      }
    }
    return objectToSearchParams({
      c: Object.fromEntries(
        tagMap.comment?.map((propertyName) => [
          propertyName,
          {
            $nin: [null, ""],
          },
        ]) || []
      ),
      p: tagMap.display,
    });
  }, [data]);

  const { value: searchState } = useSearchParamAccessor<{
    date: DateRange;
    shopId?: string;
    r: unknown;
  }>();
  const reportSearchParam = useMemo(() => {
    const searchString = objectToSearchParams({
      date: searchState.date,
      shopId: searchState.shopId,
      r: searchState.r,
    }).toString();
    return searchString ? "?" + searchString : "";
  }, [searchState]);

  const { list: clientReports, loading: loadingReportList } =
    usePublicReportList({
      clientId,
    });

  const { list: industryReports } = usePublicIndustryReportList({
    industryId: data.industryId,
  });

  const reports = useMemo(() => {
    return [
      ...(clientReports || []),
      ...(data?.hideIndustryReport ? [] : industryReports || []),
    ].sort((a, b) => (a.priority || "").localeCompare(b.priority || ""));
  }, [clientReports, industryReports, data]);

  const isEditPage = useCallback(
    (listPagePath: string) => {
      // 編集ページのURLの正規表現
      const editPageRegex = new RegExp(`^${listPagePath}/.+`);
      return editPageRegex.test(currentPath);
    },
    [currentPath]
  );

  return (
    <>
      {stagingAlertName && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            color: "#fff",
            background: isProductionEnv ? "#f00c" : "#fff9",
            padding: "0 10px",
            zIndex: 1000,
          }}
        >
          {stagingAlertName}
        </div>
      )}
      <Link to={resolveRoute("ADMIN_HOME")} className="aside-tools">
        <div className="aside-tools-label">{data?.clientName}</div>
        {hasMultipleClients && (
          <div className="aside-tools-sync">
            <Link to={resolveRoute("LIST_CLIENTS")}>
              <span
                className="mdi mdi-sync"
                data-tooltip-id="tooltip"
                data-tooltip-content="クライアント切替"
              ></span>
            </Link>
          </div>
        )}
      </Link>
      <div className="menu is-menu-main">
        <ul className="menu-list">
          {reports.map((report) => {
            const { reportId } = report;
            return (
              <li key={report.reportId}>
                <NavLink
                  to={
                    resolveRoute("UPDATE_REPORT", { clientId, reportId }) +
                    reportSearchParam
                  }
                  className="navbar-item"
                >
                  <span className="icon">
                    <i className="mdi mdi-chart-areaspline" />
                  </span>
                  <span className="menu-item-label">{report.title}</span>
                </NavLink>
              </li>
            );
          })}

          <li>
            <NavLink
              to={
                resolveRoute("LIST_ENQUETES", { clientId }) +
                "?" +
                objectToSearchParams({
                  t: recentPeriod("day", 30),
                })
              }
              className={
                isEditPage(resolveRoute("LIST_ENQUETES", { clientId }))
                  ? "navbar-item is-edit"
                  : undefined
              }
            >
              <span className="icon">
                <i className="mdi mdi-format-list-bulleted-type" />
              </span>
              <span className="menu-item-label">アンケート一覧</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={
                resolveRoute("LIST_COUPONS", { clientId }) +
                "?" +
                objectToSearchParams({
                  t: recentPeriod("day", 30),
                })
              }
              className={
                isEditPage(resolveRoute("LIST_COUPONS", { clientId }))
                  ? "navbar-item is-edit"
                  : undefined
              }
            >
              <span className="icon">
                <i className="mdi mdi-ticket" />
              </span>
              <span className="menu-item-label">クーポン一覧</span>
            </NavLink>
          </li>

          <li>
            <NavLink to={resolveRoute("LIST_SUMMARIES", { clientId })}>
              <span className="icon">
                <i className="mdi mdi-table" />
              </span>
              <span className="menu-item-label">件数集計</span>
            </NavLink>
          </li>

          <li style={{ color: "#eee", marginTop: 5, marginBottom: 5 }}>
            設定情報
          </li>

          {isMaster && (
            <li>
              <NavLink to={resolveRoute("UPDATE_CLIENT", { clientId })}>
                <span className="icon">
                  <i className="mdi mdi-pencil" />
                </span>
                <span className="menu-item-label">クライアント情報</span>
              </NavLink>
            </li>
          )}

          <li>
            <NavLink
              to={
                resolveRoute("LIST_SHOPS", { clientId }) +
                "?" +
                objectToSearchParams({
                  c: {
                    ignore: {
                      $truthy: false,
                    },
                  },
                })
              }
              className={
                isEditPage(resolveRoute("LIST_SHOPS", { clientId }))
                  ? "navbar-item is-edit"
                  : undefined
              }
            >
              <span className="icon">
                <i className="mdi mdi-store" />
              </span>
              <span className="menu-item-label">事業所一覧</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={
                resolveRoute("LIST_SURVEYS", { clientId }) +
                "?" +
                objectToSearchParams({
                  c: {
                    ignore: {
                      $truthy: false,
                    },
                  },
                })
              }
              className={
                isEditPage(resolveRoute("LIST_SURVEYS", { clientId }))
                  ? "navbar-item is-edit"
                  : undefined
              }
            >
              <span className="icon">
                <i className="mdi mdi-chat-question" />
              </span>
              <span className="menu-item-label">調査一覧</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={resolveRoute("LIST_REWARDS", { clientId })}
              className={
                isEditPage(resolveRoute("LIST_REWARDS", { clientId }))
                  ? "navbar-item is-edit"
                  : undefined
              }
            >
              <span className="icon">
                <i className="mdi mdi-gift-outline" />
              </span>
              <span className="menu-item-label">リワード一覧</span>
            </NavLink>
          </li>

          {isMaster && (
            <>
              <li style={{ color: "#eee", marginTop: 5, marginBottom: 5 }}>
                管理者メニュー
              </li>

              <li>
                <NavLink
                  to={
                    resolveRoute("LIST_REPORTS", { clientId }) +
                    reportSearchParam
                  }
                  className={
                    isEditPage(resolveRoute("LIST_REPORTS", { clientId }))
                      ? "navbar-item is-edit"
                      : undefined
                  }
                >
                  <span className="icon">
                    <i className="mdi mdi-chart-areaspline" />
                  </span>
                  <span className="menu-item-label">企業レポート一覧</span>
                </NavLink>
                <ul className="nestedNavContainer" style={{}}></ul>
              </li>

              <li>
                <NavLink
                  to={resolveRoute("LIST_MAIL_TEMPLATES", { clientId })}
                  className={
                    isEditPage(
                      resolveRoute("LIST_MAIL_TEMPLATES", { clientId })
                    )
                      ? "navbar-item is-edit"
                      : undefined
                  }
                >
                  <span className="icon">
                    <i className="mdi mdi-email" />
                  </span>
                  <span className="menu-item-label">メールテンプレート</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={
                    resolveRoute("LIST_ADMIN_ENQUETES", { clientId }) +
                    "?" +
                    objectToSearchParams({
                      c: {
                        ignore: {
                          $truthy: false,
                        },
                      },
                    })
                  }
                  activeStyle={
                    currentPath !==
                      resolveRoute("LIST_ADMIN_ENQUETES", { clientId }) +
                        "?" +
                        commentParams?.toString() &&
                    location.pathname ===
                      resolveRoute("LIST_ADMIN_ENQUETES", { clientId })
                  }
                  className={
                    isEditPage(
                      resolveRoute("LIST_ADMIN_ENQUETES", { clientId })
                    )
                      ? "navbar-item is-edit"
                      : undefined
                  }
                >
                  <span className="icon">
                    <i className="mdi mdi-format-list-bulleted-type" />
                  </span>
                  <span className="menu-item-label">
                    アンケート一覧（管理者）
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  matchSearchParams={true}
                  to={
                    resolveRoute("LIST_ADMIN_ENQUETES", { clientId }) +
                    "?" +
                    commentParams?.toString()
                  }
                  activeStyle={
                    currentPath ===
                    resolveRoute("LIST_ADMIN_ENQUETES", { clientId }) +
                      "?" +
                      commentParams?.toString()
                  }
                >
                  <span className="icon">
                    <i className="mdi mdi-format-list-bulleted-type" />
                  </span>
                  <span className="menu-item-label">コメント一覧</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={resolveRoute("LIST_ADMIN_COUPONS", { clientId })}
                  className={
                    isEditPage(resolveRoute("LIST_ADMIN_COUPONS", { clientId }))
                      ? "navbar-item is-edit"
                      : undefined
                  }
                >
                  <span className="icon">
                    <i className="mdi mdi-ticket" />
                  </span>
                  <span className="menu-item-label">
                    クーポン一覧（管理者）
                  </span>
                </NavLink>
              </li>

              {/* <li>
                <NavLink
                  to={resolveRoute("UPDATE_COMMENT", { clientId })}
                  className={
                    isEditPage(resolveRoute("UPDATE_COMMENT", { clientId }))
                      ? "navbar-item is-edit"
                      : undefined
                  }
                >
                  <span className="icon">
                    <i className="mdi mdi-ticket" />
                  </span>
                  <span className="menu-item-label">コメント生成（実験）</span>
                </NavLink>
              </li> */}
            </>
          )}

          {/* <li>
            <NavLink
              to={
                resolveRoute("LIST_REPORTS", { clientId }) + reportSearchParam
              }
            >
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">レポート（開発中）</span>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </>
  );
};

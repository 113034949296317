import React from "react";
import { formatNumber, TableSchema, withSign } from "../statSchemaUtil";
import { StatConfig } from "./type";
import { getValueColor } from "../valueColor";

export const count = ({
  isClientData,
}: {
  isClientData: boolean;
}): StatConfig =>
  isClientData
    ? {
        action: "count",
        columns: ["count"],
        statSchemas: [
          {
            value: "count-client",
            title: "全社",
            graph: true,
            table: false,
            graphColor: "#14AFF4",
          },
          {
            value: "count-client-table",
            title: "全社",
            multiple: {
              source: ["count-client"],
              element: (values) => {
                return <span>{formatNumber(values["count-client"], 0)}</span>;
              },
            },
          },
          {
            value: "count-client",
            id: "count-client-mom",
            title: "前月比",
            compareMonth: (
              value: number | null,
              prev: number | null | undefined
            ) => {
              if (typeof value === "number" && typeof prev === "number") {
                const content = withSign(formatNumber(value - prev, 0));
                const isNegative = content?.[0] === "-";
                return (
                  <span style={isNegative ? { color: "red" } : undefined}>
                    {content}
                  </span>
                );
              } else {
                return undefined;
              }
            },
          },
        ],
      }
    : {
        action: "count",
        columns: ["count"],
        statSchemas: [
          {
            value: "count-shop",
            title: "事業所",
            graph: true,
            table: false,
            graphColor: "#1C6F71",
          },
          {
            value: "count-shop-table",
            title: "事業所",
            multiple: {
              source: ["count-shop"],
              element: (values) => {
                return <span>{formatNumber(values["count-shop"], 0)}</span>;
              },
            },
          },
          {
            value: "count-shop",
            id: "count-shop-mom",
            title: "前月比",
            compareMonth: (
              value: number | null,
              prev: number | null | undefined
            ) => {
              if (typeof value === "number" && typeof prev === "number") {
                const content = withSign(formatNumber(value - prev, 0));
                const isNegative = content?.[0] === "-";
                return (
                  <span style={isNegative ? { color: "red" } : undefined}>
                    {content}
                  </span>
                );
              } else {
                return undefined;
              }
            },
          },
          {
            value: "count-client",
            title: "全社",
            graph: true,
            table: false,
            graphColor: "#14AFF4",
          },
          {
            value: "count-client-table",
            title: "全社",
            multiple: {
              source: ["count-client"],
              element: (values) => {
                return <span>{formatNumber(values["count-client"], 0)}</span>;
              },
            },
          },
          {
            value: "count-rank-shop-in-client",
            title: "社内順位",
            multiple: {
              source: [
                "count-rank-shop-in-client",
                "count-groupcount-shop-in-client",
              ],
              element: (values) => {
                if (
                  values["count-rank-shop-in-client"] == null ||
                  values["count-groupcount-shop-in-client"] == null
                ) {
                  return "";
                }
                return `${
                  values["count-groupcount-shop-in-client"] -
                  values["count-rank-shop-in-client"] +
                  1
                } / ${values["count-groupcount-shop-in-client"]}`;
              },
            },
          },
          {
            value: "count-grouptscore-shop-in-client",
            title: "社内偏差値",
            multiple: {
              source: ["count-grouptscore-shop-in-client"],
              element: ({ "count-grouptscore-shop-in-client": value }) => {
                return (
                  <td
                    style={{
                      background: getValueColor(value, 50, 2),
                      textAlign: "right",
                    }}
                  >
                    {formatNumber(value, 1)}
                  </td>
                );
              },
            },
          },
        ],
      };

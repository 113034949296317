import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";
import { getMultiLocaleValues, StringIntl } from "utils/locale";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const parse = require("loose-json");

interface EnqueteSchema extends BaseSchema {
  schemaType: "enquete";
  required?: boolean;
}

export const parseCsv = (text: string) => {
  const lines = text.split("\n").map((line) => line.split("\t"));
  const header = lines.shift();
  if (!header) throw new Error();

  const properties = lines
    .map((line) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const property = { schema: {} } as Record<string, any>;
      const optionMap = new Map<string | number, StringIntl>();
      const params = {} as Record<string, unknown>;
      line.forEach((item, index) => {
        if (item) {
          const key = header[index];
          const keyComponents = key.split(".");
          if (keyComponents[0] === "options") {
            const locale = keyComponents[1] || "ja";
            item.split("/").forEach((item, index) => {
              const match = item.match(/(.*)#(.*)/);
              const { value, title } = match
                ? {
                    title: match[1],
                    value: match[2],
                  }
                : {
                    title: item,
                    value: index,
                  };
              const oldValue = optionMap.get(value) || {};
              optionMap.set(
                value,
                getMultiLocaleValues({
                  ...(typeof oldValue === "string"
                    ? { ja: oldValue }
                    : oldValue),
                  [locale]: title,
                }) || ({} as StringIntl)
              );
            });
          } else if (key === "params") {
            Object.assign(property.schema, parse(item));
          } else if (keyComponents[0] === "params") {
            const locale = keyComponents[1] || "ja";
            const params = parse(item);
            for (const [key, value] of Object.entries(params)) {
              const oldValue = property.schema[key] || {};
              property.schema[key] = getMultiLocaleValues({
                ...(typeof oldValue === "string" ? { ja: oldValue } : oldValue),
                [locale]: value,
              });
            }
          } else if (
            key === "propertyName" ||
            key === "statName" ||
            key === "statShortName"
          ) {
            property[key] = item;
          } else if (keyComponents[0] === "title") {
            property.title = property.title || {};
            if (
              typeof property.title === "string" &&
              keyComponents[1] !== "ja"
            ) {
              property.title = {
                ja: property.title,
              };
            }
            property.title[keyComponents[1] || "ja"] = item;
            const newPropertyTitle = getMultiLocaleValues(property.title);
            property.title = newPropertyTitle;
          } else if (key === "required") {
            if (item === "TRUE") {
              property.schema[key] = true;
            }
          } else if (key === "disabled") {
            if (item === "TRUE") {
              property[key] = true;
            }
          } else if (key === "tags") {
            if (item) {
              property[key] = item.split(",");
            }
          } else {
            property.schema[key] = item;
          }
        }
      });
      if (property?.schema?.schemaType !== "conversion") {
        property.schema.options = Array.from(optionMap.entries()).map(
          ([value, title]) => {
            return { value, title };
          }
        );
      }
      const options = Array.from(optionMap.entries()).map(([value, title]) => {
        return { value, title };
      });
      if (
        property.schema.schemaType === "selector" ||
        property.schema.schemaType === "multipleSelector"
      ) {
        property.schema.options = options;
      }
      return property;
    })
    .filter((item) => item.schema.schemaType);
  console.log("properties", properties);
  return { schemaType: "object", properties };
};

const EnqueteSchemaComponent: SchemaComponent<EnqueteSchema> = (
  props: SchemaComponentInternalProps<EnqueteSchema>
) => {
  const { value, registerProps, fieldState } = useField(props, [
    requiredValidator,
  ]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (e: any) => {
    const text = e.target.value as string;
    const value = parseCsv(text);
    registerProps.onChange(value);
  };
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        as="textarea"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        style={{ height: "160px" }}
        defaultValue={""}
        {...registerProps}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};

EnqueteSchemaComponent.display = DisplayComponent("EnqueteDisplayComponent");

export default EnqueteSchemaComponent;

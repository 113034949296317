import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useClientDoc, useMailTemplateDoc } from "models/hook";
import { resolveRoute } from "pages";
import { MAIL_TEMPLATE } from "../route";
import { AdminLocaleSelector } from "hooks/localeSelector";
import { availableLocales } from "utils/locale";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const UPDATE_MAIL_TEMPLATE = MAIL_TEMPLATE.sub("", MailTemplate);

function MailTemplate({
  mailTemplateKey,
  clientId,
}: {
  mailTemplateKey: string;
  clientId: string;
}) {
  useAdminPageAuthorization(clientId);
  const { data, loading, set, isUpdate, remove } = useMailTemplateDoc({
    clientId,
    mailTemplateKey,
  });
  const { data: clientData } = useClientDoc({ clientId });
  const parameter: SchemaFormParameter = {
    title: isUpdate ? "メールテンプレート詳細" : "メールテンプレート新規作成",
    edit: {
      title: `${isUpdate ? "保存" : "保存"}`,
      confirm: `${isUpdate ? "保存" : "保存"}しますか？`,
      handler: set,
    },
    remove: {
      title: "削除",
      confirm: "削除しますか？",
      handler: remove,
    },
    schema: getSchema(isUpdate),
    backPath:
      clientId === "default"
        ? resolveRoute("LIST_SYSTEM_MAIL_TEMPLATES", { clientId })
        : resolveRoute("LIST_MAIL_TEMPLATES", { clientId }),
    clipboard: {
      objectType: `mailTemplate_${mailTemplateKey}`,
      retainKeys: ["clientId", "mailTemplateKey"],
      isDisabled: true, // MEMO: クリップボード機能のidコピーに問題があるため一時的に廃止
    },
  };
  return (
    <SchemaFormPage
      data={data}
      parameter={parameter}
      loading={loading}
      header={
        <AdminLocaleSelector
          locales={
            clientId !== "default"
              ? clientData?.availableLocales
              : availableLocales
          }
        />
      }
      editModeEnabled={true}
    />
  );
}

export default MailTemplate;

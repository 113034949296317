import React from "react";
import { Schema } from "schemaComponents";

export default ({
  isEditing,
  industryId,
}: {
  isEditing: boolean;
  industryId?: string;
}): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "業種名",
        propertyName: "industryName",
        schema: {
          schemaType: "text",
          required: true,
        },
      },
      {
        title: "詳細説明",
        propertyName: "description",
        schema: {
          schemaType: "multilineText",
        },
      },
      {
        title: "設問",
        propertyName: "questions",
        schema: {
          schemaType: "json",
        },
      },
      {
        title: "設問(CSV)",
        propertyName: "questions",
        propertyId: "questions_csv",
        schema: {
          schemaType: "enquete",
        },
      },
    ],
  };
};

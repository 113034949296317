const isLocal = location.hostname === "localhost";
const _isAdminDomain = location.hostname.startsWith("admin");
export const isAdminDomain = isLocal || _isAdminDomain || undefined;
// export const isClientDomain = isLocal || !_isAdminDomain || undefined;

export const clientDomain = (() => {
  if (!isAdminDomain) {
    return `${window.location.protocol}//${window.location.host}`;
  } else {
    return `${window.location.protocol}//${window.location.host.replace(
      /^admin\./,
      ""
    )}`;
  }
})();

export const adminDomain = (() => {
  if (isAdminDomain) {
    return `${window.location.protocol}//${window.location.host}`;
  } else {
    return `${window.location.protocol}//admin.${window.location.host}`;
  }
})();

export const currentDomain = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

export const stagingAlertName = (() => {
  if (location.hostname.includes("staging")) {
    return "staging";
  } else if (location.hostname.includes("preview")) {
    return "preview";
  } else if (location.hostname.includes("dev")) {
    return "dev";
  } else if (location.hostname.includes("localhost")) {
    return "local";
  } else if (location.hostname.endsWith(".web.app")) {
    // get first phrase before hypen
    return location.hostname.split("-")[0];
  } else {
    return undefined;
  }
})();

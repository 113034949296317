import React from "react";
import AdminLayout from "components/AdminLayout";
import { Outlet } from "react-router-dom";
import { ROOT } from "pages/root";
import { AdminMenu } from "./menu";
import LocaleProvider from "components/LocaleProvider";

export const ADMIN = ROOT.sub("/admin", Admin, {
  parent: true,
  components: {
    menu: AdminMenu,
  },
});

function Admin() {
  return (
    <LocaleProvider>
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    </LocaleProvider>
  );
}

import React from "react";
import { useFormatter } from "hooks/intl";
import classNames from "classnames";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  useField,
  requiredValidator,
  BaseSchema,
} from "react-hook-schema-form";
import { FieldWrapper } from "./common/fieldWrapper";
import { DatePickerStyled } from "components/DatePicker";
import { DisplayComponent } from "./common/displayWrapper";

interface DateSchema extends BaseSchema {
  schemaType: "date";
  required?: boolean;
}

const DateSchemaComponent: SchemaComponent<DateSchema> = (
  props: SchemaComponentInternalProps<DateSchema>
) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <DatePickerStyled
        className={classNames("form-control", {
          "has-error": fieldState.invalid,
        })}
        date={value ? new Date(value) : null}
        {...registerProps}
      />
    </FieldWrapper>
  );
};

DateSchemaComponent.display = DisplayComponent(
  "DateDisplayComponent",
  (value) => {
    const { formatDate } = useFormatter();
    return value ? formatDate(value) + "" : "";
  }
);

export default DateSchemaComponent;

import { useFormatter } from "hooks/intl";
import { Schema } from "schemaComponents";

const getSchema = ({
  issuerId,
  contractTitle,
  contractContent,
  email,
  isPublic,
}: {
  issuerId: string;
  contractTitle: string;
  contractContent: string;
  email?: string;
  isPublic?: boolean;
}): Schema => {
  const { formatMessage } = useFormatter();
  return {
    schemaType: "object",
    properties: [
      {
        propertyName: "contractConfirm",
        title: formatMessage("Field.ContractConfirm"),
        schema: {
          schemaType: "contractConfirm",
          required: true,
          email: !isPublic ? email : undefined,
          issuerId,
          contractTitle,
          contractContent,
          syncMode: "sync",
        },
      },
    ],
  };
};

export default getSchema;

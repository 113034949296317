import type { AnySchema, ValidationError } from "yup";
import { Value, ValidationContext, BaseSchema } from "../types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyError = any;

const isYupValidationError = (e: AnyError): e is ValidationError => {
  return e.name === "ValidationError";
};
export const yupValidator =
  (yupSchema: AnySchema) =>
  async (value: Value, { label }: ValidationContext) => {
    try {
      await (label ? yupSchema.label(label) : yupSchema).validate(value);
    } catch (e) {
      if (isYupValidationError(e)) {
        return e.message;
      } else {
        throw e;
      }
    }
    return null;
  };

interface RequiredValidatorSchema extends BaseSchema {
  required?: boolean;
}

export const requiredValidator = async (
  value: Value,
  { schema, label }: ValidationContext<RequiredValidatorSchema>
) => {
  if (schema.required && (value == null || value === "")) {
    return { id: "validation/required", values: { label } };
  }
};

type PageViewEvent = {
  event: "page_view";
  pagePath: string;
};

type InViewEvent = {
  event: "inView";
  label: string;
};

type ClickEvent = {
  event: "click";
  label: string;
};

export type DataLayerType = PageViewEvent | InViewEvent | ClickEvent;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dataLayer = ((window as any).dataLayer = (window as any).dataLayer || []);

const initGtm = (tagId: string) => {
  dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  const first = document.getElementsByTagName("script")[0];
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://www.googletagmanager.com/gtm.js?id=" + tagId;
  first.parentNode?.insertBefore(script, first);
};

if (location.hostname === "localhost") {
  initGtm("GTM-KQPKJBT"); // dev
} else {
  initGtm("GTM-NX5F58K"); // prod
}

export const pushGtm = (data: DataLayerType): void => {
  dataLayer.push(data);
};

import { StatResponse } from "./stat";

export type DataFrame<T> = {
  columns: string[];
  rows: string[];
  pickValue(rowName: string, columnName: string): T | null;
  column(columnName: string, label?: string): Column<T> | undefined;
  row(rowName: string, label?: string): Row<T> | undefined;
  label?: string;
};

export type Column<T> = {
  rows: string[];
  get values(): T[];
  pickValue(rowName: string): T | null;
  label?: string;
};

export type Row<T> = {
  columns: string[];
  get values(): T[];
  pickValue(columnName: string): T | null;
  label?: string;
};

const reverseMap = (list: string[]) => {
  return Object.fromEntries(list.map((value, __index) => [value, __index]));
};

const convertToNull = <T>(value: T) => {
  return value != null ? value : null;
};

export class ArrayDataFrame<T> implements DataFrame<T> {
  columns: string[];
  columnsReverse: { [K in string]: number };
  rows: string[];
  rowReverse: { [K in string]: number };
  data: T[];
  label?: string;

  constructor(params: {
    columns: string[];
    index: string[];
    data: T[];
    label?: string;
  }) {
    this.columns = params.columns;
    this.columnsReverse = reverseMap(params.columns);
    this.rows = params.index;
    this.rowReverse = reverseMap(params.index);
    this.data = params.data;
    this.label = params.label;
  }

  row(rowName: string, label?: string): Row<T> {
    return getRow(this, rowName, label);
  }

  column(columnName: string, label?: string) {
    const column = {
      dataFrame: this,
      rows: this.rows,
      get values() {
        const rowLength = this.rows.length;
        const columnIndex = this.dataFrame.columnsReverse[columnName];
        return this.dataFrame.data.slice(columnIndex * rowLength, rowLength);
      },
      pickValue(rowName: string) {
        return this.dataFrame.pickValue(rowName, columnName);
      },
      label: label || columnName,
    };
    return column as Column<T>;
  }

  pickValue(rowName: string, columnName: string) {
    return this.data[
      this.rowReverse[rowName] * this.columns.length +
        this.columnsReverse[columnName]
    ];
  }
}

export class ColumnAggregatedDataFrame<T> implements DataFrame<T> {
  columns: string[];
  rows: string[];
  columnMap: Map<string, Column<T> | undefined>;
  constructor(columnes: Column<T>[]) {
    this.columnMap = new Map(
      columnes.map((column) => [column.label as string, column] as const)
    );
    this.columns = Array.from(this.columnMap.keys()).sort();
    this.rows = Array.from(
      new Set(
        ([] as string[]).concat(
          ...columnes.map((column) => column.rows.map((rowName) => rowName))
        )
      )
    );
  }

  row(rowName: string, label?: string): Row<T> {
    return getRow(this, rowName, label);
  }

  column(columnName: string) {
    return this.columnMap.get(columnName);
  }

  pickValue(rowName: string, columnName: string) {
    const column = this.columnMap.get(columnName);
    const value = column?.pickValue(rowName);
    return convertToNull(value);
  }
}

export class RowAggregatedDataFrame<T> implements DataFrame<T> {
  columns: string[];
  rows: string[];
  rowMap: Map<string, Row<T> | undefined>;
  constructor(rows: Row<T>[]) {
    this.rowMap = new Map(
      rows.map((row) => [row.label as string, row] as const)
    );
    this.rows = Array.from(this.rowMap.keys()).sort();
    this.columns = Array.from(
      new Set(([] as string[]).concat(...rows.map((row) => row.columns)))
    );
  }

  row(rowName: string, label?: string): Row<T> {
    return getRow(this, rowName, label);
  }

  column(columnName: string, label?: string): Column<T> {
    return getColumn(this, columnName, label);
  }

  pickValue(rowName: string, columnName: string) {
    const row = this.rowMap.get(rowName);
    const value = row?.pickValue(columnName);
    return convertToNull(value);
  }
}

const getColumn = <T>(
  dataFrame: DataFrame<T>,
  columnName: string,
  label?: string
) => {
  const column = {
    dataFrame,
    rows: dataFrame.rows,
    get values() {
      return dataFrame.rows.map((rowName) => {
        return dataFrame.pickValue(rowName, columnName);
      });
    },
    pickValue(rowName: string) {
      return dataFrame.pickValue(rowName, columnName);
    },
    label: label || columnName,
  };
  return column as Column<T>;
};

const getRow = <T>(
  dataFrame: DataFrame<T>,
  rowName: string,
  label?: string
) => {
  return {
    dataFrame,
    columns: dataFrame.columns,
    get values() {
      return dataFrame.columns.map((columnName) => {
        return dataFrame.pickValue(rowName, columnName);
      });
    },
    pickValue(columnName: string) {
      return dataFrame.pickValue(rowName, columnName);
    },
    label: label || rowName,
  } as Row<T>;
};

export class RecordDataFrame<T> implements DataFrame<T> {
  columns: string[];
  rows: string[];
  records: Record<string, Record<string, T>>;
  label?: string;

  constructor(params: {
    records: (readonly [string, Record<string, T>])[];
    label?: string;
  }) {
    this.records = Object.fromEntries(params.records);
    this.label = params.label;
    this.rows = params.records.map(([rowName]) => rowName);
    this.columns = Array.from(
      new Set(
        ([] as string[]).concat(
          ...params.records.map(([_, record]) => Object.keys(record))
        )
      )
    );
  }

  row(rowName: string, label?: string): Row<T> {
    return getRow(this, rowName, label);
  }

  column(columnName: string, label?: string): Column<T> {
    return getColumn(this, columnName, label);
  }

  pickValue(rowName: string, columnName: string) {
    return convertToNull(this.records[rowName]?.[columnName]);
  }
}

import { getMultiLocaleValues } from "utils/locale";
import { parse } from "papaparse";
import { ObjectSchema } from "schemaComponents";
import { dayjs } from "utils/dayjs";

export class ParseError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ParseError";
  }
}

export function parseExcelDate(
  dateString: string,
  mode: "date" | "month" | "datetime"
) {
  const formats = (() => {
    if (mode === "date") return [/(\d{4})[-/](\d{1,2})[-/](\d{1,2})/];
    if (mode === "month") return [/(\d{4})[-/](\d{1,2})/];
    if (mode === "datetime")
      return [
        /(\d{4})[-/](\d{1,2})[-/](\d{1,2}) +(\d{1,2}):(\d{2}):(\d{2})/,
        /(\d{4})[-/](\d{1,2})[-/](\d{1,2}) +(\d{1,2}):(\d{2})/,
        /(\d{4})[-/](\d{1,2})[-/](\d{1,2})/,
        /(\d{4})[-/](\d{1,2})/,
      ];
    return [];
  })();
  for (const format of formats) {
    const match = dateString.match(format);
    if (match) {
      const year = parseInt(match[1], 10);
      const month = parseInt(match[2], 10) - 1;
      const day = parseInt(match[3], 10) || 1;
      const hour = parseInt(match[4], 10) || 0;
      const minute = parseInt(match[5], 10) || 0;
      const second = parseInt(match[6], 10) || 0;
      const date = dayjs.tz(
        { year, month, day, hour, minute, second },
        "Asia/Tokyo"
      );
      return date.valueOf() || null;
    }
  }
  return null;
}

export const isNumberLike = (value: unknown): value is number | string => {
  return (
    (typeof value === "string" && /^-?\d+(\.\d+)?$/.test(value)) ||
    typeof value === "number"
  );
};

export const parseCsv = (text: string, schema?: ObjectSchema) => {
  const parsedCsv = parse(text, { header: true, skipEmptyLines: "greedy" });
  const lines = parsedCsv.data;
  const header = parsedCsv.meta.fields;
  if (!header) throw new Error();
  const persed = lines.map((line) => {
    let obj = {};
    const arrayLine = Object.values(line as Record<string, unknown>);
    arrayLine.forEach((item, index) => {
      const key = header[index];
      const keyComponents = header[index].split(".");
      // eslint-disable-next-line no-constant-condition
      if (true) {
        const property = schema?.properties?.find(
          (property) => property.propertyName === keyComponents[0]
        );
        if (!property) return;
        if (
          property.schema.schemaType === "datetime" ||
          property.schema.schemaType === "date" ||
          property.schema.schemaType === "month"
        ) {
          if (!item) {
            obj = { ...obj, [key]: null };
          } else if (typeof item === "string") {
            const value = parseExcelDate(item, property.schema.schemaType);
            if (value !== null) {
              obj = { ...obj, [key]: value };
            } else {
              throw new ParseError("日付のフォーマットが不正です");
            }
          } else {
            throw new ParseError("日付のフォーマットが不正です");
          }
        } else if (property.schema.schemaType === "boolean") {
          const str = `${item}`;
          if (!item) {
            obj = { ...obj, [key]: null };
          } else if (str.toLowerCase() === "false" || str === "0") {
            obj = { ...obj, [key]: false };
          } else if (str.toLowerCase() === "true" || str === "1") {
            obj = { ...obj, [key]: true };
          } else {
            throw new ParseError("論理値のフォーマットが不正です");
          }
        } else if (property.schema.schemaType === "selector") {
          if (!item) {
            obj = { ...obj, [key]: null };
          } else {
            const option = property.schema.options.find(
              // 数値と文字列を区別しないで比較する
              // eslint-disable-next-line eqeqeq
              (option) => option.value == item
            );
            if (option) {
              obj = { ...obj, [key]: item };
            } else {
              throw new ParseError("選択肢のフォーマットが不正です");
            }
          }
        } else if (
          property.schema.schemaType === "text" ||
          property.schema.schemaType === "multilineText" ||
          property.schema.schemaType === "externalKey" ||
          property.schema.schemaType === "image"
        ) {
          obj = { ...obj, [key]: item || null };
        } else if (property.schema.schemaType === "number") {
          if (isNumberLike(item)) {
            const value = Number(item);
            obj = { ...obj, [key]: value };
          } else {
            throw new ParseError("数値のフォーマットが不正です");
          }
        } else if (
          property.schema.schemaType === "textIntl" ||
          property.schema.schemaType === "multilineTextIntl"
        ) {
          const locale = keyComponents[1] || "ja";
          const key = keyComponents[0] as keyof typeof obj;
          const oldValue = obj[key];
          if (oldValue || item) {
            const newValue = getMultiLocaleValues({
              ...(typeof oldValue === "string" ? { ja: oldValue } : oldValue),
              [locale]: item,
            });
            obj = { ...obj, [key]: newValue };
          } else {
            obj = { ...obj, [key]: null };
          }
        } else if (property.schema.schemaType === "array") {
          // if (!item) return;
          const subSchema = property.schema.item as ObjectSchema;
          const keyValue = keyComponents[0];
          const arrayIndex = (keyComponents[1] as unknown as number) - 1; // 1originなので-1する
          const subKeyValue = keyComponents[2];
          const subSchemaProperty = subSchema.properties?.find(
            (property) => property.propertyName === subKeyValue
          );
          const oldValue = (obj[keyComponents[0] as keyof typeof obj] ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            []) as any[];

          if (Array.isArray(oldValue) && oldValue[arrayIndex]) {
            const newValue = oldValue.map((oldItem, oldIndex) => {
              if (oldIndex === arrayIndex) {
                return {
                  ...oldItem,
                  [subKeyValue]: item
                    ? subSchemaProperty?.schema.schemaType === "number" ||
                      subSchemaProperty?.schema.schemaType === "float"
                      ? Number(item)
                      : item
                    : null,
                };
              }
              return oldItem;
            });
            obj = {
              ...obj,
              [keyValue]: newValue,
            };
          } else {
            obj = {
              ...obj,
              [keyValue]: [
                ...oldValue,
                {
                  [subKeyValue]:
                    subSchemaProperty?.schema.schemaType === "number" ||
                    subSchemaProperty?.schema.schemaType === "float"
                      ? Number(item)
                      : item,
                },
              ],
            };
          }
        }
      }
    });
    return Object.keys(obj).length !== 0 ? obj : undefined;
  });
  return persed
    .map((item) => {
      for (const [key, value] of Object.entries(item || {})) {
        if (value && Array.isArray(value)) {
          const filteredEmptyArray = filterEmptyObjects(value);
          item = { ...item, [key]: filteredEmptyArray };
        }
      }
      return item;
    })
    .filter((item) => item);
};

// 配列から空のオブジェクトをフィルタリングする
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterEmptyObjects(arr: any[]): any[] {
  const filteredArray = arr.filter((obj) => {
    // オブジェクトかどうかを確認し、少なくとも 1 つのプロパティが truthy であるかどうかをチェックする
    if (typeof obj !== "object" || obj === null) {
      return false;
    }
    return Object.values(obj).some((value) => value);
  });
  return filteredArray;
}

/**
 * https://personal.sron.nl/~pault/#fig:scheme_bright
 */

export const muted = [
  "#332288",
  "#CC6677",
  "#DDCC77",
  "#117733",
  "#88CCEE",
  "#882255",
  "#44AA99",
  "#999933",
  "#AA4499",
];

export const orange = [
  "#4DC0CF",
  "#EA5766",
  "#DDCC77",
  "#117733",
  "#88CCEE",
  "#882255",
  "#44AA99",
  "#999933",
  "#AA4499",
];

export const orange2 = [
  "#234E52",
  "#2C7A7B",
  "#38B2AC",
  "#81E6D9",
  "#E6FFFA",
  "#BAE3FD",
  "#38B4F8",
  "#0273BB",
];

const colorToVector = (colorCode: string) => {
  const [_, r, g, b] =
    colorCode.match(/#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})/) || [];
  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)] as const;
};

const vectorToColor = (vector: number[]) => {
  return (
    "#" +
    vector
      .map((value) => {
        return Math.floor(value).toString(16);
      })
      .join("")
  );
};

const rgbToHex = (vector: number[]) => {
  const [r, g, b] = vector;
  return (
    "#" +
    ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()
  );
};

const mixColor = (a: string, b: string, ratio = 0.5) => {
  const _a = colorToVector(a);
  const _b = colorToVector(b);
  return rgbToHex(
    Array(3)
      .fill(0)
      .map((_, index) => {
        return _a[index] * ratio + _b[index] * (1 - ratio);
      })
  );
};

// 開始と終了のカラーコード間のカラーグラデーションを生成
const generateGradient = (startHex: string, endHex: string, steps: number) => {
  if (steps === 1) return [startHex];
  return Array.from({ length: steps })
    .map((_, i) => {
      const factor = i / (steps - 1);
      return mixColor(startHex, endHex, factor);
    })
    .reverse();
};

export const generateGoodBadGradient = (
  goodStart: string,
  goodEnd: string,
  badStart: string,
  badEnd: string,
  totalSteps: number,
  switchStep: number
) => {
  const goodSteps = switchStep;
  const badSteps = totalSteps - switchStep;
  const goodGradient = generateGradient(goodStart, goodEnd, goodSteps);
  const badGradient = generateGradient(badStart, badEnd, badSteps);
  return [...goodGradient, ...badGradient].reverse();
};

const generateBarGraphColors = (
  totalSteps: number,
  colors: string[][],
  thresholds?: number[]
): string[] => {
  const generatedColors: string[] = [];

  if (!thresholds) {
    const stepsPerColor = Math.floor(totalSteps / colors.length);
    thresholds = colors.map((_, index) => (index + 1) * stepsPerColor);
    // 最後の閾値を調整してtotalStepsに合わせる
    thresholds[thresholds.length - 1] = totalSteps;
  }

  for (let index = 0; index < thresholds.length; index++) {
    const threshold = thresholds[index];
    const prevThreshold = index === 0 ? 0 : thresholds[index - 1];
    const colorSegmentLength = threshold - prevThreshold;
    const gradient = generateGradient(
      colors[index][0],
      colors[index][1],
      colorSegmentLength
    );
    generatedColors.push(...gradient);
  }
  return generatedColors;
};

export const generateCustomBarGraphColors = (totalSteps: number): string[] => {
  switch (totalSteps) {
    case 11:
      return generateBarGraphColors(
        totalSteps,
        [red700, red200, teal200, teal500, teal700],
        [7, 8, 9, 10, 11]
      );
    case 7:
      return generateBarGraphColors(
        totalSteps,
        [redGradient, pinkGradient, greenGradient],
        [4, 5, 7]
      );
    case 5:
      return generateBarGraphColors(
        totalSteps,
        [redGradient, pinkGradient, greenGradient],
        [2, 3, 5]
      );
    default:
      return generateBarGraphColors(totalSteps, [
        redGradient,
        pinkGradient,
        greenGradient,
      ]);
  }
};

const opacity = 0.3;

const redGradient = ["#D22D52", "#D22D52"];
const pinkGradient = ["#F3CEDB", "#F3CEDB"];
const greenGradient = ["#D3EEDD", "#4EAD5B"];

const red700 = ["#C41111", "#C41111"];
const red200 = ["#FFC6C6", "#FFC6C6"];
const teal200 = ["#81E6D9", "#81E6D9"];
const teal500 = ["#319795", "#319795"];
const teal700 = ["#285E61", "#285E61"];

export const firstEmphasized = [
  orange2[0],
  mixColor(orange2[1], "#ffffff", opacity),
  mixColor(orange2[2], "#ffffff", opacity),
  mixColor(orange2[3], "#ffffff", opacity),
  mixColor(orange2[4], "#ffffff", opacity),
  mixColor(orange2[5], "#ffffff", opacity),
  mixColor(orange2[6], "#ffffff", opacity),
  mixColor(orange2[7], "#ffffff", opacity),
];

console.log({ firstEmphasized });

export const bright = [
  "#4477AA",
  "#EE6677",
  "#228833",
  "#CCBB44",
  "#66CCEE",
  "#AA3377",
];

export const highContrast = ["#004488", "#DDAA33", "#BB5566"];

export const vibrant = [
  "#EE7733",
  "#0077BB",
  "#33BBEE",
  "#EE3377",
  "#CC3311",
  "#009988",
];

export const pale = ["#BBCCEE", "#CCEEFF", "#CCDDAA", "#EEEEBB", "#FFCCCC"];

export const light = [
  "#77AADD",
  "#EE8866",
  "#EEDD88",
  "#FFAABB",
  "#99DDFF",
  "#44BB99",
  "#BBCC33",
  "#AAAA00",
];

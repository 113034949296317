export interface DocumentData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [field: string]: any;
}

interface FieldValue {
  isEqual(other: FieldValue): boolean;
}

type Primitive = string | number | boolean | undefined | null;

export type WithFieldValue<T> =
  | T
  | (T extends Primitive
      ? T
      : // eslint-disable-next-line @typescript-eslint/ban-types
      T extends {}
      ? {
          [K in keyof T]: WithFieldValue<T[K]> | FieldValue;
        }
      : never);

export type PartialWithFieldValue<T> =
  | Partial<T>
  | (T extends Primitive
      ? T // eslint-disable-next-line @typescript-eslint/ban-types
      : T extends {}
      ? {
          [K in keyof T]?: PartialWithFieldValue<T[K]> | FieldValue;
        }
      : never);

export type OrderByDirection = "desc" | "asc";

export interface FieldPath {
  isEqual(other: FieldPath): boolean;
}

export const OperatorMap = {
  $gt: ">",
  $gte: ">=",
  $eq: "==",
  $ne: "!=",
  $lt: "<",
  $lte: "<=",
  $in: "in",
  $elemMatch: "array-contains",
  ">": ">",
  ">=": ">=",
  "==": "==",
  "<": "<",
  "<=": "<=",
  in: "in",
  "array-contains": "array-contains",
  "array-contains-any": "array-contains-any",
};

export type ArrayOperator = "$in" | "in" | "array-contains-any";

export type OperatorMapType = typeof OperatorMap;
export type Operator = keyof OperatorMapType;
export type FilterFieldParams =
  | {
      [O in Operator]?: O extends ArrayOperator
        ? string[] | number[] | boolean[]
        : string | number | boolean;
    }
  | string
  | number
  | boolean
  | undefined;
export type FilterParams = { [K in string]: FilterFieldParams };
export type QueryParams = {
  filter?: FilterParams;
  documentId?: FilterFieldParams;
  orderBy?: [string | FieldPath, OrderByDirection | undefined][];
  limit?: number;
  limitToLast?: number;
  idKey?: string;
};

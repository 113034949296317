import React from "react";
import { formatNumber, TableSchema, withSign } from "../statSchemaUtil";
import { getValueColor } from "../valueColor";
import { StatConfig } from "./type";

export const rank = ({
  isClientData,
}: {
  isClientData: boolean;
}): StatConfig => {
  return {
    action: "rank",
    sequence: true,
    statSchemas: [
      {
        value: "list-shop-in-client-value",
        title: "",
      },
      {
        value: "list-shop-in-all-tscore",
        title: "社内偏差値",
        multiple: {
          source: ["list-shop-in-all-tscore"],
          element: ({ "list-shop-in-all-tscore": value }) => {
            return (
              <td
                style={{
                  background: getValueColor(value, 50, 2),
                  textAlign: "right",
                }}
              >
                {formatNumber(value, 1)}
              </td>
            );
          },
        },
      },
    ],
  };
};

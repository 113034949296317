import { defineCollection } from "../comodel-firestore";
import { DateRange, StringIntl } from "./type";

export type rewardData = {
  rewardId: string;
  clientId: string;
  createdAt: number;
  rewardName: string;
  animationId: number;
  expirationPeriod: number;
  couponDescription: StringIntl;
};

export const rewardDefinition = defineCollection({
  collectionName: "reward",
  idKey: "rewardId",
  constraints: ["clientId"],
  queryDefs: {
    list: ({
      clientId,
      createdAt,
      condition,
    }: {
      clientId: string;
      createdAt?: DateRange;
      condition?: Record<string, unknown>;
    }) => {
      return {
        constraint: { clientId },
        queryParams: {
          filter: { clientId, createdAt },
          orderBy: [["createdAt", "desc"]],
        },
        filterParams: condition,
      };
    },
  },
  defaultValue: {} as rewardData,
  onGenerate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});

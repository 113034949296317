import React from "react";
import { Schema } from "schemaComponents";

export default ({
  isEditing,
  isMaster,
}: {
  isEditing: boolean;
  isMaster: boolean;
}): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "リワード名",
        propertyName: "rewardName",
        schema: {
          schemaType: "textIntl",
          required: true,
        },
      },
      {
        title: "有効期間（日）",
        propertyName: "expirationPeriod",
        schema: {
          schemaType: "number",
          required: true,
        },
      },
      ...(isMaster
        ? [
            {
              title: "抽選アニメーション",
              propertyName: "animationId",
              schema: {
                schemaType: "selector",
                uiType: "normal",
                options: [
                  {
                    title: "1等 (ラッキーセブン)",
                    value: 1,
                  },
                  {
                    title: "2等 (赤い果実)",
                    value: 2,
                  },
                  {
                    title: "3等 (ベル)",
                    value: 3,
                  },
                  {
                    title: "4等 (青い果実)",
                    value: 4,
                  },
                  {
                    title: "5等 (オレンジ)",
                    value: 5,
                  },
                  {
                    title: "はずれ（そろわない）",
                    value: 0,
                  },
                ],
              },
            },
          ]
        : []),
      {
        title: "クーポン追加文言",
        propertyName: "couponDescription",
        schema: {
          schemaType: "multilineTextIntl",
        },
      },
      ...(isMaster
        ? [
            {
              title: "clientId",
              propertyName: "clientId",
              schema: {
                schemaType: "text",
                readOnly: true,
                required: true,
              },
            },
          ]
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([] as any[])),
      {
        title: "rewardId",
        propertyName: "rewardId",
        schema: {
          schemaType: "text",
          readOnly: true,
        },
      },
    ],
  };
};

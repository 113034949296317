import React, { ElementType, useEffect, useMemo, useState } from "react";
import firebaseApp from "firebaseApp";
import { getAuth } from "firebase/auth";
import { decodeSrc, removeKey } from "./util";

const auth = getAuth(firebaseApp);

export type MediaSpecifier = string | undefined;

export type MediaProps<P> = Pick<P, Exclude<keyof P, "src">> & {
  src: MediaSpecifier;
  elementType: ElementType<P>;
};

export const Media = <P extends { src?: string }>(props: MediaProps<P>) => {
  const { src, elementType } = props;
  const metadata = useMetadata(src);
  const copiedProps = removeKey({ ...props }, "elementType");
  const Element = elementType as ElementType<{ src?: string }>;
  return metadata ? <Element {...copiedProps} src={metadata.url} /> : <></>;
};

export const MediaWithMimeType = <
  P extends { src?: string; mimeType?: string }
>(
  props: MediaProps<P>
) => {
  const { src, elementType } = props;
  const metadata = useMetadata(src);
  const copiedProps = removeKey({ ...props }, "elementType");
  const Element = elementType as ElementType<{ src?: string }>;
  return metadata ? (
    <Element
      {...copiedProps}
      src={metadata.url}
      mimeType={metadata.contentType}
    />
  ) : (
    <></>
  );
};

export const useMetadata = (src?: MediaSpecifier) => {
  const [metadata, setMetadata] = useState<
    | {
        url?: string;
        contentType?: string;
      }
    | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      const metadata = await getMetadata(src);
      setMetadata(metadata);
    })();
  }, [src]);
  return metadata;
};

export const getMetadata = async (
  src: MediaSpecifier
): Promise<{ url: string; contentType?: string } | undefined> => {
  if (!src) {
    return undefined;
  }
  const parsedUrl = decodeSrc(src);
  const { protocol, path, host, url, contentType } = parsedUrl;
  if (["http", "https", "data"].includes(protocol as string) && url) {
    return { url, contentType };
  } else if (protocol === "gs" && path && host) {
    return getGoogleStorageMetadata({ path, host });
  } else {
    throw new Error("invalid protocol");
  }
};

export const getFile = async (src: MediaSpecifier) => {
  const metadata = await getMetadata(src);
  if (!metadata) {
    return undefined;
  }
  const { url } = metadata;
  const response = await fetch(url);
  return response.blob();
};

const getGoogleStorageMetadata = async ({
  path,
  host,
}: {
  path: string;
  host: string;
}) => {
  const objectUrl = `https://firebasestorage.googleapis.com/v0/b/${host}/o/${encodeURIComponent(
    path.replace(/^\//, "")
  )}`;
  const response = await fetch(objectUrl, {
    headers: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      authorization: `Firebase ${(auth?.currentUser as any)?.accessToken}`,
    },
  });
  const metaData: { downloadTokens: string; contentType: string } =
    await response.json();
  const { downloadTokens, contentType } = metaData;
  const url = `${objectUrl}?alt=media&token=${downloadTokens}`;
  return { url, contentType };
};

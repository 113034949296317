import {
  BlockToolConstructorOptions,
  BlockTune,
  API,
  BlockAPI,
} from "@editorjs/editorjs";

type ClipboardToolConfig = {
  copy: (data: unknown) => void;
  paste: () => Promise<unknown>;
};

export class ClopboardTool implements BlockTune {
  static isReadOnlySupported = true;
  static get isTune() {
    return true;
  }

  params: BlockToolConstructorOptions<
    Record<string, never>,
    ClipboardToolConfig
  >;

  copy: (data: unknown) => void;
  paste: () => Promise<unknown>;
  api: API;
  block: BlockAPI;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(params: any) {
    this.params = params;
    this.copy = params.config.copy;
    this.paste = params.config.paste;
    this.api = params.api;
    this.block = params.block;
  }

  render() {
    const settings = [
      {
        tooltip: "コピー",
        name: "copy",
        icon: '<i class="mdi mdi-content-copy" style="font-size: 1.5rem" />',
        onclick: async () => {
          console.log("copy", this.block, this.api);
          const blockData = await this.block.save();
          if (!blockData) return;
          console.log("copy", blockData);
          this.copy({
            id: blockData.id,
            type: blockData.tool,
            data: blockData.data,
          });
        },
      },
      {
        tooltip: "貼り付け",
        name: "paste",
        icon: '<i class="mdi mdi-content-paste" style="font-size: 1.5rem" />',
        onclick: async () => {
          const data = (await this.paste()) as {
            id: string;
            type: string;
            data: unknown;
          };
          if (!data) return;
          const index = this.api.blocks.getBlockIndex(this.block.id);
          this.api.blocks.insert(
            data.type,
            data.data,
            {},
            index + 1,
            true,
            false
          );
        },
      },
    ];
    const wrapper = document.createElement("div");
    settings.forEach((tune) => {
      const button = document.createElement("div");
      button.classList.add("cdx-settings-button");
      button.setAttribute("data-tooltip-id", "tooltip");
      button.setAttribute("data-tooltip-content", tune.tooltip);
      button.innerHTML = tune.icon;
      button.onclick = tune.onclick;
      wrapper.appendChild(button);
    });
    return wrapper;
  }
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { getAdminUser } from "functions";
import { Alert } from "react-bootstrap";
import { useAdminUserDoc } from "models/hook";
import { resolveRoute } from "pages";
import { ADMIN_USER, ADMIN_USERS } from "..";
import getSchema from "./schema";
import { PreviewComponent } from "./preview";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const CREATE_ADMIN_USER = ADMIN_USERS.sub("/new", AdminUser);
export const UPDATE_ADMIN_USER = ADMIN_USER.sub("", AdminUser);

function AdminUser({ adminUserId }: { adminUserId?: string }) {
  useAdminPageAuthorization();
  const [error, setError] = useState<
    { message: string | JSX.Element } | undefined
  >();
  const { data, loading, set, remove, isEditing } = useAdminUserDoc({
    adminUserId,
  });
  const { set: setForNewItem } = useAdminUserDoc({});
  const parameter: SchemaFormParameter = {
    title: isEditing ? "管理ユーザ詳細" : "管理ユーザ新規作成",
    schema: getSchema({ isEditing }),
    edit: {
      title: `${isEditing ? "保存" : "保存"}`,
      confirm: `${isEditing ? "保存" : "保存"}しますか？`,
      handler: async (value) => {
        if (isEditing) {
          await set(value);
        } else {
          // Check if the user exists on Firebase Authentication
          const { uid } =
            (await getAdminUser({ email: value.initialEmail })) || {};
          if (uid) {
            await setForNewItem(
              { ...value, adminUserId: uid },
              (data, oldData) => {
                if (oldData) {
                  setError({
                    message: (
                      <span>
                        同じメールアドレスのユーザが存在します。編集する場合は
                        <Link
                          onClick={() => setError(undefined)}
                          to={resolveRoute("UPDATE_ADMIN_USER", {
                            adminUserId: uid,
                          })}
                        >
                          こちら
                        </Link>
                        をクリックしてください
                      </span>
                    ),
                  });
                  throw new Error(
                    "An account binded to the specified email already exists"
                  );
                }
                return data;
              }
            );
          } else {
            // new user (both authentication and firestore)
            await set(value);
          }
        }
      },
    },
    back: {},
    remove: isEditing ? { handler: remove } : undefined,
    preview: {
      title: "ユーザ認証情報",
      component: PreviewComponent,
    },
    backPath: resolveRoute("LIST_ADMIN_USER"),
    header: {
      isStacked: true,
    },
  };
  return (
    <>
      {error && <Alert variant="danger">{error.message}</Alert>}
      <SchemaFormPage
        data={data}
        parameter={parameter}
        loading={!!(isEditing && loading)}
        editModeEnabled={true}
      />
    </>
  );
}

export default AdminUser;

import React from "react";
import {
  SchemaComponent,
  useField,
  requiredValidator,
  BaseSchema,
} from "react-hook-schema-form";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import { Multimedia, MediaSpecifier } from "./media";
import { Upload } from "./media/upload";

interface MultimediaSchema extends BaseSchema {
  schemaType: "multimedia";
  required?: boolean;
  rootPath?: string;
}

const MultimediaSchemaComponent: SchemaComponent<MultimediaSchema> = (
  props
) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  const { schema } = props;
  const { rootPath } = schema;
  return (
    <>
      <FieldWrapper fieldState={fieldState}>
        <Upload
          {...registerProps}
          value={value}
          rootPath={rootPath}
          hasError={fieldState.invalid}
        />
      </FieldWrapper>
      <Multimedia style={{ border: "1px solid #ccc" }} src={value} />
    </>
  );
};

MultimediaSchemaComponent.display = DisplayComponent(
  "MultimediaDisplayComponent",
  (value: MediaSpecifier) => {
    return <Multimedia style={{ backgroundColor: "#888" }} src={value} />;
  }
);

export default MultimediaSchemaComponent;

import React from "react";
import { Media, MediaSpecifier } from "./media";
import { Overwrite } from "./types";

type ElementProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const Image = (
  props: Overwrite<ElementProps, { src: MediaSpecifier }>
) => {
  return <Media<ElementProps> {...props} elementType="img" />;
};

import React from "react";
import { Schema } from "schemaComponents";
import { availableLocaleOptions } from "utils/locale";

const getSchema = ({
  enquete,
  clientId,
  isMaster,
}: {
  clientId: string;
  enquete: Schema;
  isMaster: boolean;
}): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "事業所名",
        propertyName: "shopId",
        schema: {
          schemaType: "externalKey",
          resourcePath: "/shop",
          titleKey: "shopName",
          query: { filter: { clientId } },
          readOnly: true,
        },
      },
      {
        title: "作成日時",
        propertyName: "createdAt",
        schema: { schemaType: "datetime", readOnly: true },
      },
      {
        title: "抽選結果",
        propertyName: "lottery",
        schema: {
          schemaType: "selector",
          readOnly: true,
          options: [
            { title: "当選", value: "win" },
            { title: "落選", value: "lose" },
            { title: "重複", value: "duplicate" },
          ],
        },
      },
      {
        title: "リワード内容",
        propertyName: "rewardId",
        schema: {
          schemaType: "externalKey",
          uiType: "normal",
          resourcePath: "/reward",
          titleKey: "rewardName",
          query: { filter: { clientId } },
          readOnly: true,
        },
      },
      {
        schema: {
          schemaType: "message",
          text: <hr />,
        },
      },
      {
        title: "アンケート結果",
        propertyName: "data",
        noLabel: true,
        schema: {
          ...enquete,
          readOnly: true,
        },
      },
      {
        schema: {
          schemaType: "message",
          text: <hr />,
        },
      },
      {
        title: "アンケートID",
        propertyName: "enqueteId",
        schema: { schemaType: "text", readOnly: true },
      },
      {
        title: "キャンペーンID",
        propertyName: "campaignId",
        schema: { schemaType: "text", readOnly: true },
      },
      {
        title: "言語",
        propertyName: "locale",
        schema: {
          schemaType: "selector",
          options: availableLocaleOptions,
          readOnly: true,
        },
      },
      {
        title: "クーポンID",
        propertyName: "couponId",
        schema: { schemaType: "text", readOnly: true },
      },
      {
        title: "調査",
        propertyName: "surveyId",
        schema: {
          readOnly: true,
          uiType: "normal",
          schemaType: "externalKey",
          resourcePath: "/survey",
          titleKey: "surveyName",
          query: { filter: { clientId } },
        },
      },

      ...(isMaster
        ? [
            {
              title: "User ID",
              propertyName: "userId",
              schema: { schemaType: "text", readOnly: true },
            },
            {
              title: "無視",
              propertyName: "ignore",
              schema: { schemaType: "boolean" },
            },
            {
              title: "Coupon URL",
              propertyName: "couponUrl",
              schema: {
                schemaType: "url",
                readOnly: true,
              },
            },
            {
              title: "Coupon Link",
              propertyId: "couponLink",
              schema: {
                schemaType: "message",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                text: ({ value }: { value: any }) => {
                  const url =
                    value?.couponId &&
                    value?.couponUrl?.replace(":id", value.couponId);
                  return url ? (
                    <a
                      style={{ wordBreak: "break-all" }}
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {url}
                    </a>
                  ) : (
                    ""
                  );
                },
              },
            },
            {
              title: "E-Mail",
              propertyName: "email",
              schema: { schemaType: "text", readOnly: true },
            },
            {
              title: "抽選アニメーション視聴完了",
              propertyName: "movieFinished",
              schema: { schemaType: "boolean" },
            },
            {
              title: "ユーザエージェント",
              propertyName: "userAgent",
              schema: { schemaType: "text", readOnly: true },
            },
            {
              title: "clientId",
              propertyName: "clientId",
              schema: {
                schemaType: "text",
                readOnly: true,
              },
            },
          ]
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([] as any[])),
    ],
  };
};
export default getSchema;

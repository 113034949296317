import EventEmitter from "events";
import { useEffect, useState } from "react";

// forceDefaultがtrueの場合はstorageから取得せず、defaultValueを返す
// 変更された時に、storageに反映させる。

const createStorageHook = (storage?: Storage) => {
  const emitter = new EventEmitter();
  return <T>(key: string, defaultValue: T, forceDefault?: boolean) => {
    const [value, setValue] = useState<T>(() => {
      if (!forceDefault) {
        const value = storage?.getItem(key);
        if (value) {
          return JSON.parse(value);
        }
      }
      return defaultValue;
    });
    const set = (value: T) => {
      storage?.setItem(key, JSON.stringify(value));
      emitter.emit(key, value);
    };
    useEffect(() => {
      const handler = (value: T) => {
        setValue(value);
      };
      emitter.on(key, handler);
      return () => {
        emitter.off(key, handler);
      };
    });
    storage &&
      useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handler = (e: any) => {
          if (e.key === key && e.storageArea === storage) {
            setValue(e.newValue ? JSON.parse(e.newValue) : undefined);
          }
        };
        addEventListener("storage", handler);
        return () => {
          removeEventListener("storage", handler);
        };
      });
    return [value, set] as const;
  };
};

export const useSessionStorage = createStorageHook(sessionStorage);
export const useLocalStorage = createStorageHook(localStorage);
export const useMemoryStorage = createStorageHook();

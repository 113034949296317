import React, { ReactNode, useState } from "react";
import {
  SchemaComponent,
  useField,
  requiredValidator,
  BaseSchema,
  SchemaComponentProps,
  joinFieldPath,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { Schema, Field } from "..";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { useFieldArray } from "react-hook-form";

interface ArraySchema extends BaseSchema {
  schemaType: "array";
  required?: boolean;
  item: Schema;
  defaultValue?: unknown;
}

const ArraySchemaComponent: SchemaComponent<ArraySchema> = (props) => {
  const { schema, fieldPath, control, readOnly } = props;
  const { fieldState, value } = useField(props, [requiredValidator]);
  const { fields, remove, move, insert, update, append } = useFieldArray({
    control,
    name: fieldPath,
  });
  const [moving, setMoving] = useState<boolean>(false);

  if (schema.readOnly || readOnly) {
    return <ArrayReadOnlySchemaComponent {...props} />;
  }

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    move(oldIndex, newIndex);
    setMoving(false);
  };
  const onSortStart = () => {
    setMoving(true);
  };
  const onAdd = (index: number) => {
    console.log("onAdd", index);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    insert(index, (schema.defaultValue as any) || {});
  };
  const onRemove = (index: number) => {
    remove(index);
    console.log("onRemove", index);
  };
  return (
    <FieldWrapper fieldState={fieldState}>
      <div
        className={classNames(
          "array-wrapper",
          moving && "array-wrapper-moving"
        )}
      >
        <SortableList
          onSortEnd={onSortEnd}
          onSortStart={onSortStart}
          useDragHandle
        >
          <i
            style={{
              fontSize: "1.2em",
              height: 15,
              position: "relative",
              top: 10,
              color: "#0d6efd",
              cursor: "pointer",
            }}
            className="mdi mdi-plus-circle-outline"
            onClick={() => onAdd(0)}
          />
          {fields?.map?.((field, index) => (
            <div key={field.id}>
              <SortableItem
                {...props}
                index={index}
                schema={schema.item}
                fieldPath={joinFieldPath(fieldPath, `${index}`)}
                onRemove={() => onRemove(index)}
              />
              <div
                style={{
                  fontSize: "1.2em",
                  height: 10,
                  position: "relative",
                  top: -9,
                }}
              >
                <i
                  style={{
                    color: "#0d6efd",
                    // zIndex: 1,
                    cursor: "pointer",
                  }}
                  className="mdi mdi-plus-circle-outline"
                  onClick={() => onAdd(index + 1)}
                />
              </div>
            </div>
          ))}
        </SortableList>
      </div>
      {/* <div style={{ width: 400 }}> {JSON.stringify(value)}</div> */}
    </FieldWrapper>
  );
};

const ArrayReadOnlySchemaComponent: SchemaComponent<ArraySchema> = (props) => {
  const { schema, fieldPath, control } = props;
  const { fieldState } = useField(props, [requiredValidator]);
  const { fields } = useFieldArray({
    control,
    name: fieldPath,
  });
  return (
    <FieldWrapper fieldState={fieldState}>
      <div className={classNames("array-wrapper")}>
        {fields?.map?.((field, index) => (
          <div key={field.id}>
            <div className="field-decoration-wrapper" style={{}}>
              <div className="field-decoration-content">
                <Field
                  {...props}
                  schema={schema.item}
                  fieldPath={joinFieldPath(fieldPath, `${index}`)}
                ></Field>
              </div>
            </div>
          </div>
        ))}
      </div>
    </FieldWrapper>
  );
};

export default ArraySchemaComponent;

const DragHandle = SortableHandle(({ children }: { children: ReactNode }) => (
  <>{children}</>
));

const SortableItem = SortableElement(
  (props: SchemaComponentProps & { onRemove: () => void }) => (
    <div className="field-decoration-wrapper" style={{}}>
      <div className="field-decoration" style={{ marginRight: 5 }}>
        <DragHandle>
          <label className="label">
            <span>
              <i
                style={{
                  fontSize: "1.2em",
                  color: "#0d6efd",
                  cursor: "grab",
                }}
                className="mdi mdi-drag-vertical"
              ></i>
            </span>
            <button
              type="button"
              // onClickCapture={() => {}}
              onClickCapture={() => {
                console.log("onClick");
                props.onRemove();
              }}
              style={{
                fontSize: "1.2em",
                color: "#0d6efd",
                cursor: "pointer",
              }}
              className="btn button-icon mdi mdi-close-circle-outline"
            ></button>
          </label>
        </DragHandle>
      </div>
      <div className="field-decoration-content">
        <Field {...props}></Field>
      </div>
    </div>
  )
);

const SortableList = SortableContainer(
  ({ children }: { children: ReactNode }) => {
    return <div style={{ position: "relative" }}>{children}</div>;
  }
);

import React, { ReactNode, useContext, useEffect } from "react";
import { DataFrame, RecordDataFrame } from "functions/dataFrame";
import { formatWithCommas } from "utils/string";
import { valueContainerCSS } from "react-select/dist/declarations/src/components/containers";

export const defaultDigits = 1; // 数値文字列の小数点以下の桁数は1桁固定

export type CalculatorDefinition<V, T extends readonly string[]> = {
  source: T;
  element: (
    values: { [K in T[number]]: number | null },
    index: number
  ) => V | number | null;
};

export type StatSchema<V> = {
  value: string;
  id?: string;
  title: ReactNode | string;
  fixed?: number;
  compareMonth?: (
    value: number | null,
    prev: number | null | undefined
  ) => V | number | null;
  multiple?: CalculatorDefinition<V, string[]>;
  table?: false;
  graph?: true;
  margeRight?: boolean;
};

export type TableSchema = StatSchema<ReactNode>;

export type GraphSchema = StatSchema<never> & {
  graph: true;
  graphColor?: string;
};

export const pickValues = <T extends readonly string[] = readonly string[]>(
  dataFrame: DataFrame<number | null>,
  indexName: string,
  columnNames: T
) => {
  return Object.fromEntries(
    columnNames.map((columnName) => [
      columnName,
      dataFrame.pickValue(indexName, columnName),
    ])
  ) as { [K in T[number]]: number | null };
};

export const resolveValue = (
  currentDataFrame: DataFrame<number | null>,
  prevDataFrame: DataFrame<number | null> | undefined,
  indexName: string,
  statSchema: TableSchema,
  index: number
) => {
  if (statSchema.multiple) {
    const values = pickValues(
      currentDataFrame,
      indexName,
      statSchema.multiple.source
    );
    return statSchema.multiple.element(values, index);
  } else if (statSchema.compareMonth) {
    if (!prevDataFrame) return undefined;
    return statSchema.compareMonth(
      currentDataFrame.pickValue(indexName, statSchema.value),
      prevDataFrame.pickValue(indexName, statSchema.value)
    );
  } else {
    return formatNumber(
      currentDataFrame.pickValue(indexName, statSchema.value),
      statSchema.fixed
    );
  }
};

export const formatNumber = (value: number | null, fixed?: number) => {
  if (value == null) {
    return undefined;
  }
  if (typeof fixed === "number") {
    // カンマ区切りにする
    return value.toLocaleString("en-US", {
      minimumFractionDigits: fixed,
      maximumFractionDigits: fixed,
    });
  }
  if (Number.isInteger(value)) {
    // カンマ区切りにする
    return value.toLocaleString("en-US");
  }
  return value.toPrecision(3);
};

export const formatDist = (freq: number | null, count: number | null) => {
  if (freq == null || count == null) {
    return "";
  }
  const totalCount = count / freq;
  const logPrecision = Math.max(-3, Math.floor(Math.log10(1 / totalCount / 3)));

  return `${formatNumber(
    freq * 100,
    Math.max(0, -logPrecision - 2)
  )}% (${count})`;
};

export const formatPercent = (freq: number | null) => {
  if (freq == null) {
    return "";
  }
  // %表記は小数点以下1桁固定にする
  return `${formatNumber(freq * 100, 1)}%`;
};

export const withSign = (str: string | undefined) => {
  if (str == null) {
    return undefined;
  }
  return str[0] === "-" && str !== "0" ? str : `+${str}`;
};

export const convertDataFrame = (
  current: DataFrame<number | null> | undefined,
  prev: DataFrame<number | null> | undefined,
  statSchemas: TableSchema[]
) => {
  if (!current) {
    return undefined;
  }
  const records = current.rows.map((rowName, rowIndex) => {
    return [
      rowName,
      Object.fromEntries(
        statSchemas.map(
          (statSchema) =>
            [
              statSchema.id || statSchema.value,
              resolveValue(current, prev, rowName, statSchema, rowIndex),
            ] as const
        )
      ),
    ] as const;
  });
  return new RecordDataFrame<ReactNode>({
    records,
    label: current.label,
  }) as DataFrame<ReactNode>;
};

export const getRequiredFields = (statSchemas: TableSchema[]) => {
  const current = new Set<string>();
  const prev = new Set<string>();
  for (const statSchema of statSchemas) {
    const fields = statSchema.multiple?.source || [statSchema.value];
    for (const field of fields) {
      current.add(field);
    }
    if (statSchema.compareMonth) {
      for (const field of fields) {
        prev.add(field);
      }
    }
  }
  return { current: Array.from(current), prev: Array.from(prev) };
};

import React, { ImgHTMLAttributes, MediaHTMLAttributes, useMemo } from "react";
import { removeKey } from "./util";

const handlers = [
  {
    type: "img",
    condition: /^image\//,
  },
  {
    type: (props: MediaHTMLAttributes<HTMLVideoElement>) => (
      <video {...props} controls />
    ),
    condition: /^video\//,
  },
] as const;

export type MultiMediaElementProps = ImgHTMLAttributes<HTMLElement> &
  MediaHTMLAttributes<HTMLElement> & { src?: string; mimeType?: string };

export const MultimediaElement = (props: MultiMediaElementProps) => {
  const { src, mimeType } = props;
  const ElementType = useMemo(() => {
    return typeof mimeType === "string"
      ? handlers.find((handler) => handler.condition.test(mimeType))?.type
      : undefined;
  }, [mimeType]);
  console.log({ src, mimeType, ElementType });
  if (!ElementType) {
    return <></>;
  }
  const copiedProps = removeKey(props, "mimeType");
  return <ElementType {...copiedProps} src={src}></ElementType>;
};

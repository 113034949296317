import { yupValidator } from "react-hook-schema-form";
import { string, setLocale } from "yup";

setLocale({
  string: {
    email: { id: "validation/email" },
  },
  // example
  // number: {
  //   min: ({ min }) => ({ key: "validation/min", values: { min } }),
  //   max: ({ max }) => ({ key: "validation/min", values: { max } }),
  // },
});

export const emailValidator = yupValidator(string().email());

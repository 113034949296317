import { useStringIntl } from "hooks/intl";
import { ObjectSchema, PropertyType } from "schemaComponents";

export const settingSchema = ({
  question,
}: {
  question: ObjectSchema;
}): ObjectSchema => {
  // const s = useStringIntl();
  const fieldOption = [
    {
      title: "抽選結果",
      value: "lottery",
    },
    ...(question.properties.map((property) => {
      return {
        title:
          (property as PropertyType & { statName: string }).statName ||
          property.title,
        value: property.propertyId || property.propertyName || null,
      };
    }) || []),
  ];
  return {
    schemaType: "object",
    properties: [
      // {
      //   title: "分析タイプ",
      //   propertyName: "statType",
      //   schema: {
      //     schemaType: "selector",
      //     options: [
      //       {
      //         title: "単純",
      //         value: "value",
      //       },
      //       {
      //         title: "分布",
      //         value: "dist",
      //       },
      //       {
      //         title: "カウント",
      //         value: "count",
      //       },
      //       {
      //         title: "順位",
      //         value: "rank",
      //       },
      //       // {
      //       {
      //         title: "クロス",
      //         value: "cross",
      //       },
      //       //   title: "時系列",
      //       //   value: "timeSeries",
      //       // },
      //     ],
      //   },
      // },
      {
        title: "時系列",
        propertyName: "timeSeries",
        schema: { schemaType: "boolean" },
      },
      // {
      //   title: "行と列を逆にする",
      //   propertyName: "transpose",
      //   schema: { schemaType: "boolean" },
      // },
      {
        title: "表示フィールド",
        propertyName: "fields",
        schema: {
          schemaType: "multipleSelector",
          uiType: "normal",
          options: fieldOption,
        },
      },
      // {
      //   title: "クロス対象フィールド",
      //   propertyName: "field",
      //   schema: {
      //     schemaType: "selector",
      //     uiType: "normal",
      //     options: fieldOption,
      //   },
      // },
      {
        title: "逆順",
        propertyName: "reverse",
        schema: {
          schemaType: "boolean",
          uiType: "toggle",
        },
      },
      // {
      //   title: "グラフタイプ",
      //   propertyName: "graphType",
      //   schema: {
      //     schemaType: "selector",
      //     uiType: "normal",
      //     options: [
      //       {
      //         title: "自動",
      //         value: null,
      //       },
      //       {
      //         title: "棒",
      //         value: "bar",
      //       },
      //       {
      //         title: "レーダーチャート",
      //         value: "radar",
      //       },
      //       {
      //         title: "折れ線",
      //         value: "line",
      //       },
      //       {
      //         title: "円",
      //         value: "pie",
      //       },
      //       {
      //         title: "積み上げ横棒",
      //         value: "proption",
      //       },
      //     ],
      //   },
      // },
      // {
      //   title: "優先表示",
      //   propertyName: "displayType",
      //   schema: {
      //     schemaType: "selector",
      //     uiType: "normal",
      //     options: [
      //       {
      //         title: "デフォルト",
      //         value: null,
      //       },
      //       {
      //         title: "表",
      //         value: "table",
      //       },
      //       {
      //         title: "グラフ",
      //         value: "graph",
      //       },
      //     ],
      //   },
      // },
      {
        title: "ユニーク化",
        propertyName: "unique",
        schema: {
          schemaType: "selector",
          uiType: "normal",
          options: [
            {
              title: "なし",
              value: null,
            },
            {
              title: "月",
              value: "month",
            },
            {
              title: "週",
              value: "week",
            },
            {
              title: "日",
              value: "day",
            },
          ],
        },
      },
    ],
  };
};

export type settingType = {
  statType: "value" | "dist" | "count";
  fields: string[];
  field: string;
  transpose?: boolean;
  timeSeries?: boolean;
  graphType: "auto" | "line" | "radar" | "bar" | "pie";
  displayType?: "table" | "graph" | null;
  reverse?: boolean;
  unique?: "month" | "week" | "day";
};

import { defineCollection } from "../comodel-firestore";
import { DateRange } from "./type";

export type adminUserData = {
  adminUserId: string;
  role?: "master" | "client";
  clientIds?: string;
  initialEmail?: string;
  createdAt?: number;
};

export const adminUserDefinition = defineCollection({
  collectionName: "adminUser",
  idKey: "adminUserId",
  constraints: ["adminUserId"],
  queryDefs: {
    list: ({
      createdAt,
      condition,
    }: {
      createdAt?: DateRange;
      condition?: Record<string, unknown>;
    }) => {
      return {
        queryParams: {
          filter: { createdAt },
          orderBy: [["createdAt", "desc"]],
        },
        filterParams: condition,
      };
    },
  },
  defaultValue: {} as adminUserData,
  // Atomic existence check to write createdAt
  onCreate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});

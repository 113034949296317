import React, { useMemo } from "react";
import getSchema, { getClientConditionSchema } from "./schema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { ADMIN_COUPONS, COUPONS } from "../route";
import { resolveRoute } from "pages";
import { useCouponList } from "models/hook";
import { couponData } from "common/models/coupon";
import { useConditionSelector } from "hooks/condition/conditionSelector";
import { useAdminAuthentication } from "hooks/auth";
import { useAdminPageAuthorization } from "hooks/authAdminPage";
import NotFound from "pages/notFound";
import { useQueryResource } from "utils/legacyFirestore";

export const LIST_COUPONS = COUPONS.sub("", Coupons);
export const LIST_ADMIN_COUPONS = ADMIN_COUPONS.sub("", AdminCoupons);

function Coupons({ clientId }: { clientId: string }) {
  return <CouponsInternal clientId={clientId} isClient={true} />;
}

function AdminCoupons({ clientId }: { clientId: string }) {
  const { role } = useAdminAuthentication();
  if (role !== "master") {
    return <NotFound></NotFound>;
  }
  return <CouponsInternal clientId={clientId} />;
}

function CouponsInternal({
  clientId,
  isClient,
}: {
  clientId: string;
  isClient?: boolean;
}) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const schema = useMemo(() => getSchema({ clientId, isMaster }), [clientId]);

  const { list: ignoredShopList } = useQueryResource({
    resourcePath: "/shop",
    titleKey: "shopName",
    query: { filter: { clientId } },
    filter: {
      ignore: true,
    },
  });
  const ignoredShopIds = ignoredShopList?.map((i) => i.value) || [];
  const { list: ignoreSurveyList } = useQueryResource({
    resourcePath: "/survey",
    titleKey: "surveyName",
    query: { filter: { clientId } },
    filter: {
      ignore: true,
    },
  });
  const ignoreSurveyIds = ignoreSurveyList?.map((i) => i.value) || [];

  const { fixedCondition } = useMemo(() => {
    if (!isClient) return {};
    return {
      fixedCondition: {
        ignore: {
          $ne: true,
        },
        shopId: {
          $nin: [...ignoredShopIds],
        },
        surveyId: {
          $nin: [...ignoreSurveyIds],
        },
      },
    };
  }, [ignoredShopIds, ignoreSurveyIds]);

  const conditionSchema = useMemo(
    () => (isClient ? getClientConditionSchema({ clientId }) : schema),
    [isClient, clientId, schema]
  );
  const {
    conditionSelector,
    condition: userCondition,
    timeCondition,
  } = useConditionSelector({
    schema: conditionSchema,
  });
  const { list, loading, get, hasMore, setRange, refresher, fetching, reload } =
    useCouponList({
      clientId,
      createdAt: timeCondition,
      condition: { ...fixedCondition, ...userCondition },
    });
  const parameter: SchemaListPageParameter<couponData> = useMemo(
    () => ({
      title: "クーポン一覧",
      edit: {
        path: ({ couponId }) =>
          isClient
            ? resolveRoute("UPDATE_COUPON", { couponId, clientId })
            : resolveRoute("UPDATE_ADMIN_COUPON", { couponId, clientId }),
      },
      create: {
        path: () => resolveRoute("ADD_COUPON", { clientId }) + "?edit=1",
        isDisabled: !isMaster,
      },
      download: {
        handler: get,
        filename: `coupons-${clientId}`,
      },
      schema,
      setRange,
    }),
    [schema, clientId, get, setRange]
  );

  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
      hasMore={hasMore}
      fetching={fetching}
      refresher={refresher}
      header={conditionSelector}
      reload={reload}
    ></SchemaListPage>
  );
}

export default Coupons;

import React, { useMemo } from "react";
import { Media, MediaSpecifier } from "./media";
import { Overwrite } from "./types";

type ElementProps = React.ImgHTMLAttributes<HTMLImageElement>;

export const File = (
  props: Overwrite<ElementProps, { src: MediaSpecifier }>
) => {
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(props.src);
    return Object.fromEntries(params.entries());
  }, [props.src]);

  return (
    <Media<ElementProps>
      {...props}
      elementType={(props) => {
        const { src, ...rest } = props;
        return (
          <a href={src as string} download={searchParams.name}>
            download
          </a>
        );
      }}
    />
  );
};

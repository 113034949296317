import React, { ReactNode } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { getHashSHA256 } from "utils/crypto";
import { errorLog, getLogId } from "utils/log";

interface ErrorBoundaryProps {
  children: ReactNode;
  recovoryKey?: string;
}

interface ErrorBoundaryState {
  error?: {
    name: string; // エラーオブジェクトの名前
    message: string;
    stack: string;
    componentStack: string;
    errorHash: string; // 同一エラーを名寄せするためのハッシュ
    logId: string; // データベースに保存するログのID（ユニーク）
    logHash: string; // お問い合わせ用の短いID（重複可能性あり）
  };
  hasError?: boolean;
  recovoryKey?: string;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  // エラー発生時にcomponentDidCatchより早く呼ばれ、他の処理をブロックする
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static getDerivedStateFromProps(
    props: ErrorBoundaryProps,
    state: ErrorBoundaryState
  ) {
    if (props.recovoryKey) {
      if (state.recovoryKey !== props.recovoryKey) {
        return {
          ...state,
          recovoryKey: props.recovoryKey,
          hasError: false,
          error: undefined,
        };
      } else {
        return { ...state, recovoryKey: props.recovoryKey };
      }
    }
    return null;
  }

  componentDidCatch(error: Error, info: { componentStack: string }) {
    (async () => {
      if (error.name === "AuthorizationError") return;
      const errorObject = {
        name: error.name,
        message: error.message,
        stack: error.stack || "",
        componentStack: info.componentStack,
      };
      const errorHash = getHashSHA256(JSON.stringify(errorObject));
      const logId = getLogId();
      const logHash = logId.slice(0, 8);
      this.setState((state) =>
        state.hasError
          ? {
              error: {
                ...errorObject,
                errorHash,
                logId,
                logHash,
              },
            }
          : state
      );
      errorLog(logId, {
        errorHash,
        logHash,
        ...errorObject,
      });
    })();
  }

  render(): ReactNode {
    if (this.state.error || this.state.hasError) {
      if (this.state.error?.name === "AuthorizationError") {
        return (
          <div style={{ padding: "20px" }}>
            <div style={{ margin: "1em" }}>
              このページを表示する権限がありません。
            </div>
          </div>
        );
      }
      const code = this.state.error?.logHash;
      return (
        <div style={{ padding: "20px" }}>
          <div style={{ margin: "1em" }}>
            ページ表示中にエラーが発生しました。
          </div>
          <div style={{ margin: "1em", display: "flex", alignItems: "center" }}>
            お問い合わせコード:
            <InputGroup style={{ width: "12em", marginLeft: "10px" }}>
              <Form.Control type="url" value={code || ""} readOnly />
              <Button
                variant="outline-secondary"
                style={{ position: "relative" }}
                onClick={() => {
                  if (this.state.error)
                    navigator.clipboard.writeText(code || "");
                }}
              >
                <i className="mdi mdi-content-copy" />
              </Button>
            </InputGroup>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

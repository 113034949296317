import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "業種名",
      propertyName: "industryName",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
  ],
};
export default schema;

import React, { ReactNode, useContext, useMemo } from "react";
import { useStat } from "functions";

import "../common/index.scss";
import classes from "./score.module.scss";
import { ReportContext } from "../../../context";
import { dayjs } from "utils/dayjs";
import { settingType } from "./settingSchema";
import { PropertyType } from "schemaComponents";
import { Loading } from "components/Loading";
import { Alert } from "react-bootstrap";
import { getGraphConfig } from "./graphConfig";
import {
  TableSchema,
  convertDataFrame,
  GraphSchema,
  getRequiredFields,
} from "./statSchemaUtil";
import * as statConfigs from "./statConfig";
import {
  Column,
  ColumnAggregatedDataFrame,
  DataFrame,
  Row,
  RowAggregatedDataFrame,
} from "functions/dataFrame";
import { useShopList, useClientDoc, useIndustryDoc } from "models/hook";
import classNames from "classnames";
import { resolveStringIntl, useStringIntl } from "hooks/intl";

const getMonthString = (target: string | undefined, offset: number) => {
  return (target ? dayjs(target, "YYYYMM") : dayjs(target))
    .add(offset, "month")
    .format("YYYYMM");
};

const alignArray = <T,>(array: T, reverse?: boolean): T => {
  if (reverse && array && Array.isArray(array)) {
    return array.slice(0).reverse() as T;
  } else {
    return array;
  }
};

function ScoreToolComponent({ data }: { data?: settingType }) {
  const reportContext = useContext(ReportContext);

  const shopId = reportContext?.shopId;
  const givenMonth = reportContext?.month || getMonthString(undefined, -1);
  const questions = reportContext.questions;
  const clientId = reportContext.clientId;
  const condition = reportContext.condition;
  const dualColumns = reportContext.dualColumns;
  const prevMonth = getMonthString(givenMonth, -1);
  const statParams = reportContext.statParams;
  const { transpose, timeSeries } = data || {};
  const { statSchemas, action, columns, sequence } = (data?.statType &&
    statConfigs[data?.statType]) || {
    statSchemas: [] as TableSchema[],
  };

  const { list: shopList } = useShopList({ clientId });

  const monthSpan = 6;
  const currentMonth = useMemo(
    () =>
      timeSeries
        ? Array(monthSpan)
            .fill(null)
            .map((_, index) => {
              return dayjs(givenMonth, "YYYYMM")
                .add(-monthSpan + 1 + index, "month")
                .format("YYYYMM");
            })
        : givenMonth,
    [timeSeries, monthSpan, givenMonth]
  );

  const statFields = getRequiredFields(statSchemas);

  const params =
    data && action && statParams
      ? {
          action,
          columns: columns || data.fields,
          column: data.field,
          unique: data.unique,
          shopId,
          condition,
          ...statParams,
        }
      : undefined;

  const requestParams = (() => {
    if (!params) {
      return undefined;
    }
    if (Array.isArray(currentMonth)) {
      // 本来、前月分のstatFieldを適用しないといけない
      // 現状では前月比較 + 時系列は、正常に処理できない（禁止していないので注意）
      const prevMonth = getMonthString(currentMonth[0], -1);
      return [
        {
          ...params,
          month: prevMonth,
          label: prevMonth,
          stats: statFields.current,
        },
        ...currentMonth.map((month) => {
          return {
            ...params,
            month,
            label: month,
            stats: statFields.current,
          };
        }),
      ];
    } else {
      return [
        {
          ...params,
          label: currentMonth,
          month: currentMonth,
          stats: statFields.current,
        },
        {
          ...params,
          label: prevMonth,
          month: prevMonth,
          stats: statFields.prev,
        },
      ].filter((requestParam) => requestParam.stats.length > 0);
    }
  })();

  const statResult = useStat(
    requestParams,
    (dataFrames) => {
      const dataFrameMap = Object.fromEntries(
        dataFrames.map((dataFrame) => [dataFrame.label, dataFrame])
      ) as Record<string, DataFrame<number | null>>;
      const statMap = Object.fromEntries(
        statSchemas.map((statSchema) => [
          statSchema.id || statSchema.value,
          statSchema.title,
        ])
      );

      if (!Array.isArray(currentMonth)) {
        // 前月
        const month = currentMonth;
        const current = dataFrameMap[month];
        const prev = dataFrameMap[getMonthString(month, -1)];
        const dataFrame = convertDataFrame(current, prev, statSchemas);
        return {
          dataFrame,
          statMap,
          currentMonth,
        };
      }

      const statDataFrames = currentMonth
        .map((month) => {
          const current = dataFrameMap[month];
          const prev = dataFrameMap[getMonthString(month, -1)];
          return convertDataFrame(current, prev, statSchemas);
        })
        .filter((item) => item) as DataFrame<ReactNode>[];

      if (!transpose) {
        const dataFrame = new RowAggregatedDataFrame(
          statDataFrames
            .map((dataFrame) => {
              return dataFrame.row(dataFrame.rows[0], dataFrame.label);
            })
            .filter((item) => item) as Row<ReactNode>[]
        );
        return { dataFrame, statMap, currentMonth };
      } else {
        const dataFrame = new ColumnAggregatedDataFrame(
          statDataFrames
            .map((dataFrame) => {
              return dataFrame.column(dataFrame.columns[0], dataFrame.label);
            })
            .filter((item) => item) as Column<ReactNode>[]
        );
        return { dataFrame, statMap, currentMonth };
      }
    },
    [transpose, currentMonth]
  );

  const properties = useMemo(() => {
    return [
      ...(questions?.properties || []),
      {
        propertyName: "lottery",
        title: "抽選結果",
        schema: {
          schemaType: "selector",
          readOnly: true,
          options: [
            { title: "当選", value: 1 },
            { title: "落選", value: 2 },
            { title: "重複", value: 3 },
          ],
        },
      },
      {
        propertyName: "count",
        title: "回答数",
        schema: {
          schemaType: "number",
        },
      },
    ] as PropertyType[];
  }, [questions]);

  const requestCurrentMonth = statResult.data?.currentMonth;

  // const propertyMap = useMemo(() => {
  //   return Object.fromEntries(
  //     properties.map((property) => {
  //       return [property.propertyName, property];
  //     })
  //   ) as Record<string, PropertyType>;
  // }, [properties]);

  const s = useStringIntl();

  const fieldMap = useMemo(() => {
    if (action === "rank") {
      return Object.fromEntries(
        shopList?.map((shop) => [
          shop.shopId,
          resolveStringIntl(shop.shopName),
        ]) || []
      );
    }
    return Object.fromEntries(
      (function* () {
        for (const property of properties || []) {
          yield [
            property.propertyName,
            (property as { statShortName?: string }).statShortName ||
              s(property.title).slice(0, 10),
          ];
          if ("options" in property.schema) {
            for (const option of property.schema.options) {
              // TODO この処理は望ましくない。title以外で判別するべき。
              if (option.title !== "特になし")
                yield [
                  `${property.propertyName}-${option.value}`,
                  option.title,
                ];
            }
          } else if (property.schema.schemaType === "numberSelector") {
            for (
              let i = property.schema.minValue;
              i <= property.schema.maxValue;
              ++i
            ) {
              yield [`${property.propertyName}-${i}`, i];
            }
          }
        }
        if (Array.isArray(requestCurrentMonth)) {
          for (const month of requestCurrentMonth) {
            yield [month, dayjs(month, "YYYYMM").format("YYYY-MM")];
          }
        }
      })()
    ) as Record<string, string>;
  }, [action, action === "rank" ? shopList : properties, requestCurrentMonth]);

  // 表示する際のスキーマ
  const columnSchemas =
    Array.isArray(requestCurrentMonth) && transpose
      ? requestCurrentMonth.map((month) => {
          return {
            value: month,
            title: dayjs(month, "YYYYMM").format("YYYY-MM"),
            graph: true,
          } as GraphSchema;
        })
      : statSchemas;
  // const columnSchemaForGraph = columnSchemas.filter(
  //   (schema) => schema.graph
  // ) as GraphSchema[];
  const columnSchemaForTable = columnSchemas.filter(
    (schema) => schema.table !== false
  );

  // const {
  //   GraphComponent,
  //   datasetOption,
  //   graphOption: _graphOption,
  // } = getGraphConfig({
  //   currentMonth: requestCurrentMonth,
  //   data,
  //   propertyMap,
  // });

  // const graphData = (() => {
  //   const dataFrame = statResult.data?.dataFrame;
  //   if (!dataFrame) {
  //     return undefined;
  //   }
  //   const rows = dataFrame.rows
  //     .filter((rowName) => fieldMap[rowName] != null)
  //     .map((rowName) => {
  //       return {
  //         value: rowName,
  //         title: fieldMap[rowName],
  //       };
  //     });
  //   const columns = columnSchemaForGraph.map((schema) => {
  //     return {
  //       value: schema.id || schema.value,
  //       title: schema.title,
  //     };
  //   });

  //   if (transpose) {
  //     return {
  //       labels: columns.map((columnItem) => columnItem.title),
  //       datasets: rows.map((rowItem, rowIndex) => {
  //         return {
  //           label: rowItem.title as string,
  //           data: columns
  //             .map((columnItem) => {
  //               return dataFrame.pickValue(rowItem.value, columnItem.value);
  //             })
  //             .map((v) => (v == null ? NaN : v)),
  //           ...datasetOption(rowIndex),
  //         };
  //       }),
  //     };
  //   } else {
  //     return {
  //       labels: rows.map((rowItem) => rowItem.title),
  //       datasets: columns.map((columnItem, columnIndex) => {
  //         return {
  //           label: columnItem.title as string,
  //           data: rows
  //             .map((rowItem) => {
  //               return dataFrame.pickValue(rowItem.value, columnItem.value);
  //             })
  //             .map((v) => (v == null ? NaN : v)),
  //           ...datasetOption(columnIndex),
  //         };
  //       }),
  //     };
  //   }
  // })();

  // const graphOptions = useMemo(
  //   () => ({
  //     aspectRatio: 640 / 400,
  //     maintainAspectRatio: true,
  //     ..._graphOption,
  //   }),
  //   [_graphOption]
  // );

  const culcPercentage = (score: number) => {
    return Math.round(((score * 10) / 70) * 100 * 10) / 10;
  };

  return (
    <div className="statComponent">
      {statResult.loading && <Loading></Loading>}
      {statResult.error && (
        <Alert variant="danger">
          <div>{statResult.error.message}</div>
        </Alert>
      )}
      {statResult.data?.dataFrame && (
        <div className={classes.main}>
          <div className={classes.header}>
            <div className={classes.title}>スコア明細</div>
            <div className={classes.th}>
              <span className={classes.th__self}>事業所</span>/
              <span className={classes.th__average}>全体平均</span>
            </div>
          </div>
          <div className={classes.content}>
            <ul className={classes.list}>
              {
                // statResult.data?.dataFrame?.rows
                alignArray(statResult.data?.dataFrame?.rows, data?.reverse)
                  .filter((rowName) => fieldMap[rowName] != null)
                  .map((rowName, rowIndex) => {
                    const shopSchema = columnSchemaForTable.filter(
                      (columnSchema) => columnSchema.title === "事業所"
                    )[0];
                    const self = statResult.data?.dataFrame?.pickValue(
                      rowName,
                      shopSchema.id || shopSchema.value
                    );
                    const _self = self ? (self as string) : "0";
                    const selfNumber = Number(_self.split("%")[0]);
                    const allSchema = columnSchemaForTable.filter(
                      (columnSchema) => columnSchema.title === "全体"
                    )[0];
                    const all = statResult.data?.dataFrame?.pickValue(
                      rowName,
                      allSchema.id || allSchema.value
                    );
                    const _all = all ? (all as string) : "0";
                    const allNumber = Number(_all.split("%")[0]);
                    return (
                      <li className={classes.item} key={rowIndex}>
                        <div className={classes.name}>{fieldMap[rowName]}</div>
                        <div className={classes.content_wrapper}>
                          <div className={classes.bg}>
                            <div className={classes.graph}>
                              <div
                                className={classes.graph__self}
                                style={{
                                  maxWidth: culcPercentage(selfNumber),
                                }}
                              ></div>
                              <div
                                className={classes.graph__average}
                                style={{
                                  maxWidth: culcPercentage(allNumber),
                                }}
                              ></div>
                            </div>
                            <div className={classes.score}>
                              <div className={classes.score__inner}>
                                <span className={classes.score__self}>
                                  {self}
                                </span>
                                <span>/</span>
                                <span className={classes.score__average}>
                                  {all}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
              }
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default ScoreToolComponent;

import React from "react";
import { createResolver } from "./resolve";
import {
  SchemaComponent,
  SchemaComponentProps,
  SchemaComponentInternalProps,
  DisplayComponent,
  BaseSchema,
  AnySchema,
} from "./types";
import { useValue } from "./hooks";

export const createSelector = (
  schemaComponents: Record<string, SchemaComponent<AnySchema>>
) => {
  Object.freeze(schemaComponents);
  const resolve = createResolver(schemaComponents);
  const Field = (props: SchemaComponentProps) => {
    const { fieldPath, readOnly, control, schema, label } = props;
    const Component = resolve(schema.schemaType);
    if (Component?.display && (readOnly || schema.readOnly)) {
      return (
        <Display
          fieldPath={fieldPath || ""}
          component={Component.display}
          {...props}
        />
      );
    } else if (Component) {
      return (
        <Component
          control={control}
          fieldPath={fieldPath || ""}
          readOnly={readOnly || schema.readOnly}
          schema={schema}
          label={label}
        />
      );
    } else {
      console.error("Cannot resolve schema", props);
      return <div>Cannot resolve schema</div>;
    }
  };
  return Field;
};

const Display = <S extends BaseSchema>(
  props: SchemaComponentInternalProps<S> & { component: DisplayComponent<S> }
) => {
  const { component: Component, schema } = props;
  const value = useValue(props);
  return <Component value={value} schema={schema} />;
};

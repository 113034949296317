import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useResetPasswordAdmin } from "hooks/auth";
import Alert from "react-bootstrap/Alert";
import ErrorMessage from "components/ErrorMessage";
import { ADMIN as BASE } from "..";
import { resolveRoute } from "pages";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useToost } from "hooks/dialogs";
import "./register.scss";
import { useFormatter } from "hooks/intl";

export const REGISTER = BASE.sub("/register", Register);

function Register() {
  const { formatMessage } = useFormatter();
  const [signInError, setSignInError] = useState(
    null as { message: string } | null
  );
  const [resetPassword] = useResetPasswordAdmin();
  const toost = useToost();
  const error = signInError;
  const parameter: SchemaFormParameter = {
    title: "パスワードリセット",
    schema: {
      schemaType: "object",
      properties: [
        {
          propertyName: "email",
          title: formatMessage("Field.Email"),
          schema: { schemaType: "email" },
        },
      ],
    },
    edit: {
      title: "送信",
      handler: async (value) => {
        await resetPassword({
          email: value.email,
          path: resolveRoute("LOGIN"),
        });
        console.log("completed");
        await toost({
          message: "パスワードリセットのためのメールを送信しました",
          duration: 3000,
        });
      },
      confirm: false,
    },
  };
  return (
    <>
      <div className="consumer-container">
        <section className="main-section remove-card-border adjust-width">
          <SchemaFormPage data={{}} parameter={parameter} />
          {error && (
            <Alert variant="danger">
              <ErrorMessage error={error} />
            </Alert>
          )}
          <Link to={resolveRoute("LOGIN")}>ログインはこちら</Link>
        </section>
      </div>
    </>
  );
}

export default Register;

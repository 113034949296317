import React, { useMemo } from "react";
import getSchema from "./schema";
import {
  getDataTransferSchema,
  getDataTransferSchemaForClient,
} from "./dataTransferSchema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { useShopList } from "models/hook";
import { SHOPS } from "../route";
import { resolveRoute } from "pages";
import { shopData } from "common/models/shop";
import { useConditionSelector } from "hooks/condition/conditionSelector";
import { useAdminAuthentication } from "hooks/auth";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const LIST_SHOPS = SHOPS.sub("", Shops);

type fullShopData = shopData & {
  entryURL: string;
  publicEnqueteURL: string;
};

function Shops({ clientId }: { clientId: string }) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { conditionSelector, condition, timeCondition } = useConditionSelector({
    schema: getDataTransferSchema({ clientId, isMaster, isAuthSkipped: false }),
    hideTimeCondition: true,
  });
  const { list, loading, upsert, get } = useShopList({
    clientId,
    createdAt: timeCondition,
    condition,
  });
  const parameter: SchemaListPageParameter<fullShopData> = useMemo(
    () => ({
      title: "事業所一覧",
      edit: {
        path: ({ shopId }) => resolveRoute("UPDATE_SHOP", { shopId, clientId }),
      },
      create: {
        path: () => resolveRoute("ADD_SHOP", { clientId }) + "?edit=1",
        isDisabled: !isMaster,
      },
      schema: getSchema({ clientId }),
      download: {
        schema: isMaster
          ? getDataTransferSchema({
              clientId,
              isMaster,
              isAuthSkipped: false,
              isDownload: true,
            })
          : getDataTransferSchemaForClient({ clientId }),
        filename: `shops-${clientId}`,
        handler: get,
      },
      upload: {
        schema: getDataTransferSchema({
          clientId,
          isMaster,
          isAuthSkipped: false,
          isUpload: true,
        }),
        handler: upsert,
        isDisabled: !isMaster,
      },
      clientId,
    }),
    [clientId]
  );
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
      header={conditionSelector}
    ></SchemaListPage>
  );
}

export default Shops;

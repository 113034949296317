import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { StyledFirebaseAuth } from "react-firebaseui";
import classes from "./Login.module.scss";
import { useAdminAuthentication } from "hooks/auth";
import Alert from "react-bootstrap/Alert";
import { getAuth, GoogleAuthProvider, EmailAuthProvider } from "firebase/auth";
import firebaseApp from "firebaseApp";
import ErrorMessage from "components/ErrorMessage";
import { ADMIN as BASE } from "..";
import { resolveRoute } from "pages";
import { currentDomain } from "utils/domain";
import { resolve } from "path";

const auth = getAuth(firebaseApp);

export const LOGIN = BASE.sub("/login", Login);

function Login() {
  const {
    user,
    isAdmin,
    isNotAdmin,
    error: authError,
  } = useAdminAuthentication();

  const [signInError, setSignInError] = useState(
    null as { message: string } | null
  );
  const error = authError || signInError;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSignInSuccessHandler = (authResult: any) => {
    console.log("authResult", authResult);
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSignInFailHandler = (signInEror: any) => {
    console.log("signInEror", signInEror);
    setSignInError({ message: signInEror });
  };

  if (isAdmin) {
    return <Navigate to={resolveRoute("ADMIN_HOME")} replace />;
  }

  return (
    <>
      <div className="consumer-container wide-form">
        <section className="hero adjust-width">
          <div className="hero-body">
            <h1 className="title">ログイン</h1>
          </div>
        </section>
        <section className="main-section adjust-width">
          <div className={classes.socialButtons}>
            <StyledFirebaseAuth
              uiConfig={uiConfig(onSignInSuccessHandler, onSignInFailHandler)}
              firebaseAuth={auth}
            />
          </div>
          {error && (
            <Alert variant="danger">
              <ErrorMessage error={error} />
            </Alert>
          )}
          {isNotAdmin && (
            <Alert variant="danger">
              ログイン中のメールアドレス({user?.email})
              には管理者権限がありません。別のメールアドレスでログインしなおすか、管理者まで連絡してください
            </Alert>
          )}
          <Link to={resolveRoute("REGISTER")}>
            はじめての方、またはパスワードを忘れた方はこちら
          </Link>
          {/* <p>
            注：一つのメールアドレスで、Google認証とパスワード認証を併用することはできません。
            最初にログインした方の認証方式で、その後もログインしてください。
            認証方式を変更したい場合は、管理者にお問い合わせください。
          </p> */}
        </section>
        <section className="main-section"></section>
      </div>
    </>
  );
}

export default Login;

const uiConfig = (
  onSignInSuccessHandler:
    | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((authResult: any, redirectUrl?: string) => boolean),
  onSignInFailHandler:
    | undefined
    | ((error: firebaseui.auth.AuthUIError) => Promise<void> | void)
): firebaseui.auth.Config => {
  return {
    callbacks: {
      signInSuccessWithAuthResult: onSignInSuccessHandler,
      signInFailure: onSignInFailHandler,
    },
    signInFlow: "popup",
    signInSuccessUrl: "/home",
    signInOptions: [
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
        disableSignUp: { status: true },
      },
      {
        provider: GoogleAuthProvider.PROVIDER_ID,
        fullLabel: "Continue with Google",
        scopes: [
          "https://www.googleapis.com/auth/user.addresses.read",
          "https://www.googleapis.com/auth/userinfo.email",
        ],
      },
    ],
  };
};

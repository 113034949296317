import React, { useMemo, useRef, useState } from "react";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import { Button, InputGroup, ProgressBar } from "react-bootstrap";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import firebaseApp from "firebaseApp";
import { MediaSpecifier } from "./media";
import { encodeSrc } from "./util";
import { useToost } from "hooks/dialogs";
import FormClearButton from "components/FormClearButton";

const storage = getStorage(firebaseApp);

const wait = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const Upload = (props: {
  value: MediaSpecifier;
  onChange: (value: MediaSpecifier | null) => void;
  name: string;
  rootPath?: string;
  hasError?: boolean;
  accept?: string;
  mode?: "source" | "filename";
  onFile?: (value: File | null) => void;
}) => {
  const { value, onChange, rootPath, name, hasError, accept, mode, onFile } =
    props;
  const inputRef = useRef<HTMLInputElement>(null);
  const clear = () => {
    onChange(null);
    onFile?.(null);
    setProgress(0);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const [progress, setProgress] = useState<number>(0);
  const toost = useToost();
  const displayValue = useMemo(() => {
    if (mode !== "filename") {
      return value && typeof value === "object"
        ? JSON.stringify(value || "")
        : value || "";
    } else {
      const searchParams = new URLSearchParams(value);
      return searchParams.get("name") || "";
    }
  }, [value]);
  return (
    <>
      {progress > 0 && <ProgressBar now={progress} style={{ height: 2 }} />}
      <InputGroup className="mb-3">
        <Form.Control
          type="url"
          className={classNames({
            "has-error": hasError,
          })}
          value={displayValue}
          style={{ position: "relative", paddingRight: "39px" }}
          name={name}
          onChange={(e) => {
            if (mode === "filename") return;
            const str = ((value: string) => {
              try {
                return JSON.parse(value);
              } catch {
                return value;
              }
            })(e.target.value);
            onChange(str);
          }}
          readOnly={mode === "filename"}
          disabled={mode === "filename"}
        />
        <FormClearButton
          onClick={clear}
          style={{ position: "absolute", right: "39px" }}
        />
        <Button variant="outline-secondary" style={{ position: "relative" }}>
          <i className="mdi mdi-cloud-upload-outline" />
          <input
            {...(accept ? { accept } : undefined)}
            type="file"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              opacity: 0,
            }}
            ref={inputRef}
            onChange={async (e) => {
              const target = e.target as HTMLInputElement;
              const blob = target.files?.[0];
              if (blob) {
                onFile?.(blob);
                const path = `${rootPath || ""}/${Date.now()}`;
                const fileRef = ref(storage, path);
                const contentType = blob.type;
                if (
                  accept &&
                  !contentType?.startsWith(accept.replaceAll("*", ""))
                ) {
                  await toost({
                    message: "ファイルタイプが間違っています",
                    duration: 10000,
                  });
                  return;
                }

                const encodeRFC5987ValueChars = (str: string) => {
                  return (
                    encodeURIComponent(str)
                      // 単一引用符 ('), 括弧 (), アスタリスク (*) をRFC 5987に準拠してエンコード
                      .replace(
                        /['()]/g,
                        (match) => "%" + match.charCodeAt(0).toString(16)
                      )
                      .replace(/\*/g, "%2A")
                      // RFC 3986ではスペースは"+"に置き換えられますが、RFC 5987では"%20"になります。
                      .replace(/%20/g, " ")
                  );
                };

                const task = uploadBytesResumable(fileRef, blob, {
                  contentType,
                  cacheControl: "public, max-age=2592000",
                  contentDisposition: `inline; filename*=UTF-8''${encodeRFC5987ValueChars(
                    blob.name
                  )}`,
                });
                task.on("state_changed", (snapshot) => {
                  if (snapshot.state === "running") {
                    const percent =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(percent);
                  }
                  if (
                    snapshot.state === "error" ||
                    snapshot.state === "canceled"
                  ) {
                    setProgress(0);
                    target.value = "";
                  }
                });
                await task;
                console.log(
                  "upload",
                  {
                    protocol: "gs",
                    host: firebaseApp.options.storageBucket,
                    path,
                    contentType: blob.type,
                    // size: blob.size,
                  },
                  encodeSrc({
                    protocol: "gs",
                    host: firebaseApp.options.storageBucket,
                    path: `/${path}`,
                    contentType: blob.type,
                    name: blob.name,
                    // size: blob.size,
                  })
                );
                onChange(
                  encodeSrc({
                    protocol: "gs",
                    host: firebaseApp.options.storageBucket,
                    path: `/${path}`,
                    contentType: blob.type,
                    name: blob.name,
                    // size: blob.size,
                  })
                );
                await wait(500);
                setProgress(0);
                target.value = "";
              }
            }}
          />
        </Button>
      </InputGroup>
    </>
  );
};

import React from "react";
import classNames from "classnames";

const FormClearButton = ({
  className,
  style,
  onClick,
}: {
  className?: string;
  style?: React.CSSProperties;
  onClick: () => void;
}) => {
  return (
    <button
      className={classNames("btn", className)}
      style={{
        color: "#6c757d",
        cursor: "pointer",
        zIndex: 1000,
        boxShadow: "none",
        ...style,
      }}
      type="button"
      onClick={onClick}
    >
      <i className="mdi mdi-close-thick" />
    </button>
  );
};

export default FormClearButton;

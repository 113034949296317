import { Value, Validator, ValidationContext, BaseSchema } from "./types";

export const validate = async <S extends BaseSchema>(
  value: Value,
  validators: Validator<S>[],
  context: ValidationContext<S>
) => {
  const { schema } = context;
  for (const validator of [...validators, ...(schema.validators || [])]) {
    const result = await validator(value, context);
    if (result) {
      return result;
    }
  }
};

import { defineCollection } from "../comodel-firestore";

export type reportCommentData = {
  shopId: string;
  month: string;
  reportCommentId: string;
  comments: {
    個別評価項目: string;
    総合満足度: string;
    その他: string;
  };
};

export const reportCommentDefinition = defineCollection({
  collectionName: "reportComment",
  idKey: "reportCommentId",
  constraints: ["shopId", "month"],
  queryDefs: {
    list: ({ shopId }: { shopId: string }) => {
      return {
        constraint: { shopId },
        queryParams: { filter: { shopId } },
      };
    },
  },
  defaultValue: {} as reportCommentData,
  // Emulate virtual idKey (write)
  onAccess: (o) => ({
    ...o,
    reportCommentId: `${o.shopId}:${o.month}`,
  }),
  // Emulate virtual idKey (read)
  onRead: (o) => ({ ...o, month: o.reportCommentId.split(":")[1] }),
  onGenerate: (o) => ({ ...o, createdAt: Date.now() }),
  onWrite: (o) => ({ ...o, updatedAt: Date.now() }),
});

import { fromByteArray, toByteArray } from "base64-js";
import { useAccessorFromArray } from "hooks/accessor";
import { useNormalizedMemo } from "hooks/common";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const toBase64Url = (str: string) => {
  const byteArray = new TextEncoder().encode(str);
  return fromByteArray(byteArray)
    .replace(/=+$/, "")
    .replaceAll("+", "-")
    .replaceAll("/", "-");
};

const fromBase64Url = (base64url: string) => {
  const byteArray = toByteArray(
    base64url
      .replaceAll("-", "+")
      .replaceAll("-", "/")
      .padEnd(Math.ceil(base64url.length / 4) * 4, "=")
  );
  return new TextDecoder().decode(byteArray);
};

export const objectToSearchParams = (obj: Record<string, unknown>) => {
  const rest = {};
  const searchParams = new URLSearchParams();
  const traverse = (obj: Record<string, unknown>, paths: string[] = []) => {
    for (const [key, value] of Object.entries(obj)) {
      const currentPaths = [...paths, key];
      if (
        !key.includes(".") &&
        value &&
        typeof value === "object" &&
        !Array.isArray(value)
      ) {
        traverse(value as Record<string, unknown>, currentPaths);
      } else if (!key.includes(".") && typeof value === "string") {
        searchParams.set(currentPaths.join("."), value);
      } else {
        setByPath(rest, currentPaths, value);
      }
    }
  };
  traverse(obj);
  if (Object.keys(rest).length) {
    searchParams.set("o", toBase64Url(JSON.stringify(rest)));
  }
  return searchParams;
};

export const objectFromSearchParams = (searchParams: URLSearchParams) => {
  const rest = searchParams.get("o");
  searchParams.delete("o");
  const base = rest ? JSON.parse(fromBase64Url(rest)) : {};
  for (const [key, value] of searchParams.entries()) {
    setByPath(base, key.split("."), value);
  }
  return base;
};

const setByPath = (
  obj: Record<string, unknown>,
  paths: string[],
  value: unknown,
  pathIndex = 0
): void => {
  if (pathIndex >= paths.length - 1) {
    obj[paths[pathIndex]] = value;
  } else {
    let target = obj[paths[pathIndex]];
    if (!target || typeof target !== "object") {
      target = {} as Record<string, unknown>;
      obj[paths[pathIndex]] = target;
    }
    setByPath(target as Record<string, unknown>, paths, value, pathIndex + 1);
  }
};

export const useSearchParamState = <T>() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useNormalizedMemo(() => {
    return objectFromSearchParams(searchParams) as T;
  }, [searchParams]);
  const setParams = useCallback(
    (params: T) => {
      const searchParams = objectToSearchParams(
        params as Record<string, unknown>
      );
      setSearchParams(searchParams);
    },
    [setSearchParams]
  );
  return [params, setParams] as const;
};

export const useSearchParamAccessor = <T>() => {
  return useAccessorFromArray(useSearchParamState<T>());
};

const normalizeDifference = <T>(
  newObject: T,
  oldObject: T | undefined
): [T, boolean] => {
  if (newObject && oldObject && typeof newObject === "object") {
    if (!Array.isArray(newObject)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = {} as any;
      let changed = false;
      for (const [key, value] of Object.entries(newObject)) {
        const [field, _changed] = normalizeDifference(
          value,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (oldObject as any)[key]
        );
        response[key] = field;
        changed = changed || _changed;
      }
      for (const key of Object.keys(oldObject)) {
        if (!(key in newObject)) {
          changed = true;
        }
      }
      return [changed ? response : oldObject, changed];
    } else {
      let changed =
        !Array.isArray(oldObject) || newObject.length !== oldObject.length;
      const response = newObject.map((item, index) => {
        const [field, _changed] = normalizeDifference(
          item,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (oldObject as any)[index]
        );
        changed = changed || _changed;
        return field;
      });
      return [(changed ? response : oldObject) as T, changed];
    }
  }
  return [newObject, newObject !== oldObject];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _window = window as any;

_window.objectToSearchParams = objectToSearchParams;
_window.objectFromSearchParams = objectFromSearchParams;
_window.normalizeDifference = normalizeDifference;

_window.getCurrentParams = () => {
  console.log(objectFromSearchParams(new URLSearchParams(location.search)));
};

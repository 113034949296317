import React from "react";
import { Control, useWatch } from "react-hook-form";
import { shopData } from "common/models/shop";
import ConsumerEntry from "pages/consumer/entry";
import LocaleProvider from "components/LocaleProvider";
import { editingLocaleStorgeKey } from "hooks/localeSelector";
import { Tab, Tabs } from "react-bootstrap";
import { useFieldAccessor } from "hooks/accessor";
import { useSearchParamAccessor } from "utils/url";
import EnqueteCompleted from "pages/consumer/enqueteCompleted";
import ConsumerEnqueteComponents from "pages/consumer/enquete";
import { useClientDoc } from "models/hook";

const { ConsumerEnquete, ConsumerPublicEnquete } = ConsumerEnqueteComponents;

export const PreviewComponent = ({
  control,
  clientId,
}: {
  control: Control;
  clientId: string;
}) => {
  const shopData = useWatch({
    control: control as unknown as Control<shopData>,
  });
  const searchParamAccessor = useSearchParamAccessor<{
    previewTabKey?: string;
  }>();
  const { value: tabKey, setValue: setTabKey } = useFieldAccessor(
    searchParamAccessor,
    "previewTabKey"
  );
  const { data: clientData } = useClientDoc({ clientId });
  // useWatchの戻り値が配列のデフォルト値をサポートしていないので、型を強制的に変換する
  return (
    <LocaleProvider
      localeStorageKey={editingLocaleStorgeKey}
      availableLocales={clientData.availableLocales}
    >
      <div className="tab-wrapper" style={{ paddingTop: "0px" }}>
        <Tabs
          activeKey={tabKey}
          onSelect={(_key) => {
            if (_key) setTabKey(_key);
          }}
        >
          {!shopData?.isAuthSkipped ? (
            <Tab eventKey="coupon" title="エントリー" mountOnEnter>
              <div className="preview" style={{ marginTop: "10px" }}>
                <ConsumerEntry shopData={(shopData as shopData) || null} />
              </div>
            </Tab>
          ) : null}
          <Tab eventKey="enquete" title="アンケート" mountOnEnter>
            <div className="preview" style={{ marginTop: "10px" }}>
              {shopData?.isAuthSkipped ? (
                <ConsumerPublicEnquete
                  shopData={(shopData as shopData) || null}
                />
              ) : (
                <ConsumerEnquete shopData={(shopData as shopData) || null} />
              )}
            </div>
          </Tab>
          {!shopData?.isAuthSkipped ? null : (
            <Tab eventKey="complete" title="完了画面" mountOnEnter>
              <div className="preview" style={{ marginTop: "10px" }}>
                <EnqueteCompleted shopData={(shopData as shopData) || null} />
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    </LocaleProvider>
  );
};

import React from "react";
import { Control, useWatch } from "react-hook-form";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import ConsumerLayout from "components/ConsumerLayout";
import { surveyData } from "common/models/survey";
import LocaleProvider from "components/LocaleProvider";
import {
  ConsumerLocaleSelector,
  editingLocaleStorgeKey,
} from "hooks/localeSelector";
import { useFormatter, useStringIntl } from "hooks/intl";
import { useClientDoc } from "models/hook";

export const PreviewComponent = ({
  control,
  clientId,
}: {
  control: Control;
  clientId: string;
}) => {
  const { data: clientData } = useClientDoc({ clientId });
  return (
    <LocaleProvider
      localeStorageKey={editingLocaleStorgeKey}
      availableLocales={clientData.availableLocales}
    >
      <PreviewComponentInternal control={control} clientId={clientId} />
    </LocaleProvider>
  );
};

const PreviewComponentInternal = ({
  control,
  clientId,
}: {
  control: Control;
  clientId: string;
}) => {
  const surveyData = useWatch({
    control: control as unknown as Control<surveyData>,
  });
  const { data: clientData } = useClientDoc({ clientId });
  const { formatMessage } = useFormatter();
  const parameter: SchemaFormParameter = {
    title: formatMessage("Enquete.Title"),
    edit: {
      title: formatMessage("Form.Submit"),
      confirm: formatMessage("Form.Confirm"),
      handler: async () => {},
    },
    enableFormAlert: true,
    schema: surveyData?.enquete || {
      schemaType: "object",
      properties: [],
    },
  };
  return (
    <div className="preview">
      <ConsumerLayout
        shopData={{
          shopName: "事業所名",
          showShopNameInHeader: true,
          clientId,
        }}
      >
        <div className={"wide-form"}>
          <SchemaFormPage
            data={{}}
            parameter={parameter}
            header={
              (clientData?.availableLocales?.length || 0) > 1 ? (
                <div
                  className="adjust-width"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  <ConsumerLocaleSelector
                    locales={clientData?.availableLocales || []}
                  />
                </div>
              ) : undefined
            }
          />
        </div>
      </ConsumerLayout>
    </div>
  );
};

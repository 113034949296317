import React from "react";
import {
  SchemaComponent,
  useField,
  requiredValidator,
  BaseSchema,
} from "react-hook-schema-form";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import { MediaSpecifier } from "./media";
import { Upload } from "./media/upload";
import { File } from "./media/file";

interface FileSchema extends BaseSchema {
  schemaType: "file";
  required?: boolean;
  rootPath?: string;
  maxHeight?: number | string;
  accept?: string;
}

const FileSchemaComponent: SchemaComponent<FileSchema> = (props) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  const { schema } = props;
  const { rootPath, maxHeight, accept } = schema;
  return (
    <>
      <FieldWrapper fieldState={fieldState}>
        <Upload
          {...registerProps}
          onChange={(value) => {
            registerProps.onChange(value);
          }}
          value={value}
          rootPath={rootPath}
          hasError={fieldState.invalid}
          mode="filename"
          accept={accept}
        />
      </FieldWrapper>
      <File style={{ border: "1px solid #ccc", maxHeight }} src={value} />
    </>
  );
};

FileSchemaComponent.display = DisplayComponent(
  "FileDisplayComponent",
  (value: MediaSpecifier, schema: FileSchema) => {
    const { maxHeight } = schema;
    return <File style={{ backgroundColor: "#fff", maxHeight }} src={value} />;
  }
);

export default FileSchemaComponent;

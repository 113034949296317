import React, { useMemo } from "react";

import schema from "./schema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";

import { MAIL_TEMPLATES } from "../route";
import { resolveRoute } from "pages";
import { mailTemplateData } from "common/models/mailTemplate";
import { useMailTemplateList } from "models/hook";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const LIST_MAIL_TEMPLATES = MAIL_TEMPLATES.sub("", MailTemplates);

function MailTemplates({ clientId }: { clientId: string }) {
  useAdminPageAuthorization(clientId);
  const { list: originalList } = useMailTemplateList({ clientId });
  const { list, loading } = useMemo(() => {
    const map = new Map<string, mailTemplateData>(
      originalList?.map((item) => [item.mailTemplateKey, item])
    );
    console.log("map", map);
    return {
      list: [
        {
          mailTemplateKey: "registerUser",
          mailTemplateName: "ユーザ登録",
          ...map.get("registerUser"),
        },
        {
          mailTemplateKey: "coupon",
          mailTemplateName: "クーポン",
          ...map.get("coupon"),
        },
        // {
        //   mailTemplateKey: "retryGacha",
        //   mailTemplateName: "抽選お詫びメール",
        //   exists: map.has("retryGacha"),
        // },
      ],
      loading: false,
    };
  }, [originalList]);
  const parameter: SchemaListPageParameter<mailTemplateData> = useMemo(
    () => ({
      title:
        (clientId === "default" ? "システム" : "") + "メールテンプレート一覧",
      edit: {
        path:
          clientId === "default"
            ? ({ mailTemplateKey }) => {
                return resolveRoute("UPDATE_SYSTEM_MAIL_TEMPLATE", {
                  mailTemplateKey,
                  clientId,
                });
              }
            : ({ mailTemplateKey }) => {
                return resolveRoute("UPDATE_MAIL_TEMPLATE", {
                  mailTemplateKey,
                  clientId,
                });
              },
      },
      download: {
        filename:
          clientId === "default"
            ? "systemMailTemplates"
            : `mailTemplates-${clientId}`,
      },
      schema,
    }),
    [clientId]
  );
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
    ></SchemaListPage>
  );
}

export default MailTemplates;

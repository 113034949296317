import React, { useMemo } from "react";
import schema from "./schema";
import getDataTransferSchema from "./dataTransferSchema";
import SchemaListPage, {
  SchemaListPageParameter,
} from "components/SchemaListPage";
import { useSurveyList } from "models/hook";
import { SURVEYS } from "../route";
import { resolveRoute } from "pages";
import { surveyData } from "common/models/survey";
import { useConditionSelector } from "hooks/condition/conditionSelector";
import { useAdminPageAuthorization } from "hooks/authAdminPage";
import { useAdminAuthentication } from "hooks/auth";

export const LIST_SURVEYS = SURVEYS.sub("", Surveys);

function Surveys({ clientId }: { clientId: string }) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { conditionSelector, condition, timeCondition } = useConditionSelector({
    schema: getDataTransferSchema({ clientId, isMaster }),
    hideTimeCondition: true,
  });
  const { list, loading, upsert, get } = useSurveyList({
    clientId,
    createdAt: timeCondition,
    condition,
  });
  const parameter: SchemaListPageParameter<surveyData> = useMemo(
    () => ({
      title: "調査一覧",
      edit: {
        path: ({ surveyId }) =>
          resolveRoute("UPDATE_SURVEY", { surveyId, clientId }),
      },
      create: {
        path: () => resolveRoute("ADD_SURVEY", { clientId }) + "?edit=1",
        isDisabled: !isMaster,
      },
      schema,
      download: {
        schema: getDataTransferSchema({ clientId, isMaster }),
        isDisabled: !isMaster,
        filename: `surveys-${clientId}`,
        handler: get,
      },
      upload: {
        schema: getDataTransferSchema({ clientId, isMaster }),
        handler: upsert,
        isDisabled: !isMaster,
      },
    }),
    [clientId]
  );
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
      header={conditionSelector}
    ></SchemaListPage>
  );
}

export default Surveys;

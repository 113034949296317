import React from "react";
import { clientData } from "common/models/client";
import { useStringIntl } from "hooks/intl";

export const MaintenaceMessage = ({
  systemSetting,
}: {
  systemSetting: clientData;
}) => {
  const s = useStringIntl();
  return (
    <div className={"wide-form"}>
      <div className="adjust-width" style={{ marginTop: "10px" }}>
        <p
          style={{
            // fontSize: "24px",
            // fontWeight: "bold",
            whiteSpace: "pre-wrap",
          }}
        >
          {s(systemSetting.maintenanceModeMessage)}
        </p>
      </div>
    </div>
  );
};

export const isMaintenanceMode = (systemSetting: clientData) => {
  return (
    !localStorage.getItem("ignoreMaintenanceMode") &&
    systemSetting.maintenanceMode
  );
};

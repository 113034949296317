import React from "react";
import classNames from "classnames";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  useField,
  requiredValidator,
  BaseSchema,
} from "react-hook-schema-form";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import { useFormatter } from "hooks/intl";
import { InputGroup } from "react-bootstrap";

import "./searchDate.scss";
import { dayjs } from "utils/dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { MonthPicker, relativeMonth } from "./searchDate";

interface MonthSchema extends BaseSchema {
  schemaType: "month";
  required?: boolean;
}

const timeZone = "Asia/Tokyo";

const MonthSchemaComponent: SchemaComponent<MonthSchema> = (
  props: SchemaComponentInternalProps<MonthSchema>
) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <InputGroup className="searchDate">
        <MonthPicker
          className={classNames("form-control", {
            "has-error": fieldState.invalid,
          })}
          dateRange={value || relativeMonth(null, 0)}
          {...registerProps}
          onChange={(v) => {
            registerProps.onChange(v);
          }}
        />
      </InputGroup>
    </FieldWrapper>
  );
};

MonthSchemaComponent.display = DisplayComponent(
  "MonthDisplayComponent",
  (value) => {
    const { formatDate } = useFormatter();
    return value ? formatDate(value) + "" : "";
  }
);

export default MonthSchemaComponent;

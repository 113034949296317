export const joinFieldPath = (
  base: string | null | undefined,
  ext: string | null | undefined
) => {
  if (base && ext) {
    return base + "." + ext;
  } else {
    return base || ext || "";
  }
};

import React, { ReactElement, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

export const ParametricHTML = ({
  value,
  params,
}: {
  value: string;
  params: Record<string, string>;
}) => {
  const applied = ((value || "") as string).replace(
    /{{(.*?)}}/g,
    (_, capture) => {
      return params?.[capture] || "";
    }
  );
  return <div dangerouslySetInnerHTML={{ __html: applied }} />;
};

export const InjectedHTML = ({
  value,
  params,
  elements,
  containerTypes,
}: {
  value: string;
  params: Record<string, string>;
  elements: Record<string, ReactElement>;
  containerTypes?: Record<string, string>;
}) => {
  console.log("###", {
    value,
    params,
    elements,
  });

  const [rawElements, setRawElements] = React.useState<
    [string, HTMLElement | null][]
  >([]);
  const classNameSuffix = useMemo(() => {
    return `-${Math.random().toString(36).slice(2, 9)}`;
  }, []);
  useEffect(() => {
    const list: [string, HTMLElement][] = [];
    for (const key of Object.keys(elements)) {
      for (const element of document.querySelectorAll(
        `.${key}-${classNameSuffix}`
      )) {
        list.push([key, element as HTMLElement]);
      }
    }
    setRawElements(list);
  }, [params, value]);

  return (
    <>
      <ParametricHTML
        value={value}
        params={{
          ...params,
          ...Object.fromEntries(
            Object.keys(elements).map((key) => [
              key,
              `<${
                containerTypes?.[key] || "div"
              } class="${key}-${classNameSuffix}"></${
                containerTypes?.[key] || "div"
              }>`,
            ])
          ),
        }}
      />
      {rawElements.map(([key, rawElement]) => {
        return rawElement && createPortal(elements[key], rawElement);
      })}
    </>
  );
};

import { ObjectSchema } from "schemaComponents";
import { availableLocaleOptions } from "utils/locale";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "クライアント名",
      propertyName: "clientName",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
    {
      title: "ロゴ",
      propertyName: "logoUrl",
      schema: {
        schemaType: "image",
        maxHeight: 30,
      },
    },
    {
      title: "業種",
      propertyName: "industryId",
      schema: {
        schemaType: "externalKey",
        resourcePath: "/industry",
        titleKey: "industryName",
        uiType: "normal",
        required: true,
      },
    },
    {
      title: "プライバシーポリシー",
      propertyName: "privacyPolicy",
      schema: {
        schemaType: "boolean",
      },
    },
    {
      title: "言語",
      propertyName: "availableLocales",
      schema: {
        schemaType: "multipleSelector",
        uiType: "normal",
        options: availableLocaleOptions,
      },
    },
    {
      title: "クライアントID",
      propertyName: "clientId",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
  ],
};
export default schema;

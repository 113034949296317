import React from "react";
import { Schema } from "schemaComponents";
import { availableLocaleOptions } from "utils/locale";

export default ({
  isEditing,
  clientId,
  isMaster,
}: {
  isEditing: boolean;
  clientId?: string;
  isMaster: boolean;
}): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "クライアント名",
        propertyName: "clientName",
        schema: {
          schemaType: "text",
          required: true,
        },
      },
      {
        title: "業種",
        propertyName: "industryId",
        schema: {
          schemaType: "externalKey",
          resourcePath: "/industry",
          titleKey: "industryName",
          uiType: "normal",
          required: true,
        },
      },
      {
        title: "詳細説明",
        propertyName: "description",
        schema: {
          schemaType: "multilineText",
        },
      },
      ...(isMaster
        ? [
            {
              title: "連続抽選無効化通知テキスト",
              propertyName: "invalidationNotificationText",
              schema: {
                schemaType: "textIntl",
              },
            },
          ]
        : []),
      {
        title: "ロゴ",
        propertyName: "logoUrl",
        schema: {
          schemaType: "image",
          rootPath: clientId ? `clients/${clientId}` : "images",
          maxHeight: 250,
        },
      },
      {
        title: "言語",
        propertyName: "availableLocales",
        schema: {
          schemaType: "multipleSelector",
          uiType: "normal",
          options: availableLocaleOptions,
        },
      },
      ...(isMaster
        ? [
            {
              title: "業種共通レポートを隠す",
              propertyName: "hideIndustryReport",
              schema: {
                schemaType: "boolean",
              },
            },
          ]
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([] as any[])),
      ...(isMaster
        ? [
            {
              title: "設問",
              propertyName: "questions",
              schema: {
                schemaType: "json",
              },
            },
          ]
        : []),
      ...(isMaster
        ? [
            {
              title: "設問(CSV)",
              propertyName: "questions",
              propertyId: "questions_csv",
              schema: {
                schemaType: "enquete",
              },
            },
          ]
        : []),
      {
        title: "プライバシーポリシー",
        propertyName: "privacyPolicy",
        schema: {
          schemaType: "multilineTextIntl",
        },
      },
    ],
  };
};

import { useEffect } from "react";

export const useDomEvent = (
  element: EventTarget,
  eventNames: string[],
  handler: (event: Event) => void,
  deps: React.DependencyList = []
) => {
  useEffect(() => {
    for (const eventName of eventNames) {
      element.addEventListener(eventName, handler);
    }
    return () => {
      for (const eventName of eventNames) {
        element.removeEventListener(eventName, handler);
      }
    };
  }, deps);
};

import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { INDUSTRY, INDUSTRIES } from "../route";
import { resolveRoute } from "pages";
import { useIndustryDoc } from "models/hook";
import { useAdminPageAuthorization } from "hooks/authAdminPage";

export const UPDATE_INDUSTRY = INDUSTRY.sub("/edit", Industry);
export const ADD_INDUSTRY = INDUSTRIES.sub("/edit/new", Industry);

function Industry({ industryId }: { industryId?: string }) {
  useAdminPageAuthorization();
  const { data, loading, set, remove, isEditing, ref } = useIndustryDoc({
    industryId,
  });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "業種詳細" : "業種新規作成",
    schema: getSchema({ isEditing, industryId }),
    edit: {
      title: `${isEditing ? "保存" : "作成"}`,
      confirm: `${isEditing ? "保存" : "作成"}しますか？`,
      handler: set,
    },
    remove: isEditing ? { handler: remove } : undefined,
    backPath: resolveRoute("LIST_INDUSTRIES"),
    header: {
      isStacked: true,
    },
    clipboard: {
      objectType: "industry",
      retainKeys: ["industryId"],
    },
  };
  console.log("Industry", { isEditing, ref, industryId });
  return (
    <SchemaFormPage
      data={data}
      parameter={parameter}
      loading={isEditing && loading}
      editModeEnabled={true}
    />
  );
}

export default Industry;

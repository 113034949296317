import classNames from "classnames";
import { useModalForm } from "hooks/dialogs";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import {
  SchemaComponentInternalProps,
  SchemaComponent,
  joinFieldPath,
  BaseSchema,
  useValue,
  useField,
} from "react-hook-schema-form";

import { Schema, Field } from "..";
import { StringIntl } from "utils/locale";
import { useFormatter, useStringIntl } from "hooks/intl";

export interface PropertyParameter {
  propertyName?: string;
  propertyId?: string;
  title: StringIntl;
  schema: Schema;
  noLabel?: boolean;
  disabled?: boolean;
  tags?: string[];
  tooltip?: string;
}

interface ObjectSchema extends BaseSchema {
  schemaType: "object";
  properties: PropertyParameter[];
  uiType?: "normal" | "dialog";
  outputOptions?: {
    useRealFields?: boolean;
  };
}

const Property = ({
  fieldPath,
  readOnly,
  control,
  property,
}: SchemaComponentInternalProps<BaseSchema> & {
  property: PropertyParameter;
}) => {
  const { title, propertyName, schema, noLabel, tooltip } = property;
  const s = useStringIntl();
  const { formatMessage } = useFormatter();
  const content = (
    <Field
      control={control}
      fieldPath={joinFieldPath(fieldPath, propertyName)}
      readOnly={readOnly}
      schema={schema}
      label={s(title)}
    />
  );
  if (noLabel) {
    return content;
  } else
    return (
      <div className="field-pair" key={0}>
        <div className="field-label">
          <label className="label">
            <span
              style={{
                verticalAlign: "middle",
              }}
            >
              {s(title)}
            </span>
            {schema.required && !readOnly && !schema.readOnly && (
              <span
                style={{
                  verticalAlign: "middle",
                  marginLeft: "0.2em",
                  border: "1px solid #999",
                  padding: "0em 0.3em",
                  color: "#999",
                  fontSize: "0.8em",
                  borderRadius: "3px",
                  whiteSpace: "nowrap",
                }}
              >
                {formatMessage("Field.Required")}
              </span>
            )}
            {tooltip && (
              <>
                {" "}
                <Button
                  variant="secondary"
                  data-tooltip-id="tooltip"
                  data-tooltip-html={
                    true && `<div class="tooltip-content">${tooltip}</div>`
                  }
                  style={{
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    padding: "0",
                  }}
                >
                  <span className="mdi mdi-information-variant size10"></span>
                </Button>
              </>
            )}
          </label>
        </div>
        <div className="field-content">{content}</div>
      </div>
    );
};

const ObjectSchemaComponent: SchemaComponent<ObjectSchema> = ({
  fieldPath,
  readOnly,
  control,
  schema,
  label,
}: SchemaComponentInternalProps<ObjectSchema>) => {
  const { properties, uiType } = schema;

  if (uiType === "dialog") {
    const modalForm = useModalForm();
    const { value, registerProps } = useField(
      { fieldPath, control, schema, label },
      []
    );
    return (
      <InputGroup>
        <div style={{ flex: "1", alignSelf: "center" }}>
          <Field
            schema={properties[0].schema}
            fieldPath={joinFieldPath(fieldPath, properties[0].propertyName)}
            control={control}
            label={label}
            readOnly={true}
          />
        </div>

        <Button
          variant="outline-secondary"
          onClick={async () => {
            const newValue = await modalForm({
              params: {
                schema: { ...schema, uiType: undefined },
                title: label,
              },
              value,
            });
            if (newValue) {
              registerProps.onChange(newValue);
            }
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </InputGroup>
    );
  }
  return (
    <>
      {properties
        ?.filter(({ disabled }) => !disabled)
        .map((property, index) => (
          <Property
            schema={property.schema}
            control={control}
            readOnly={readOnly}
            fieldPath={fieldPath}
            key={String(property.propertyId || property.propertyName)}
            property={property}
          />
        ))}
    </>
  );
};

export default ObjectSchemaComponent;

import React from "react";

import { useLocation, Link } from "react-router-dom";
import { useClientDoc, useShopDoc } from "models/hook";
import { useAdminAuthentication } from "hooks/auth";
import {
  Alert,
  Container,
  Dropdown,
  Nav,
  NavDropdown,
  Navbar,
  Spinner,
} from "react-bootstrap";
import ErrorMessage from "components/ErrorMessage";
import { resolveRoute } from "pages";
import { useTracker } from "utils/analytics";
import { Image } from "schemaComponents/components/media";
import { shopData } from "common/models/shop";
import { useModalForm } from "hooks/dialogs";
import { availableLocaleOptions, Locale, messages } from "utils/locale";
import { useFormatter } from "hooks/intl";
import { ConsumerDropdownLocaleSelector } from "hooks/localeSelector";

import "./style.scss";
import ErrorBoundary from "components/ErrorBoundary";
import LocaleProvider from "components/LocaleProvider";

type Props = React.PropsWithChildren<{
  shopData?: Partial<shopData> | null;
  shopId?: string;
  skipAuthentication?: boolean;
}>;

const ConsumerLayout: React.FC<Props> = ({
  children,
  shopData: injectedShopData,
  shopId,
  skipAuthentication,
}: Props) => {
  useTracker();
  const { user, loading, error } = useAdminAuthentication();
  const { formatString } = useFormatter();

  const { data: shopData } =
    injectedShopData !== undefined
      ? { data: injectedShopData || undefined }
      : useShopDoc({ shopId });
  const { data: clientData } = useClientDoc({ clientId: shopData?.clientId });
  if (user?.uid || skipAuthentication) {
    //
  } else if (!error && loading) {
    return (
      <div style={{ padding: "20px" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return <Timeout error={error} shopId={shopId} />;
  }

  const { logoURL: logoUrl, showShopNameInHeader } = shopData || {};
  const location = useLocation();
  return (
    <div id="consumer-root">
      <div className="consumer-container consumer-header">
        <div className="adjust-width" style={{ display: "flex" }}>
          <div style={{ flex: "1" }}>
            {logoUrl && (
              <Image
                src={logoUrl}
                style={{
                  height: "40px",
                  marginRight: "10px",
                  verticalAlign: "middle",
                }}
              />
            )}
            {showShopNameInHeader && (
              <span
                style={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                  marginLeft: 5,
                  lineHeight: "40px",
                }}
              >
                {formatString(shopData?.shopName)}
              </span>
            )}
          </div>
          {/* {intl.formatMessage({ id: "Language" })} */}
          {(clientData?.availableLocales?.length || 0) > 1 && (
            <ConsumerDropdownLocaleSelector
              locales={clientData?.availableLocales}
            >
              <i
                className="mdi mdi-earth"
                style={{ fontSize: "24px", cursor: "pointer" }}
              ></i>
            </ConsumerDropdownLocaleSelector>
          )}
        </div>
      </div>
      {/* <div style={{ height: "50px" }}></div> */}
      <div className="consumer-container">
        <ErrorBoundary recovoryKey={location.key}>{children}</ErrorBoundary>
      </div>
    </div>
  );
};
export default ConsumerLayout;

const Timeout = ({ error, shopId }: { error?: Error; shopId?: string }) => {
  const { formatMessage } = useFormatter();
  return (
    <Alert variant="danger">
      <div>{formatMessage("Error.Timeout")}</div>
      {error && (
        <div>
          <ErrorMessage error={error} />
        </div>
      )}
      {shopId && (
        <div>
          {formatMessage("Error.RetryFromStart")}
          <br />
          <Link to={resolveRoute("CONSUMER_ENTRY", { shopId })}>
            {formatMessage("Word.Link")}
          </Link>
        </div>
      )}
    </Alert>
  );
};

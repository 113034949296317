import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useClientDoc, useSurveyDoc } from "models/hook";
import { SURVEY, SURVEYS } from "../route";
import { resolveRoute } from "pages";
import { PreviewComponent } from "./preview";
import { AdminLocaleSelector } from "hooks/localeSelector";
import { useAdminPageAuthorization } from "hooks/authAdminPage";
import { useAdminAuthentication } from "hooks/auth";

export const UPDATE_SURVEY = SURVEY.sub("", Survey);
export const ADD_SURVEY = SURVEYS.sub("/new", Survey);

function Survey({
  surveyId,
  clientId,
}: {
  surveyId?: string;
  clientId: string;
}) {
  useAdminPageAuthorization(clientId);
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { data, loading, set, remove, isEditing } = useSurveyDoc({
    surveyId,
    clientId,
  });
  const { data: clientData } = useClientDoc({ clientId });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "調査詳細" : "調査新規作成",
    schema: getSchema({ isEditing, clientId, isMaster }),
    preview: {
      title: "プレビュー",
      component: ({ control }) => {
        return <PreviewComponent control={control} clientId={clientId} />;
      },
    },
    edit: {
      title: `${isEditing ? "保存" : "保存"}`,
      confirm: `${isEditing ? "保存" : "保存"}しますか？`,
      handler: set,
      isDisabled: !isMaster,
    },
    remove: isEditing ? { handler: remove } : undefined,
    backPath: resolveRoute("LIST_SURVEYS", { clientId }),
    clipboard: {
      objectType: "survey",
      retainKeys: ["clientId", "surveyId"],
      isDisabled: !isMaster,
    },
  };
  return (
    <SchemaFormPage
      data={data}
      parameter={parameter}
      loading={isEditing && loading}
      header={<AdminLocaleSelector locales={clientData?.availableLocales} />}
      editModeEnabled={true}
    />
  );
}

export default Survey;

import React from "react";
import { formatDist, formatNumber, formatPercent } from "../statSchemaUtil";
import { StatConfig } from "./type";

export const dist = ({ isClientData }: { isClientData: boolean }): StatConfig =>
  isClientData
    ? {
        action: "dist",
        statSchemas: [
          {
            value: "client-freq",
            title: "全社",
            graph: true,
            table: false,
            graphColor: "#14AFF4",
          },
          {
            value: "client-freq-table",
            title: "全社",
            multiple: {
              source: ["client-freq"],
              element: (values) => {
                return formatPercent(values["client-freq"]);
              },
            },
            margeRight: true,
          },
          {
            value: "client-count-table",
            title: "(件数)",
            multiple: {
              source: ["client-count"],
              element: (values) => {
                return <span>{formatNumber(values["client-count"], 0)}</span>;
              },
            },
          },
        ],
      }
    : {
        action: "dist",
        statSchemas: [
          {
            value: "shop-freq",
            title: "事業所",
            graph: true,
            table: false,
            graphColor: "#1C6F71",
          },
          {
            value: "client-freq",
            title: "全社",
            graph: true,
            table: false,
            graphColor: "#14AFF4",
          },
          {
            value: "shop-freq-table",
            title: "事業所",
            multiple: {
              source: ["shop-freq"],
              element: (values) => {
                return formatPercent(values["shop-freq"]);
              },
            },
            margeRight: true,
          },
          {
            value: "shop-count-table",
            title: "(件数)",
            multiple: {
              source: ["shop-count"],
              element: (values) => {
                return <span>{formatNumber(values["shop-count"], 0)}</span>;
              },
            },
          },
          {
            value: "client-freq-table",
            title: "全社",
            multiple: {
              source: ["client-freq"],
              element: (values) => {
                return formatPercent(values["client-freq"]);
              },
            },
            margeRight: true,
          },
          {
            value: "client-count-table",
            title: "(件数)",
            multiple: {
              source: ["client-count"],
              element: (values) => {
                return <span>{formatNumber(values["client-count"], 0)}</span>;
              },
            },
          },
        ],
      };
